import React from "react";
import PageLayout from "../../components/Patricia/PageLayout/PageLayout";
import PatriciaTopNav from "../../components/Patricia/PatriciaNav/PatriciaTopNav";
import settings from "../../app/settings";

function TermsAndConditions() {

    return (
        <div className='uk-background-muted uk-animation-fade patricia-hebrew-font uk-height-viewport uk-margin-left uk-margin-right' uk-height-viewport="" dir="rtl">
        <PatriciaTopNav logo={settings.appLogo} dir="rtl"/>
            <div dir={'rtl'}>
                <h4>1. כללי </h4>
                <p>
                    ברוכים הבאים לאתר Patricia כפי שניתן לצפות ולהשתמש בו מכל מכשיר קצה לרבות באמצעות אפליקציית החברה. (להלן: "האתר") המופעל על ידי חברת ויז'ואל אסטייט בע"מ (להלן: "החברה"). האתר כולל  פלטפורמה למכירה מקוונת של מוצרים בתחום עיצוב הבית ואספקת מוצרים לבית, המופעלת על ידי החברה באתר החברה ו/או בכל תצורת מדיה אחרת (להלן: "הפלטפורמה"). השימוש באתר, בתכניו ובשירותים המוצעים בו כפופים לתנאי השימוש המפורטים להלן (להלן: "תנאי השימוש"). החברה שומרת לעצמה את הזכות לשנות או לעדכן את תנאי השימוש מעת לעת, לפי שיקול דעתה הבלעדי. תוקפו של שינוי או עדכון יחל מרגע פרסומו באתר. בעשותך שימוש באתר הנך מאשרת ומצהירה כי הנך מעל גיל 18 וכי הפרטים שמסרת הינם פרטים אישיים שלך או של צד שלישי שיש לך את הסמכות למסור את פרטיו. תנאי השימוש יחולו בכל מקרה של שימוש באתר באמצעות כל סוג מחשב או כלי תקשורת אחר (לדוגמא טלפון סלולארי או מחשב נייד וכו'). חלקים מהאתר ומתנאי השימוש מנוסחים בלשון זכר מטעמי נוחות בלבד, אך כל האמור בהם מופנה לכל המינים.
                </p>
                <h4>2. שירותים הכרוכים בהרשמה </h4>
                <p>
                    2.1. קבלת גישה לחלק מהשירותים ו/או התכנים המוצעים באתר כרוכה בהרשמה לאתר ו/או לשירות.
                </p>
                <p>
                    2.2. במסגרת הרשמה כאמור תתבקש לספק לחברה מספר פרטים אישיים אודותיך, ובכלל זה, שם מלא, כתובת דואר אלקטרוני, מען, מספר טלפון וכד' (להלן יחד: "המידע"). חלק מהמידע שתתבקש למסור הינו פרטי חובה – כלומר לא ניתן להירשם מבלי למסור פרטים אלו. פרטי החובה יסומנו באופן מפורש.
                </p>
                <p>
                    2.3. בנוסף לפרטים כאמור, ברישומך לחלק מהשירותים ו/או התכנים המוצעים באתר תתבקש לבחור שם משתמש ו/או סיסמה. פרטים אלו ישמשו לזיהויך לצורך קבלת גישה לשירותים ו/או התכנים כאמור. הנך אחראי באופן מלא לשמירת סודיות שם משתמש ו/או סיסמה ומתחייב להודיע לחברה באופן מיידי על כל שימוש שאינו מורשה בשם משתמש ו/או הסיסמה שלך ו/או על כל הפרת סודיות אחרת. לחברה לא תהא כל אחריות לשימוש בלתי מורשה של כל צד ג' באמצעות הנתונים המופיעים בטופס הרישום. מבלי לגרוע מהאמור מובהר כי לחברה לא תהא כל אחריות לשימוש בלתי מורשה בחשבון המשתמש שלך.
                </p>
                <p>
                    2.4. כל הפעולות המתבצעות באמצעות חשבון המשתמש שלך (ובכלל זה ביצוע עסקאות) ייחשבו כפעולות שמבוצעות על-ידך, בין שפעולות אלה מתבצעות בהסכמתך או בידיעתך ובין שבלעדיהן.
                </p>
                <p>
                    2.5. הנך מתחייב לספק מידע אמיתי, מדויק, נכון ומלא אודותיך, כפי שמתבקש במהלך ההרשמה לאתר ו/או לשירות. כמו כן, הנך מתחייב לעדכן את המידע כפי שנמסר על ידך במהלך ההרשמה במטרה לשמור על נכונותו ודיוקו.
                </p>
                <p>
                    2.6. החברה שומרת לעצמה את הזכות להשעות, לבטל או לסרב לספק את השירות הניתן לך על ידה באתר, בכל מקרה בו החברה תהא סבורה, על פי שיקול דעתה המוחלט והבלעדי, כי המידע שמסרת אינו אמיתי, מעודכן, שלם או נכון בזמן הרישום או לאחריו, ו/או כי הפרת תנאי שימוש אלה ו/או כי קיים חשש לכך שביצעת מעשה עבירה ו/או מעשה מרמה או שלא כדין מכל בחינה שהיא, והכול עם או בלי הודעה מוקדמת.
                </p>
                <h4>3. שירותים ומוצרים הכרוכים בתשלום </h4>
                <p>
                    3.1. חלק מהשירותים והמוצרים המוצעים באתר ניתנים בחינם וחלקם כרוכים בתשלום. החברה תציין באתר אילו כרוכים בתשלום ואת מחירם של שירותים ומוצרים כאמור. המחירים המופיעים באתר כוללים מע"מ על פי דין. החברה רשאית לשנות את מחירם של השירותים והמוצרים המוצעים באתר, מעת לעת, על פי שיקול דעתה הבלעדי.
                </p>
                <p>
                    3.2. השלמת עסקה המבוצעת דרך האתר (ואספקת המוצרים או מתן השירותים מכוחה) כפופה לקליטת ההזמנה, אישור ההזמנה על ידי החברה או בית עסק או נותן השירותים הרלוונטי (לפי העניין) ואישור ביצוע העסקה על ידי חברת האשראי או מפעיל שירותי התשלום באמצעותו שילמת.
                </p>
                <p>
                    3.3. התשלום בגין השירותים והמוצרים הכרוכים בתשלום יבוצע באמצעות אמצעי התשלום שיפורטו באתר מעת לעת. החברה שומרת לעצמה את הזכות להפסיק את השימוש באמצעי תשלום כלשהו באתר, להתיר שימוש באמצעי תשלום נוספים ולהחיל הסדרי תשלום שונים על סוגי אמצעי התשלום אותם תכבד.
                </p>
                <p>
                    3.3 קבלת גישה לחלק מהשירותים ו/או התכנים המוצעים באתר כרוכה בהרשמה לאתר ו/או לשירות.
                </p>
                <p>
                    3.4. במקרה של תשלום באמצעות כרטיס אשראי יהא עליך למסור את מספר כרטיס האשראי, תעודת הזהות של בעל הכרטיס, סוג הכרטיס ואת תוקפו. הנך מצהיר כי הפרטים שמסרת בעת ביצוע הרכישה הנם נכונים ובתוקף וכן כי יש לך את הסמכות לחייב כרטיס האשראי באמצעותו בוצע התשלום על ידך. בכדי לוודא שפרטי כרטיס האשראי נמסרו בהסכמתך וידיעתך, החברה עשויה לאמת את המידע האישי אשר סופק במסגרת הליך הרישום כנגד מאגרי מידע של צדדים שלישיים. בעת ביצוע בדיקות אימות אלו, פרטים אישיים שסופקו על ידך יימסרו לצדדים שלישיים אשר עשויים לשמור תיעוד של מידע זה.
                </p>
                <p>
                    3.5. התשלום באמצעי תשלום שונים עשוי להיות כרוך בעמלות שונות שייגבו ממך על-ידי חברת האשראי או מפעיל שירותי התשלום באמצעותו שילמת ובהתאם לתנאי השירות של אותו חברה או מפעיל. הנך נושאת באחריות הבלעדית לשאת בעמלות אלה, ככל הנדרש.
                </p>
                <p>
                    3.6. החברה תהא רשאית לגבות ממך בכל דרך חוקית כל חיוב שלא כובד באמצעי התשלום באמצעותו שילמת, בתוספת דמי טיפול שעשויים להיות מוטלים על החוב שלך.
                </p>
                <p>
                     3.7. החברה רשאית שלא לאשר ו/או למנוע ביצוע של תשלום, על פי שיקול דעתה הבלעדי, לרבות אך לא רק, במקרה שבו פרטי הרישום היו שגויים או לא מלאים או במקרה שהופרו תנאי השימוש.
                </p>
                <p>
                    3.8. החברה ו/או מי מטעמה אינה ולא תהא אחראית לכל בעיה ו/או תקלה ו/או נזק שייגרם לך או למי מטעמך בשל ביצוע ו/או גביית התשלומים. בכל בעיה ו/או תקלה ו/או טענה בקשר עם האמור, עליך לפנות ישירות לשירות הלקוחות של חברת האשראי או מפעיל שירותי התשלום באמצעותו שילמת.
                </p>
                <p>
                    3.9. מובהר כי אין בתשלום דרך האתר כדי להטיל על החברה אחריות כלשהי בקשר עם עסקה וזו מבוצעת ישירות בינך לבין בית עסק או נותן שירותים הרלוונטי.
                </p>
                <h4>4. מועדון לקוחות  </h4>
                <p>
                    ייתכן שהחברה תפעיל מועדון לקוחות, אשר ייועד ללקוחות רשומים ו/או משלמים העומדים בתנאי ההצטרפות למועדון הלקוחות כפי שיפורסמו באתר מעת לעת. תנאי שימוש אלו ותנאי חברות במועדון הלקוחות משלימים זה לזה, ואין באחד כדי לגרוע מהשני. החברה תהא רשאית להציע את חלק מהשירותים הניתנים באתר לחברי מועדון לקוחות בלבד וכן להציע לחברי המועדון הלקוחות הטבות ו/או מבצעים ייחודיים, והכל לפי שיקול דעתה הבלעדי.
                </p>
                <h4>5. רכישת מוצרים ו/או שירותים המוצעים למכירה על ידי צדדים שלישיים  </h4>
                <p>
                    5.1. במסגרת האתר מפעילה החברה פלטפורמה המאפשרת לבתי עסק ונותני שירותים שונים לפרסם ולהציע את מוצריהם ו/או שירותיהם ולמשתמשי האתר לרכוש את המוצרים ו/או השירותים כאמור באמצעות האתר.
                </p>
                <p>
                    5.2. כל עסקה שתבצע דרך ו/או באמצעות ו/או מכוח הטבה או שירות הניתן במסגרת האתר תבוצע ישירות בינך לבין בית העסק או נותן השירותים המוכר את המוצר או המספק את השירות נשוא העסקה (לפי העניין); החברה אינה ולא תיחשב צד לעסקה בינך לבין בית העסק ו/או נותן השירות; כמו כן, החברה לא תישא בכל אחריות, ישירה או עקיפה, בקשר עם העסקה.
                </p>
                <p>
                    5.3. החברה מציגה באתר את פרטיהם, תמונתם ומחירם של השירותים והמוצרים כפי שהתקבלו מבית העסק ו/או נותני השירותים ו/או המפרסמים השונים. החברה לא תהא אחראית לפערים, אי-דיוקים ו/או טעויות אשר יהיו בין פרטיהם, תמונתם ומחירם של השירותים והמוצרים המוצגים באתר לבין מצב הדברים בפועל ולא תחוב בכל נזק ו/או חסרון כיס שיגרם לך או לצד שלישי כתוצאה מהסתמכות על המוצג באתר.
                </p>
                <p>
                    5.4. ביטול העסקה ייעשה מול בית העסק או נותן השירות בהתאם למדיניות הביטולים החלה אצלו ומפורסמת לצד פרסום המוצר ו/או השירות ובכפוף להוראות הדין החלות (לרבות הוראות חוק הגנת הצרכן).
                </p>
                <p>
                    5.5. האחריות המלאה והבלעדית בקשר עם המוצרים ו/או השירותים המוצעים באתר, לרבות פרסום המוצרים ו/או השירותים המוצעים באתר ופרטיהם, תנאי העסקה וביטולה, אספקת המוצרים ו/או השירותים, טיב ואיכות המוצרים ו/או השירותים ותנאי האחריות למוצרים ו/או השירותים חלה אך ורק על בית העסק או נותן השירות, בהתאם לכל דין ובהתאם למקובל אצל בית העסק או נותן השירות. בכל מקרה, החברה לא תישא בכל אחריות, מכל סוג שהוא, עקיפה או ישירה, למוצרים או השירותים נשוא העסקה כאמור, ולא תיקח חלק במחלוקות אם וכאשר יתעוררו בין הצדדים השונים בעסקה.
                </p>
                <p>
                    5.6. החברה מציגה באתר את מחיריהם של מוצרים ושירותים שונים, כפי שהתקבלו מבתי העסק, נותני השירותים ו/או המפרסמים השונים וכן ממקורות שונים, לפי שיקול דעתה הבלעדי והמוחלט של החברה ("מחירים מומלצים"). החברה אינה מתחייבת לכך שהמחירים משקפים בהכרח את מחיר השוק המתאים לכל סוג וסוג של שירות או מוצר, וייתכנו שירותים ומוצרים מקבילים שמחירי השוק שלהם נמוכים או גבוהים יותר מהמחירים המוצגים באתר.
                </p>
                <h4>6. חוות דעת</h4>
                <p>
                    6.1. באתר תינתן אפשרות לפרסם חוות דעת ביחס לשירותים ו/או מוצרים וכן ביחס לבתי עסק ו/או נותני שירותים המתפרסמים באתר (להלן: "חוות הדעת"). חוות הדעת יכול שייכללו דירוג או ציון ביחס לקריטריונים מוגדרים מראש וכן מלל חופשי.
                </p>
                <p>
                    6.2. האפשרות לפרסם חוות דעת פתוחה לשימושם של גולשים רשומים בלבד. חלק מהפרטים אשר נמסרו על-ידך בעת הרישום לאתר עשויים להופיע בעת פרסום חוות הדעת על ידך באתר.
                </p>
                <p>
                    6.3. חוות הדעת יהיו חשופות לכלל גולשי האתר ו/או רשת האינטרנט. החברה לא תישא כלפיך באחריות בקשר עם התגובות אשר יתקבלו ביחס לפרסום חוות הדעת ו/או לכל תוצאה ו/או נזק ו/או חסרון כיס שתגרם לך או לרכושך בעקבות התגובות הנ"ל. החברה לא תישא כלפיך באחריות לשימוש אשר ייעשה על ידי צד שלישי כלשהו בתכנים אשר פורסמו על-ידך במסגרת חוות הדעת.
                </p>
                <p>
                    6.4. בפרסום חוות דעת הנך מתבקשת להתייחס להתרשמותך וחוות דעתך הסובייקטיבית בלבד. אין לעשות שימוש בחוות הדעת לכל מטרה אחרת שאינה הצגת התרשמותך וחוות דעתך הסובייקטיבית. כל מטרה אחרת, לרבות למטרות יחסי ציבור, אסורה בהחלט.
                </p>
                <p>
                    6.5. דע כי נציג מטעם בית העסק נשוא חוות דעתך יהיה רשאי להגיב לחוות הדעת שביקשת לפרסם. בנוסף, החברה רשאית לדרוש ממך אסמכתאות וכן ראיות בכתב המוכיחות כי אכן קיבלת את השירות ו/או המוצר נשוא חוות דעתך מבית העסק ו/או נותן השירות ולחברה יהא שיקול הדעת הבלעדי וללא כל מתן הודעה מוקדמת, להתיר את פרסום חוות הדעת ו/או התגובות או להסירן.
                </p>
                <p>
                    6.6. כמו כן, החברה עשויה לבצע מעת לעת, ועל פי שיקול דעתה הבלעדי, בדיקה של אמינות חוות הדעת באמצעות סקרים טלפוניים ו/או אחרים המופנים לגולשים, וכן באמצעות בדיקות פנימיות של החברה (להלן: "סקרי אמינות"). החברה תהא רשאית, בכפוף לשיקול דעתה, לפרסם את תוצאות סקרי האמינות. בהסכימך לתנאי שימוש אלו הנך מסכים כי החברה תיצור עמך קשר (באמצעות דוא"ל, בהודעת טקסט לטלפון הנייד (SMS/MMS) או בכל אופן אחר) לצורך ביצוע סקרי אמינות. יובהר כי מקורם של סקרי האמינות הינו במשתמשי האתר ולפיכך אין החברה אחראית לדיוקם ו/או לנכונותם וההסתמכות עליהם הינה באחריותך הבלעדית.
                </p>
                <p>
                    6.7. החברה רשאית לבטל את חוות הדעת ו/או התגובות, לשנות, מעת לעת, את מבנם, מראם וזמינותם של השירותים והתכנים שבהם, וכן להחליט שלא לפרסם חוות דעת ו/או תגובה וכן להסיר מפרסום חוות דעת ו/או תגובה וכן לעדכן או לערוך חוות דעת ו/או תגובה, והכול ללא הודעה מוקדמת ועל-פי שיקול דעתה הבלעדי והמוחלט, לרבות, אך לא רק, במקרים בהם החברה סבורה כי חוות הדעת איננה אמינה.
                </p>
                <p>
                    6.8. ייתכן שהתכנים אשר מתפרסמים בחוות הדעת אינם אמיתיים ו/או מדויקים ו/או נכונים, במלואם או בחלקם. החברה אינה אחראית לכל נזק, הפסד, חסרון כיס או הוצאה שייגרמו לך אם תפעל על בסיס המידע, התכנים או המסרים המפורסמים בחוות הדעת וכל הסתמכות עליהם הינה באחריותך הבלעדית.
                </p>
                <h4>7. קישורים ומידע פרסומי</h4>
                <p>
                    7.1. במסגרת הביקור באתר יכול שיוצג בפניך מידע מסחרי-פרסומי. מקורו של מידע כאמור יכול שיהיה מהחברה ו/או מצדדים שלישיים. במקרים בהם מקורו של מידע זה מצדדים שלישיים, החברה אינה יכולה לערוב לאמינות ולדיוק המידע, לפיכך, בעשיית שימוש באתר הנך מוותר על כל טענה, דרישה או תביעה בגין אובדן, הפסד או נזקים הנובעים מהסתמכות או שימוש במידע אשר מקורו מצדדים שלישיים.
                </p>
                <p>
                    7.2. ככל שיש באתר קישוריות (לינקים) לאתרים אחרים שאינם מנוהלים ומופעלים על-ידי החברה, לא תישא החברה באחריות לתוכנם של אותם אתרים ולמידע המתפרסם בהם, לרבות שלמותו, דיוקו, עדכניותו ונכונותו, או לכל פרט אחר הקשור בהם. החברה לא תהיה אחראית לכל נזק ישיר או עקיף, כספי או אחר, שייגרם לך כתוצאה משימוש או הסתמכות על תוכנם של האתרים שתגיע אליהם באמצעות האתר.
                </p>
                <h4>8. הגבלת אחריות של החברה ומי מטעמה</h4>
                <p>
                    8.1. שימושך באתר, בשירותים ובתכנים המופיעים בו והסתמכותך עליהם הם על אחריותך בלבד. השימוש באתר, בשירותים ובתכנים בו הינו כמות שהוא (AS IS) ועל בסיס זמינות (AS AVAILABLE) ולא תהיה לך כל טענה, תביעה או דרישה כלפי החברה ו/או מי מטעמה בגין תכונות האתר, השירותים והתכנים, נכונות התכנים, שלמותם, עדכניותם ודיוקם, יכולותיו של האתר, מגבלות או התאמת האתר והתכנים לצרכיך, לרבות בגין הפסקתם (זמנית או קבועה), אי שמירה ו/או אובדן של תכנים, לרבות שנשלחו אליך או על-ידך, כמו גם פגיעה או שיבוש בשלמות או בתוכן של תכנים שקיבלת או שיגרת ו/או בקשר עם השירותים אשר יסופקו באמצעות האתר, זמינותם, התאמתם לצרכיך ואיכותם.
                </p>
                <p>
                    8.2. החברה אינה אחראית ולא תישא בכל אחריות ביחס לתכנים ו/או המידע שיפורסמו באתר וכן לזמינות השירותים הנמצאים באתר ו/או באתרים מקושרים (לרבות בנוגע לתכנים הנמסרים לפרסום) ובכל מכשיר קצה בהם הם מופיעים, תוכנם, מהימנותם, דיוקם, אמינותם והשפעתם על המחשבים של הגולשים באתר.
                </p>
                <p>
                    8.3. על אף האמור בהסכם זה, ובכפוף לדין החל, החברה לא תהא אחראית ולא תישא בשום אחריות, בשום מקרה ומכוח כל עילה משפטית שהיא, בין אם מכוח עילה חוזית, נזיקית, או כל עילה אחרת, כלפיך, בגין כל נזק לרכוש ו/או בגין כל פיצוי או נזק ישיר, עקיף, מיוחד, עונשי או תוצאתי (special, incidental or consequential) מכל סוג אשר נגרם בקשר עם ו/או כתוצאה מהשימוש בפלטפורמה, לרבות רכישות שירותים ומוצרים.
                </p>
                <p>
                    8.4. סך האחריות הכוללת של  החברה בגין כל התביעות, נזקים, עלויות והפסדים הנובעים מכוח הסכם זה, לא תעלה, בשום נסיבות שהן, על סך התשלומים ששולמו בפועל (אם בכלל) על ידך לחברה,  במהלך ששת (6) החודשים שקדמו למועד העלאת תביעה כאמור.
                </p>
                <p>
                    8.5. החברה איננה מתחייבת כי כל הקישורים שימצאו באתר יהיו תקינים ויובילו אותך לאתר אינטרנט פעיל. החברה רשאית לסלק מהאתר קישורים שנכללו בו בעבר, להוסיף קישורים חדשים או להימנע מהוספת קישורים חדשים - הכול לפי שיקול דעתה הבלעדי והמוחלט. מובהר ומוסכם בזה, כי הצבת מודעות, קישוריות ומידע מסחרי באתר לא תתפרש כהצעה של האתר להשתמש, כהבעת תמיכה, עידוד, הסכמה או מתן חסות של האתר, במפורש או מכללא לתכנים ו/או לשירותים המוצעים על ידי בתי העסק ונותני השירותים השונים באתר.
                </p>
                <p>
                    8.6. החברה אינה מתחייבת שהאתר ו/או השירותים והמוצרים בו יהיו זמינים ונגישים בכל עת. החברה אינה מתחייבת כי מכלול השירותים והמוצרים המוצעים באתר או שיוצעו בו יוסיפו להיות מוצעים, ויתכן ושירות כזה או אחר יישלל ללא מתן הודעה מוקדמת.
                </p>
                <p>
                     8.7. החברה רשאית לסגור את האתר ולשנות מעת לעת את מבנהו, מראהו וזמינותם של השירותים, המוצרים והתכנים הניתנים בו וזאת ללא צורך להודיע לך על כך מראש. לא תהיה לך כל טענה, תביעה או דרישה כלפי החברה בקשר לכך.
                </p>
                <h4>9. הגנה על הפרטיות</h4>
                <p>
                    על המידע שיימסר על ידך בעת ההרשמה לאתר ו/או לאיזה משירותים בו וכן על המידע שייאסף על ידי החברה אגב שימושך באתר ו/או באיזה מהשימושים בו חלות הוראות חוק הגנת הפרטיות, התשמ"א – 1981. שימוש בפרטים כאמור יהיה בהתאם להוראות מדיניות הפרטיות של האתר המצויה תחת לשונית "מדיניות פרטיות" ומהווה חלק בלתי נפרד מתנאי שימוש אלו (להלן: "מדיניות הפרטיות"). הואיל ומדיניות הפרטיות יכולה להשתנות מעת לעת, מומלץ להתעדכן במסמך מדיניות הפרטיות מעת לעת.
                </p>
                <h4>10. אבטחת מידע</h4>
                <p>
                10.1. המידע באתר מאובטח ומוגן לפי אמות מידה סבירות ומקובלות המתעדכנות מעת לעת. כמו כן, החברה מיישמת באתר מערכות ונהלים לאבטחת מידע. בעוד שמערכות ונהלים אלה מצמצמים את הסיכונים לחדירה בלתי מורשית למחשבי החברה, אין בהם ביטחון מוחלט. לכן, החברה אינה מתחייבות שהשירותים באתר ו/או המידע שיתקבל במסגרת השימוש באתר יהיו חסינים באופן מוחלט מפני גישה בלתי מורשית למידע המאוחסן בהם.
                </p>
                <p>
                    10.2. מערכת הסליקה של האתר תופעל על ידי חברה שפועלת על פי תקן אבטחה PCI DSS. הזנת הפרטים של כרטיס האשראי תבוצע דרך האתר ישירות למערכת שירותי הסליקה המאובטחת והפרטים לא יישמרו במערכות החברה.
                </p>
                <h4>11. זכויות קנייניות באתר ותכניו</h4>
                <p>
                    מלוא זכויות הקניין הרוחני (מכל סוג שהן), לרבות שם החברה, סימני מסחר, סודות מסחריים וזכויות יוצרים, בין אם רשומים ובין אם לאו, באתר – לרבות, מבלי לגרוע, הרעיון העומד בבסיס האתר, הלוגו, בסיס הנתונים של האתר, רשימת המוצרים, תיאור המוצרים, תמונות המוצרים, עיצוב המוצרים, נראות האתר ועיצובו הגרפי ("Look and Feel"), שם המתחם (Domain Name) של האתר, וכן בכל תוכנה, יישום, קובץ גרפי, טקסטים, קוד מחשב, תכנים אודיו-ויזואליים וכל חומר או תוכן אחר הכלול באתר - הינם קניינה הבלעדי של החברה בלבד ו/או של צד שלישי שהעניק רשות לחברה להשתמש בהם. אין להעתיק, להפיץ, לשכפל, לשדר, להעביר בהעברת המשך (re-transmission) להציג, להנפיק רישיון, להציג בפומבי, להעמיד לרשות הציבור, ליצור יצירות נגזרות, להשכיר, לתרגם או למסור לצד שלישי כל חלק מהנ"ל בלא קבלת הסכמתה המפורשת של החברה או של אותם צדדים שלישיים בעלי הזכויות (לפי העניין), בכתב ומראש.
                </p>
                <h4>12. שיפוי </h4>
                <p>
                    הנך מתחייב לשפות את החברה, עובדיה, מנהליה, בעלי מניותיה או מי מטעמם בגין כל תביעה, דרישה, נזק, הפסד, אובדן רווח, תשלום או כל הוצאה שייגרמו להם - ובכלל זה שכר טרחת עורך דין (סביר) והוצאות משפט - עקב הפרת תנאי שימוש אלה ו/או הפרת החוק על ידך ו/או עקב כל תביעה ו/או דרישה של צד שלישי כלשהו כתוצאה מהפרה של תנאי מתנאי שימוש של האתר ו/או הפרת החוק על ידך.
                </p>
                <h4>13. תנאי שימוש נוספים באתר</h4>
                <p>
                    13.1. השימוש באתר לרבות הפלטפורמה הינו לשימוש פרטי בלבד. כל שימוש אחר באתר, לרבות שימוש מסחרי (לרבות מכירה של המוצרים ו/או השירותים המוצעים באתר לצדדים שלישיים במכירה קבוצתית ו/או סיטונאית) כפוף לקבלת אישור החברה מראש ובכתב.
                </p>
                <p>
                  13.2. מבלי לגרוע מהאמור בתנאים אלו, בעשותך שימוש באתר, לרבות הפלטפורמה, הינך מתחייב כי לא תעשה אחד או יותר מהדברים הבאים:
                </p>
                <p>
                        13.2.1. התחזות לאדם או גוף כלשהו, לרבות נציג, עובד או מנהל של החברה ו/או מסירת תיאור מסולף או הצגה כוזבת של קשר בינך ובין צד שלישי.
                </p>
                <p>
                    13.2.2. הטענה, שליחה או שידור של כל חומר שקרי ומטעה או חומר אשר נועד להערים על אדם או על גוף כלשהו, במישרין ו/או בעקיפין.
                </p>
                <p>
                    13.2.3. הטענה, שליחה או שידור של כל חומר המכיל כל סוג של וירוס מחשב או כל קוד מחשב אחר, המתוכנן להרוס, להפריע, או להגביל את השימוש (ובכלל זה כל שימוש אחר מלבד שימוש הוגן וסביר בשירות) בכל אחד מן המחשבים, השרתים, החומרה ו/או התוכנה אשר מצויים בשימוש החברה לצורך אספקת השירותים המוצעים באתר זה.
                </p>
                <p>
                    13.2.5. שינוי, עיבוד, העתקה, שאיבת מידע, התאמה, מתן רישיון משנה, תרגום, מכירה, הפצה, שכפול, שידור, צילום, הצגה בפומבי, העברה לציבור, העמדה לרשות הציבור, עשיית יצירות נגזרות, ביצוע פעולות של הנדסה חוזרת, פירוק או הרכבה מחדש של איזה מהמידע ו/או התכנים המצויים באתר ו/או כל אחד מחלקי הקוד המרכיבים את האתר, לרבות בסיס המידע, וכן פעולות כאלה לגבי החומרה והתוכנה אשר מצויות בשימוש עבור השירות.
                </p>
                <p>
                    13.2.6. שינוי ו/או סילוף של המידע ו/או התכנים המצויים באתר וכן ביצוע כל פעולה העלולה לפגוע בנכונות או מהימנות המידע או התכנים כאמור ו/או בכבודה או בשמה של החברה או מי מטעמה כבעל זכויות היוצרים.
                </p>
                <p>
                    13.2.7. קישור של תכנים מהאתר, שאינם דף הבית של האתר או הצגה או פרסום של תכנים כאמור בכל דרך אחרת, אלא אם הקישור יהיה לדף אינטרנט באתר במלואו וכפי שהוא (as is) כך שניתן יהיה לצפות ולהשתמש בו באופן הזהה לחלוטין לשימוש ולצפייה באתר.
                </p>
                <p>
                    13.2.8. פגיעה בכל דרך שהיא בזכויות יוצרים, סימני מסחר, או כל זכות קניינית אחרת אשר מצויה באתר זה.
                </p>
                <p>
                    13.2.9. הצבת האתר, או כל חלק ממנו, בתוך מסגרת של אתר אחר ,(frame) גלויה או סמויה, או כחלק מאתר אחר ,(mirror) ובכלל זה באמצעות כל תכנה, מכשיר, אביזר או פרוטוקול תקשורת, המשנים את עיצובם באתר או מחזירים תכנים כלשהם ובפרט, פרסומות ותכנים מסחריים, ללא הסכמה בכתב ומראש של החברה.
                </p>
                <p>
                    13.2.10. שימוש בכל יישום מחשב או אמצעי אחר, לרבות תוכנות מסוג Robots Crawlers וכדומה וכן בכל רובוט, "תולעת" ,(spider) מנוע אחזור וחיפוש מידע, או בכל כלי אוטומטי או ידני אחר אשר מתוכנן ללקט, לאנדקס, לאחזר, לאגור, לאסוף או לאתר מידע באתר או כלי כאמור אשר מתוכנן לחשוף את מבנה מאגר הנתונים והקוד באתר זה.
                </p>
                <p>
                    13.2.11. הפרעה בכל דרך אחרת, או קטיעה של האתר (לרבות על ידי הפרעה לפעולות השרת ורשת המחשבים המחוברים לאתר או של רשת המחשבים המחוברת אליה) או הפרה באופן כזה או אחר של הדרישות והפרוצדורות של האתר.
                </p>
                <p>
                    13.2.12. שימוש שנועד להתחקות אחר אדם אחר או להטריד אדם אחר בכל דרך שהיא.
                </p>
                <p>
                    13.2.13. איסוף או אגירת מידע אישי על משתמשים אחרים לשם מטרות כלכליות או בלתי חוקיות. כמו כן מכירה, הפצה או כל שימוש כלכלי במידע באתר או כל שימוש אחר באופן העלול לפגוע באדם אשר המידע רלוונטי לו.
                </p>
                <p>
                    13.3. מבלי לגרוע מהאמור בתנאים אלו, החברה תהיה רשאית למנוע ממך לגשת לשירותים באתר וכן לבטל רכישות שנעשו, גם אם בעבר הזמנת מוצרים ו/או שירותים באמצעות האתר, באופן זמני או לצמיתות, בכל אחד מהמקרים הבאים, לפי שיקול דעתה הבלעדי:
                </p>
                <p>
                    13.3.1. אם בעת ההרשמה באתר מסרת פרטים כוזבים ו/או שגויים. הזנת פרטים כוזבים הינה עבירה פלילית והחברה תהיה זכאית לנקוט בכל האמצעים העומדים לרשותה על פי כל דין נגד משתמש אשר יגיש פרטים כוזבים כאמור.
                </p>
                <p>
                    13.3.2. אם אתה חייב כספים לחברה או לחברות קשורות ולא פרעת את חובך, למרות שחלף המועד לתשלום.
                </p>
                <p>
                    13.3.3. אם יהא חשד ממשי לכך שביצעת מעשה ו/או מחדל הפוגעים ו/או העשויים לפגוע באתר ו/או החברה ו/או בצדדים שלישיים (לרבות לקוחות, עובדים וספקים).
                </p>
                <p>
                    13.3.4. אם יהא חשד ממשי לכך שעשית שימוש לא חוקי באתר ו/או בשירותי האתר לרבות ביצוע מעשה הנחזה כבלתי חוקי ו/או במטרה לאפשר, להקל, לסייע ו/או לעודד ביצועו של מעשה כאמור.
                </p>
                <p>
                    13.3.5. אם יהא חשד ממשי לכך שהפרת את תנאי השימוש ו/או תנאי כל שירות מקוון אחר המוצע באתר.
                </p>
                <p>
                    13.3.6. אירעה תקלה טכנית ו/או תקלה באמצעי התקשורת אשר מנעה או הייתה עלולה למנוע רכישה מסוימת.
                </p>
                <p>
                    13.3.7. במקרה של כוח עליון, לרבות כל אירוע שאינו מצוי בשליטתה של החברה ומונע לדעת החברה את המשך המכירה, ביצועה או מימושה, לרבות פעולות מלחמה, איבה, טרור, שביתה, אסון טבע, מגפה וכו'.
                </p>
                <h4>14. שונות </h4>
                <p>
                    14.1. תנאי השימוש מתווספים לכל הוראות תנאי שימוש אחרים המצויים באתר. במקרה של אי התאמה - תנאי שימוש אלו יגברו.
                </p>
                <p>
                    14.2. החברה שומרת לעצמה את הזכות להפסיק את שימושך באתר אם לא תעמוד באחד או יותר מתנאי השימוש.
                 </p>
                <p>
                    14.3. הדין החל על השימוש באתר, על רכישה באמצעותו ועל תנאי השימוש הוא הדין הישראלי בלבד, אשר יגבר על כל כללי ברירת דין המפנים לתחולת הדין הזר. סמכות השיפוט הבלעדית בקשר עם האמור נתונה לבתי-המשפט המוסמכים במחוזות תל-אביב והמרכז בישראל.
                 </p>
                <p>
                    14.4. לשאלות או בירורים ניתן לפנות לשירות הלקוחות של החברה בטלפון 052-687-0702
                </p>
                <p>
                    עודכן לאחרונה: דצמבר 2020
                </p>


















            </div>
        </div>


    )
}

export default TermsAndConditions;