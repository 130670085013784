import React, {useContext, useState} from "react";
import {Link, withRouter} from "react-router-dom";
import {StateContext, useStateValue} from "../../app/state";
import {Logo} from "../../components/Logo/Logo";
import livingRoom from "../../register-page-image.jpeg"
import {Input} from "../../components/Input/Input";
import {Button} from "../../components/Button/Button";
import Modal from "../../components/Modal/Modal";
import OneLineInput from "../../components/Patricia/OneLineInput/OneLineInput";
import {
    createConsumer,
    loginUser,
    notification,
    patriciaAiAssignTourToUser,
    patriciaAiRegister
} from "../../app/actions";
import UiKit from "uikit";
import PageLayout from "../../components/Patricia/PageLayout/PageLayout";
import heLang from "../../localization/patricia-he-il";
import enLang from "../../localization/patricia-en-us";
import PatriciaProgressBar from "../../components/Patricia/RegistrationProgressBar/PatriciaProgressBar";
import PatriciaTopNav from "../../components/Patricia/PatriciaNav/PatriciaTopNav";
import settings from "../../app/settings";
import PatriciaFooter from "../../components/Patricia/PatriciaFooter/PatriciaFooter";
import Cookies from "universal-cookie";
import {
    isMobile
} from "react-device-detect";

function Register(props){
    const context = useContext(StateContext);
    const [
        {
            app,
        },
        dispatch
    ] = context;
    const [loading, setLoading] = useState(false);
    const [errData, setErrData] = useState(null);
    const [errorOccurred, setErrorOccurred] = useState(false);
    const registeredId = "registered-id"
    const Lang = props.lang === 'en' ? enLang : heLang;
    const lang = Lang.register;
    const dir = props.lang == 'en' ? 'ltr' : 'rtl';

    function onSubmit(e){
        e.preventDefault();
        setLoading(true)
        if (errData) setErrData(null);
        if (errorOccurred) setErrorOccurred(false);
        const formData = new FormData(e.target);
        for (var entry of formData.entries()) {
            if (!formData.get(entry[0])) {
                formData.delete(entry[0]);
            }
        }
        let lang = props.lang === 'en' ? "en" : "he"
        formData.append("lang", lang)
        patriciaAiRegister({ payload: formData }, res => {
            window.analytics.track('[registration] registration completed', {
                location: 'register'
            });
            let email = formData.get('email');
            let password = formData.get('password');
            handleLogin(email, password)
        }, err => {
            if (err.status === 500){
                setErrorOccurred(true);
            }
            setErrData(err.data);
            let failureCause = null;
            if(err.data.email){
                failureCause = "email";
            } else if(err.data.password) {
                failureCause = "password"
            } else if(err.data.phone) {
                failureCause = "pone"
            }
            window.analytics.track(`[registration] registration failed ${failureCause}`, {
                location: 'register'
            });
        }).then(() => {
            setLoading(false);
        });

    }

    function handleLogin(email, password) {
        let payload = {
            email: email,
            password: password
        };
        loginUser({ payload }, loginCallback);
    }

    function loginCallback (res, error) {
        if (!error) {
            const newAuth = {
                isAuthenticated: true,
                token: `${res.token_type} ${res.access_token}`
            };
            dispatch({
                type: "setAuth",
                newAuth
            });
            const cookies = new Cookies();
            const tourId = cookies.get('tourId');
            window.analytics.identify(res.user.id, {
                name: res.user.name,
                email: res.user.email,
                method: 'auto login',
                location: 'register',
            });
            patriciaAiAssignTourToUser({id: tourId}, (res) => {
                window.location.href = `/properties/${res.property.id}?relation[]=consumer&relation[]=project&relation[]=media&retailer`;
            })
        }
        else {
            window.analytics.track("auto log-in failed", {
                location: 'register'
            });
        }
        setLoading(false);
    };

    function onMoveToLogIn(){
        UiKit.modal(`#${registeredId}`).hide();
        props.history.push({
            pathname: '/login',
            state: {
                from: {
                    pathname: "/register"
                }
                }
            });
    }

    function getEmailError(){
        const emailExistsError = "The email has already been taken."
        if (errData.email == emailExistsError) {
            return lang.errors.emailExists;
        } else {
            return lang.errors.email;
        }
    }
return (
    <div className='uk-background-muted uk-animation-fade uk-height-1-1 patricia-hebrew-font' uk-height-viewport="" dir={dir}>
        <PatriciaTopNav logo={settings.appLogo} dir={dir}/>
        <div className='uk-flex uk-flex-row'>
            {!isMobile && <div className='uk-width-1-2'>
                <div className="uk-background-cover uk-height-1-1 uk-panel uk-flex uk-flex-center uk-flex-middle" uk-img="" data-src={livingRoom}  style={{ maxWidth: "100%", borderRadius: "30px"}}/>
                {/*<img src={livingRoom} alt="" style={{ maxWidth: "100%", borderRadius: "30px"}}/>*/}
            </div>}
            <div className='uk-height-1-1 uk-width-1-2@l uk-width-1-1@s uk-text-center uk-margin-small-top'>
                <div className="uk-text-center uk-padding-small uk-margin-small-top patricia-hebrew-header-font" style={{fontSize: "45px"}}>{lang.header}</div>
                {!isMobile && <PatriciaProgressBar current={3} headrs={Lang.registrationProgressBar}/>}
                <form onSubmit={onSubmit} className='uk-width-auto uk-padding-large uk-padding-remove-bottom uk-margin-auto' style={{width: "80%"}}>
                    <OneLineInput name="name" type="text" label={lang.fields.fullName} required={true} error={errData && errData.name}/>
                    <OneLineInput name="email" type="email" label={lang.fields.email} required={true} error={errData && errData.email && getEmailError()}/>
                    <OneLineInput name="phone" type="text" label={lang.fields.phone} required={true} error={errData && errData.phone && lang.errors.phone}/>
                    <OneLineInput name="password" type="password" label={lang.fields.password} required={true} info={lang.errors.password} error={errData && errData.password && lang.errors.password} />
                    <OneLineInput name="password_confirmation" type="password" label={lang.fields.passwordValidation} required={true} info={lang.errors.password_confirmation} error={errData && errData.password_confirmation && lang.errors.password_confirmation} />
                    <OneLineInput name="address" type="text" label={lang.fields.address} error={errData && errData.address} />
                    <div className={dir === 'ltr' ? "uk-text-left" : "uk-text-right"}>
                        <label><input className={`uk-checkbox uk-margin-small-${dir === 'ltr' ? "right" : "left"}`} type="checkbox" required={true}/>{lang.fields.consent}
                        </label>
                        <a href="/termsAndConditions" target="_blank" style={{color:"blue", textDecoration: "underline"}}>{lang.fields.conditions}</a>
                    </div>

                    <div className='uk-align-center uk-margin-large-top'>
                        {errorOccurred && <div className='uk-text-alert uk-margin-small-bottom'>{lang.errors.general}</div>}
                        <button className='uk-button uk-text-center uk-width-1-2 reg-next-button'>{lang.next}
                            {loading && (
                                <span
                                    className="uk-margin-small-left uk-animation-fade"
                                    uk-spinner="ratio: .7"
                                />
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <PatriciaFooter origin={'registration'} dir={dir}/>
        <Modal id={registeredId} title="Thank you!">
            <div>
                <p>now you can login to Patricia.</p>
                <p>you will be redirected to the login page in few seconds.</p>
                <div className='uk-text-small'>or <a onClick={onMoveToLogIn} style={{ color: "blue" }}>Click here</a></div>
            </div>
        </Modal>
    </div>
);
}
export default Register;