import React from "react";

const PropertyCard = props => (
  <div
    className="uk-card uk-card-default uk-border-rounded uk-overflow-hidden uk-position-relative"
    style={{ cursor: "pointer" }}
  >
    {/* <span
      uk-icon="forward"
      className="uk-position-small uk-position-top-right"
      uk-tooltip="Open in new tab"
    ></span> */}
    <div
      className="uk-child-width-1-2 uk-flex-stretch"
      uk-grid=""
      style={{ minHeight: "100px", maxHeight: "100px", cursor: "pointer" }}
      onClick={props.onClick ? () => props.onClick(props.id) : null}
    >
      <div
        className="uk-background-cover"
        data-src={props.thumb}
        uk-img=""
      ></div>
      <div className="uk-padding-small uk-flex uk-flex-middle">
        <span>{props.name}</span>
      </div>
    </div>
  </div>
);

export default PropertyCard;
