import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { StateContext } from "../app/state";
import * as QueryString from "query-string";
import TopBar from "../components/TopBar/TopBar";
import Title from "../components/Title/Title";
import Gallery from "../components/Gallery/Gallery";
import SectionTitle from "../components/SectionTitle/SectionTitle";
import Quote from "../components/Quote/Quote";
import { indexProperties, updateProperty } from "../app/actions";
import { groupBy } from "lodash";
import { Loader } from "../components/Loader/Loader";
import ErrorBoundary from "../components/ErrorCatch/ErrorBoundary";
import { Button } from "../components/Button/Button";
import { Input } from "../components/Input/Input";
import UiKit from "uikit";
import Spread from "../components/Spread/Spread";
import SellerLayout from "../layouts/SellerLayout";
import Tour from "../components/Tour/Tour";
import Modal from "../components/Modal/Modal";
import DesignStyle from "../components/DesignStyle/DesignStyle";

class PropertyPage extends Component {
  static contextType = StateContext;
  constructor(props) {
    super(props);
    this.scrollToTop = React.createRef();
    this.checkTourQuiz = setInterval(this.listenToTour, 300);
    this.startQuizId = "start-quiz-id";
  }
  state = {
    dir: null,
    panoramaLink: null
  };
  componentDidMount() {
    window.addEventListener("scroll", this.scrollCheck);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollCheck);
  }

  listenToTour = () => {
    const [
      {
        active: { propertie: property },
      }
    ] = this.context;
    if (property && property.tour) clearInterval(this.checkTourQuiz);
    else return;
    // if(property && property.tour && !property.tour.quizResultId){
    //   UiKit.modal(`#${this.startQuizId}`).show();
    // }
  };

  scrollCheck = () => {
    if (window.scrollY >= 300) {
      if (this.scrollToTop.current.classList.contains("uk-hidden"))
        this.scrollToTop.current.classList.remove("uk-hidden");
    } else {
      if (!this.scrollToTop.current.classList.contains("uk-hidden"))
        this.scrollToTop.current.classList.add("uk-hidden");
    }
  };
  scrollToTopFunction = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.scrollToTop.current.classList.add("uk-hidden");
  };
  tourLink = () => {
    const [
      {
        active: { propertie: property },
        auth: { user }
      }
    ] = this.context;
    const link = `vizitRetail:*${property.tour.data.wallUrl}${
      property.tour.designs && property.tour.designs.length > 0
        ? `*design~${property.tour.designs[0].designUrl}`
        : ""
    }${property.extra.matrixId ? `*matrix:${property.extra.matrixId}` : ""}*height:${
      property.tour.measurements.ceilingHeight
    }*tourId:${property.tour.id}*mu:${"cm"}*retailer:${user &&
      user.seller &&
      user.seller.retailerId}${
      user && user.seller ? `*sellerId:${user.id}` : ""
    }${
      property.tour.data.dataUrl ? `*data~${property.tour.data.dataUrl}` : ""
    }${
      property.extra.quoteId ? `*quoteId:${property.extra.quoteId}` : ""
    }*baseEp~${process.env.REACT_APP_APP_ENDPOINT}`;
    console.log(link);
    window.location.href = link;
  };
  handleDir = () => {
    const [
      {
        auth: { user }
      }
    ] = this.context;
    if (user && user.locality && user.locality === "he-il")
      this.setState({
        dir: "rtl"
      });
    else {
      const search = QueryString.parse(this.props.location.search);
      if (search.lang && (search.lang === "he" || search.lang === "he-il"))
        this.setState({
          dir: "rtl"
        });
    }
  };
  handleIndexProperties = consumerId => {
    this.setState({
      topBarLoading: true
    });
    indexProperties(
      {
        params: {
          consumer: consumerId,
          relation: "project"
        }
      },
      res => {
        const [
          {
            active: {
              propertie: { id }
            }
          }
        ] = this.context;
        const properties = res.data.filter(p => p.id !== id);
        this.setState({
          properties: groupBy(properties, "project.name"),
          topBarLoading: false
        });
      }
    );
  };
  clusterImages = property => {
    let media = [];
    if (property.media) media = media.concat(property.media);
    if (property.tour) {
      if (property.tour.media) media = media.concat(property.tour.media);
    }
    return media;
  };
  updateProperty = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const [{ active }, dispatch] = this.context;
    this.setState({
      sending: true
    });
    updateProperty({ id: active.propertie.id, payload: formData }, () => {
      active.propertie.name = formData.get("name");
      dispatch({
        type: "updateActive",
        newActive: active
      });
      this.setState({
        sending: false
      });
      UiKit.modal(`#update-property`).hide();
    });
  };
  goToProperty = id => {
    window.location.href = `/properties/${id}?relation[]=consumer&relation[]=project&relation[]=media`;
  };

  toConsumer = (id, retailerId) => {
    this.props.history.push(
      `/consumers/${id}?relation[]=properties&relation[]=user&retailer=${retailerId}`
    );
  };

  onStartInspirationQuizClicked = (e, retailerId, tourId) => {
    e.preventDefault();
    window.open(`/takeInspirationQuiz?retailer=${retailerId}&tour=${tourId}`);
    UiKit.modal(`#${this.startQuizId}`).hide();


}

  render() {
    const [
      {
        active: { propertie: property },
        auth: { user, token },
        localization: { publicLayout: lang }
      }
    ] = this.context;
    if (!property || !user) {
      return (
        <SellerLayout>
          <div className="uk-section uk-text-center">
            <Loader></Loader>
          </div>
        </SellerLayout>
      );
    }
    if (!this.state.dir) this.handleDir();
    if (!this.state.properties && !this.state.topBarLoading)
      this.handleIndexProperties(property.consumerId);
    return (
      <SellerLayout>
        <ErrorBoundary>
          <TopBar
            loading={this.state.topBarLoading}
            lang={lang.topBar}
            title={`${lang.topBar.title} ${property.consumer &&
              property.consumer.user &&
              property.consumer.user.name}`}
            properties={this.state.properties}
            dir={this.state.dir}
            onPropertyClick={this.goToProperty}
            onBack={() =>
              this.toConsumer(property.consumerId, user.seller.retailerId)
            }
          />
        </ErrorBoundary>
        <div className="uk-container uk-padding-remove">
          <div
            id="update-property"
            uk-modal="bg-close:false"
            dir={this.state.dir}
          >
            <div className="uk-modal-dialog uk-modal-body uk-width-large">
              <button
                className="uk-modal-close-default"
                type="button"
                uk-close=""
              />
              <div
                className="viz-bg-white uk-padding"
                dir={user && user.locality === "he-il" ? "rtl" : "ltr"}
              >
                <form
                  className="uk-width-expand@m"
                  onSubmit={this.updateProperty}
                >
                  <div>
                    <h2 className="uk-text-bold">{lang.editProperty.title}</h2>
                  </div>
                  <Input
                    name="name"
                    required={true}
                    value={property.name}
                    label={lang.editProperty.fields.name.label}
                  ></Input>
                  <Button
                    label={lang.editProperty.actions.confirm}
                    type="primary"
                    cssClasses="uk-width-1-1 uk-margin-small-top"
                    loading={this.state.sending}
                  />
                </form>
              </div>
            </div>
          </div>

          <div className="uk-background-default  viz-box-shadow-special">
            <div
              className="uk-container uk-margin-large-top uk-margin-medium-left uk-margin-medium-right"
              style={{ paddingTop: "2rem" }}
              dir={this.state.dir}
            >
              <ErrorBoundary>
                <Title
                  actions={[
                    {
                      icon: "pencil",
                      atts: { "uk-toggle": `#update-property` }
                    }
                  ]}
                  title={property.name}
                  subtitle={property.project && property.project.name}
                  className="uk-margin-medium-bottom"
                  retailer={user && user.seller && user.seller.retailer}
                >
                  <a
                    className="uk-button uk-button-default uk-border-rounded"
                    uk-tooltip={lang.actions.copyLink}
                    onClick={() => {
                      const val = document.getElementById("current_url");
                      val.select();
                      document.execCommand("copy");

                      console.log(val.value);
                      alert(lang.state.copied + val.value);
                    }}
                  >
                    <input
                      id="current_url"
                      value={`${window.location.href}&locality=${
                        user && user.locality ? user.locality : ""
                      }`}
                      style={{
                        width: 1,
                        height: 1,
                        padding: 0,
                        margin: 0,
                        opacity: 0,
                        pointerEvents: "none"
                      }}
                    />
                    <span uk-icon="icon:link" />
                  </a>
                </Title>
              </ErrorBoundary>
            </div>
          </div>

          <div
            className="uk-background-default"
            uk-sticky=""
            style={{ zIndex: "90000 !important" }}
            dir={this.state.dir}
          >
            <div className="uk-container">
              <div className="uk-flex uk-flex-left">
                <ul
                  className="uk-tab uk-margin-remove uk-width-expand uk-flex-left"
                  uk-scrollspy-nav="closest: li; scroll: true; offset: 150"
                >
                  {property.tour &&
                    property.tour.data &&
                    property.tour.data.dataUrl && (
                      <li className="uk-active uk-padding-remove">
                        <a href="#tour">{lang.sections.tour}</a>
                      </li>
                    )}
                  {((property.media && property.media.length > 0) ||
                    (property.tour.media &&
                      property.tour.media.length > 0)) && (
                    <li className="uk-active uk-padding-remove">
                      <a href="#gallery">{lang.sections.gallery}</a>
                    </li>
                  )}
                  {!!property.tour.floorplan && (
                    <li>
                      <a href="#plan">{lang.sections.plan}</a>
                    </li>
                  )}
                  {/* <li>
                  <a href="#tour">{lang.sections.tour}</a>
                </li> */}
                  {property.extra && property.extra.quoteId && (
                    <li>
                      <a href="#quote">{lang.sections.quote}</a>
                    </li>
                  )}
                  {property.tour &&
                    property.tour.spreads &&
                    property.tour.spreads.length > 0 &&
                    property.tour.spreads[0].snaps &&
                    property.tour.spreads[0].snaps.length > 0 && (
                      <li>
                        <a href="#spread">{lang.sections.spread}</a>
                      </li>
                    )}
                  {property.tour && property.tour.quizResultId && (
                      <li>
                        <a href="#designStyle">{lang.sections.designStyle}</a>
                      </li>
                  )}
                </ul>
                <div className="viz-nav-title uk-width-auto uk-padding-small">
                  <span>{property.name}</span>
                  {property.project && (
                    <span className="uk-text-meta">
                      {" "}
                      / {property.project.name}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className="uk-section"
            dir={this.state.dir}
            style={{ paddingTop: "0" }}
          >
            {property.tour && property.tour.data && property.tour.data.dataUrl && (
              <div className="uk-padding uk-background-default viz-box-shadow-special uk-margin-large-top">
                <div id="tour">
                  <SectionTitle content={lang.sections.tour} icon="move">
                    <a
                      className="uk-button uk-button-default uk-border-rounded"
                      uk-tooltip={lang.actions.copyLink}
                      onClick={() => {
                        const val = document.getElementById("tour_url");
                        val.select();
                        document.execCommand("copy");

                        console.log(val.value);
                        alert(lang.state.copied + val.value);
                      }}
                    >
                      <input
                        id="tour_url"
                        value={this.state.panoramaLink}
                        style={{
                          width: 1,
                          height: 1,
                          padding: 0,
                          margin: 0,
                          opacity: 0,
                          pointerEvents: "none"
                        }}
                      />
                      <span uk-icon="icon:link" />
                    </a>
                  </SectionTitle>
                  <ErrorBoundary>
                    <Tour
                      passLink={val =>
                        this.setState({
                          panoramaLink: val
                        })
                      }
                      tourId={property.tour.id}
                      retailer={user.seller.retailerId}
                      lang={user.locality.split("-")[0]}
                      auth={token}
                    />
                  </ErrorBoundary>
                </div>
              </div>
            )}
            <div className="uk-padding uk-background-default viz-box-shadow-special uk-margin-large-top">
              <div id="gallery">
                <SectionTitle
                  content={lang.sections.gallery}
                  icon="image"
                ></SectionTitle>
                <ErrorBoundary>
                  <Gallery
                    lang={lang.gallery}
                    images={this.clusterImages(property)}
                    upload={true}
                    {...lang.gallery}
                    mediable_id={
                      property && property.tour ? property.tour.id : property.id
                    }
                    mediable_type={
                      property && property.tour ? "App\\Tour" : "App\\Property"
                    }
                  ></Gallery>
                </ErrorBoundary>
              </div>
            </div>
            {!!property.tour.floorplan && (
              <div className="uk-padding uk-background-default viz-box-shadow-special uk-margin-large-top">
                <div id="plan">
                  <SectionTitle
                    content={lang.sections.plan}
                    icon="grid"
                  ></SectionTitle>
                  <div className="uk-position-relative uk-text-center">
                    <img
                      data-src={property.tour.floorplan.sourceUrl}
                      alt=""
                      uk-img=""
                      height="720"
                      style={{
                        height: "720px"
                      }}
                    />
                    <Button
                      label={lang.downloadPlan}
                      icon="download"
                      cssClasses="uk-position-top-left uk-position-small uk-background-default uk-box-shadow-hover"
                      onClick={() => {
                        window.open(property.tour.floorplan.mediaUrl, "_blank");
                      }}
                    ></Button>
                  </div>
                </div>
              </div>
            )}
            {/* <div id="tour" className="uk-margin-large-top">
              <SectionTitle
                content={lang.sections.tour}
                icon="video-camera"
              ></SectionTitle>
            </div> */}
            {property.extra && property.extra.quoteId && (
              <div className="uk-padding uk-background-default viz-box-shadow-special uk-margin-large-top">
                <div id="quote">
                  <SectionTitle
                    content={lang.sections.quote}
                    icon="cart"
                  ></SectionTitle>
                  <ErrorBoundary>
                    <Quote
                      userId={user.id}
                      quoteId={property.extra && property.extra.quoteId}
                      lang={lang.quote}
                      locality={user.locality}
                      matrixId={property.extra && property.extra.matrixId}
                      project={property.project}
                      propertyId={property.id}
                    ></Quote>
                  </ErrorBoundary>
                </div>
              </div>
            )}
            {property.tour &&
              property.tour.spreads &&
              property.tour.spreads.length > 0 && (
                <div className="uk-padding uk-background-default viz-box-shadow-special uk-margin-large-top">
                  <div id="spread">
                    <SectionTitle
                      content={lang.sections.spread}
                      icon="move"
                    ></SectionTitle>
                    <ErrorBoundary>
                      <Spread
                        tourId={property.tour.id}
                        retailer={user.seller.retailerId}
                        lang={user.locality.split("-")[0]}
                        auth={token}
                      />
                    </ErrorBoundary>
                  </div>
                </div>
              )}
            {property.tour &&
            property.tour.quizResultId && (
                <div className="uk-padding uk-background-default viz-box-shadow-special uk-margin-large-top">
                  <div id="designStyle">
                    <SectionTitle
                        content={lang.sections.designStyle}
                        icon="move"
                        className='uk-margin-remove'
                    ></SectionTitle>
                    <ErrorBoundary>
                      <DesignStyle tour={property.tour} lang={lang.designStyle}/>
                    </ErrorBoundary>
                  </div>
                </div>
            )}
          </div>
        </div>
        <div className="uk-section" dir={this.state.dir}>
          <div className="uk-container">
            <hr className="uk-divider-icon" />
            <div className="uk-text-center uk-text-small">
              <span>{`${lang.footer.powered} Visual Estate`}</span>
            </div>
          </div>
        </div>
        <div
          className="uk-position-fixed uk-text-right"
          style={{ bottom: "20px", right: "20px", zIndex: "900" }}
        >
          <div className="uk-width-auto uk-flex uk-flex-column uk-flex-bottom">
            <div ref={this.scrollToTop} className="uk-width-auto uk-hidden">
              <Button
                shape="circle"
                icon="chevron-up"
                onClick={this.scrollToTopFunction}
                cssClasses="uk-box-shadow-large uk-margin-small-bottom uk-background-default viz-border-remove"
                atts={{
                  style: { padding: "0 10px" }
                }}
              />
            </div>
            <Button
              label={lang.tourStart}
              type="primary"
              icon="play"
              onClick={this.tourLink}
              cssClasses="uk-box-shadow-large"
            />
          </div>
        </div>
        <Modal
            id={this.startQuizId}
            title={lang.InspirationQuizModal.title}
            dir={user && user.locality === "he-il" ? "rtl" : ""}
        >
          {/*<p>{lang.messages.info.body}</p>*/}
          <div uk-grid="" className="uk-margin-top uk-grid-small">
            <div className="uk-width-auto">
              <Button
                  label={lang.InspirationQuizModal.actions.later}
                  cssClasses="uk-width-1-1 uk-modal-close"
              />
            </div>
            <div className="uk-width-expand">
              <Button
                  label={lang.InspirationQuizModal.actions.start}
                  cssClasses="uk-width-1-1"
                  type="primary"
                  //loading={loding}
                  onClick={(e) => this.onStartInspirationQuizClicked(e, user.seller.retailerId, property.tour.id)}
              />
            </div>
          </div>
        </Modal>
      </SellerLayout>
    );
  }
}

export default withRouter(PropertyPage);
