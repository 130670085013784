import React from 'react';
import ColorPaletteAnswer from "./ColorPaletteAnswer";

function TextAnswer(props){
    return (
        <div className={`quiz-button uk-width-auto uk-padding-small uk-height-auto uk-inline ${props.cssClasses} ${props.selected ? " selected": null}`} onClick={props.onAnswerClicked} >
            <span className='uk-position-bottom-center'>
                {props.text}
            </span>
        </div>
    )
}

export default TextAnswer;