import { useStateValue } from "./state";
import { get, set, findIndex } from "lodash";

export const ConsumerListener = ({ listenTo, listenFor, action }) => {
  const [{ active, tasks }, dispatch] = useStateValue();
  const consumer = active.consumer;
  if (!consumer || !consumer.id) return null;
  const __targets = get(consumer, listenTo);
  if (!__targets || __targets.length === 0) return null;
  const targets = __targets.filter(t => t[listenFor] && t[listenFor] === true);

  const handler = target => {
    if (tasks.consumer.properties[target.id]) return;
    const interval = target.checked ? 60000 : 300000;
    tasks.consumer.properties[target.id] = setTimeout(
      queries,
      interval,
      target.id,
      determine
    );
    dispatch({
      type: "updateTasks",
      tasks
    });
  };

  const queries = (id, resolve) => {
    action({ id }, ({ data }) => {
      if (data) resolve(data);
    });
  };

  const determine = data => {
    if (!consumer || !__targets) return;
    const index = findIndex(__targets, ["id", data.id]);
    if (data[listenFor] && data[listenFor] === true) data.checked = true;
    if (consumer.id === data.consumerId) {
      set(consumer, `${listenTo}[${index}]`, data);
      dispatch({
        type: "updateActive",
        newActive: {
          ...active,
          consumer
        }
      });
    }
    if (data.checked && tasks.consumer.properties[data.id]) {
      delete tasks.consumer.properties[data.id];
      dispatch({
        type: "updateTasks",
        tasks
      });
    }
  };

  if (targets && targets.length > 0)
    targets.forEach(t => {
      handler(t);
    });
  return null;
};
