import React from "react";
//import Lang from "../../../localization/patricia-he-il";
import heLang from "../../../localization/patricia-he-il";
import enLang from "../../../localization/patricia-en-us";
function PatriciaFooter(props){
    const Lang = props.dir == 'ltr' ? enLang : heLang;
    const lang = Lang.footer;
    function onEmailClicked(){
        window.analytics.track(`${props.origin} support mail clicked`, {
            location: window.location.pathname.replace("/", "")
        });
    }
    return (
        <div className='uk-height-max-small uk-text-center uk-padding uk-align-center'>
            <p>{lang.note}<a href="mailto:team@patricia-ai.com" style={{color: "blue"}} onClick={onEmailClicked}> team@patricia-ai.com</a></p>
        </div>
    )
}

export default PatriciaFooter;