import React, { Component } from "react";
import { Button } from "../Button/Button";

class Dropdown extends Component {
  state = {
    value: this.props.value || this.props.buttonLabel
  };
  onSelect = (e, option) => {
    e.preventDefault();
    if (this.props.onSelect) this.props.onSelect(option, this.props.extraProp);
    this.setState({
      value:
        option.name || option.label || (option.project && option.project.name)
    });
  };
  render() {
    const {
      buttonType,
      buttonSize,
      mode = "click",
      options,
      buttonIcon = "chevron-down",
      buttonCls
    } = this.props;
    const { value } = this.state;
    return (
      <div>
        <Button
          label={value}
          type={buttonType}
          size={buttonSize}
          icon={buttonIcon}
          cssClasses={buttonCls}
          onClick={e => e.preventDefault()}
        />
        <div
          className="uk-box-shadow-small uk-height-max-medium"
          style={{ overflowY: "auto" }}
          data-elastic
          uk-dropdown={`mode:${mode};animation: uk-animation-slide-bottom-small; duration: 200`}
        >
          <ul className="uk-nav uk-dropdown-nav">
            {options.map((option, i) => (
              <li
                className={
                  (option.name ||
                    option.label ||
                    (option.project && option.project.name)) === value
                    ? "uk-active"
                    : ""
                }
                key={i}
              >
                <a href="#" onClick={e => this.onSelect(e, option)}>
                  {option.name ||
                    option.label ||
                    (option.project && option.project.name)}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default Dropdown;
