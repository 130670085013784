import React from "react";
import { Button } from "../Button/Button";

/**
 *
 * @param {
 * message: string;
 * title?: string;
 * close?: boolean;
 * type?: "primary" | "success" | "warning" | "danger";
 * cssClasses?: string;
 * action?: object;
 * } props
 */
export const Alert = props => {
  const { message, title, close = false, type, cssClasses, action } = props;
  return (
    <div>
      <div
        uk-alert=""
        className={`${cssClasses} ${type ? `uk-alert-${type}` : ""} uk-alert`}
      >
        {close && <a className="uk-alert-close" uk-close="" />}
        {title && <h3>{title}</h3>}
        <p>{message}</p>
        {action && <Button {...action} />}
      </div>
    </div>
  );
};
