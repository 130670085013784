import React from "react";
import { Button } from "../Button/Button";

const Dialog = props => {
  const Wrapper = props.form ? `form` : `div`;
  return (
    <div>
      <div
        className={`${
          props.removeOverlay
            ? "uk-background-transparent"
            : "uk-overlay-primary"
        } uk-position-cover ${props.className}`}
        style={{ zIndex: "999" }}
        hidden
      ></div>
      <div
        className={`uk-overlay uk-position-top-center uk-position-small@m ${props.className}`}
        style={{ zIndex: "999" }}
        hidden
      >
        <Wrapper
          className="uk-align-center"
          onSubmit={
            props.form && props.formOnSubmit ? props.formOnSubmit : () => {}
          }
        >
          <div className="uk-card uk-card-default uk-card-body uk-width-large uk-box-shadow-medium">
            {(props.onCancel || props.cancelAtts) && (
              <button
                className="uk-modal-close-default"
                type="button"
                uk-close=""
                onClick={props.onCancel}
                {...props.cancelAtts}
              ></button>
            )}
            <div className="uk-padding">
              <h2 className="uk-text-bold">{props.title}</h2>
              {!props.form && <p>{props.content}</p>}
              {props.form && props.content}
              <div className="uk-flex uk-flex-right">
                {(props.onCancel || props.cancelAtts) && !props.formSending && (
                  <Button
                    label={props.actions.cancel}
                    cssClasses="uk-margin-small-right uk-margin-small-left"
                    onClick={props.onCancel}
                    atts={props.cancelAtts}
                  />
                )}
                {(props.onConfirm ||
                  props.confirmAtts ||
                  (props.form && props.formOnSubmit)) && (
                  <Button
                    type="primary"
                    label={props.actions.confirm}
                    onClick={props.onConfirm}
                    atts={props.confirmAtts}
                    loading={props.formSending}
                  />
                )}
              </div>
            </div>
          </div>
        </Wrapper>
      </div>
    </div>
  );
};
export default Dialog;
