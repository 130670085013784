import React, { Component } from "react";
import QuoteComponent from "./PatriciaQuoteComponent";
import QuotePlace from "../../Quote/QuotePlace";
import QuoteSum from "../../Quote//QuoteSum";
import {
    getQuote,
    updateQuote,
    deleteQuote,
    deleteQuoteComponent,
    updateQuoteComponent,
    sendQuote,
    updateUser,
    createPlace,
    getMatrix,
    downloadPropertyCSV,
    getPrivateConsumersRooms,
    notification, patriciaAiAddProductToCart, patriciaAiDeleteProduct, patriciaAiAddManyProductsToCart
} from "../../../app/actions";
import { Loader } from "../../Loader/Loader";
import { set, isEmpty, find, flatMap } from "lodash";
import Dialog from "../../Dialog/Dialog";
import { groupArrayBy } from "../../../helpers/HelperFunctions";
import { Input } from "../../Input/Input";
import Modal from "../../Modal/Modal";
import UIkit from "uikit";
import { Button } from "../../Button/Button";
import Dropdown from "../../Dropdown/Dropdown";
import PatriciaQuoteComponent from "./PatriciaQuoteComponent";
import {withRouter} from "react-router-dom";
import {MobileView, BrowserView,
    isMobile
} from "react-device-detect";

class PatriciaQuote extends Component {
    state = {
        id: this.props.quoteId,
        compacting: [],
        alertUnnamed: false,
        selectedComponents: [],
        selectedRoom: null,
        editOfferNumberActive: false,
        componentsInCart: []
    };
    constructor(props) {
        super(props);
        this.consumerEmail = React.createRef();
        this.placeModalId = "place-creation-modal";
    }
    componentDidMount() {
        this.handleGetQuote();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.shoppingCart && prevProps.shoppingCart.products) {
            if (this.props.shoppingCart.products.length != prevProps.shoppingCart.products.length) {
                this.handleGetQuote();
            }
        }
    }

    handleGetMatrix = () => {
        getMatrix({
            id: this.props.matrixId,
            params: {specs: true, relation: ["rooms"]}
        }, res => {
            this.setState({
                rooms: res.data.rooms || res.data,
                isModyVision: res.data.is_mody_vision,
                specs: res.data.specsList.standardSpecs
            })
            if (res.data.is_mody_vision) {
                this.addModyVisionPriceToComponents();
                this.handleGetPrivateConsumersRooms()
            }
        });
    };

    handleGetPrivateConsumersRooms = () => {
        this.setState({
            loading: true
        });
        getPrivateConsumersRooms({}, res => {
            let rooms = res.data;
            rooms.forEach(room => {
                if (this.props.locality === "he-il"){
                    room.label = room.localeName;
                    delete room.name;
                }
            });
            this.setState({
                privateConsumersRooms: rooms,
                loading: false
            })
        })
    };

    handleGetQuote = () => {
        if (!this.state.id) return;
        this.setState({
            loading: true
        });
        getQuote(
            {
                id: this.state.id,
                params: { relation: ["quoteComponents"], locale: "true" }
            },
            res => {
                const quote = res.data;
                Object.keys(quote.components).forEach(r => {
                    Object.keys(quote.components[r]).forEach(p => {
                        quote.components[r][p].components.forEach((c, k) => {
                            if (c.connected && c.connected.length > 0)
                                c.connected.forEach(connected => {
                                    quote.components[r][p].components.push(connected);
                                });
                            delete c.connected;
                        });
                    });
                });
                this.setState({
                    ...quote,
                    loading: false,
                    selectedComponents: []
                });
            }
        ).then(() => {
            this.findComponentsInCart()
            if (this.state.isModyVision) {
                this.addModyVisionPriceToComponents()
            }
        });
    };

    checkIfShouldCompact = array => {
        if (!array || array.length < 2 || this.isPrivateConsumerOrModyVisionConsumer()) return false;
        for (let i = 0; i < array.length; i++) {
            if (
                i > 0 &&
                array[i].product.item.path[0] !== array[i - 1].product.item.path[0]
            )
                return false;
        }
        return true;
    };

    compactPlace = array => {
        if (!array || array.length < 2) return false;
    };

    updateComponent = (e, id) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        this.setState({
            loading: true
        });
        if (formData.has("discount"))
            formData.append("discount_by", this.props.userId);
        updateQuoteComponent({ id, payload: formData }, () => {
            this.handleGetQuote();
        });
    };

    removeComponents = () => {
        this.setState({
            components: null
        });
    };

    downloadQuote = () => {
        const link = `${process.env.REACT_APP_APP_ENDPOINT}/quotes/download/${
            this.state.id
        }?lang=${this.props.locality.split("-")[0]}`;
        window.open(link, "_blank");
    };

    isDownLoadCsvButtonShow = () => {
        if (this.props.project) {
            let projectCreatedDate = new Date(this.props.project.timeStamps.created.split("-").reverse().join("-")); //reverse string because Date class cunstructor recives strings format yyyy-mm-dd
            let canDownloadCreatedProjectFromDate = new Date("2020-08-10");

            if (projectCreatedDate < canDownloadCreatedProjectFromDate) {
                return false;
            } else {
                return true;
            }
        }
        if (this.isPrivateConsumerOrModyVisionConsumer()){
            return true;
        }
        return false;
    }

    onDownloadCsvClicked = () => {
        let isUnamedPlacesOrRooms = false;
        if (this.props.matrixId && !this.state.isModyVision) {
            Object.keys(this.state.components).map((room, key) => {
                if (room === "unnamed") {
                    isUnamedPlacesOrRooms = true;
                    return;
                }
                this.state.components[room].map((place, key) => {
                    if (place.name === "unnamed") {
                        isUnamedPlacesOrRooms = true;
                        return;
                    }
                });
            });
        }
        if (isUnamedPlacesOrRooms) {
            this.setState({
                alertUnnamed: true
            });
            notification(
                "can not download CSV with unnamed rooms or places",
                "danger"
            );
        } else {
            if (this.isShowMatrixUpdatedAlert()) {
                UIkit.modal("#matrix-updated-modal").show();
            } else {
                this.downloadCSV();
            }
        }
    };

    downloadCSV = () => {
        this.setState({
            loading: true
        });
        downloadPropertyCSV({id: this.state.id, payload: {property_id: this.props.propertyId}}, res => {
            window.open(res, "_blank");
        }).then(() => {
            this.setState({
                loading: false
            });
            UIkit.modal("#matrix-updated-modal").hide();
        })
    }

    isPrivateConsumerOrModyVisionConsumer = () => {
        if (this.props.matrixId == null || this.state.isModyVision) {
            return true;
        }
        return false;
    }

    onEditQuoteClicked = () => {
        this.setState({editQuoteActive: !this.state.editQuoteActive});
    }

    addToCheckedComponents = (component) => {
        let checkedComponents = this.state.selectedComponents;
        let index = checkedComponents.findIndex(checkedComponent => checkedComponent === component);
        if (index == -1) {
            checkedComponents.push(component);
        } else {
            checkedComponents.splice(index, 1);
        }
        this.setState({selectedComponents: checkedComponents});
    }

    onChangeSelectedComponentsRoomClicked(e){
        e.preventDefault();
        const formData = new FormData();
        if (this.state.selectedRoom && this.state.selectedComponents) {
            this.setState({
                loading: true
            });
            this.state.selectedComponents.map((quoteComponent, key) => {
                formData.append("room_id", this.state.selectedRoom.id);
                updateQuoteComponent({id: quoteComponent, payload: formData }, () => {
                    if (key === this.state.selectedComponents.length -1) {
                        this.handleGetQuote();
                    }
                });
            });
        }
    }

    deleteQuote = () => {
        this.removeComponents();
        deleteQuote({ id: this.state.id }, () => {
            this.setState({
                id: null
            });
        });
    };

    handlePlaceSet = place => {
        UIkit.modal(`#${this.placeModalId}`).show();
        this.setState({
            creatingPlace: {
                room_id:
                    place.components[0].productRoom && place.components[0].productRoom.id,
                components: place.components.map(c => c.id),
                shouldLoose:
                    place.components[0].product.item.category === "wall" ||
                    place.components[0].product.item.category === "floor"
                        ? true
                        : false
            }
        });
    };

    toggleComponentStatus = (e = null, id, status, action) => {
        if (e) e.preventDefault();
        const { components } = this.state;
        Object.keys(components).forEach(r => {
            Object.keys(components[r]).forEach(p => {
                components[r][p].components.forEach((c, k) => {
                    if (c.id === id) {
                        let value = [r, p, k];
                        switch (action) {
                            case "set":
                                if (status.includes(".")) c = set(c, status, value);
                                else c[status] = value;
                                break;
                            case "delete":
                                if (status.includes(".")) set(c, status, null);
                                else delete c[status];
                                break;
                            default:
                                break;
                        }
                    }
                });
            });
        });
        this.setState({ components });
    };

    deleteComponent = (e, pointer) => {
        e.preventDefault();
        const id = this.state.components[pointer[0]][pointer[1]].components[
            pointer[2]
            ].id;
        this.setState({
            loading: true
        });
        deleteQuoteComponent({ id }, () => {
            this.handleGetQuote();
        });
    };

    sendQuote = () => {
        this.setState({
            loading: true
        });
        const { id } = this.state;
        sendQuote({ id }, res => {
            if (res.status === 400) this.consumerEmail.current.click();
        }).then(() => {
            this.setState({
                loading: false
            });
        });
    };

    handlePlaceCreation = e => {
        e.preventDefault();
        const { creatingPlace } = this.state;
        creatingPlace.sending = true;
        this.setState({
            creatingPlace
        });
        const formData = new FormData(e.target);
        createPlace({ payload: formData }, res => {
            UIkit.modal(`#${this.placeModalId}`).hide();
            this.handleGetQuote();
        }).then(() => {
            this.setState({
                creatingPlace: null
            });
        });
        this.setState({alertUnnamed : false})
    };

    handleUpdateEmail = e => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const id = this.state.consumer.id;
        this.setState({
            emailSending: true
        });
        updateUser({ id, payload: formData }, res => {
            this.consumerEmail.current.click();
            this.sendQuote();
        }).then(() => {
            this.setState({
                emailSending: null
            });
        });
    };

    calculateTotalAmountForCompressedComponents = place => {
        const groups = groupArrayBy(place.components, "productSku");
        const totalGroupAmount =
            Object.keys(groups).map(groupKey => (
                groups[groupKey].reduce((a, v) => {
                    return a + v.priceBreakdown.amount;
                }, 0)));
        return totalGroupAmount;
    }

    compressedComponents = place => {
        const groups = groupArrayBy(place.components, "productSku");
        return Object.keys(groups).map(groupKey => (
            <PatriciaQuoteComponent
                key={groupKey}
                atts={{
                    "uk-toggle": `target:#${place.name +
                    place.id}; cls: viz-compact-place`
                }}
                className="viz-aggr"
                product={groups[groupKey][0].product}
                compacted={true}
                priceBreakdown={
                    groups[groupKey].length < 1
                        ? groups[groupKey][0].priceBreakdown
                        : {
                            amount: groups[groupKey].reduce((a, v) => {
                                return a + v.priceBreakdown.amount;
                            }, 0),
                              cost: groups[groupKey][0].priceBreakdown.cost,
                            unit: groups[groupKey][0].priceBreakdown.unit
                        }
                }
                lang={this.props.lang.agrComponent}
                loss={false}
                refund={false}
                netAmount={place.netAmount}
                componentsInCart = {this.state.componentsInCart}
                onAddToCartClicked={this.onAddProductToCart}
                onRemoveFromCartClicked={this.onRemoveProductFromCart}
            ></PatriciaQuoteComponent>
        ));
    };

    updateQuoteOffer = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        if (formData.has("offer_number") ) {
            this.setState({
                loading: true
            });
            updateQuote({id: this.props.quoteId, payload: formData}, () => {
                this.handleGetQuote();
            });
        }
        this.setState({
            editOfferNumberActive: false,
        });
    }

    isShowMatrixUpdatedAlert = () => {
        if (this.state.matrix) {
            const quoteUpdatedDate = new Date(this.state.timeStamps.lastUpdated.split("-").reverse().join("-"));
            const matrixUpdatedDate = new Date(this.state.matrix.timeStamps.lastUpdated.split("-").reverse().join("-"));
            if (quoteUpdatedDate < matrixUpdatedDate) {
                return this.props.lang.matrixUpdatedAlert;
            } else {
                return null;
            }
        }
        return null;
    }

    setEditOfferNumber = () => {
        this.setState({editOfferNumberActive: !this.state.editOfferNumberActive})
    }

    getModyVisionPrice = (productId) => {
        for (let i = 0; i < this.state.specs.length; i++) {
            if (productId === this.state.specs[i].product.id) {
                return this.state.specs[i].mody_vision_price;
            }
        }
        return null;
    }

    addModyVisionPriceToComponents = () => {
        let newComponents = {...this.state.components};
        Object.keys(newComponents).map((room, key) => (
            newComponents[room].map((place, key) => (
                place.components.map((entry, entryKey) => {
                        entry.mody_vision_price = this.getModyVisionPrice(entry.product.id);
                    }
                )
            ))
        ));
        this.setState({components: newComponents});
    }

    onAddProductToCart = (e, productId, price, sku, quoteComponentId) => {
        let formData = new FormData();
        formData.append("product_id", productId);
        formData.append("price", price);
        patriciaAiAddProductToCart({id: this.props.shoppingCart.id, payload: formData}, () => {
            window.analytics.track("[property_page] \"add to cart\"  clicked", {
                location: "property_page/my cart",
                sku: sku,
            });
            let newComponentsInCart = [...this.state.componentsInCart]
            newComponentsInCart.push(quoteComponentId);
            this.setState({componentsInCart: newComponentsInCart})
        })
    }

    onRemoveProductFromCart = (e, productId,  quoteComponentId) => {
        let formData = new FormData();
        formData.append("product_id", productId);
        patriciaAiDeleteProduct({id: this.props.shoppingCart.id, payload: formData}, () => {
            let newComponentsInCart = [...this.state.componentsInCart]
            let index = newComponentsInCart.findIndex(component => quoteComponentId === component);
            if (index != -1) {
                newComponentsInCart.splice(index, 1);
            }
            this.setState({componentsInCart: newComponentsInCart})
        });
    }

    onAddAllProductsToCart = (e) => {
        let formData = new FormData();
        let products = [];
        let productSkus=[];
        let newComponentsInCart = [...this.state.componentsInCart]
        Object.keys(this.state.components).map((room) => {
            this.state.components[room].map((place, key) => {
                place.components.map((component, entryKey) => {
                    let componentIndex = newComponentsInCart.findIndex(componentInCart => componentInCart === component.id);
                    if (componentIndex === -1) {
                        newComponentsInCart.push(component.id)
                    }

                    let index = products.findIndex(product => product.id === component.product.id);
                    if (index != -1){
                        products[index].amount =  products[index].amount++;
                    } else {
                        let productObj = {
                            id: component.product.id,
                            price: component.product.productPrice,
                            amount: 1
                        }
                        products.push(productObj);
                    }

                    productSkus.push(component.product.productSku)
                });
            });
        });

        formData.append("products", JSON.stringify(products));
        patriciaAiAddManyProductsToCart({id: this.props.shoppingCart.id, payload: formData}, () => {
            this.setState({componentsInCart: newComponentsInCart})
        });
        window.analytics.track("[property_page] \"add all to cart\" clicked", {
            location: 'property_page/my cart',
            skus: productSkus
        });
    }

    findComponentsInCart = () => {
        let newComponentsInCart = []
        let orderProductIds = flatMap(this.props.shoppingCart.products, 'id');
        if (this.state.components) {
            Object.keys(this.state.components).map((room, key) => (
                this.state.components[room].map((place, key) => {
                        place.components.map((component) => {
                            let isComponentInCart = orderProductIds.includes(component.product.id)
                            if (isComponentInCart) {
                                let index = orderProductIds.findIndex((el) => el === component.product.id);
                                if (index != -1) {
                                    orderProductIds.splice(index, 1)
                                }
                                newComponentsInCart.push(component.id);
                            }
                        })
                    }
                )));
            this.setState({componentsInCart: newComponentsInCart});
        }
    }

    render() {
        const promptClass = "quote-delete-prompt";
        const togglePrompt = {
            "uk-toggle": `target:.${promptClass}; animation: uk-animation-fade`
        };
        const emailClass = "consumer-update-dialog";
        const toggleEmail = {
            "uk-toggle": `target:.${emailClass}; animation: uk-animation-fade`
        };
        if (this.state.id) {
            if (isEmpty(this.state.components))
                return (
                    <div className="viz-border-1 uk-border-rounded uk-padding ">
                        <Loader></Loader>
                    </div>
                );
            const buttons = [
                {
                    icon: "download",
                    tooltip: this.props.lang.actions.download,
                    onClick: this.downloadQuote
                },
                {
                    icon: "mail",
                    tooltip: this.props.lang.actions.sendQuote,
                    onClick: this.sendQuote
                },
                {
                    icon: "refresh",
                    tooltip: this.props.lang.actions.refresh,
                    onClick: this.handleGetQuote
                },
                {
                    icon: "trash",
                    tooltip: this.props.lang.actions.delete,
                    atts: togglePrompt
                }
            ];
            if (this.isDownLoadCsvButtonShow()) {
                buttons.push({
                    icon: "cloud-download",
                    tooltip: this.props.lang.actions.downloadPropertyCSV,
                    onClick: this.onDownloadCsvClicked,
                })
            }
            if (this.isPrivateConsumerOrModyVisionConsumer()) {
                buttons.push({
                    icon: "pencil",
                    tooltip: this.props.lang.actions.editQuote,
                    onClick: this.onEditQuoteClicked,
                })
            }

            return (
                <div className="viz-border-1 uk-border-rounded uk-padding uk-position-relative ">
                    <Modal
                        id={this.placeModalId}
                        title={this.props.lang.placeCreate.title}
                    >
                        {this.state.creatingPlace && (
                            <form onSubmit={this.handlePlaceCreation}>
                                <p>{this.props.lang.placeCreate.desc}</p>
                                <input
                                    type="hidden"
                                    name="room_id"
                                    defaultValue={this.state.creatingPlace.room_id}
                                />
                                <input
                                    type="hidden"
                                    name="components"
                                    defaultValue={this.state.creatingPlace.components.join()}
                                />
                                <Input
                                    name="name"
                                    label={this.props.lang.placeCreate.fields.name}
                                    required={true}
                                    atts={{
                                        pattern: "^[a-zA-Z\\s0-9,]+"
                                    }}
                                />
                                <Input
                                    name="locale_name"
                                    label={this.props.lang.placeCreate.fields.locale_name}
                                />
                                {this.state.creatingPlace.shouldLoose && (
                                    <React.Fragment>
                                        <Input
                                            name="loss"
                                            label={this.props.lang.placeCreate.fields.loss}
                                            type="number"
                                            required={true}
                                        />
                                        <Input
                                            name="upgrade_loss"
                                            label={this.props.lang.placeCreate.fields.upgrade_loss}
                                            type="number"
                                            value="0"
                                        />
                                    </React.Fragment>
                                )}
                                <Input
                                    name="refund"
                                    label={this.props.lang.placeCreate.fields.refund}
                                    type="number"
                                    value="0"
                                />
                                <div>
                                    <Button
                                        label={this.props.lang.placeCreate.actions.create.label}
                                        type="primary"
                                        cssClasses="uk-width-1-1 uk-margin-small-top"
                                        loading={this.state.creatingPlace.sending}
                                    />
                                </div>
                            </form>
                        )}
                    </Modal>
                    <Modal
                        id={"matrix-updated-modal"}
                        title={this.props.lang.matrixUpdatedModal.title}
                    >
                        <div>
                            {this.props.lang.matrixUpdatedModal.message}
                        </div>
                        <div>
                            <Button
                                label={"cancel"}
                                type="primary"
                                cssClasses="uk-width-1-1 uk-margin-small-top"
                                onClick={() => { UIkit.modal("#matrix-updated-modal").hide();}}
                            />
                            <Button
                                label={"download anyway"}
                                type="primary"
                                cssClasses="uk-width-1-1 uk-margin-small-top"
                                onClick={this.downloadCSV}
                            />
                        </div>

                    </Modal>
                    {this.state.loading && (
                        <Loader
                            cssClasses={"uk-overlay uk-overlay-default uk-position-cover"}
                            style={{ zIndex: "900" }}
                        ></Loader>
                    )}
                    <Dialog
                        {...this.props.lang.prompt}
                        cancelAtts={togglePrompt}
                        className={promptClass}
                        onConfirm={this.deleteQuote}
                    />
                    <div
                        ref={this.consumerEmail}
                        hidden
                        uk-toggle={`target:.${emailClass}`}
                    ></div>
                    <Dialog
                        {...this.props.lang.consumerEmail}
                        content={
                            <Input
                                required={true}
                                name="email"
                                label={this.props.lang.consumerEmail.input.label}
                            ></Input>
                        }
                        cancelAtts={toggleEmail}
                        formSending={this.state.emailSending}
                        className={emailClass}
                        form={true}
                        formOnSubmit={this.handleUpdateEmail}
                    />
                    <div className={`uk-margin-top uk-flex ${isMobile && "uk-flex-column"}`}>
                        <div className="uk-width-expand">
                            <div>
                                <span className="uk-text-meta">
                                  {this.props.lang.lastUpdated}:{" "}
                                    {this.state.timeStamps.lastUpdated}
                                </span>
                            </div>
                        </div>
                        <div className="uk-width-auto"  style={{ left: "12%" }}>
                            <div className="uk-margin-bottom">
                                <button
                                    className="uk-float-right  uk-text-truncate uk-button uk-button-primary uk-border-rounded patricia-ai-color uk-margin-bottom"
                                    onClick={(e) => this.onAddAllProductsToCart(e)}>
                                    <span className="uk-text-truncate uk-text-capitalize" style={{fontSize: "12px"}}>{this.props.lang.actions.addAllProductsToCart}</span>
                                </button>
                                {/*<ButtonGroup buttons={buttons} />*/}
                            </div>
                            {this.state.editQuoteActive && (
                                <div class="uk-flex uk-margin-small-top uk-width-1-1">
                      <span className="uk-width-1-1  uk-margin-small-left">
                          <Dropdown
                              options={this.state.privateConsumersRooms}
                              buttonLabel={"select room"}
                              buttonCls={"uk-width-1-1 uk-margin-small uk-text-nowrap"}
                              buttonIcon="chevron-down"
                              buttonType="default"
                              onSelect={(val) => this.setState({selectedRoom: val})}
                              value= {this.state.selectedRoom ? this.state.selectedRoom.locale_name : null}
                          />
                      </span>
                                    <span className="uk-width-1-1">
                        <Button
                            label={"apply"}
                            type="primary"
                            onClick={e => this.onChangeSelectedComponentsRoomClicked(e)}
                            cssClasses = "uk-width-1-1 uk-margin-small"
                        ></Button>
                      </span>
                                </div>
                            )}
                        </div>
                    </div>
                    <div>
                        {Object.keys(this.state.components).map((room, key) => (
                            <div
                                key={key}
                                className="uk-card uk-card-default uk-border-rounded uk-margin"
                            >
                                {/*<div className="uk-card-header">*/}
                                {/*    <h5 className={`uk-margin-remove-bottom uk-text-bold ${this.state.alertUnnamed && room === "unnamed" ? ' uk-text-alert' : null}`}>{room}</h5>*/}
                                {/*</div>*/}
                                <div className="uk-card-body">
                                    <ul className="uk-list uk-list-large">
                                        {this.state.components[room].map((place, key) => (
                                            <li
                                                key={key}
                                                id={place.name + place.id}
                                                className={
                                                    this.checkIfShouldCompact(place.components)
                                                        ? "viz-compact-place"
                                                        : ""
                                                }
                                            >
                                                {this.checkIfShouldCompact(place.components) &&
                                                this.compressedComponents(place)}
                                                {place.components.map((entry, entryKey) => (
                                                    <PatriciaQuoteComponent
                                                        onUpdate={this.updateComponent}
                                                        getQuote={this.handleGetQuote}
                                                        actions={
                                                            !this.props.noActions
                                                                ? [
                                                                    {
                                                                        icon: "pencil",
                                                                        onClick: this.toggleComponentStatus,
                                                                        status: "editing"
                                                                    },
                                                                    {
                                                                        icon: "trash",
                                                                        onClick: this.toggleComponentStatus,
                                                                        status: "deleting"
                                                                    }
                                                                ]
                                                                : null
                                                        }
                                                        confirmDelete={this.deleteComponent}
                                                        togglePrompt={this.toggleComponentStatus}
                                                        index={entryKey}
                                                        key={entryKey}
                                                        {...entry}
                                                        lang={this.props.lang.component}
                                                        refund={place.refund}
                                                        netAmount={this.checkIfShouldCompact(place.components) ?
                                                            (place.netAmount / (this.calculateTotalAmountForCompressedComponents(place) / entry.priceBreakdown.amount)) : place.netAmount}
                                                        matrix={this.state.rooms}
                                                        propertyId={this.props.propertyId}
                                                        onChecked={this.state.editQuoteActive ? this.addToCheckedComponents : null}
                                                        checked={!!this.state.selectedComponents.find(element => entry.id === element)}
                                                        modyVisionPrice={this.state.isModyVision ? entry.mody_vision_price : false}
                                                        componentsInCart ={this.state.componentsInCart}
                                                        onAddToCartClicked={this.onAddProductToCart}
                                                        onRemoveFromCartClicked={this.onRemoveProductFromCart}
                                                        isJMI={entry.product.item && entry.product.item.manufacturer.name === "JMI"}
                                                    ></PatriciaQuoteComponent>
                                                ))}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="uk-flex-right" uk-grid="">
                        <div className="uk-width-1-3@m uk-width-1-1@s">
                            {/*<QuoteSum*/}
                            {/*    sum={this.state.sum}*/}
                            {/*    currency="ILS"*/}
                            {/*    tax={0.17}*/}
                            {/*    lang={this.props.lang.sum}*/}
                            {/*></QuoteSum>*/}
                            <button
                                className="uk-float-left  uk-text-truncate uk-button uk-button-primary uk-border-rounded patricia-ai-color uk-margin-top"
                                onClick={(e) => {
                                    window.analytics.track("[property_page] continue button clicked", {
                                        location: 'property_page/my cart'
                                    });
                                    this.props.history.push('/shoppingCart')
                                }}>
                                <span className="uk-text-truncate uk-text-capitalize" style={{fontSize: "12px"}}>{this.props.lang.actions.checkout}</span>
                            </button>
                        </div>
                    </div>
                </div>
            );
        }
        return <div></div>;
    }
}

export default withRouter(PatriciaQuote);
