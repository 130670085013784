import React from "react";
import TeamMember from "../TeamMember/TeamMember";
import ReactPaginate from "react-paginate";
import { StateContext } from "../../app/state";
import { Button } from "../Button/Button";

class TeamIndex extends React.Component {
  static contextType = StateContext;
  state = {
    currentPage: 0,
    pageCount: Math.ceil(this.props.members.length / this.props.factor)
  };
  handlePageClick = ({ selected }) => {
    this.setState({
      currentPage: selected
    });
  };
  pageDivision = () => {
    const { factor, members } = this.props;
    const { currentPage, pageCount } = this.state;
    if (!members) return;
    return members.slice(currentPage * factor, (currentPage + 1) * factor);
  };

  componentDidUpdate(prevProps) {
    if (this.props.members && prevProps.members) {
      if (prevProps.members.length !== this.props.members.length) {
        const pageCount = Math.ceil(
          this.props.members.length / this.props.factor
        );
        const currentPage =
          this.state.currentPage + 1 > pageCount
            ? this.state.currentPage - 1
            : this.state.currentPage;
        this.setState({
          pageCount,
          currentPage
        });
      }
    }
  }
  render() {
    const [
      {
        localization: {
          organization: { team: lang }
        }
      }
    ] = this.context;
    return (
      <React.Fragment>
        <div className="uk-flex-middle" uk-grid="">
          <div className="uk-width-expand">
            <div>
              <h2>
                {lang.title}{" "}
                <span className="uk-text-meta">
                  ({this.props.members && this.props.members.length})
                </span>
              </h2>
            </div>
          </div>
          <div className="uk-width-auto">
            <div>
              <Button
                label={lang.actions.inviteMember}
                atts={{
                  "uk-toggle": `target:#${this.props.inviteModalId}`
                }}
              ></Button>
            </div>
          </div>
        </div>
        {this.props.members && (
          <React.Fragment>
            <div className="uk-padding-small" style={{ paddingBottom: 0 }}>
              <div className="uk-padding-small" uk-grid="">
                <div className="uk-width-medium">
                  <div className="uk-text-meta uk-text-bold">
                    {lang.columns.info}
                  </div>
                </div>
                <div className="uk-width-1-5">
                  <div className="uk-text-meta uk-text-bold">
                    {lang.columns.userLevel}{" "}
                    <span
                      uk-icon="icon:info"
                      className="uk-margin-small"
                      uk-tooltip={lang.columns.userLevelInfo}
                    ></span>
                  </div>
                </div>
                <div className="uk-width-1-5">
                  <div className="uk-text-meta uk-text-bold">
                    {lang.columns.location}
                  </div>
                </div>
                <div className="uk-width-1-5">
                  <div className="uk-text-meta uk-text-bold">
                    {lang.columns.joined}
                  </div>
                </div>
                <div className="uk-width-auto uk-visible@xl">
                  <div className="uk-text-meta uk-text-bold">
                    {lang.columns.actions}
                  </div>
                </div>
              </div>
            </div>
            {this.pageDivision().map((member, key) => (
              <TeamMember
                key={key}
                member={member}
                onDiscountChange={this.props.onDiscountChange}
                levelOptions={this.props.levelOptions}
                locationOptions={this.props.locationOptions}
                onDelete={this.props.onDelete}
                onLevelChange={this.props.onLevelChange}
                onCreateTourCreator={this.props.onCreateTourCreator}
                onDeleteTourCreator={this.props.onDeleteTourCreator}
              />
            ))}
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              breakClassName={"uk-disabled"}
              pageCount={this.state.pageCount}
              marginPagesDisplayed={
                this.state.pageCount <= 5
                  ? 0
                  : Math.ceil(this.state.pageCount * 0.3)
              }
              pageRangeDisplayed={
                this.state.pageCount <= 5
                  ? this.state.pageCount
                  : Math.ceil(this.state.pageCount * 0.65)
              }
              onPageChange={this.handlePageClick}
              containerClassName={"uk-pagination uk-flex-center"}
              subContainerClassName={"pages pagination"}
              activeClassName={"uk-active"}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default TeamIndex;
