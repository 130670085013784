export default {
  errorBoundary: {
    title: "Oops, something went wrong...",
    message:
      "It seems like you have encountered an error with this section of the app. Worry not, we are now aware of this error and our team will fix it ASAP! Meanwhile, we recommend you refresh the page so you can continue using our awesome app and move on with your day :)",
    action: "Refresh page"
  },
  account: {
    title: "Account settings",
    tabs: {
      info: "Information",
      password: "Password"
    },
    forms: {
      info: {
        title: "User information",
        fields: {
          name: {
            label: "Name"
          },
          email: {
            label: "Email address"
          },
          phone: {
            label: "Phone number",
            tooltip: "Phone number must be 10 digits long"
          }
        },
        actions: {
          update: "Update"
        }
      },
      password: {
        title: "Update password",
        fields: {
          password: {
            label: "Current password"
          },
          newPassword: {
            label: "New password",
            tooltip:
              "Password needs to be between 8 to 20 characters with at least 1 uppercase letter 1 lowercase letter and 1 number"
          },
          newPasswordConfirm: {
            label: "Confirm new password",
            tooltip: "Must match new password"
          }
        },
        actions: {
          update: "Update"
        }
      }
    }
  },
  nav: {
    top: {
      findConsumer: "Find Consumer",
      account: "Account",
      logout: "Logout",
      organization: "Organization",
      downloads: "Downloads",
      retail: "Retail Client",
      editor: "Editor Client",
    },
    side: {
      projects: "Projects",
      consumers: "Consumers",
      developers: "Developers",
      designStyles: "Design Styles",
      inspirationQuizzes: "Inspiration Quizzes",
      contact: "contact us",
      email: "email: ",
      phone: "or phone: 052-6567777"
    }
  },
  organization: {
    control: {
      members: "Members",
      locations: "Locations"
    },
    locations: {
      title: "Locations",
      cards: {
        employees: "Employees"
      },
      actions: {
        delete: "Delete Location",
        add: "Add Location"
      },
      create: {
        title: "Create a new location for the organization",
        fields: {
          address: {
            label: "Address"
          },
          phone: {
            label: "Phone number",
            placeholder: "+972 3 655 3232"
          },
          hours: {
            label: "Opening hours",
            placeholder: "i.e. Mon-Thu 08:00-20:00"
          }
        },
        errors: {
          general: "Please choose address from google autocomplete"
        },
        actions: {
          create: {
            label: "Create location"
          }
        }
      }
    },
    team: {
      levels: ["Member", "Manager", "Administrator"],
      na: "Not Available",
      ns: "Not set",
      upTo: "Up to",
      title: "Members",
      actions: {
        removeMember: "Remove Member",
        inviteMember: "Invite Member",
        tourCreator: "tour creator"
      },
      columns: {
        info: "Information",
        level: "Discount allowed (%)",
        userLevel: "User level",
        userLevelInfo:
          "Over level 3 grants a user access to the projects and organization menus",
        location: "Location",
        joined: "Joined on",
        actions: "Actions"
      },
      invite: {
        title: "Invite a member to your team",
        fields: {
          name: {
            label: "Member's name"
          },
          email: {
            label: "Member's email"
          }
        },
        actions: {
          invite: "Send invite"
        }
      }
    }
  },
  fields: {
    email: {
      placeholder: "email@company.com",
      label: "Email",
      error: "Please enter a valid email registered with this Visual Estate"
    },
    password: {
      placeholder: "*********",
      label: "Password",
      error: "Please enter a valid password",
      tooltip:
        "Password needs to be between 8 to 20 characters with at least 1 uppercase letter 1 lowercase letter and 1 number"
    },
    remember: {
      label: "Remember me",
      tooltip: "Remember your account session with this device"
    },
    passwordConfirmation: {
      placeholder: "*********",
      label: "Confirm password",
      tooltip: "Must match the password"
    }
  },
  resetPassword: {
    title: "Reset your password",
    body:
      "Enter your email and a reset password link will be sent to you so you can set a new password"
  },
  setPassword: {
    title: "Set a new password",
    success: "Your password has been updated successfully!",
    body_1: "Go back to the",
    body_2: "Login Page",
    body_3: "and login using your newly set password"
  },
  actions: {
    login: {
      label: "Login"
    },
    reset: {
      label: "Send reset link"
    },
    setPassword: {
      label: "Set password"
    },
    forgotPassword: "Forgot your password?"
  },
  consumers: {
    options: {
      byConsumer: "By consumer",
      byProject: "By Project & unit"
    },
    searchByConsumer: {
      placeholder: "Enter email, phone or name...",
      noResults: "No results found for",
      title: "Please identify the consumer"
    },
    searchByProject: {
      placeholder: "Enter Project name",
      noResults: "No results found for",
      title: "Please identify the consumer"
    },
    searchByUnit: {
      placeholder: "Enter name of unit",
      noResults: "No results found for",
      title: "Please identify the consumer",
      flagTooltip: "Clear project from search"
    },
    messages: {
      noConsumer: {
        title: "Create a new consumer",
        body:
          "No consumers were found matching your search. Would you like to create a new consumer?",
        action: "Create new consumer",
        uploadFileAction: "Create consumers from file"
      }
    },
    create: {
      title: "Create a new consumer",
      fields: {
        email: {
          placeholder: "email@gmail.com",
          label: "Consumer's email"
        },
        name: {
          placeholder: "John Doe",
          label: "Consumer's name"
        },
        phone: {
          placeholder: "052 555 5555",
          label: "Consumer's phone number"
        }
      },
      actions: {
        create: {
          label: "Create"
        }
      }
    }
  },
  projects: {
    search: {
      placeholder: "Enter project name, or developer's name",
      noResults: "No results found for",
      title: "Find Project"
    },
    developer: "Developer",
    results: {
      action: "view"
    },
    index: {
      all: "Projects",
      filter: "Filter projects by name or developer",
      create: "Create new",
      uploadFile: "upload projects from file",
      tags: {
        noMatrix: "No Commons",
        noUnits: "No units"
      }
    },
    single: {
      modals: {
        update: {
          title: "Update project information",
          fields: {
            name: "Project name",
            developer: "Developer"
          },
          actions: {
            update: "Update",
            cancel: "Cancel"
          }
        },
        delete: {
          title: "Delete this project?",
          body:
            "You are about to delete this project, all the units in it and it's matrix. Once these are deleted there is no restoring them",
          note: "Do you wish to continue?",
          actions: {
            delete: "Delete",
            cancel: "Cancel"
          }
        }
      },
      tabs: {
        units: "Units",
        matrix: "Matrix"
      },
      actions: {
        startTour: "Start Tour",
        createUnit: "Add a unit to this project",
        delete: "Delete project",
        matrix: "Matrix actions",
        matrixClear: {
          title: "Clear matrix",
          body:
            "You are about to delete this project's matrix. Do you wish to continue?",
          actions: {
            clear: "Clear Matrix",
            cancel: "Cancel"
          }
        },
        matrixUpload: {
          title: "Upload matrix",
          placeholder: "Select Excel file to upload",
          actions: {
            upload: "Upload"
          }
        },
        matrixDuplicate: {
          title: "Duplicate Matrix",
          subtitle: "Select the project who's matrix you whish to duplicate",
          select: "Select project",
          noProjects: "You have no other projects",
          actions: {
            cancel: "Cancel",
            duplicate: "Duplicate"
          }
        }
      }
    },
    matrix: {
      items: {
        title: "Standard specifications",
        actions: {
          save: "Save",
          cancel: "Cancel",
          add: "Add product"
        },
        search: {
          placeholder: "Search your products",
          noResults: "No products found matching the term: "
        },
        loss: {
          label: "Set loss (%) for this location"
        }
      },
      noRooms: "There are no rooms in this project",
      noPlaces: "There are no places in room ",
      room: {
        title: "Rooms",
        fields: {
          name: {
            label: "Room name",
            tooltip: "Enter a name for the room to be identified"
          },
          localeName: {
            label: "Localized room name",
            tooltip: "Localize the name of the room"
          }
        },
        actions: {
          create: "Create room",
          addRoom: "Add a room to this project",
          delete: "Delete selected room"
        }
      },
      place: {
        title: "Places in ",
        actions: {
          addPlace: "Create a place in this room",
          create: "Create a place",
          delete: "Delete selected place"
        },
        fields: {
          name: {
            label: "Place name",
            tooltip: "Name this place to identify within the room"
          },
          localeName: {
            label: "Localized place name",
            tooltip: "Localize the name of the place"
          }
        }
      }
    },
    update: {
      cover: {
        title: "Update cover image",
        file: "Choose file",
        action: "Update"
      },
      name: {
        title: "Update project name",
        placeholder: "Enter project name",
        action: "Update"
      }
    },
    create: {
      fromList: "Choose from list",
      back: "back",
      create: "Create",
      developer: {
        select: "Please select a Developer",
        create: "Create a Developer",
        fields: {
          name: {
            label: "Developer name",
            placeholder: "Construction Ltd."
          },
          url: {
            label: "Developer website",
            placeholder: "https://developer.com",
            tooltip:
              "Having this information help you understand how traffic flows through the project you are involved with"
          }
        }
      },
      project: {
        create: "Create a project with:",
        fields: {
          name: {
            label: "Project name",
            placeholder: "Cool project"
          }
        }
      },
      unit: {
        create: "Create new unit",
        complete: "Complete",
        continue: "Continue",
        fields: {
          doorWidth: {
            label: "Front door width"
          },
          ceilingHeight: {
            label: "CeilingHeight"
          },
          stories: {
            label: "Number of stories"
          },
          file: {
            label: "Upload floor plan",
            placeholder: "Choose file"
          },
          name: {
            label: "Unit Name"
          },
          amount: {
            label: "Amount of this unit"
          }
        }
      },
      continue: "Continue"
    }
  },
  units: {
    search: {
      placeholder: "Enter unit type name",
      noResults: "No results found for",
      title: "Find the unit"
    },
    project: "Project",
    results: {
      action: "Select"
    },
    actions: {
      delete: "Delete unit",
      wall: "Update <.wall> file",
      design: "Update <.design> file",
      plan: "View Floor plan",
      cover: "Update cover image",
      update: "Update",
      file: "Choose File",
      editor: "Run as Consumer",
      spread: "Spread Unit changes to related properties"
    }
  },
  consumer: {
    createProperty: {
      title: "Create a property",
      steps: {
        name: {
          title: "Name the property"
        },
        upload: {
          title: "Upload a floor plan"
        },
        mes: {
          title: "Measurements"
        }
      },
      fields: {
        name: {
          label: "Property name"
        },
        plan: {
          label: "Drop file here"
        },
        door_width: {
          label: "Front door width (cm)"
        },
        ceiling_height: {
          label: "Ceiling height (cm)"
        }
      },
      actions: {
        cancel: {
          label: "Cancel"
        },
        next: {
          label: "Next"
        },
        finish: {
          label: "Finish"
        }
      }
    },
    properties: {
      action: "Start tour",
      latest: "Latest quote",
      delete: {
        content:
          "Are you sure you wish to delete this property? * once deleted the property can not be restored",
        title: "Delete property",
        actions: {
          confirm: "delete",
          cancel: "cancel"
        }
      },
      messages: {
        notAvail: "This property is being processed"
      }
    },
    actions: {
      newProperty: "Add property",
      uploadPdf: "Upload PDF",
      delete: {
        title: "Delete consumer",
        body:
          "You are about to delete this consumer and all of the properties associated with this consumer.",
        warning: "Do you wish to continue?",
        actions: {
          delete: "Delete",
          cancel: "Cancel"
        }
      },
      update: {
        title: "Update consumer",
        body:
          "Make sure to fill all the consumer fields for better usage and tracking",
        fields: {
          name: "Full name",
          email: "Email address",
          phone: "Phone number",
          clientNumber: "client number"
        },
        actions: {
          update: "Update",
          cancel: "Cancel"
        }
      }
    },
    messages: {
      properties: {
        body: "The consumer has no properties to his or her account",
        title: "No Properties",
        action: "Create a property"
      },
      info: {
        title: "Some info is missing",
        body:
          "Some information about this consumer is missing from the system. We recommend you add this information for better tracking and experience",
        actions: {
          later: "Later",
          continue: " Update info"
        }
      },
      add: {
        title: "How would you like to add a property?",
        body:
          "You can choose the origin of the property you want to add to this consumer, either from a project or you can upload a property directly for this user.",
        actions: {
          new: "Create new",
          fromProject: "From project"
        }
      }
    },
    propertyDisplay: {
      soon: "soon",
      tabs: {
        quotes: "Quotes",
        info: "Property Info",
        designs: "Designs"
      },
      actions: {
        delete: "Delete this property",
        edit: "Edit property name"
      },
      quotes: {
        empty: "This property has no quotes",
        actions: {
          title: "Quote Actions",
          delete: "Delete Quote",
          download: "Download Quote",
          reload: "Reload Quote",
          discount: "Set discount price",
          removeDiscount: "Remove discount",
          amount: "Set custom amount/area"
        },
        delete: {
          title: "Delete this quote?",
          delete: "Delete",
          cancel: "Cancel"
        },
        topBar: {
          updated: "Last Updated",
          total: "Total",
          totalTax: "Total with tax",
          tax: "Tax"
        },
        table: {
          sku: "SKU",
          name: "Product name",
          amount: "Amount",
          unit: "Unit",
          cpu: "Cost per Unit",
          total: "Total",
          meter: "sqm",
          room: "Room",
          place: "Place",
          discount: "Discount price",
          amountLoss: "After Loss",
          loss: "Loss(%)"
        }
      }
    }
  },
  messages: {
    error: {
      login: "Please fill out all the fields correctly",
      wrongPassword: "incorrect email or password"
    },
    resetSent: "your reset link has been sent",
    update: {
      success: "Successfully updated"
    }
  },
  publicLayout: {
    actions: {
      copyLink: "Copy a shareable public link"
    },
    state: {
      copied: "Copied the link: "
    },
    editProperty: {
      title: "Edit property information",
      actions: {
        confirm: "Submit",
        cancel: "Cancel"
      },
      fields: {
        name: {
          label: "Property name"
        }
      }
    },
    tourStart: "Start tour",
    downloadPlan: "Download plan",
    topBar: {
      title: "Back to",
      more: "More Properties"
    },
    sections: {
      gallery: "Gallery",
      tour: "Tour",
      quote: "Quote",
      plan: "Floor plan",
      spread: "Layouts",
      designStyle: "Design Style",
      addToCart: "Add To Cart",
      designPackages: "Design Packages",
      goToCheckout: "Checkout"
    },
    gallery: {
      click: "Click to select an image to upload",
      drop: "Drop image file here to upload",
      delete: {
        title: "Delete this image?",
        content:
          "Are you sure you wish to delete this image? * once deleted the image can not be restored",
        actions: {
          cancel: "Cancel",
          confirm: "Delete"
        }
      }
    },
    designPackages: {
      header: "Choose design package",
      ok: "OK",
      chosenMessage: "Your chosen design package:",
      modals: {
        confirmation: {
          message: "By clicking \"ok\" button I confirm choosing this design package in value of 2500£. \n Your choice will be transmitted to Israel Canada representative to coordinate delivery time.",
          ok: "OK",
          cancel: "cancel"
        },
        packageProducts: {
          title: "Design Package"
        }
      }
    },
    quote: {
      tabs: {
        home: "Home",
        kitchen: "Kitchen",
      },
      prompt: {
        title: "Delete this quote",
        content:
          "Are you sure you wish to delete this quote? * once deleted the quote can not be restored",
        actions: {
          cancel: "Cancel",
          confirm: "Remove"
        }
      },
      consumerEmail: {
        title: "Set consumer's email",
        input: {
          label: "Consumer's email"
        },
        actions: {
          cancel: "Cancel",
          confirm: "Save"
        }
      },
      actions: {
        download: "Download quote",
        downloadPropertyCSV: "Download property CSV",
        downloadKitchenCSV: "Download kitchen CSV",
        editQuote: "edit quote",
        refresh: "Reload the quote",
        delete: "Delete quote",
        toggleList: "Toggle compact mode",
        sendQuote: "Send quote via Email",
        editOfferNumber: "edit offer number",
        addAllProductsToCart: "Add All Products To Cart",
        checkout: "Checkout"
      },
      lastUpdated: "Last updated",
      matrixUpdatedAlert: "The matrix was changed since quote last update",
      offer: "offer",
      missing_offer: "missing offer number",
      matrixUpdatedModal: {
        title: "Please Note",
        message: "The matrix of this unit has been updated and the standards in this quote could be wrong and may need updating. Please check the product selections to make sure."
      },
      place: {
        loss: "Loss",
        refund: "Reimbursement",
        createPlace: "Create a place here"
      },
      placeCreate: {
        title: "Create a place",
        desc: "Create a place out of this unnamed place for the quote",
        fields: {
          name: "Place name (English)",
          locale_name: "Place name (Local language)",
          loss: "Loss (%)",
          upgrade_loss: "Upgrade loss (%)",
          refund: "Reimbursement on upgrade"
        },
        actions: {
          create: {
            label: "Create"
          }
        }
      },
      component: {
        delete: {
          content: "Are you sure you wish to remove '%s' from this quote?",
          actions: {
            confirm: "Remove",
            cancel: "Cancel"
          }
        },
        edit: {
          label: "Editing",
          costNote: "*cost will be re-calculated on save",
          addDiscount: "Set discounted price",
          cancel: "Cancel",
          confirm: "Apply"
        },
        loss: "Loss",
        refund: "Reimbursement",
        price: "Price",
        discount: "Discounted price",
        amount: "Amount",
        calculated_amount: "Calculated Amount",
        matrix_amount: "Matrix Amount",
        cost: "Cost",
        sqm: "sqm",
        unit: "unit",
        length: "m",
        room: "room",
        place: "place",
        addToCart: "Add To Cart",
        productAddedToCart: "product added to cart"
      },
      agrComponent: {
        price: "Price",
        amount: "Amount (agr.)",
        calculated_amount: "Calculated Amount",
        matrix_amount: "Matrix Amount",
        cost: "Cost",
        sqm: "sqm",
        length: "m",
        unit: "unit",
        room: "room",
      },
      sum: {
        subTotal: "Sub total",
        tax: "Tax",
        total: "Total"
      }
    },
    designStyle: {
      actions: {
        startQuiz: "Start New Quiz",
        chooseDesignStyle: "Choose Different Design Style"
      },
      modals: {
        changeDesignStyle: {
          title: "Select Design Style",
          labels: {
            designStyles: "Design Styles"
          },
          actions: {
            update: "Update",
            cancel: "Cancel"
          }
        }
      }
    },
    footer: {
      powered: "Powered by"
    },
    InspirationQuizModal: {
      title: "Help Patricia understand what is the best design style for you",
      actions: {
        start:  "Start now",
        later:  "Later"
      }
    }
  },
  developers: {
    messages: {
      noProjects: "You do not have any projects with this developer",
      addDeveloper: "Add Developer",
      search: {
        placeholder: "Search developers"
      }
    },
    card: {
      projects: "projects",
      projectsTooltip: "Go to your projects with this developer"
    },
    modals: {
      delete: {
        title: "Delete developer",
        body:
          "You are about to delete this developer. Deleting the developer will also delete all related projects and units. Upon deletion these cannot be restored. ",
        note: "Do you wish to continue?",
        actions: {
          delete: "Delete",
          cancel: "Cancel"
        }
      },
      create: {
        actions: {
          create: "Create",
          cancel: "Cancel"
        }
      },
      update: {
        title: "Update developer",
        fields: {
          name: {
            label: "Name"
          },
          url: {
            label: "Url"
          },
          logo: {
            label: "Logo"
          }
        },
        actions: {
          update: "Update",
          cancel: "Cancel"
        }
      }
    }
  },
  designStyles: {
    messages: {
      addDesignStyle: "Add Design Style",
      search: {
        placeholder: "Search Product"
      }
    },
    search: {
      placeholder: "Search a Product"
    },
    modals: {
      create: {
        actions: {
          create: "Create",
          update: "Update",
          cancel: "Cancel"
        }
      },
      delete: {
        title: "Delete Design Style",
        body: "You are going to delete a design style.are you sure?",
        actions: {
          delete: "Delete",
          cancel: "Cancel"
        },
      },
      update: {
        title: "Update Design Style",
      },
      deleteProduct: {
        title: "Delete Product",
        body: "You are going to delete a product.are you sure?",
        actions: {
          delete: "Delete",
          cancel: "Cancel"
        }
      },
      editDescription: {
        title: "Edit Design Style Description",
        actions: {
          update: "Update",
          cancel: "Cancel"
        }
      },
      labels: {
        name: "Name",
        photo: "Photo",
        pointValue: "Point Value"
      }
    }
  },
  createInspirationQuiz: {
    createNewQuiz: "Create New Quiz",
    createNewQuestion: "Create New Question",
    modals: {
      createNewQuiz: {
        labels: {
          name: "Name"
        },
        actions: {
          create: "create",
          cancel: "Cancel"
        }
      },
      deleteQuiz: {
        title: "delete quiz",
        body: "You are going to delete a quiz.are you sure?",
        actions: {
          delete: "delete",
          cancel: "cancel"
        }
      },
      createNewQuestion: {
        title: "Add Question",
        labels: {
          answersType: "Answers Type",
        },
        actions: {
          create: "create",
          cancel: "cancel"
        }
      },
      editQuestion: {
        title: "Edit Question",
        labels: {
          question: "Question",
          questionNumber: "Question Number",
        },
        actions: {
          update: "Update",
          cancel: "Cancel"
        }
      },
      deleteQuestion: {
        title: "Delete Question",
        body: "You are going to delete a question. are you sure?",
        actions: {
          delete: "Delete",
          cancel: "Cancel"
        }
      },
      editQuizResultText: {
        title: "edit result text",
        actions: {
          create: "OK",
          cancel: "Cancel"
        }
      },
      addTextAnswer: {
        title: "Add Text Answer",
        labels: {
          answer: "Answer",
          priceTag: "Price Tag",
          colorTags: "Color Tags",
          generalTags: "General Tags",
          pointValue: "Point Value",
          priceTagInfo: "click the + or Enter to add (one tag only)",
          tagInfo: "click the + or Enter to add"
        },
        actions: {
          create: "Create",
          cancel: "Cancel"
        }
      },
      addIImageAnswer: {
        title: "Add Image Answer",
        labels: {
          answer: "Image",
          priceTag: "Price Tag",
          colorTags: "Color Tags",
          generalTags: "General Tags",
          pointValue: "Point Value",
          priceTagInfo: "click the + or Enter to add (one tag only)",
          tagInfo: "click the + or Enter to add"
        },
        actions: {
          create: "Create",
          cancel: "Cancel"
        }
      },
      editAnswer: {
        title: "Edit Answer",
        labels: {
          answer: "Answer",
          priceTag: "Price Tag",
          colorTags: "Color Tags",
          generalTags: "General Tags",
          pointValue: "Point Value",
          priceTagInfo: "click the + or Enter to add (one tag only)",
          tagInfo: "click the + or Enter to add"
        },
        actions: {
          update: "Update",
          cancel: "Cancel"
        }
      },
      deleteAnswer: {
        title: "Delete Answer",
        body: "You are going to delete an answer. are you sure?",
        actions: {
          delete: "Delete",
          cancel: "Cancel"
        }
      },
    }
  },
  patricia: {
    shoppingCart: {
      header: "Shopping Cart",
      headers: {
        product: "Product",
        name: "Name",
        sku: "SKU",
        provider: "Provider",
        quantity: "Quantity",
        price: "Price",
        totalPrice: "Total Price",
        actions: "Actions",
      },
      actions: {
        save:  "Save",
        continue: "Continue",
        clearCart: "Clear Cart",
        cancel: "Cancel",
        myHome: "My Home"
      },
      messages: {
        clearCart: "Are you sure you want to clear your cart?",
        emptyCart: "Your Cart Is Empty. Go shopping!"
      },
      summary: {
        subTotal: "Sub total",
        total: 'Total',
        vat: 'VAT'
      },
      freeDeliveries: {
        message: "launching celebration: free deliveries!",
        ok: "OK"
      }
    },
    productModal: {
      patriciaPrice: "patricia price",
      brand: "brand",
      additionalInfo: "additional info",
      sku: "sku"
    },
    shipping:{
      header: "Delivery Details",
      fields: {
        email:"email",
        firstName: "First Name",
        lastName: "Last Name",
        streetAddress: "Street Address",
        number: "Number",
        apartment: "Aprtment",
        floor: "Floor",
        city: "City",
        phone: "Phone Number",
        notes: "notes",
        deliveryMethod: "Delivery Method",
        freeDeliveries: "Free deliveries for launching month",
        upTo21Days: "ASAP (up to 21 business days)",
        laterDate: "Later Date"
      },
      errors: {
        phone: "please enter a valid phone number(digits only)"
      },
      actions: {
        next: "next",
      }
    },
    checkout: {
      header: "secure payment",
    },
    sideMenu: {
      myHome: "My 3D Home",
      shoppingCart: "Shopping Cart",
      settings: "Settings",
      orders: "My Orders",
    },
    orders: {
      header: "My Orders",
      noOrdersYet: "You Haven't Ordered anything yet. go to your home and start shopping.",
      headers: {
        orderNumber: "Order Number",
        date: "Date",
        orderTotal: 'order total',
        selectedDelivery: "Selected Delivery",
        shippingAddress: "Shipping Address"
      },
      actions: {
        myHome: "my home",
        orderDetails: "Order details"
      }
    }
  },
};

