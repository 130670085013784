import React from 'react';

function Question(props) {
    return(
        <div className={`uk-margin-small ${props.cssClass}`} style={{fontSize:"48px"}}>
            {props.text}
        </div>
    )
}

export default Question;