import React, { Component } from "react";
import { Alert } from "../components/Alert/Alert";
import Search from "../components/Search/Search";
import SellerLayout from "../layouts/SellerLayout";
import { StateContext } from "../app/state";
import { extendUnitToProperty } from "../app/actions";
import { Loader } from "../components/Loader/Loader";
import { withRouter } from "react-router-dom";
import { LocalSearch } from "../app/utilities";
import { Units } from "../components/Units/Units";

class UnitSearch extends Component {
  static contextType = StateContext;
  state = {
    searchTerm: null
  };
  handleChange = term => {
    const [
      {
        data: { units }
      }
    ] = this.context;
    this.setState({
      filteredUnits: LocalSearch(term, units)
    });
  };
  handleSelect = unit => {
    const [
      {
        active: {
          consumer: { id: consumerId }
        },
        auth: {
          user: {
            seller: { retailerId }
          }
        },
        componentData
      },
      dispatch
    ] = this.context;
    dispatch({
      type: "updateComponentData",
      newComponentData: {
        ...componentData,
        loading: true
      }
    });
    extendUnitToProperty(
      {
        id: unit.id,
        payload: { name: unit.name, consumer_id: consumerId }
      },
      res => {
        this.props.history.push(
          `/consumers/${consumerId}?relation[]=properties&relation[]=user&retailer=${retailerId}`
        );
      }
    );
  };
  gotToProjects = () => {
    const [
      {
        auth: {
          user: {
            seller: { retailerId }
          }
        },
        active: { consumer }
      }
    ] = this.context;
    this.props.history.push(
      `/consumers/${consumer.id}/projectCommons?retailer=${retailerId}&developer`
    );
  };
  render() {
    const [
      {
        localization: { units: lang },
        active: { projectCommon: project },
        data: { units },
        componentData: { loading },
        auth: { user }
      }
    ] = this.context;
    const { searchTerm, filteredUnits } = this.state;
    return (
      <SellerLayout>
        <div className="uk-section uk-position-relative">
          {(loading || (!project && !units)) && (
            <div
              className="uk-position-cover uk-flex uk-flex-middle uk-flex-center"
              style={{ zIndex: 100000 }}
            >
              <Loader shadow={true} />
            </div>
          )}
          {project && units && (
            <div
              className="uk-container"
              style={{ opacity: loading ? ".2" : "1" }}
            >
              <ul className="uk-breadcrumb uk-margin-remove">
                <li>
                  <a onClick={this.gotToProjects}>{project.project.name}</a>
                </li>
              </ul>
              <h2 className="uk-animation-fade uk-animation-fast uk-text-bold uk-margin-small-top uk-margin-medium-bottom">
                {lang.search.title}
              </h2>
              <Search
                lang={lang.search}
                onChange={this.handleChange}
                delayed={false}
              />
              <div className="uk-width-large uk-align-center">
                {searchTerm && (!units || units.length === 0) && (
                  <Alert
                    message={lang.messages.noConsumer.body}
                    title={lang.messages.noConsumer.title}
                    type="action"
                    cssClasses="viz-box-shadow-special uk-padding uk-margin-medium-top uk-animation-slide-bottom-small uk-animation-fast"
                    action={{
                      label: lang.messages.noConsumer.action,
                      type: "primary",
                      cssClasses: "uk-width-1-1"
                    }}
                  />
                )}
              </div>
              <div>
                {units && (
                  <Units
                    units={filteredUnits || units}
                    onSelect={this.handleSelect}
                    dir={user && user.locality === "he-il" ? "rtl" : ""}
                    action={lang.results.action}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </SellerLayout>
    );
  }
}

export default withRouter(UnitSearch);
