import React from 'react';
import ImgAnswer from "./ImgAnswer";

function ColorPaletteAnswer(props) {
    return (
        <div className="quiz-button uk-margin-auto uk-margin-medium-top uk-flex uk-flex-center" onClick={props.onAnswerClicked} style={{ height: "80px"}}>
            {props.colors.map((color) => {
                // return <div className="" style={{ backgroundColor: color, boxShadow: "15px 15px 15px rgba(0, 0, 0, 0.08)", height:"80px", width: "80px"}}></div>
                return (
                    <div className="quiz-color-palette-circle" style = {{
                        transition: "flex-basis $timing ease-in-out",
                        backgroundColor: color}}>
                </div>)
            })}
        </div>
    )
}

export default ColorPaletteAnswer;