import React from "react";
import { Button } from "../Button/Button";
import { Loader } from "../Loader/Loader";

/**
 *
 * @param {
 * actionLabel?: string;
 * title: string;
 * content: string;
 * onClick?: () =>{};
 * image?: string;
 * tags?: array;
 * } props
 */
export const Card = props => {
  const {
    actionLabel,
    title,
    content,
    onClick,
    onCard,
    labels,
    image,
    dir = "",
    tags,
    children,
    loading,
    deleting,
    lang,
    toggleDelete,
    id,
    onDelete
  } = props;
  const atts = {};
  if (loading && lang.messages) atts["uk-tooltip"] = lang.messages.notAvail;
  return (
    <div {...atts}>
      <div
        className="uk-card uk-transition-toggle uk-height-small uk-animation-fade"
        tabIndex="0"
        onClick={!loading && !deleting ? onCard : () => {}}
        style={{
          pointerEvents: loading ? "none" : "all"
        }}
      >
        {loading && (
          <div
            className="uk-overlay uk-overlay-default uk-position-cover uk-flex uk-flex-middle uk-flex-center"
            style={{ zIndex: "900" }}
          >
            <Loader />
          </div>
        )}
        {deleting && (
          <div
            className="uk-overlay uk-position-cover uk-background-default uk-animation-fade uk-animation-fast"
            style={{ zIndex: "100" }}
          >
            <div>
              <p className="uk-text-bold">{lang.delete.content}</p>
              <div className="uk-flex uk-flex-right">
                <Button
                  label={lang.delete.actions.cancel}
                  cssClasses="uk-margin-small-left uk-margin-small-right"
                  onClick={e => toggleDelete(e, id, "unset")}
                ></Button>
                <Button
                  label={lang.delete.actions.confirm}
                  type="primary"
                  onClick={e => onDelete(e, id)}
                ></Button>
              </div>
            </div>
          </div>
        )}
        {!deleting && (
          <React.Fragment>
            <div className="viz-box-shadow-special viz-card-background viz-bg-white">
              <div className="uk-height-1-1" uk-grid="">
                <div className="uk-width-expand" />
                <div
                  className={
                    "uk-width-1-2 uk-height-1-1 uk-background-cover viz-card-image " +
                    dir
                  }
                  uk-img=""
                  data-src={image || ""}
                />
              </div>
            </div>
            <div className="uk-card-body uk-position-cover">
              <div className="uk-position-top-left">
                {tags &&
                  tags.map((t, i) => (
                    <span
                      key={i}
                      className="uk-label uk-label-danger uk-margin-small-right"
                    >
                      {t}
                    </span>
                  ))}
              </div>
              {children}
              <div uk-grid="" uk-height-match="">
                <div className="uk-width-2-3">
                  <h3
                    className="uk-text-bold uk-card-title uk-margin-small uk-text-truncate"
                    title={title}
                  >
                    {title}
                  </h3>
                  {labels && <div>{labels()}</div>}
                  {content && (
                    <p
                      className="uk-text-meta uk-text-bold uk-text-truncate"
                      title={content}
                    >
                      {content}
                    </p>
                  )}
                  {/* <ul className="uk-list viz-card-list uk-transition-fade">
                <li>
                  <span uk-icon="icon:check" />
                  <span>Item</span>
                </li>
                <li>Item</li>
              </ul> */}
                </div>
                <div className="uk-width-1-3 uk-text-right uk-flex uk-flex-middle uk-flex-right uk-padding-remove">
                  {actionLabel && onClick && (
                    <Button
                      label={actionLabel}
                      type="primary"
                      onClick={onClick}
                      cssClasses="uk-transition-slide-bottom-small viz-box-shadow-special"
                    />
                  )}
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};
