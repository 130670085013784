import React, { Component } from "react";
import SellerLayout from "../layouts/SellerLayout";
import { Loader } from "../components/Loader/Loader";
import { StateContext } from "../app/state";
import {
  deleteSeller,
  createSeller,
  createLocation,
  deleteLocation,
  updateSeller, createTourCreator, deleteTourCreator, deleteTourCreatorByUserId
} from "../app/actions";
import TeamIndex from "../components/TeamIndex/TeamIndex";
import TeamInviteForm from "../components/TeamInviteForm/TeamInviteForm";
import LocationIndex from "../components/LocationIndex/LocationIndex";
import LocationForm from "../components/LocationForm/LocationForm";

class TeamView extends Component {
  static contextType = StateContext;
  state = {
    modals: {
      invite: "invite-member-modal",
      location: "create-location-modal"
    },
    processing: {
      invite: false,
      location: false
    }
  };

  handleDeleteMember = memberId => {
    const [{ active }, dispatch] = this.context;
    active.retailer.sellers = active.retailer.sellers.map(member => {
      if (member.id === memberId) member.processing = true;
      return member;
    });
    dispatch({
      type: "updateActive",
      newActive: active
    });
    deleteSeller({ id: memberId }, () => {
      active.retailer.sellers = active.retailer.sellers.filter(member => {
        return member.id !== memberId;
      });
      dispatch({
        type: "updateActive",
        newActive: active
      });
    });
  };

  handleUserUpdate = (e, memberId) => {
    e.preventDefault();
    const [{ active }, dispatch] = this.context;
    active.retailer.sellers = active.retailer.sellers.map(member => {
      if (member.id === memberId) member.processing = true;
      return member;
    });
    dispatch({
      type: "updateActive",
      newActive: active
    });
    const formData = new FormData(e.target);
    updateSeller({ payload: formData, id: memberId }, res => {
      active.retailer.sellers = active.retailer.sellers.map(member => {
        if (member.id === memberId) return res.data;
        return member;
      });
      dispatch({
        type: "updateActive",
        newActive: active
      });
    });
  };

  handleMemberInvite = event => {
    event.preventDefault();
    const { target } = event;
    const { processing } = this.state;
    processing.invite = true;
    this.setState({
      processing
    });
    const formData = new FormData(target);
    createSeller({ payload: formData }, res => {
      const [{ active }, dispatch] = this.context;
      if (active.retailer.sellers && Array.isArray(active.retailer.sellers))
        active.retailer.sellers.unshift(res.data);
      else active.retailer.sellers = [res.data];
      dispatch({
        type: "updateActive",
        newActive: active
      });
    }).then(() => {
      processing.invite = false;
      this.setState({
        processing
      });
      target.reset();
    });
  };

  handleMemberLocation = (location, memberId) => {
    const [{ active }, dispatch] = this.context;
    active.retailer.sellers = active.retailer.sellers.map(member => {
      if (member.id === memberId) member.processing = true;
      return member;
    });
    dispatch({
      type: "updateActive",
      newActive: active
    });
    updateSeller(
      { payload: { location_id: location.value }, id: memberId },
      res => {
        active.retailer.sellers = active.retailer.sellers.map(member => {
          if (member.id === memberId) return res.data;
          return member;
        });
        dispatch({
          type: "updateActive",
          newActive: active
        });
      }
    );
  };
  handleLocationCreate = event => {
    event.preventDefault();
    const { processing } = this.state;
    const { target } = event;
    processing.location = true;
    this.setState({
      processing
    });
    const formData = new FormData(target);
    createLocation({ payload: formData }, res => {
      const [{ active }, dispatch] = this.context;
      if (active.retailer.locations && Array.isArray(active.retailer.locations))
        active.retailer.locations.unshift(res.data);
      else active.retailer.locations = [res.data];
      dispatch({
        type: "updateActive",
        newActive: active
      });
    }).then(() => {
      processing.location = false;
      this.setState({
        processing
      });
      target.reset();
    });
  };

  handleDeleteLocation = locationId => {
    const [{ active }, dispatch] = this.context;
    active.retailer.locations = active.retailer.locations.map(location => {
      if (location.id === locationId) location.processing = true;
      return location;
    });
    dispatch({
      type: "updateActive",
      newActive: active
    });
    deleteLocation({ id: locationId }, () => {
      active.retailer.locations = active.retailer.locations.filter(location => {
        return location.id !== locationId;
      });
      dispatch({
        type: "updateActive",
        newActive: active
      });
    });
  };

  handleCreateTourCreatorCheckbox = (userId) => {
    const [{ active }, dispatch] = this.context;
    active.retailer.sellers = active.retailer.sellers.map(member => {
      if (member.id === userId) member.processing = true;
      return member;
    });
    const formData = new FormData();
    formData.append("user_id", userId);
    createTourCreator({payload: formData}, () => {
          active.retailer.sellers = active.retailer.sellers.map(member => {
            if (member.id === userId) {
              member.user.isTourCreator = true;
              member.processing = false;
            }
            return member;
          });
          dispatch({
            type: "updateActive",
            newActive: active
          });
        }
    );
   };

  handleDeleteTourCreatorCheckbox = (userId) => {
    const [{ active }, dispatch] = this.context;
    active.retailer.sellers = active.retailer.sellers.map(member => {
      if (member.id === userId) member.processing = true;
      return member;
    });
    const formData = new FormData();
    formData.append("user_id", userId);
    deleteTourCreatorByUserId({payload: formData}, (res) => {
          active.retailer.sellers = active.retailer.sellers.filter(member => {
            if (member.id === userId) {
              member.user.isTourCreator = false;
              member.processing = false;
            }
            return member;
          });
          dispatch({
            type: "updateActive",
            newActive: active
          });
         }
    );
  };


  render() {
    const [
      {
        componentData: { loading },
        active: { retailer },
        auth: { user },
        localization: {
          organization: { control: lang }
        }
      }
    ] = this.context;
    const dir = user && user.locality === "he-il" ? "rtl" : "ltr";
    return (
      <SellerLayout>
        <div className="uk-position-relative">
          {(loading || !retailer) && (
            <div
              className="uk-position-cover uk-flex uk-flex-middle uk-flex-center"
              style={{ zIndex: 100000 }}
            >
              <Loader shadow={true} />
            </div>
          )}
          <div
            className={`uk-container uk-container-expand`}
            style={{ opacity: loading ? ".2" : "1" }}
          >
            <ul uk-tab="" className="uk-margin-small-top">
              <li className="uk-active">
                <a href="">{lang.members}</a>
              </li>
              <li>
                <a href="">{lang.locations}</a>
              </li>
            </ul>
            <ul className="uk-switcher uk-margin-medium-top uk-margin-medium-bottom">
              <li className="uk-active">
                <TeamInviteForm
                  dir={dir}
                  id={this.state.modals.invite}
                  onSubmit={this.handleMemberInvite}
                  processing={this.state.processing.invite}
                ></TeamInviteForm>
                {retailer && user && (
                  <TeamIndex
                    onDiscountChange={this.handleUserUpdate}
                    onLevelChange={this.handleUserUpdate}
                    locationOptions={{
                      options: retailer.locations.map(location => {
                        return { name: location.address, value: location.id };
                      }),
                      onChange: this.handleMemberLocation
                    }}
                    members={
                      retailer.sellers &&
                      retailer.sellers.filter(member => {
                        return member.user && member.id !== user.id;
                      })
                    }
                    factor={ retailer.sellers &&
                    retailer.sellers.filter(member => {
                      return member.user && member.id !== user.id;
                    }).length}
                    onDelete={this.handleDeleteMember}
                    inviteModalId={this.state.modals.invite}
                    onCreateTourCreator={this.handleCreateTourCreatorCheckbox}
                    onDeleteTourCreator={this.handleDeleteTourCreatorCheckbox}
                  />
                )}
              </li>
              <li>
                {retailer && (
                  <LocationForm
                    id={this.state.modals.location}
                    dir={dir}
                    locateableId={retailer.id}
                    onSubmit={this.handleLocationCreate}
                    processing={this.state.processing.location}
                  ></LocationForm>
                )}
                {retailer && (
                  <LocationIndex
                    onDelete={this.handleDeleteLocation}
                    locationModalId={this.state.modals.location}
                    locations={retailer.locations}
                  ></LocationIndex>
                )}
              </li>
            </ul>
          </div>
        </div>
      </SellerLayout>
    );
  }
}

export default TeamView;
