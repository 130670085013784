import React from "react";
import { withRouter, Link } from "react-router-dom";
import { parse } from "query-string";
import { Input } from "../components/Input/Input";
import { Button } from "../components/Button/Button";
import { StateContext } from "../app/state";
import { setPassword } from "../app/actions";

class ResetPassword extends React.Component {
  static contextType = StateContext;
  state = {
    sending: false,
    token: null,
    done: false
  };
  componentDidMount = () => {
    const search = parse(this.props.location.search);
    this.setState({
      token: search.token
    });
  };
  handleSetPassword = e => {
    e.preventDefault();
    this.setState({
      sending: true
    });
    const formData = new FormData(e.target);
    formData.append("token", this.state.token);
    setPassword({ payload: formData }, res => {
      this.setState({
        done: true
      });
    }).then(() => this.setState({ sending: false }));
  };
  render() {
    const [
      {
        localization: { fields, actions, setPassword }
      }
    ] = this.context;
    return (
      <div>
        <div
          className="uk-padding uk-flex uk-flex-column uk-flex-center uk-flex-middle"
          style={{ minHeight: "80vh" }}
        >
          <div className="viz-box-shadow-special uk-padding uk-animation-fade viz-bg-white uk-width-large">
            <div className="uk-animation-slide-bottom-small">
              <div>
                <h4 className="uk-text-bold">{setPassword.title}</h4>
              </div>
              {this.state.done && (
                <p>
                  {setPassword.success} <br />
                  {setPassword.body_1} <a href="/login">{setPassword.body_2}</a>{" "}
                  {setPassword.body_3}
                </p>
              )}
              {!this.state.done && (
                <form
                  className="uk-width-large uk-width-expand@m"
                  id="login-form"
                  onSubmit={this.handleSetPassword}
                >
                  <Input
                    atts={{
                      autoFocus: true
                    }}
                    name="password"
                    type="password"
                    placeholder={fields.password.placeholder}
                    label={fields.password.label}
                    tooltip={fields.password.tooltip}
                    required={true}
                  />
                  <Input
                    name="password_confirmation"
                    type="password"
                    placeholder={fields.passwordConfirmation.placeholder}
                    label={fields.passwordConfirmation.label}
                    tooltip={fields.passwordConfirmation.tooltip}
                    required={true}
                  />
                  <Button
                    label={actions.setPassword.label}
                    type="primary"
                    cssClasses="uk-width-1-1 uk-margin-small-top"
                    loading={this.state.sending}
                  />
                </form>
              )}
            </div>
          </div>
          <div className="uk-text-center uk-padding">
            <Link to="/login" className="uk-text-muted">
              {actions.login.label}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ResetPassword);
