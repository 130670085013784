import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { StateContext } from "../app/state";
import * as QueryString from "query-string";
import TopBar from "../components/TopBar/TopBar";
import Title from "../components/Title/Title";
import Gallery from "../components/Gallery/Gallery";
import SectionTitle from "../components/SectionTitle/SectionTitle";
import Quote from "../components/Quote/Quote";
import { indexProperties, updateProperty } from "../app/actions";
import { groupBy } from "lodash";
import { Loader } from "../components/Loader/Loader";
import ErrorBoundary from "../components/ErrorCatch/ErrorBoundary";
import { Button } from "../components/Button/Button";
import { Input } from "../components/Input/Input";
import Spread from "../components/Spread/Spread";
import Tour from "../components/Tour/Tour";

class PropertyPage extends Component {
  static contextType = StateContext;
  constructor(props) {
    super(props);
    this.scrollToTop = React.createRef();
  }
  state = {
    dir: "ltr"
  };
  componentDidMount() {
    window.addEventListener("scroll", this.scrollCheck);
    this.handleDir();
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollCheck);
  }
  scrollCheck = () => {
    if (window.scrollY >= 300) {
      if (this.scrollToTop.current.classList.contains("uk-hidden"))
        this.scrollToTop.current.classList.remove("uk-hidden");
    } else {
      if (!this.scrollToTop.current.classList.contains("uk-hidden"))
        this.scrollToTop.current.classList.add("uk-hidden");
    }
  };
  scrollToTopFunction = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.scrollToTop.current.classList.add("uk-hidden");
  };
  handleDir = () => {
    const search = QueryString.parse(this.props.location.search);
    if (
      search.locality &&
      (search.locality === "he" || search.locality === "he-il")
    )
      this.setState({
        dir: "rtl"
      });
  };
  clusterImages = property => {
    let media = [];
    if (property.media) media = media.concat(property.media);
    if (property.tour) {
      if (property.tour.media) media = media.concat(property.tour.media);
    }
    return media;
  };

  render() {
    const [
      {
        active: { propertie: property },
        localization: { publicLayout: lang }
      }
    ] = this.context;
    if (!property) {
      return (
        <div className="uk-section uk-text-center">
          <Loader></Loader>
        </div>
      );
    }

    return (
      <>
        <div className="uk-container uk-padding-remove">
          <div className="uk-background-default  viz-box-shadow-special">
            <div
              className="uk-container uk-margin-large-top uk-margin-medium-left uk-margin-medium-right"
              style={{ paddingTop: "2rem" }}
              dir={this.state.dir}
            >
              <ErrorBoundary>
                <Title
                  actions={[
                    {
                      icon: "pencil",
                      atts: { "uk-toggle": `#update-property` }
                    }
                  ]}
                  title={property.name}
                  subtitle={property.project && property.project.name}
                  className="uk-margin-medium-bottom"
                  // add retailer
                  // retailer={user && user.seller && user.seller.retailer}
                ></Title>
              </ErrorBoundary>
            </div>
          </div>

          <div
            className="uk-background-default"
            uk-sticky=""
            style={{ zIndex: "90000 !important" }}
            dir={this.state.dir}
          >
            <div className="uk-container">
              <div className="uk-flex uk-flex-left">
                <ul
                  className="uk-tab uk-margin-remove uk-width-expand uk-flex-left"
                  uk-scrollspy-nav="closest: li; scroll: true; offset: 150"
                >
                  {property.tour &&
                    property.tour.data &&
                    property.tour.data.dataUrl && (
                      <li className="uk-active uk-padding-remove">
                        <a href="#tour">{lang.sections.tour}</a>
                      </li>
                    )}
                  {((property.media && property.media.length > 0) ||
                    (property.tour.media &&
                      property.tour.media.length > 0)) && (
                    <li className="uk-active uk-padding-remove">
                      <a href="#gallery">{lang.sections.gallery}</a>
                    </li>
                  )}
                  {!!property.tour.floorplan && (
                    <li>
                      <a href="#plan">{lang.sections.plan}</a>
                    </li>
                  )}
                  {/* <li>
                  <a href="#tour">{lang.sections.tour}</a>
                </li> */}
                  {property.extra && property.extra.quoteId && (
                    <li>
                      <a href="#quote">{lang.sections.quote}</a>
                    </li>
                  )}
                  {property.tour &&
                    property.tour.spreads &&
                    property.tour.spreads.length > 0 &&
                    property.tour.spreads[0].snaps &&
                    property.tour.spreads[0].snaps.length > 0 && (
                      <li>
                        <a href="#spread">{lang.sections.spread}</a>
                      </li>
                    )}
                </ul>
                <div className="viz-nav-title uk-width-auto uk-padding-small">
                  <span>{property.name}</span>
                  {property.project && (
                    <span className="uk-text-meta">
                      {" "}
                      / {property.project.name}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className="uk-section"
            dir={this.state.dir}
            style={{ paddingTop: "0" }}
          >
            {property.tour && property.tour.data && property.tour.data.dataUrl && (
              <div className="uk-padding uk-background-default viz-box-shadow-special uk-margin-large-top">
                <div id="tour">
                  <SectionTitle
                    content={lang.sections.tour}
                    icon="move"
                  ></SectionTitle>
                  <ErrorBoundary>
                    <Tour
                      tourId={property.tour.id}
                      // add retailer
                      // retailer={user.seller.retailerId}
                      lang={this.state.dir === "rtl" ? "he" : "en"}
                    />
                  </ErrorBoundary>
                </div>
              </div>
            )}
            <div className="uk-padding uk-background-default viz-box-shadow-special uk-margin-large-top">
              <div id="gallery">
                <SectionTitle
                  content={lang.sections.gallery}
                  icon="image"
                ></SectionTitle>
                <ErrorBoundary>
                  <Gallery
                    lang={lang.gallery}
                    images={this.clusterImages(property)}
                    upload={false}
                    {...lang.gallery}
                    mediable_id={
                      property && property.tour ? property.tour.id : property.id
                    }
                    mediable_type={
                      property && property.tour ? "App\\Tour" : "App\\Property"
                    }
                  ></Gallery>
                </ErrorBoundary>
              </div>
            </div>
            {!!property.tour.floorplan && (
              <div className="uk-padding uk-background-default viz-box-shadow-special uk-margin-large-top">
                <div id="plan">
                  <SectionTitle
                    content={lang.sections.plan}
                    icon="grid"
                  ></SectionTitle>
                  <div className="uk-position-relative uk-text-center">
                    <img
                      data-src={property.tour.floorplan.sourceUrl}
                      alt=""
                      uk-img=""
                      height="720"
                      style={{
                        height: "720px"
                      }}
                    />
                    <Button
                      label={lang.downloadPlan}
                      icon="download"
                      cssClasses="uk-position-top-left uk-position-small uk-background-default uk-box-shadow-hover"
                      onClick={() => {
                        window.open(property.tour.floorplan.mediaUrl, "_blank");
                      }}
                    ></Button>
                  </div>
                </div>
              </div>
            )}
            {/* <div id="tour" className="uk-margin-large-top">
              <SectionTitle
                content={lang.sections.tour}
                icon="video-camera"
              ></SectionTitle>
            </div> */}
            {property.extra && property.extra.quoteId && (
              <div className="uk-padding uk-background-default viz-box-shadow-special uk-margin-large-top">
                <div id="quote">
                  <SectionTitle
                    content={lang.sections.quote}
                    icon="cart"
                  ></SectionTitle>
                  <ErrorBoundary>
                    <Quote
                      noActions
                      // userId={user.id}
                      quoteId={property.extra && property.extra.quoteId}
                      lang={lang.quote}
                      locality={this.state.dir === "rtl" ? "he-il" : "en-us"}
                    ></Quote>
                  </ErrorBoundary>
                </div>
              </div>
            )}
            {/* {property.tour &&
              property.tour.spreads &&
              property.tour.spreads.length > 0 &&
              property.tour.spreads[0].snaps &&
              property.tour.spreads[0].snaps.length > 0 && (
                <div className="uk-padding uk-background-default viz-box-shadow-special uk-margin-large-top">
                  <div id="spread">
                    <SectionTitle
                      content={lang.sections.spread}
                      icon="move"
                    ></SectionTitle>
                    <ErrorBoundary>
                      <Spread
                        tourId={property.tour.id}
                        // Need to add retailer
                        // retailer={user.seller.retailerId}
                        lang={this.state.dir === "rtl" ? "he" : "en"}
                        // auth={token}
                      />
                    </ErrorBoundary>
                  </div>
                </div>
              )} */}
          </div>
        </div>
        <div className="uk-section" dir={this.state.dir}>
          <div className="uk-container">
            <hr className="uk-divider-icon" />
            <div className="uk-text-center uk-text-small">
              <span>{`${lang.footer.powered} Visual Estate`}</span>
            </div>
          </div>
        </div>
        <div
          className="uk-position-fixed uk-text-right"
          style={{ bottom: "20px", right: "20px", zIndex: "900" }}
        >
          <div className="uk-width-auto uk-flex uk-flex-column uk-flex-bottom">
            <div ref={this.scrollToTop} className="uk-width-auto uk-hidden">
              <Button
                shape="circle"
                icon="chevron-up"
                onClick={this.scrollToTopFunction}
                cssClasses="uk-box-shadow-large uk-margin-small-bottom uk-background-default viz-border-remove"
                atts={{
                  style: { padding: "0 10px" }
                }}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(PropertyPage);
