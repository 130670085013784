import React from "react";
import Dropdown from "../Dropdown/Dropdown";
import { useStateValue } from "../../app/state";
import { Button } from "../Button/Button";
import Editable from "../Editable/Editable";
import {Checkbox} from "../Checkbox/Checkbox";

const TeamMember = props => {
  const [
    {
      localization: {
        organization: { team: lang }
      }
    }
  ] = useStateValue();
  return (
    <React.Fragment>
      <div className="uk-background-default uk-padding-small uk-border-rounded viz-box-shadow-special uk-margin uk-position-relative">
        {props.member.processing && (
          <div
            className="uk-position-cover uk-overlay-default"
            style={{ zIndex: 10 }}
          >
            <div className="uk-position-center">
              <div uk-spinner=""></div>
            </div>
          </div>
        )}
        <div className="uk-padding-small uk-flex-middle" uk-grid="">
          <div className="uk-width-medium">
            <div className="uk-flex uk-flex-middle">
              <div>
                {props.member.user &&
                  props.member.user.profilePicture &&
                  props.member.user.profilePicture.thumbUrl && (
                    <div
                      className="viz-profile-avatar"
                      uk-img=""
                      data-src={
                        props.member.user &&
                        props.member.user.profilePicture &&
                        props.member.user.profilePicture.thumbUrl
                      }
                    />
                  )}
                {props.member.user && !props.member.user.profilePicture && (
                  <div className="viz-profile-avatar-replace">
                    <span uk-icon="icon:user" />
                  </div>
                )}
              </div>
              <div className="uk-margin-small-left">
                <p className="uk-margin-remove uk-text-bold">
                  {props.member.user
                    ? `${
                        props.member.user.name.split(" ")[0]
                      } ${props.member.user.name.split(" ")[1] &&
                        props.member.user.name.split(" ")[1][0]}.`
                    : ""}
                </p>
                <p className="uk-margin-remove uk-text-meta">
                  {props.member.user && props.member.user.email}
                  <br />
                  {props.member.user && props.member.user.phone}
                </p>
              </div>
            </div>
          </div>
          <div className="uk-width-1-5">
            <div>
              <Editable
                name={"level"}
                processing={props.member.processing}
                targetId={props.member.id}
                onSubmit={props.onLevelChange}
                value={props.member.user.level}
                placeholder={lang.ns}
                // button={true}
              ></Editable>
            </div>
          </div>
          <div className="uk-width-1-5">
            <div>
              {props.locationOptions && (
                <Dropdown
                  value={
                    props.member.location
                      ? props.member.location.address
                      : "User Location"
                  }
                  options={props.locationOptions.options}
                  onSelect={props.locationOptions.onChange}
                  extraProp={props.member.id}
                ></Dropdown>
              )}
              {!props.locationOptions && (
                <Button
                  label={lang.na}
                  disabled={true}
                  type={"disabled"}
                ></Button>
              )}
            </div>
          </div>
          <div className="uk-width-1-5">
            <div>{props.member.user.timeStamps.created}</div>
          </div>
          <div className="uk-auto">
            <div>
              <ul className="uk-iconnav">
                <li>
                  <a
                    uk-icon="icon:trash"
                    uk-tooltip={lang.actions.removeMember}
                    onClick={() => props.onDelete(props.member.id)}
                  ></a>
                  <Checkbox
                      label={lang.actions.tourCreator}
                      name="is Tour creator"
                      value={props.member.user.isTourCreator}
                      uk-tooltip={lang.actions.removeMember}
                      onChange={() => props.member.user.isTourCreator ? props.onDeleteTourCreator(props.member.user.id) : props.onCreateTourCreator(props.member.user.id)}
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default TeamMember;
