import React, { useRef, useEffect } from "react";

const Spread = props => {
  const iframeRef = useRef();

  function receiveMessage({ data }) {
    iframeRef.current.style.height = `${data.height}px`;
  }
  useEffect(() => {
    window.addEventListener("message", receiveMessage, false);
    return () => {
      window.removeEventListener("message", receiveMessage);
    };
  });
  return (
    <iframe
      title="layouts"
      src={`${process.env.REACT_APP_NEW_APP}/layout?id=${props.tourId}&locale=${
        props.lang
      }&auth=${props.auth}&retailer=${props.retailer}&host=${
        window.location.hostname
      }${
        process.env.REACT_APP_ENV === "STAGING"
          ? `&endpoint=${process.env.REACT_APP_APP_ENDPOINT}`
          : ""
      }`}
      width="100%"
      scrolling="no"
      ref={iframeRef}
    />
  );
};

export default Spread;
