import React, { Component } from "react";
import { Alert } from "../components/Alert/Alert";
import Search from "../components/Search/Search";
import SellerLayout from "../layouts/SellerLayout";
import ConsumerForm from "../components/ConsumerForm/ConsumerForm";
import { StateContext } from "../app/state";
import QueryString from "query-string";
import { withRouter } from "react-router-dom";
import { Loader } from "../components/Loader/Loader";
import { Button } from "../components/Button/Button";
import { LocalSearch } from "../app/utilities";

class ConsumerSearch extends Component {
  static contextType = StateContext;
  state = {
    searchBy: this.props.match.path.split("/")[1],
    project: this.props.match.params.projectId,
    units: [],
  };
  handleSearchChange = e => {
    e.preventDefault();
    this.setState({
      searchBy: e.target.value,
      project: ""
    });
    this.props.history.push(`/${e.target.value}`);
  };
  handleChange = term => {
    if (!this.state.project) {
      const [{ componentData, auth }, dispatch] = this.context;

      componentData.searchTerm = term;
      dispatch({
        type: "updateComponentData",
        newComponentData: componentData
      });
      const query = {};
      if (this.state.searchBy === "consumers") {
        query.filter = JSON.stringify({ name: term, email: term, phone: term });
        query.relation = "user";
      } else {
        query.name = term;
        query.common = true;
      }
      query.retailer = auth.user.seller.retailerId;
      this.props.history.push({
        search: QueryString.stringify(query)
      });
    } else {
      const [
        {
          active: {
            project: { properties }
          }
        }
      ] = this.context;
      this.setState({
        units: LocalSearch(term, properties)
      });
    }
  };
  handleResetProject = () => {
    this.setState({
      project: ""
    });
    this.props.history.push(`/projects`);
  };
  handleSelect = id => {
    const {
      auth: {
        user: {
          seller: { retailerId }
        }
      }
    } = this.context[0];
    this.setState({
      loading: true
    });
    if (this.state.searchBy === "consumers" || this.state.project) {
      this.props.history.push(
        `/consumers/${id}?relation[]=properties&relation[]=user&retailer=${retailerId}`
      );
    } else {
      this.props.history.push(
        `/projects/${id}?relation[]=properties&retailer=${retailerId}&search`
      );
      this.setState({
        project: id
      });
    }
  };

  render() {
    const [
      {
        localization: { consumers: lang },
        active: { project },
        data: { consumers, projects },
        componentData: { loading, searching },
        auth: { user }
      }
    ] = this.context;
    return (
        <SellerLayout>
          {(process.env.REACT_APP_ENV === "PRODUCTION" || user && user.level > 0) &&
        <div className="uk-section uk-position-relative">
          {loading && (
            <div
              className="uk-position-cover uk-flex uk-flex-middle uk-flex-center"
              style={{ zIndex: 100000 }}
            >
              <Loader shadow={true} />
            </div>
          )}
          <div
            className={`uk-container`}
            style={{ opacity: loading ? ".2" : "1" }}
          >
            <div uk-grid="">
              <div className="uk-width-expand">
                <div>
                  <h2 className="uk-animation-fade uk-text-bold uk-margin-medium">
                    {lang.searchByConsumer.title}
                  </h2>
                </div>
              </div>
              <div className="uk-width-auto">
                <div className="uk-flex">
                  <Button
                    label={lang.messages.noConsumer.action}
                    type="default"
                    cssClasses="uk-width-1-1 uk-margin-small-right"
                    atts={{ "uk-toggle": "target: #create-consumer" }}
                  ></Button>
                </div>
              </div>
            </div>
            <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
              <label>
                <input
                  class="uk-radio"
                  type="radio"
                  name="search-by"
                  value="consumers"
                  checked={this.state.searchBy === "consumers"}
                  onChange={this.handleSearchChange}
                />{" "}
                {lang.options.byConsumer}
              </label>
              <label>
                <input
                  class="uk-radio"
                  type="radio"
                  name="search-by"
                  value="projects"
                  checked={this.state.searchBy === "projects"}
                  onChange={this.handleSearchChange}
                />{" "}
                {lang.options.byProject}
              </label>
            </div>
            <Search
              onClick={this.handleSelect}
              lang={
                this.state.searchBy === "consumers"
                  ? lang.searchByConsumer
                  : this.state.searchBy === "projects" && !this.state.project
                  ? lang.searchByProject
                  : lang.searchByUnit
              }
              flag={this.state.project && project && project.name}
              results={
                this.state.searchBy === "consumers"
                  ? consumers
                  : this.state.searchBy === "projects" && this.state.project
                  ? this.state.units
                  : projects
              }
              onChange={this.handleChange}
              searching={searching}
              flagOnClick={this.handleResetProject}
            />
            <div className="uk-width-large uk-align-center">
              {!searching && (!consumers || consumers.length === 0) && (
                <Alert
                  message={lang.messages.noConsumer.body}
                  title={lang.messages.noConsumer.title}
                  type="action"
                  cssClasses="viz-box-shadow-special uk-padding uk-margin-medium-top uk-animation-slide-bottom-small"
                  action={{
                    label: lang.messages.noConsumer.action,
                    type: "primary",
                    cssClasses: "uk-width-1-1",
                    atts: { "uk-toggle": "target: #create-consumer" }
                  }}
                />
              )}
            </div>
          </div>
        </div>}
        <div id="create-consumer" uk-modal="bg-close:false">
          <div className="uk-modal-dialog uk-modal-body uk-width-large">
            <button
              className="uk-modal-close-default"
              type="button"
              uk-close=""
            />
            <ConsumerForm lang={lang.create} />
          </div>
        </div>
      </SellerLayout>
    );
  }
}

export default withRouter(ConsumerSearch);
