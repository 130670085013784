import React from "react";
import { Input } from "../Input/Input";
import { Button } from "../Button/Button";
import DropZone from "../DropZone/DropZone";

const CreateProperty = props => {
  return (
    <React.Fragment>
      <progress
        style={{ height: "3px" }}
        className="uk-progress"
        value={`${props.step}` || 1}
        max="3"
      ></progress>
      <ul
        uk-switcher={`connect: #${props.switcherId}; animation: uk-animation-fade`}
        hidden
      >
        <li className="uk-active">
          <a href="#"></a>
        </li>
        <li>
          <a href="#"></a>
        </li>
        <li>
          <a href="#"></a>
        </li>
      </ul>
      <ul id={props.switcherId} className="uk-switcher">
        <li className="uk-active">
          <h4 className="uk-text-bold">{props.lang.steps.name.title}</h4>
          <form onSubmit={e => props.onProgress(e, 1)}>
            <Input
              name="name"
              label={props.lang.fields.name.label}
              required="true"
            />
            <div uk-grid="" className="uk-child-width-auto uk-flex-between">
              <div>
                <Button
                  label={props.lang.actions.cancel.label}
                  onClick={props.onCancel}
                />
              </div>
              <div>
                <Button
                  label={props.lang.actions.next.label}
                  type="primary"
                  icon="triangle-right"
                />
              </div>
            </div>
          </form>
        </li>
        <li>
          <h4 className="uk-text-bold">{props.lang.steps.upload.title}</h4>
          <form onSubmit={e => props.onProgress(e, 2)}>
            <DropZone
              fieldName="floorplan"
              className="uk-margin-bottom"
              required={true}
            >
              <p>{props.lang.fields.plan.label}</p>
            </DropZone>
            <div uk-grid="" className="uk-child-width-auto uk-flex-between">
              <div>
                <Button
                  label={props.lang.actions.cancel.label}
                  onClick={props.onCancel}
                />
              </div>
              <div>
                <Button
                  label={props.lang.actions.next.label}
                  type="primary"
                  icon="triangle-right"
                />
              </div>
            </div>
          </form>
        </li>
        <li>
          <h4 className="uk-text-bold">{props.lang.steps.mes.title}</h4>
          <form onSubmit={e => props.onProgress(e, 3)}>
            <Input
              name="door_width"
              label={props.lang.fields.door_width.label}
              type="number"
              required={true}
            />
            <Input
              name="ceiling_height"
              label={props.lang.fields.ceiling_height.label}
              type="number"
              required={true}
            />
            <div uk-grid="" className="uk-child-width-auto uk-flex-between">
              <div>
                <Button
                  label={props.lang.actions.cancel.label}
                  onClick={props.onCancel}
                />
              </div>
              <div>
                <Button
                  label={props.lang.actions.finish.label}
                  type="primary"
                  loading={props.processing}
                />
              </div>
            </div>
          </form>
        </li>
      </ul>
    </React.Fragment>
  );
};

export default CreateProperty;
