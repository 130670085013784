import React, {useContext, useState} from "react";
import {StateContext} from "../../app/state";
import {getAuthUser, notification, updatePassword, updateUser} from "../../app/actions";
import SellerLayout from "../../layouts/SellerLayout";
import Title from "../../components/Title/Title";
import {Loader} from "../../components/Loader/Loader";
import {Input} from "../../components/Input/Input";
import {Button} from "../../components/Button/Button";
import PageLayout from "../../components/Patricia/PageLayout/PageLayout";

function PatriciaAccountSettings(){
    const context = useContext(StateContext);
    const [
        {
            auth,
            localization: {
                messages: { update: updateMessageLang },
                account: accountLang
            }
        },
        dispatch
    ] = context;
    const [sending, setSending] = useState(false);

    function handleUserUpdate(e) {
        e.preventDefault();
        setSending(true);
        const formData = new FormData(e.target);
        let formKeys = [];
        for (var key of formData.keys()) {
            if (auth.user[key] === formData.get(key)) {
                formKeys.push(key);
            }
        }
        formKeys.forEach(key => {
            formData.delete(key);
        });
        updateUser({ payload: formData, id: auth.user.id }, res => {
            notification(updateMessageLang.success, "success");
            getAuthUser({ params: { seller: true, consumer: true } }, ({ data }) => {
                auth.user = data;
                dispatch({
                    type: "setAuth",
                    newAuth: auth
                });
            });
        }).then(() => {
            setSending(false);
        });
    };

   function handleUpdatePassword(e) {
        e.preventDefault();
        const formData = new FormData(e.target);
        setSending(true);
        updatePassword({ payload: formData }, () => {
            e.target.reset();
        }).then(() => {
            setSending(false);
        });
    };

        return (
            <PageLayout>
                <div className="uk-container uk-container-expand">
                    <Title title={accountLang.title} />
                    {!auth.user && <Loader />}
                    {auth.user && (
                        <React.Fragment>
                            <ul
                                uk-tab="animation:uk-animation-fade"
                                className="uk-margin-small-top"
                            >
                                <li className="uk-active">
                                    <a href="#">{accountLang.tabs.info}</a>
                                </li>
                                <li>
                                    <a href="#">{accountLang.tabs.password}</a>
                                </li>
                            </ul>
                            <ul className="uk-switcher uk-margin-medium-top uk-margin-medium-bottom">
                                <li className="uk-active">
                                    <div>
                                        <div className="uk-align-center uk-background-default viz-box-shadow-special uk-width-large uk-card-body">
                                            <h4 className="uk-text-bold">{accountLang.forms.info.title}</h4>
                                            <form onSubmit={handleUserUpdate}>
                                                <Input
                                                    label={accountLang.forms.info.fields.name.label}
                                                    name="name"
                                                    value={auth.user.name}
                                                />
                                                <Input
                                                    label={accountLang.forms.info.fields.email.label}
                                                    name="email"
                                                    value={auth.user.email}
                                                />
                                                <Input
                                                    tooltip={accountLang.forms.info.fields.phone.tooltip}
                                                    label={accountLang.forms.info.fields.phone.label}
                                                    name="phone"
                                                    value={auth.user.phone.replace(/\D/g, "")}
                                                />
                                                <Button
                                                    label={accountLang.forms.info.actions.update}
                                                    type="primary"
                                                    cssClasses="uk-width-1-1 patricia-ai-color"
                                                    loading={sending}
                                                />
                                            </form>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <div className="uk-align-center uk-background-default viz-box-shadow-special uk-width-large uk-card-body">
                                            <h4 className="uk-text-bold">
                                                {accountLang.forms.password.title}
                                            </h4>
                                            <form onSubmit={handleUpdatePassword}>
                                                <Input
                                                    label={accountLang.forms.password.fields.password.label}
                                                    name="password"
                                                    required={true}
                                                    type="password"
                                                />
                                                <Input
                                                    tooltip={accountLang.forms.password.fields.newPassword.tooltip}
                                                    label={accountLang.forms.password.fields.newPassword.label}
                                                    name="new_password"
                                                    required={true}
                                                    type="password"
                                                />
                                                <Input
                                                    tooltip={accountLang.forms.password.fields.newPassword.tooltip}
                                                    label={accountLang.forms.password.fields.newPasswordConfirm.label}
                                                    name="new_password_confirmation"
                                                    required={true}
                                                    type="password"
                                                />
                                                <Button
                                                    label={accountLang.forms.password.actions.update}
                                                    type="primary"
                                                    cssClasses="uk-width-1-1 patricia-ai-color"
                                                    loading={sending}
                                                />
                                            </form>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </React.Fragment>
                    )}
                </div>
            </PageLayout>
        );
}

export default PatriciaAccountSettings;