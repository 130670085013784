import React, {useEffect, useState} from "react";
import { Button } from "../../components/Button/Button";
import {createDesignPackageOrder, getDesignPackage, indexDesignPackages} from "../../app/actions";
import Modal from "../Modal/Modal";
import UiKit from "uikit";
import {findIndex} from "lodash";
import DesignPackageProductsModal from "./DesignPackageProductsModal";
import {Loader} from "../Loader/Loader";
import {
    MobileView,
    isMobile
} from "react-device-detect";

function DesignPackageSection(props){

    const confirmationModalId = "confirmation-modal";
    const DesignPackageProductsModalId = 'order-details-id';
    const [designPackages, setDesignPackages] = useState(null);
    const [selectedDesignPackage, setSelectedDesignPackage] = useState(null);
    const [isDesignPackageChosen, setIsDesignPackageChosen] = useState(false);
    const [currentDesignPackage, setCurrentDesignPackage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingChooseRequest, setLoadingChooseRequest] = useState(false);

    useEffect(() => {
        setLoading(true)
        if (!props.designPackageId) {
            indexDesignPackages({}, res => {
                setDesignPackages(res.data);
            }).then(() => setLoading(false));
        } else {
            setIsDesignPackageChosen(true)
            getDesignPackage({id: props.designPackageId}, (res) => {
                setSelectedDesignPackage(res.data)
            }).then(() => setLoading(false));
        }
    }, [])

    function selectDesignPackage(e) {
        if (selectedDesignPackage && e.target.value === selectedDesignPackage.id) {
            setSelectedDesignPackage(null);
        } else {
            let index = findIndex(designPackages, ['id', e.target.value]);
            setSelectedDesignPackage(designPackages[index]);
        }
    }

    function onConfirm() {
        setLoadingChooseRequest(true);
        let formData = new FormData();
        formData.append('design_package_id', selectedDesignPackage.id);
        formData.append('property_id', props.propertyId);
        createDesignPackageOrder({payload: formData }).then(() => {
            setIsDesignPackageChosen(true);
            setLoadingChooseRequest(false);
            UiKit.modal(`#${confirmationModalId}`).hide();
        });
    }

    function onSelect(e){
        UiKit.modal(`#${confirmationModalId}`).show();
    }

    function onDesignPackageClicked(designPackage){
        setCurrentDesignPackage(designPackage);
        UiKit.modal(`#${DesignPackageProductsModalId}`).show();
    }

    return (
        <div className="viz-border-1 uk-border-rounded uk-padding uk-position-relative">
            <h3>{props.lang.header}</h3>
            {loading ? <Loader/> :
                isDesignPackageChosen ?
                <div>
                    <p>{`${props.lang.chosenMessage} ${selectedDesignPackage.name}.`}</p>
                </div>
                :
                <div className={`${!isMobile ? "uk-flex" : "uk-display-block"}`}>
                    <div className={`uk-width-2-4 uk-child-width-1-2 {${isMobile ? "uk-margin-xlarge-bottom" : null}`} uk-grid="">
                        {designPackages && designPackages.map((designPackage, key) => {
                            return (
                                <label className='' key={key}  style={{ cursor: "pointer" }}>
                                    <input className='uk-radio uk-margin-small-left'
                                           type="radio"
                                           name="design_package"
                                           value={designPackage.id}
                                           onClick={() => onDesignPackageClicked(designPackage)}
                                           onChange={(e) => selectDesignPackage(e)}/>
                                    {designPackage.name}
                                </label>
                            )
                        })}
                    </div>
                    <div className={`uk-width-1-4@l uk-position-relative ${isMobile ? "uk-margin-large-top uk-position-bottom-right" : null}`}>
                        <Button
                            label={props.lang.ok}
                            cssClasses={`patricia-ai-color  ${!isMobile ? "uk-width-1-1@ uk-position-bottom" : "uk-width-1-2 uk-position-bottom-left"}`}
                            type="primary"
                            onClick={onSelect}
                        />
                    </div>
                </div>
            }
            <Modal
                id={confirmationModalId}
                title=""
                dir={props.user && props.user.locality === "he-il" ? "rtl" : ""}
            >
                <p className="patricia-hebrew-font">{props.lang.modals.confirmation.message}</p>
                <div uk-grid="" className="uk-margin-top uk-grid-small patricia-hebrew-font">
                    <div className="uk-width-auto">
                        <Button
                            label={props.lang.modals.confirmation.cancel}
                            cssClasses="uk-width-1-1 uk-modal-close"
                        />
                    </div>
                    <div className="uk-width-expand">
                        <Button
                            label={props.lang.modals.confirmation.ok}
                            cssClasses="uk-width-1-1 patricia-ai-color"
                            type="primary"
                            loading={loadingChooseRequest}
                            onClick={onConfirm}
                        />
                    </div>
                </div>
            </Modal>
            <DesignPackageProductsModal
                dir={props.user && props.user.locality === "he-il" ? "rtl" : ""}
                designPackage={currentDesignPackage}
                lang={props.lang.modals.packageProducts}
            />
        </div>
    )
}

export default DesignPackageSection;