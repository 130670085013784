import React, {useContext, useEffect, useState} from "react";
import PageLayout from "../../components/Patricia/PageLayout/PageLayout";
import Modal from "../../components/Modal/Modal";
import {StateContext} from "../../app/state";
import heLang from "../../localization/patricia-he-il";
import enLang from "../../localization/patricia-en-us";
import underConstruction from "../../under-construction.png"
import {Loader} from "../../components/Loader/Loader";
import {MobileView, BrowserView,
    isMobile
} from "react-device-detect";
function UnderConstructionPage() {
    const context = useContext(StateContext);
    const [
        {
            active: { propertie: property },
            auth: { user },
            designSet
        }
    ] = context;

    const Lang = user && user.locality === "en-us" ? enLang : heLang;
    const lang = Lang.underConstructionPage;
    // const [designSet, setDesignSet] = useState();
    const [loading, setLoading] = useState(false);

    // useEffect(() => {
    //     if (property.tour) {
    //         setLoading(true)
    //         getDesignSetByTour({params: {tour:  property.tour.id}}, res => {
    //             setDesignSet(res.data);
    //             setLoading(false)
    //         }).then(
    //         )
    //     }
    // }, [])

    return (
    <PageLayout>
        <BrowserView>
            <div className="uk-flex uk-flex-center uk-align-center uk-height-viewport patricia-hebrew-font" uk-height-viewport="expand: true">
                <div className='uk-margin-auto-top'>
                    <img src={underConstruction} alt="" style={{ maxWidth: "100%"}} uk-img=""/>
                    {/*<div className="uk-background-contain uk-panel uk-height-1-1  uk-flex uk-flex-center uk-flex-middle" uk-img="" data-src={underConstruction}/>*/}
                </div>
                <div className='uk-width-1-3 uk-text-center uk-margin-medium-top' style={{alignSelf: "center"}} >
                    <h1>{lang.title}</h1>
                        {loading ? <Loader/> : ( designSet ?
                            <div className="uk-margin-medium-bottom">
                                <div>{lang.designStyle.line1} <span>{designSet.design_style.name},</span></div>
                                <div>{lang.designStyle.line2} <span>{designSet.design_style.description}</span>.</div>
                                <div>{lang.designStyle.line3} </div>
                            </div>
                         : null)}
                    <div>{lang.body}</div>
                </div>
            </div>
        </BrowserView>
        <MobileView>
            <div className='uk-width-1-1 uk-text-center uk-padding' style={{alignSelf: "center"}} >
                <h5>{lang.title}</h5>
                <img src={underConstruction} alt="" uk-img=""/>
                <div>{lang.body}</div>
            </div>
        </MobileView>
    </PageLayout>
    )
}

export default UnderConstructionPage;