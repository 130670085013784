import React from "react";
import { StateContext } from "../app/state";
import SellerLayout from "../layouts/SellerLayout";
import Title from "../components/Title/Title";
import { Loader } from "../components/Loader/Loader";
import { Logo } from "../components/Logo/Logo";
import Modal from "../components/Modal/Modal";
import { withRouter } from "react-router-dom";
import { Button } from "../components/Button/Button";
import UIkit from "uikit";
import {
  updateDeveloper,
  deleteDeveloper,
  notification,
  createDeveloper
} from "../app/actions";
import { Input } from "../components/Input/Input";
import Search from "../components/Search/Search";
import { LocalSearch } from "../app/utilities";

class DevelopersIndex extends React.Component {
  static contextType = StateContext;
  constructor(props) {
    super(props);
    this.updateId = "update-developer-modal";
    this.deleteId = "delete-developer-modal";
    this.createId = "create-developer-modal";
  }
  state = {
    searchTerm: null,
    activeDeveloperIndex: null,
    sending: false,
    filteredDevelopers: null
  };
  handleSearch = term => {
    const [
      {
        data: { developers }
      }
    ] = this.context;
    this.setState({
      searchTerm: term,
      filteredDevelopers: LocalSearch(term, developers)
    });
  };
  handleCreateDeveloper = e => {
    e.preventDefault();
    this.setLoading(true);
    const form = e.target;
    const formData = new FormData(e.target);
    createDeveloper({ payload: formData, params: { data: "true" } }, res => {
      const [{ data }] = this.context;
      data.developers.unshift(res);
      UIkit.modal(`#${this.createId}`).hide();
      form.reset();
    }).then(() => this.setLoading(false));
  };
  setActiveDeveloper = (e, i) => {
    e.stopPropagation();
    this.setState({
      activeDeveloperIndex: i
    });
  };
  setSearchTerm = str => {
    this.setState({
      searchTerm: str
    });
  };
  resetActiveDeveloper = () => {
    this.setState({
      activeDeveloperIndex: null
    });
  };
  resetSearchTerm = () => {
    this.setState({
      searchTerm: null
    });
  };
  setLoading = bool => {
    this.setState({
      sending: bool
    });
  };
  handleDeleteDeveloper = id => {
    this.setLoading(true);
    const { activeDeveloperIndex } = this.state;
    deleteDeveloper({ id }, res => {
      UIkit.modal(`#${this.deleteId}`).hide();
      this.resetActiveDeveloper();
      this.updateDevelopersIndex(activeDeveloperIndex);
    }).then(() => this.setLoading(false));
  };
  handleUpdateDeveloper = (e, id) => {
    e.preventDefault();
    this.setLoading(true);
    const formData = new FormData(e.target);
    updateDeveloper({ payload: formData, id }, res => {
      this.updateDevelopersIndex(this.state.activeDeveloperIndex, res);
      UIkit.modal(`#${this.updateId}`).hide();
    }).then(() => this.setLoading(false));
  };
  updateDevelopersIndex = (key, developer = null) => {
    const [{ data }, dispatch] = this.context;
    if (developer) data.developers[key] = developer;
    else delete data.developers[key];
    dispatch({
      type: "updateData",
      newData: data
    });
  };
  gotoProjects = id => {
    const [{ componentData }, dispatch] = this.context;
    dispatch({
      type: "updateComponentData",
      newComponentData: { ...componentData, loading: true }
    });
    this.props.history.push(`/projectCommons?developer=${id}`);
  };
  render() {
    const [
      {
        localization: { developers: lang },
        data: { developers }
      }
    ] = this.context;
    const { activeDeveloperIndex, sending, filteredDevelopers } = this.state;
    return (
      <SellerLayout>
        <Modal id={this.createId} title="Create a developer">
          <form onSubmit={e => this.handleCreateDeveloper(e)}>
            <Input
              label={lang.modals.update.fields.name.label}
              name="name"
              required={true}
            />
            <Input
              label={lang.modals.update.fields.url.label}
              name="url"
              placeholder={"https://developer.com"}
              required={true}
            />
            <div className="uk-margin">
              <label
                htmlFor="logo"
                className="uk-text-meta uk-text-uppercase uk-margin-small-bottom uk-display-block"
              >
                {lang.modals.update.fields.logo.label}
              </label>
              <div uk-form-custom="target: true" className="uk-width-1-1">
                <input name="logo" type="file" accept="image/*" />
                <input
                  className="uk-input"
                  type="text"
                  placeholder={"File"}
                  disabled
                />
              </div>
            </div>
            <div className="uk-grid-small" uk-grid="">
              <div className="uk-width-auto">
                <Button
                  label={lang.modals.create.actions.cancel}
                  cssClasses="uk-modal-close"
                />
              </div>
              <div className="uk-width-expand">
                <Button
                  label={lang.modals.create.actions.create}
                  type="primary"
                  cssClasses="uk-width-1-1"
                  loading={sending}
                />
              </div>
            </div>
          </form>
        </Modal>
        <div className="uk-container uk-margin-small-bottom  uk-animation-fade">
          <div uk-grid="" className="uk-margin uk-margin-top uk-flex-middle">
            <Title title="Developers" className="uk-width-expand" />
            <div className="uk-width-auto">
              <Button
                label={lang.messages.addDeveloper}
                icon="plus"
                atts={{ "uk-toggle": `target:#${this.createId}` }}
              />
            </div>
          </div>
          {!developers && <Loader />}
          {developers && (
            <React.Fragment>
              <Modal
                id={this.deleteId}
                title={`${lang.modals.delete.title}: ${developers &&
                  activeDeveloperIndex !== null &&
                  developers[activeDeveloperIndex].name}`}
              >
                <p>{lang.modals.delete.body}</p>
                <p className="uk-text-bold">{lang.modals.delete.note}</p>
                <div className="uk-grid-small" uk-grid="">
                  <div className="uk-width-auto">
                    <Button
                      label={lang.modals.delete.actions.cancel}
                      cssClasses="uk-modal-close"
                    />
                  </div>
                  <div className="uk-width-expand">
                    <Button
                      label={lang.modals.delete.actions.delete}
                      type="primary"
                      cssClasses="uk-width-1-1"
                      onClick={() =>
                        this.handleDeleteDeveloper(
                          developers[activeDeveloperIndex].id
                        )
                      }
                      loading={sending}
                    />
                  </div>
                </div>
              </Modal>
              <Modal id={this.updateId} title={lang.modals.update.title}>
                <form
                  onSubmit={e =>
                    this.handleUpdateDeveloper(
                      e,
                      developers[activeDeveloperIndex].id
                    )
                  }
                >
                  {activeDeveloperIndex !== null && (
                    <React.Fragment
                      key={Math.random()
                        .toString(36)
                        .substring(2, 15)}
                    >
                      <Input
                        label={lang.modals.update.fields.name.label}
                        name="name"
                        value={developers[activeDeveloperIndex].name}
                        required={true}
                      />
                      <Input
                        label={lang.modals.update.fields.url.label}
                        name="url"
                        value={developers[activeDeveloperIndex].url}
                        required={true}
                      />
                      <div className="uk-margin">
                        <label
                          htmlFor="logo"
                          className="uk-text-meta uk-text-uppercase uk-margin-small-bottom uk-display-block"
                        >
                          {lang.modals.update.fields.logo.label}
                        </label>
                        <div
                          uk-form-custom="target: true"
                          className="uk-width-1-1"
                        >
                          <input name="logo" type="file" accept="image/*" />
                          <input
                            className="uk-input"
                            type="text"
                            placeholder={"File"}
                            disabled
                          />
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                  <div className="uk-grid-small" uk-grid="">
                    <div className="uk-width-auto">
                      <Button
                        label={lang.modals.update.actions.cancel}
                        cssClasses="uk-modal-close"
                      />
                    </div>
                    <div className="uk-width-expand">
                      <Button
                        label={lang.modals.update.actions.update}
                        type="primary"
                        cssClasses="uk-width-1-1"
                        loading={sending}
                      />
                    </div>
                  </div>
                </form>
              </Modal>
              <div className="uk-margin-bottom">
                <Search
                  delayed={false}
                  lang={lang.messages.search}
                  onChange={this.handleSearch}
                />
              </div>
              <div
                className="uk-child-width-1-4@m uk-child-width-1-1@s uk-animation-fade"
                uk-grid=""
              >
                {(filteredDevelopers || developers).map((d, key) => (
                  <div key={d.id}>
                    <div
                      className="uk-card uk-card-default uk-height-medium uk-card-body uk-flex uk-flex-column uk-flex-between viz-actions-container"
                      onClick={
                        d.data.projectsCount
                          ? () => this.gotoProjects(d.id)
                          : () =>
                              notification(lang.messages.noProjects, "danger")
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <ul className="uk-iconnav uk-position-top-right uk-position-small viz-actions">
                        <li>
                          <a
                            onClick={e => this.setActiveDeveloper(e, key)}
                            uk-icon="icon:pencil"
                            uk-tooltip="Edit developer"
                            uk-toggle={`target:#${this.updateId}`}
                          ></a>
                        </li>
                        <li>
                          <a
                            onClick={e => this.setActiveDeveloper(e, key)}
                            uk-icon="icon:trash"
                            uk-tooltip="Delete developer"
                            uk-toggle={`target:#${this.deleteId}`}
                          ></a>
                        </li>
                      </ul>
                      <div
                        className="uk-align-center uk-width-auto"
                        style={{ marginTop: "0", marginBottom: "0" }}
                      >
                        {d.logo && (
                          <Logo
                            src={d.logo.thumbUrl}
                            type="circle"
                            width="70px"
                            cssClasses="uk-margin-remove"
                          />
                        )}
                        {!d.logo && <span uk-icon="icon: nut; ratio: 4" />}
                      </div>
                      <div className="uk-text-center uk-margin-medium-top">
                        <h5 className="uk-text-bold">{d.name}</h5>
                      </div>
                      <div>
                        <hr />
                        <p
                          className="uk-text-center"
                          uk-tooltip={lang.card.projectsTooltip}
                          style={{ cursor: "pointer" }}
                        >
                          {d.data.projectsCount}
                          <br />
                          <span className="uk-text-muted uk-text-small">
                            {lang.card.projects}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </React.Fragment>
          )}
        </div>
      </SellerLayout>
    );
  }
}

export default withRouter(DevelopersIndex);
