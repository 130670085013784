import React from "react";
import { Logo } from "../Logo/Logo";

/**
 *
 * @param {
 * name: string;
 * phone?: string;
 * email?: string;
 * developer?: string;
 * media: string;
 * id: string;
 * } props
 */
export const Result = props => {
  const { name, phone, email, developer, media } = props;
  return (
    <div>
      <div className="uk-flex-middle" uk-grid="">
        <div className="uk-width-auto">
          <div>
            <Logo width="50px" type="circle" src={media} />
          </div>
        </div>
        <div className="uk-width-expand">
          <div>
            <h5 className="uk-margin-remove uk-text-bold">{name}</h5>
            <ul className="uk-iconnav uk-text-meta uk-padding-remove">
              {email && (
                <li className="uk-padding-remove">
                  <span uk-icon="icon: mail; ratio: .8" />
                  &nbsp; {email}
                </li>
              )}
              {phone && (
                <li>
                  <span uk-icon="icon: receiver; ratio: .8" />
                  &nbsp; {phone}
                </li>
              )}
              {developer && (
                <li>
                  <span uk-icon="icon: cog; ratio: .8" />
                  &nbsp; Developer
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
