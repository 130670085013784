export default {
    registrationProgressBar: {
        steps: [
            "Floor Plan Upload",
            "Inspiration Quiz",
            "Additional Information"

        ],
    },
    register: {
        header: "Register",
        next: "Next",
        steps: {
            register: "Register",
            floorplan: "Floor Plan",
            inspirationQuiz: "Inspiration Quiz"
        },
        fields: {
            fullName: "Full Name",
            email: "e-mail",
            phone: "Phone Number",
            password: "Password",
            passwordValidation: "Confirm Password",
            address: "Address",
            consent: "I have read and agree to ",
            conditions: "Terms of Use"

        },
        errors: {
            email: "Invalid email address.",
            emailExists: "An account with this email already exists.",
            phone: "Phone number must be 10 numbers long.",
            password: "Password must have a length of 8-20 characters, and must contain at least one upper case letter, one lower case letter and one number.",
            password_confirmation: "The passwords do not match.",
            general: "There was an error. Please try again later."
        }
    },
    uploadPdf: {
        header: "Floor Plan Upload",
        alreadyHaveAnAccount: "Already have an account?",
        login: "Login",
        fields: {
            aptAddress: "Apartment name/address",
            projectName: "Project name",
            uploadFloorPlan: "Drag a floor plan here or click to select file",
        },
        buttons: {
            next: "Continue"
        },
        errors: {
            noFloorplan: "Please select a floor plan (JPEG, PNG or PDF file)",
            errorOccured: "There was an error. Please try again later or contact customer support."
        }
    },
    topNav: {
        backToPatricia: "Back to the site",
    },
    footer: {
        note: "Need help? Call us at 052-6567777, or email us"
    },
    inspirationQuiz: {
        header: "Help Patricia understand your design style",
        actions: {
            skip: "Skip",
            next: "Next",
            back: "Previous",
            done: "Finish"
        },
        result: {
            line1: "Thank you, you helped Patricia understand your design style!",
            line2: "It looks like you prefer a design style of ",
            line3: "characterized by",
            line4: "Now all that is left is to register and in a short time you will receive a virtual tour of your home with your design style.",
        },
    },
    underConstructionPage: {
        title: "Patricia is preparing your home. Soon you will be able to tour it and choose the products you liked most.",
        designStyle: {
            line1: "It seems that you prefer a design style of ",
            line2: "Characterized by ",
            line3: "In a short time you will receive a virtual tour of your home with your design style.",
        },
        body: "We will email you when it's ready.",
    },
    login: {
        fields: {
            email: {
                placeholder: "email@company.com",
                label: 'E-mail',
                error: "Please enter a valid e-mail address"
            },
            password: {
                placeholder: "*********",
                label: "Password",
                error: "Please enter a valid password",
                tooltip:
                    "The password must be in English and be 8-20 characters long, with at least one upper case letter, one lower case letter and one number."
            },
            remember: {
                label: "Remember me",
                tooltip: "Remember me on this machine"
            },
            passwordConfirmation: {
                placeholder: "*********",
                label: "Confirm password",
                tooltip: "Passwords must match"
            }
        },
        actions: {
            login: {
                label: "Login"
            },
            reset: {
                label: "Send password reset link"
            },
            setPassword: {
                label: "Update password"
            },
            forgotPassword: "Forgot password?",
            createAccount: {
                notYetRegistered: "Don't have an account?",
                createAnAccount: "Create a new user"
            }
        },
    },
    checkoutProgressBar: {
        steps: [
            "Shipping details",
            "Summary and payment",
        ],
    },
    successPage: {
        thankYou: "Thank you! The payment was successful.",
        contactAfterOrder: "One of our representatives will contact you to arrange delivery"
    }
}