import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useStateValue } from "./state";

export const AuthRoute = ({ component: Component, ...rest }) => {
  const [{ auth }] = useStateValue();
  return (
    <Route
      {...rest}
      render={props =>
        auth.isAuthenticated ? (
          <Component {...props} path={`${props.path}?`} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export const DualRoute = ({ component: Component, ...rest }) => {
  const [{ auth }] = useStateValue();
  return (
    <Route
      {...rest}
      render={props =>
        auth.isAuthenticated ? (
          <Component.Authenticated {...props} path={`${props.path}?`} />
        ) : (
          <Component.Public {...props} path={`${props.path}?`} />
        )
      }
    />
  );
};

export const ProjectsRoute = ({ component: Component, ...rest }) => {
  const [{ active, auth }] = useStateValue();
  return (
    <Route
      {...rest}
      render={props =>
        auth.isAuthenticated && active.consumer ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/consumers", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export const UnitsRoute = ({ component: Component, ...rest }) => {
  const [{ active, auth }] = useStateValue();
  return (
    <Route
      {...rest}
      render={props =>
        auth.isAuthenticated && active.consumer && active.project ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/projectCommons",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

export const LoginRoute = ({ component: Component, ...rest }) => {
  const [
    {
      auth: { isAuthenticated }
    }
  ] = useStateValue();
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <Redirect to="/" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};
