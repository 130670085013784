import React from "react";

function ProductCard(props){
    return (
        <div className="uk-card uk-card-default uk-card-body">
            <h6 className="uk-card-title">{props.product.name}</h6>
            <div className="uk-position-relative uk-visible-toggle uk-light" tabIndex="-1" uk-slideshow="">
                <ul className="uk-slideshow-items">
                    {props.product.item.photos_urls.map((url) => {
                        return (
                        <li>
                            <img src={url} alt="" style={{height: "100%", width: "100%", objectFit: "contain"}} />
                        </li>)
                    })}
                </ul>
                <a className="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous=""
                   uk-slideshow-item="previous" style={{color: "black"}}></a>
                <a className="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next=""
                   uk-slideshow-item="next" style={{color: "black"}}></a>
            </div>
        </div>
    );
}
export default ProductCard;