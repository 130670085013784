import React from "react";

const Thumbnail = props => (
  <React.Fragment>
    <div
      style={{
        backgroundPosition: `center center`,
        backgroundSize: "cover",
        height: "100px",
        cursor: "pointer",
        padding: "5px"
        // pointerEvents: props.uploading ? "none" : "all"
      }}
      className={`uk-border-rounded uk-position-relative uk-text-right viz-actionable uk-visible@m uk-box-shadow-hover-small ${props.index ===
        props.activeKey && "viz-primary-shadow"}`}
      onClick={props.uploading ? null : () => props.onClick(props.index)}
      data-src={props.thumbUrl}
      uk-img=""
    >
      {props.actions && (
        <div className="uk-box-shadow-small viz-actionable-actions">
          <ul className="uk-iconnav uk-iconnav-vertical uk-text-left">
            {props.actions.map((action, key) => (
              <li key={key}>
                <a
                  href="#"
                  uk-icon={`icon:${action.icon}`}
                  onClick={e => action.onClick(e, props.id)}
                ></a>
              </li>
            ))}
          </ul>
        </div>
      )}

      {props.uploading && (
        <div
          className="uk-overlay-primary uk-position-cover uk-border-rounded uk-flex uk-flex-middle uk-flex-center uk-padding-small uk-light"
          style={{ zIndex: "1" }}
        >
          <div uk-spinner=""></div>
        </div>
      )}
    </div>
    <div uk-lightbox="animation: fade" className="uk-hidden@m">
      <a href={props.uploading ? "!#" : props.mediaUrl}>
        <div
          className="uk-border-rounded uk-position-relative"
          style={{
            backgroundImage: `url('${props.thumbUrl}')`,
            backgroundPosition: "center center",
            backgroundSize: "cover",
            height: "100px",
            cursor: "zoom-in"
          }}
          data-src={props.thumbUrl}
          uk-img=""
        >
          {props.uploading && (
            <div
              className="uk-overlay-primary uk-position-cover uk-border-rounded uk-flex uk-flex-middle uk-flex-center uk-padding-small uk-light"
              style={{ zIndex: "1" }}
            >
              <div uk-spinner=""></div>
            </div>
          )}
        </div>
      </a>
    </div>
  </React.Fragment>
);

export default Thumbnail;
