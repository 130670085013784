import React, { Component } from "react";
import SellerLayout from "../layouts/SellerLayout";
import { Card } from "../components/Card/Card";
import { Logo } from "../components/Logo/Logo";
import { Loader } from "../components/Loader/Loader";
import { StateContext } from "../app/state";
import { Alert } from "../components/Alert/Alert";
import { withRouter } from "react-router-dom";
import { Button } from "../components/Button/Button";
import { Input } from "../components/Input/Input";
import {
  updateUser,
  deleteProperty,
  deleteConsumer,
  createProperty
} from "../app/actions";
import UiKit from "uikit";
import Title from "../components/Title/Title";
import Modal from "../components/Modal/Modal";
import AddProperty from "../components/CreateTour/AddProperty";
import CreateProperty from "../components/CreateTour/CreateProperty";
import { joinFormData } from "../helpers/HelperFunctions";

class ConsumerPage extends Component {
  static contextType = StateContext;
  constructor(props) {
    super(props);
    this.deleteId = "consumer-delete";
    this.updateId = "consumer-update";
    this.infoId = "consumer-info";
    this.addProperty = "property-add";
    this.propertyCreate = "property-create";
    this.propertyCreateSwitcher = "property-create-switcher";
    this.checkUser = setInterval(this.listenToConsumer, 300);
  }
  state = {
    activeProperty: null,
    sending: false,
    rooms: null,
    processing: false,
    propertyForm: null,
    propertyFormStep: 1
  };
  listenToConsumer = () => {
    const [
      {
        active: { consumer }
      }
    ] = this.context;
    if (consumer && consumer.user) clearInterval(this.checkUser);
    else return;
    const hasInfo = this.isUserFull(consumer.user);

    if (!hasInfo) UiKit.modal(`#${this.infoId}`).show();
  };

  handlePromptConfirm = () => {
    UiKit.modal(`#${this.infoId}`).hide();
    UiKit.modal(`#${this.updateId}`).show();
  };
  isUserFull = user => {
    if (!user.phone || !user.email || !user.name) return false;
    return true;
  };
  handleTourStart = prop => {
    const {
      tour: { id, data, designs, measurements },
      extra
    } = prop;
    const [
      {
        auth: { user }
      }
    ] = this.context;
    const link = `vizitRetail:*${data.wallUrl}${
      designs && designs.length > 0 ? `*design~${designs[0].designUrl}` : ""
    }${extra.matrixId ? `*matrix:${extra.matrixId}` : ""}*height:${
      measurements.ceilingHeight
    }*tourId:${id}*mu:${"cm"}*retailer:${user &&
      user.seller &&
      user.seller.retailerId}${
      user && user.seller ? `*sellerId:${user.id}` : ""
    }${data.dataUrl ? `*data~${data.dataUrl}` : ""}${
      extra.quoteId ? `*quoteId:${extra.quoteId}` : ""
    }*baseEp~${process.env.REACT_APP_APP_ENDPOINT}`;
    console.log(link);
    window.location.href = link;
  };
  handlePropertyClick = (e, p, retailer) => {
    if (
      e.target.classList.contains("uk-button-primary") ||
      e.target.parentNode.classList.contains("uk-button-primary")
    )
      return;
    window.location.href = `/properties/${p}?relation[]=consumer&relation[]=project&relation[]=media&retailer=${retailer}`;
  };

  handleDeleteConsumer = (e, id) => {
    e.preventDefault();
    this.setState({
      sending: true
    });
    deleteConsumer({ id }, res => {
      const [{ active }, dispatch] = this.context;
      UiKit.modal(`#${this.deleteId}`).hide();
      dispatch({
        type: "updateActive",
        newActive: {
          ...active,
          consumer: null
        }
      });
      this.props.history.push(`/consumers`);
    });
  };

  handleConsumerUpdate = e => {
    e.preventDefault();
    const [
      {
        active: { consumer }
      },
      dispatch
    ] = this.context;
    this.setState({
      sending: true
    });
    const formData = new FormData(e.target);
    let formKeys = [];
    for (var key of formData.keys()) {
      if (consumer.user[key] === formData.get(key)) {
        formKeys.push(key);
      }
    }
    formKeys.forEach(key => {
      formData.delete(key);
    });
    updateUser({ payload: formData, id: consumer.user.id }, ({ data }) => {
      UiKit.modal(`#${this.updateId}`).hide();
      dispatch({
        type: "updateActive",
        newActive: {
          consumer: {
            ...consumer,
            user: data
          }
        }
      });
    }).then(() => {
      this.setState({
        sending: false
      });
    });
  };

  deleteProperty = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    const [{ active }, dispatch] = this.context;
    for (let i = 0; i < active.consumer.properties.length; i++) {
      if (active.consumer.properties[i].id === id) {
        active.consumer.properties[i].loading = true;
      }
    }
    dispatch({
      type: "updateActive",
      newActive: active
    });
    deleteProperty({ id }, () => {
      active.consumer.properties = active.consumer.properties.filter(
        p => p.id !== id
      );
      dispatch({
        type: "updateActive",
        newActive: active
      });
    });
  };

  toggleDelete = (e, id, action) => {
    e.preventDefault();
    e.stopPropagation();
    const [{ active }, dispatch] = this.context;
    for (let i = 0; i < active.consumer.properties.length; i++) {
      if (active.consumer.properties[i].id === id) {
        switch (action) {
          case "set":
            active.consumer.properties[i].deleting = true;
            break;
          case "unset":
            delete active.consumer.properties[i].deleting;
            break;
          default:
            break;
        }
      }
    }
    dispatch({
      type: "updateActive",
      newActive: active
    });
  };

  openPropertyCreateForm = () => {
    UiKit.modal(`#${this.addProperty}`).hide();
    UiKit.modal(`#${this.propertyCreate}`).show();
  };

  closePropertyCreateForm = (e = null) => {
    if (e) e.preventDefault();
    const forms = document
      .getElementById(this.propertyCreateSwitcher)
      .getElementsByTagName("form");
    for (let i = 0; i < forms.length; i++) {
      forms[i].reset();
    }
    UiKit.switcher(`#${this.propertyCreateSwitcher}`).show(0);
    UiKit.modal(`#${this.propertyCreate}`).hide();
    this.setState({
      propertyFormStep: 1,
      propertyForm: null
    });
  };

  handlePropertyCreateProgress = (e, step) => {
    e.preventDefault();
    let propertyForm = null;
    const formData = new FormData(e.target);
    if (step === 1) {
      propertyForm = [formData];
    } else {
      propertyForm = this.state.propertyForm;
      propertyForm.push(formData);
    }
    switch (step) {
      case 3:
        const [{ active }, dispatch] = this.context;
        const form = joinFormData(propertyForm[1], [
          propertyForm[0],
          propertyForm[2]
        ]);
        form.append("consumer_id", active.consumer.id);
        this.setState({
          sending: true
        });
        createProperty({ payload: form }, ({ data }) => {
          if (active.consumer.properties) active.consumer.properties.push(data);
          else active.consumer.properties = [data];
          dispatch({
            type: "updateActive",
            newActive: active
          });
        }).then(() => {
          this.closePropertyCreateForm();
          this.setState({
            sending: false
          });
        });
        break;
      default:
        this.setState({
          propertyFormStep: step + 1,
          propertyForm
        });
        UiKit.switcher(`#${this.propertyCreateSwitcher}`).show(step++);
        break;
    }
  };

  render() {
    const [
      {
        localization: { consumer: lang },
        active: { consumer },
        componentData: { loading },
        auth: { user }
      }
    ] = this.context;
    const { sending, propertyFormStep } = this.state;
    const gotToProjects = () => {
      UiKit.modal(`#${this.addProperty}`).hide();
      const {
        seller: { retailerId }
      } = user;
      this.props.history.push(
        `/consumers/${consumer.id}/projectCommons?retailer=${retailerId}&developer`
      );
    };
    return (
      <SellerLayout>
        <div className="uk-section uk-position-relative">
          {(loading || !consumer) && (
            <div
              className="uk-position-cover uk-flex uk-flex-middle uk-flex-center"
              style={{ zIndex: 100000 }}
            >
              <Loader shadow={true} />
            </div>
          )}
          {consumer && consumer.user && (
            <div
              className="uk-container"
              style={{ opacity: loading ? ".2" : "1" }}
            >
              {/* Switcher modal */}
              <Modal
                id={this.propertyCreate}
                title={lang.createProperty.title}
                dir={user && user.locality === "he-il" ? "rtl" : ""}
                onExit={this.closePropertyCreateForm}
              >
                <CreateProperty
                  onCancel={this.closePropertyCreateForm}
                  switcherId={this.propertyCreateSwitcher}
                  onProgress={this.handlePropertyCreateProgress}
                  step={propertyFormStep}
                  lang={lang.createProperty}
                  processing={sending}
                />
              </Modal>
              <Modal
                id={this.addProperty}
                title={lang.messages.add.title}
                dir={user && user.locality === "he-il" ? "rtl" : ""}
              >
                <AddProperty
                  body={lang.messages.add.body}
                  {...lang.messages.add.actions}
                  onProjectSelect={gotToProjects}
                  onNewSelect={this.openPropertyCreateForm}
                />
              </Modal>
              <Modal
                id={this.infoId}
                title={lang.messages.info.title}
                dir={user && user.locality === "he-il" ? "rtl" : ""}
              >
                <p>{lang.messages.info.body}</p>
                <div uk-grid="" className="uk-margin-top uk-grid-small">
                  <div className="uk-width-auto">
                    <Button
                      label={lang.messages.info.actions.later}
                      cssClasses="uk-width-1-1 uk-modal-close"
                    />
                  </div>
                  <div className="uk-width-expand">
                    <Button
                      label={lang.messages.info.actions.continue}
                      cssClasses="uk-width-1-1"
                      type="primary"
                      loading={sending}
                      onClick={this.handlePromptConfirm}
                    />
                  </div>
                </div>
              </Modal>
              <Modal
                id={this.deleteId}
                title={lang.actions.delete.title}
                dir={user && user.locality === "he-il" ? "rtl" : ""}
              >
                <p>{lang.actions.delete.body}</p>
                <span>{lang.actions.delete.warning}</span>
                <div
                  uk-grid=""
                  className="uk-margin-top uk-grid-small uk-child-width-1-2"
                >
                  <div>
                    <Button
                      label={lang.actions.delete.actions.cancel}
                      cssClasses="uk-width-1-1 uk-modal-close"
                    />
                  </div>
                  <div>
                    <Button
                      label={lang.actions.delete.actions.delete}
                      cssClasses="uk-width-1-1"
                      type="primary"
                      loading={sending}
                      onClick={e => this.handleDeleteConsumer(e, consumer.id)}
                    />
                  </div>
                </div>
              </Modal>
              <Modal
                id={this.updateId}
                title={lang.actions.update.title}
                dir={user && user.locality === "he-il" ? "rtl" : ""}
              >
                <p>{lang.actions.update.body}</p>
                <form onSubmit={this.handleConsumerUpdate}>
                  <Input
                    label={lang.actions.update.fields.name}
                    name="name"
                    value={consumer.user.name}
                    required={true}
                  />
                  <Input
                    label={lang.actions.update.fields.email}
                    name="email"
                    type="email"
                    value={consumer.user.email}
                  />
                  <Input
                    label={lang.actions.update.fields.phone}
                    name="phone"
                    type="tel"
                    value={consumer.user.phone.replace(/\D/g, "")}
                  />
                  <Input
                      label={lang.actions.update.fields.clientNumber}
                      name="consumer_number"
                      value={consumer.consumer_number}
                  />
                  <div
                    uk-grid=""
                    className="uk-margin-top uk-grid-small uk-child-width-1-2"
                  >
                    <div>
                      <Button
                        label={lang.actions.update.actions.cancel}
                        cssClasses="uk-width-1-1 uk-modal-close"
                      />
                    </div>
                    <div>
                      <Button
                        label={lang.actions.update.actions.update}
                        cssClasses="uk-width-1-1"
                        type="primary"
                        loading={sending}
                      />
                    </div>
                  </div>
                </form>
              </Modal>
              <div uk-grid="" className="uk-margin-large uk-grid-small">
                <div className="uk-width-auto uk-animation-fade">
                  {consumer.user.avatar && (
                    <Logo
                      src={consumer.user.avatar.thumbUrl}
                      type="circle"
                      width="80px"
                    />
                  )}
                </div>
                <div className="uk-width-expand">
                  <div>
                    <Title
                      title={consumer.user.name}
                      margin={true}
                      actions={[
                        {
                          icon: "trash",
                          atts: {
                            "uk-toggle": `target:#${this.deleteId}`
                          }
                        },
                        {
                          icon: "pencil",
                          atts: {
                            "uk-toggle": `target:#${this.updateId}`
                          }
                        }
                      ]}
                      subtitle={
                        <ul className="uk-list">
                          {!!consumer.user.email && (
                            <li className="uk-text-small">
                              <span uk-icon="icon: mail; ratio: .8" />
                              &nbsp;
                              {consumer.user.email}
                            </li>
                          )}
                          {!!consumer.user.phone && (
                            <li className="uk-text-small">
                              <span uk-icon="icon: receiver; ratio: .8" />
                              &nbsp; {consumer.user.phone}
                            </li>
                          )}
                          {!!consumer.consumer_number && (
                              <li className="uk-text-small">
                                <span uk-icon="icon: hashtag; ratio: .8" />
                                &nbsp; {consumer.consumer_number}
                              </li>
                          )}
                        </ul>
                      }
                    />
                  </div>
                </div>
                <div className="uk-width-auto uk-animation-fade">
                  <Button
                    label={lang.actions.newProperty}
                    size="large"
                    atts={{
                      "uk-toggle": `target:#${this.addProperty}`
                    }}
                  />
                </div>
              </div>
              <div>
                {consumer.properties && consumer.properties.length > 0 && (
                  <div
                    className="uk-child-width-1-2@m uk-child-width-1-1@s"
                    uk-grid=""
                  >
                    {consumer.properties.map(p => {
                      return (
                        <Card
                          loading={p.loading}
                          id={p.id}
                          key={p.id}
                          title={p.name}
                          image={p.cover && p.cover.thumbUrl}
                          actionLabel={lang.properties.action}
                          onClick={() => this.handleTourStart(p)}
                          onCard={e =>
                            this.handlePropertyClick(
                              e,
                              p.id,
                              user.seller.retailerId
                            )
                          }
                          dir={
                            user && user.locality === "he-il" ? "dir-rtl" : ""
                          }
                          lang={lang.properties}
                          deleting={p.deleting}
                          toggleDelete={this.toggleDelete}
                          onDelete={this.deleteProperty}
                        >
                          <div className="uk-position-top-right uk-position-small uk-margin-small-right uk-margin-small-top">
                            <ul className="uk-iconnav">
                              <li>
                                <a
                                  uk-icon="icon: trash"
                                  uk-tooltip={lang.properties.delete.title}
                                  onClick={e =>
                                    this.toggleDelete(e, p.id, "set")
                                  }
                                />
                              </li>
                            </ul>
                          </div>
                        </Card>
                      );
                    })}
                  </div>
                )}
                {(!consumer.properties || consumer.properties.length === 0) && (
                  <Alert
                    message={lang.messages.properties.body}
                    title={lang.messages.properties.title}
                    type="action"
                    cssClasses="uk-align-center viz-box-shadow-special uk-padding uk-margin-medium-top uk-animation-slide-bottom-small uk-width-large"
                    action={{
                      label: lang.messages.properties.action,
                      type: "primary",
                      cssClasses: "uk-width-1-1 patricia-ai-color",
                      atts: {
                        "uk-toggle": `target:#${this.addProperty}`
                      }
                    }}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </SellerLayout>
    );
  }
}

export default withRouter(ConsumerPage);
