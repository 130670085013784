import React from "react";
import { Input } from "../Input/Input";
import { Alert } from "../Alert/Alert";
import { Result } from "../Result/Result";
import { Loader } from "../Loader/Loader";
import { Button } from "../Button/Button";

class Search extends React.Component {
  state = {
    searchTerm: "",
    typeTimeout: 0
  };

  handleChange = e => {
    if (this.state.typeTimeout) {
      clearTimeout(this.state.typeTimeout);
    }
    const term = e.target.value;
    if (!term) {
      this.setState({
        searchTerm: ""
      });
      if (this.props.onClearSearch) {
        this.props.onClearSearch();
      }
      return;
    }
    this.setState({
      typeTimeout: setTimeout(() => {
        this.props.onChange(term);
        this.setState({
          searchTerm: term
        });
      }, 1000)
    });
  };
  onSelect = id => {
    this.setState({
      searchTerm: ""
    });
    document.getElementById("search-input").value = "";
    this.props.onClick(id);
  };

  render() {
    const {
      lang,
      results,
      searching,
      onClick,
      flagOnClick,
      delayed = true,
      onChange,
      flag
    } = this.props;
    const { searchTerm } = this.state;
    return (
      <div className="uk-animation-fade uk-animation">
        <div
          className={`viz-box-shadow-special uk-padding-small viz-bg-white ${flag &&
            "uk-flex"}`}
        >
          {flag && (
            <div>
              <Button
                label={flag}
                tooltip={lang.flagTooltip}
                onClick={flagOnClick}
              ></Button>
              {"  :  "}
            </div>
          )}
          <Input
            id="search-input"
            name="search"
            icon="search"
            placeholder={lang.placeholder}
            onChange={
              delayed ? this.handleChange : e => onChange(e.target.value)
            }
            cssClasses="uk-margin-remove viz-border-remove uk-flex-1"
          />
        </div>
        <div>
          {searching && <Loader cssClasses="uk-margin-top" shadow={true} />}
          {(!results || (results && results.length === 0)) &&
            searchTerm &&
            !searching && (
              <Alert message={`${lang.noResults}: ${searchTerm}`} />
            )}
        </div>
        {!searching && results && results.length !== 0 && (
          <div
            className="uk-margin-small-top uk-height-max-large viz-box-shadow-special uk-animation-slide-bottom-small viz-bg-white"
            style={{ overflowY: "auto", overflowX: "hidden" }}
          >
            <ul className="uk-list uk-list-divider uk-margin-remove">
              {results.map(result => (
                <li
                  key={result.id}
                  className="uk-padding-small uk-margin-remove"
                  uk-toggle="mode: hover; cls: uk-background-muted;"
                  onClick={
                    onClick
                      ? () => this.onSelect(result.consumerId || result.id)
                      : null
                  }
                >
                  <Result
                    media={
                      result.user &&
                      result.user.profilePicture &&
                      result.user.profilePicture.thumb_url
                    }
                    name={result.user ? result.user.name : result.name}
                    id={result.id}
                    phone={result.user && result.user.phone}
                    email={result.user && result.user.email}
                  />
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  }
}

export default Search;
