import React from "react";

const noop = () => {};

/**
 * Button component
 *
 * @param {
 *      label: string;
 *      onClick?: () =>{};
 *      disabled?: boolean;
 *      type?: "default" | "primary" | "secondary" | "danger" | "disabled" | "action";
 *      size?: "small" | "large";
 *      cssClasses?: string;
 *      tooltip?: string;
 *      loading?: boolean;
 *      atts?: object;
 *      icon?: string;
 * } props
 */
export const Button = props => {
  const {
    label,
    onClick,
    disabled = false,
    type = "default",
    size,
    cssClasses = "",
    tooltip,
    loading = false,
    shape,
    atts,
    icon
  } = props;
  return (
    <button
      className={`uk-text-truncate uk-button uk-button-${type} ${
        disabled ? `uk-button-disabled` : ""
      } uk-border-${shape || "rounded"} ${cssClasses} ${
        size ? `uk-button-${size}` : ""
      } ${loading ? "uk-button-disabled" : ""}`}
      onClick={!disabled ? onClick : noop}
      uk-tooltip={tooltip}
      {...atts}
    >
      {label && (
        <span className="uk-text-truncate uk-text-capitalize">{label}</span>
      )}
      {icon && (
        <span
          className={label && `uk-margin-small-left`}
          uk-icon={`icon:${icon}`}
        />
      )}
      {loading && (
        <span
          className="uk-margin-small-left uk-animation-fade"
          uk-spinner="ratio: .7"
        />
      )}
    </button>
  );
};
