import React from "react";
import {
    MobileView, BrowserView,
    isMobile
} from "react-device-detect";

function ProductModal(props) {

    function isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    return (
        <div dir={props.dir} id={props.id} uk-modal="bg-close: true" className='patricia-hebrew-font uk-modal-container'>
            <div
                className="uk-modal-dialog uk-modal-body"
                dir={props.dir}
            >
                {!props.noEscape && (
                    <button
                        className="uk-modal-close-default"
                        type="button"
                        uk-close=""
                        onClick={props.onExit ? props.onExit : () => {}}
                    />
                )}
                {props.product &&
                <div className="viz-bg-white uk-padding" dir={props.dir}>
                    <div className="patricia-product-modal">
                            {props.product && props.product.item.photos_urls &&
                            <div className="uk-position-relative uk-visible-toggle" uk-slideshow="animation: push">
                                <ul className="uk-slideshow-items">
                                    {props.product.item.photos_urls.map((url) => {
                                        return(
                                            <li>
                                                <img src={url} alt=""/>
                                            </li>)
                                    })
                                    }
                                </ul>
                                <a className="uk-position-center-left uk-position-small" href="#"
                                   uk-slidenav-previous="" uk-slideshow-item="previous"></a>
                                <a className="uk-position-center-right uk-position-small" href="#" uk-slidenav-next=""
                                   uk-slideshow-item="next"></a>
                            </div>}
                            <div>
                                <div className='uk-padding uk-padding-remove-bottom'>
                                    <h2 className="uk-text-bold">{props.product.name}</h2>
                                </div>
                                <div className='font-weight-bold patricia-ai-text-color uk-display-block uk-padding uk-margin-small-bottom uk-padding-remove-bottom uk-padding-remove-top uk-text-right' style={{fontSize: "18px"}}>
                                    {(props.product.item.original_price > props.product.price &&  props.lang.patriciaPrice) } {props.product.price} &#8362;&nbsp;
                                    {props.product.item.original_price &&
                                    props.product.item.original_price > props.product.price &&
                                    <div className='font-weight-bold patricia-ai-text-color' style={{textDecoration: "line-through", fontSize: "16px"}}>{props.product.item.original_price} &#8362;</div>}
                                </div>
                                <hr/>
                                <div className="children-small-margin-bottom uk-margin-medium-top uk-padding uk-padding-remove-top uk-padding-remove-bottom uk-margin-remove-top" dangerouslySetInnerHTML={{__html: props.product.description}} />
                                <div className='uk-padding uk-padding-remove-top uk-padding-remove-bottom'>{props.lang.sku}: <span>{props.product.sku}</span></div>
                                <div className='uk-padding uk-padding-remove-top uk-padding-remove-bottom uk-margin-small-top uk-text-right'>
                                    {props.lang.brand}:
                                    {props.product.item.manufacturer.logo && props.product.item.manufacturer.logo.media_url ?
                                        <span className='uk-width-1-7 uk-text-small' style={{verticalAlign: "middle"}}>
                                            <img className="uk-margin-left" height="150" width="150" src={props.product.item.manufacturer.logo.media_url}/>
                                        </span> :
                                        <span className='uk-width-1-7 uk-text-small' style={{verticalAlign: "middle"}}>{props.product.item.manufacturer.name}</span>
                                    }
                                </div>
                            </div>
                        </div>
                    {props.product.item.technical_info &&
                    <ul uk-accordion="">
                        <li>
                            <a className="uk-accordion-title uk-accordion-icon" href="#" onClick={() => {
                                window.analytics.track("[my_cart] additional info clicked", {
                                    location: 'my_cart/product_modal',
                                    sku: props.product.sku
                                });}}>
                                {props.lang.additionalInfo}
                            </a>
                            {isJson(props.product.item.technical_info) ?
                            <div dir="ltr" className="uk-accordion-content">
                                  {Object.keys(JSON.parse(props.product.item.technical_info)).map((key) => {
                                            return (
                                                <div className='uk-text-small uk-text-thin'>{`${key.replaceAll('_', " ")}: `}<span>{JSON.parse(props.product.item.technical_info)[key]}</span> </div>
                                            )
                                    })}
                            </div>
                                :
                            <div dir="ltr" className="uk-accordion-content">
                                <div>{props.product.item.technical_info}</div>
                            </div>}
                        </li>
                    </ul>}
                </div>}
            </div>
        </div>




    )
}

export default ProductModal;