import React from "react";
/**
 *
 * @parm {
 * src: string;
 * width?: string;
 * type?: "default" | "circle" | "rounded" | "pill";
 * alt?: string;
 * cssClasses?: string;
 * } Props
 */
export const Logo = props => {
  const {
    src,
    width = "100px",
    type = "default",
    height,
    alt,
    cssClasses
  } = props;

  let elemProps = {};

  if (alt)
    elemProps["uk-toggle"] =
      "target: > div; mode:hover; animation:  uk-animation-fade";

  return (
    <div>
      <div
        style={{
          width: width,
          height: type === "circle" ? height || width : height || "auto"
        }}
        className={`uk-border-${type} uk-background-norepeat uk-position-relative uk-overflow-hidden uk-flex-center uk-flex uk-flex-middle ${cssClasses}`}
        {...elemProps}
      >
        <img
          data-src={src}
          alt={alt}
          uk-img=""
          className="uk-responsive-img"
          style={{
            width: width,
            height: type === "circle" ? height || width : "auto"
          }}
        />
        {alt && (
          <div
            className="uk-position-center uk-overlay-default"
            style={{ width: width, height: height || width }}
            hidden
          >
            <span className="uk-position-center uk-text-center">{alt}</span>
          </div>
        )}
      </div>
    </div>
  );
};
