import React, {useEffect} from "react";
import Lang from "../../localization/patricia-he-il";
import heLang from "../../localization/patricia-he-il";
import enLang from "../../localization/patricia-en-us";

function QuizResultComponent(props) {
    const Lang = props.dir == 'ltr' ? enLang : heLang;
    const lang = Lang.inspirationQuiz;

    // useEffect(() => {
    //     dispatch({
    //         type: "setDesignSet",
    //         date: props.designStyle
    //     });
    // }, [])

    return(
        <div className='uk-container uk-text-center' dir={props.dir}>
            {/*<div className="uk-margin-medium-bottom">{props.resultText}</div>*/}
            <div className="uk-margin-medium-bottom">
                <div>{lang.result.line1}</div>
                <div>{lang.result.line2} <span>{props.designStyle.name},</span></div>
                <div>{lang.result.line3} <span>{props.designStyle.description}</span>.</div>
                <div>{lang.result.line4} </div>
            </div>
            <div className={"uk-card uk-card-default uk-cover-container uk-height-large uk-margin-medium-bottom"}>
                {/*<img src={props.designStyle.photo && props.designStyle.photo.thumbUrl} alt="" uk-cover="" style={{ maxWidth: "100%"}}/>*/}
                <div className="uk-grid-collapse" uk-grid="">
                    {props.quizChosenAnswers.map((answer) => {
                        if (answer.photo) {
                            return (
                                <div className="uk-width-1-4 uk-background-cover uk-background-center-center viz-card-image" uk-img="" data-src={answer.photo.thumbUrl || ""} style={{height: "115px"}}></div>
                            )
                        }
                    })}
                </div>
            </div>
            <div className="uk-button uk-button-small uk-width-small reg-next-button"
                 // style={{ color:"white", backgroundColor:"red", borderStyle: "solid", borderWidth: "1px", borderColor: "red", borderRadius: "10px"}}
                 onClick={props.onDonClicked}>
                {lang.actions.done}
            </div>
        </div>
    )
}

export default QuizResultComponent;