import React, {useContext, useEffect, useRef, useState} from 'react';
import Question from "../components/InsperationQuiz/Question/Question";
import ColorPaletteAnswer from "../components/InsperationQuiz/Answers/ColorPaletteAnswer";
import TextAnswer from "../components/InsperationQuiz/Answers/TextAnswer";
import ImgAnswer from "../components/InsperationQuiz/Answers/ImgAnswer";
import Answer from "../components/InsperationQuiz/Answers/Answer";
import settings from "../app/settings";
import {Navbar} from "../components/Navbar/Navbar";
import {Logo} from "../components/Logo/Logo";
import {
    calculateDesignSets,
    calculateQuizResult,
    createDesignStyle,
    createQuizResult,
    getRetailerInspirationQuiz,
    saveChosenAnswers
} from "../app/actions";
import UIkit from "uikit";
import {StateContext} from "../app/state";
import {Loader} from "../components/Loader/Loader";
import {forEach} from "lodash";
import QuizResultComponent from "../components/InsperationQuiz/QuizResultComponent";
import PatriciaTopNav from "../components/Patricia/PatriciaNav/PatriciaTopNav";
import heLang from "../localization/patricia-he-il";
import enLang from "../localization/patricia-en-us";
import PatriciaProgressBar from "../components/Patricia/RegistrationProgressBar/PatriciaProgressBar";
import {
    isMobile
} from "react-device-detect";

function InspirationQuizPage(props){
    const context = useContext(StateContext);
    const [
        {
            auth: { user },
            data: { takeInspirationQuiz },
        },
        dispatch
    ] = context;
    const [quizData, setQuizData] = useState(null);
    const [quizResultId, setQuizResultId] = useState(null);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [quizChosenAnswers, setQuizChosenAnswes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [resultDesignStyle, setResultDesignStyle] = useState(null);
    const [quizResult, setQuizResult] = useState(null);
    const answers = useRef();
    const Lang = props.lang == 'en' ? enLang : heLang;
    const lang = Lang.inspirationQuiz;
    useEffect(() => {
        if (takeInspirationQuiz) {
            setQuizResultId(takeInspirationQuiz.id)
            setQuizData(takeInspirationQuiz.quiz);
        }
    }, [takeInspirationQuiz]);

    useEffect(() => {
        if( answers.current) {
            answers.current.children.length > 0 &&  answers.current.children[1] && answers.current.children[1].scrollIntoView({inline: "center"});
        }

    })

    // useEffect(() => {
    //     if ('speechSynthesis' in window && quizData && currentQuestionIndex < quizData.questions.length) {
    //
    //         var synthesis = window.speechSynthesis;
    //
    //         // Get the first `en` language voice in the list
    //         var voice = synthesis.getVoices().filter(function (voice) {
    //             return voice.name === 'Microsoft Zira Desktop - English (United States)';
    //         })[0];
    //
    //         // Create an utterance object
    //         var utterance = new SpeechSynthesisUtterance(quizData.questions[currentQuestionIndex].question_text);
    //
    //         // Set utterance properties
    //         utterance.voice = voice;
    //         utterance.pitch = 1.5;
    //         utterance.rate = 1.25;
    //         utterance.volume = 0.8;
    //
    //         // Speak the utterance
    //         document.getElementById("body-id").click();
    //         synthesis.speak(utterance);
    //     }
    // }, [quizData, currentQuestionIndex])

    // function onAnswerClicked(e, key){
    //     e.preventDefault();
    //     setSelectedAnswer(key);
    //     onNextClicked(e);
    //     let currentSelectedAnswers = [...selectedAnswers];
    //     if (currentSelectedAnswers.includes(key)) {
    //         currentSelectedAnswers.splice(currentSelectedAnswers.indexOf(key), 1);
    //     } else {
    //         currentSelectedAnswers.push(key)
    //     }
    //     setSelectedAnswers(currentSelectedAnswers);
    //
    // }
    function onBackClicked(e){
        e.preventDefault();
        window.analytics.track('[inspirationquiz] "back" clicked', {
            location: "inspirationquiz"
        });

        let newQuizChosenAnswers = [...quizChosenAnswers];
        let index = null;

        while (index != -1) {
            index = newQuizChosenAnswers.findIndex((answer) => answer.question_id === quizData.questions[currentQuestionIndex - 1].id);
            if(index != -1){
                newQuizChosenAnswers.splice(index, 1);
            }
        }
        setQuizChosenAnswes(newQuizChosenAnswers);
        setSelectedAnswer(null);
        setCurrentQuestionIndex(currentQuestionIndex - 1)
    }

    function onNextClicked(e, key = null){
        e.preventDefault();
        let newQuizChosenAnswers = [...quizChosenAnswers];
        if (key != null) {
            newQuizChosenAnswers.push(quizData.questions[currentQuestionIndex].answers[key])
            // selectedAnswers.forEach((selectedAnswer)=>{
            //     newQuizChosenAnswers.push(quizData.questions[currentQuestionIndex].answers[selectedAnswer])
            // });
            if (currentQuestionIndex === 1) {
                window.analytics.track('[inspirationquiz] first choose clicked', {
                    location: "inspirationquiz"
                });
            }
        }

        if(currentQuestionIndex + 1 === quizData.questions.length){
            setLoading(true);
            const formData = new FormData();
            formData.append("quiz_result_id", quizResultId);
            formData.append("answers",  JSON.stringify(newQuizChosenAnswers));
            saveChosenAnswers({ payload: formData})
                .then(() => {
                    calculateQuizResult({id: quizResultId}, res => {
                        setQuizResult(res.data)
                    }).then(() => {
                        let formData = new FormData();
                        formData.append('retailer_id', process.env.REACT_APP_RETAILER)
                        calculateDesignSets({id: quizResultId, payload: formData}, (res) => {
                            setResultDesignStyle(res.data)
                            dispatch({
                                type: "setDesignSet",
                                data: res.data
                            })
                        }).then(()=>{
                            setLoading(false);
                        })
                    })
                    newQuizChosenAnswers = [];
                })
            window.analytics.track('[inspirationquiz] last choose clicked', {
                location: "inspirationquiz"
            });
         }
        else if(quizData.questions[currentQuestionIndex + 1].answers_type === "color palette") {
            const formData = new FormData();
            formData.append("quiz_result_id", quizResultId);
            formData.append("answers", JSON.stringify(newQuizChosenAnswers));
            saveChosenAnswers({payload: formData})
                .then(() => {
                    //call to calculate color palette endpoint
                    newQuizChosenAnswers = [];
                })
        }
        setQuizChosenAnswes(newQuizChosenAnswers);
        setSelectedAnswer(null);
        setCurrentQuestionIndex(currentQuestionIndex + 1)
    }

    function onDoneQuizClick(e) {
        e.preventDefault();
        window.analytics.track('[inspirationquiz] "done" clicked', {
            location: "inspirationquiz"
        });
        if (quizData.retailer.name === 'patricia') {
            window.location.href = `/register?lang=${props.lang}`;
            //window.location.href = `/properties/${propertyId}?relation[]=consumer&relation[]=project&relation[]=media&retailer`;
        }
    }



    function createRowsOfColorPaletteAnswers(answers){
        // array of 2 elements (2 rows)
        const rows = [...Array(2)];
        // chunk the answers into the array of rows
        const answersRows = rows.map( (row, index) => answers.slice(index * 2, index * 2 + 2));
        return answersRows;
    }

    const dir = props.lang == "en" ? 'ltr' : 'rtl';
    return (
        <div id="body-id" className='uk-text-center patricia-hebrew-font uk-background-muted' dir={dir}>
        <PatriciaTopNav logo={settings.appLogo} dir={dir}/>
            <div className='uk-width-1-2@l uk-width-1-1@S uk-margin-auto patricia-hebrew-font uk-margin-small-top'>
                <h1 className="uk-text-center uk-padding-small patricia-hebrew-header-font">{lang.header}</h1>
                {!isMobile && <PatriciaProgressBar current={2} headrs={Lang.registrationProgressBar}/>}
            </div>
        <div className='uk-flex'>
            {/*<Logo className="uk-position-top-left" src={settings.appNewLogo} width="150px" height="150px" />*/}
            {quizData && !loading ?  <progress className="uk-progress uk-align-center" value={currentQuestionIndex + 1} max={quizData.questions.length} style={{ width: "50%"}}></progress> : null}
        </div>
            {!quizData || loading ? <Loader/> :
                currentQuestionIndex === quizData.questions.length ?
                    <QuizResultComponent dir={dir} resultText={quizData.result_text} onDonClicked={(e) => onDoneQuizClick(e)} loading={loading} designStyle={resultDesignStyle} quizChosenAnswers={quizChosenAnswers} dispatch={dispatch}/>
                    : (
            <div className='uk-text-center'>
                <div className="uk-text-center uk-margin-medium-bottom">
                    <div className="uk-margin-small uk-text-small">{`${currentQuestionIndex + 1}/${quizData.questions.length}`}</div>
                    <Question id="question" text={quizData.questions[currentQuestionIndex].question_text}></Question>
                </div>
                <div style={{overflowX: isMobile ? "scroll" : "unset", maxWidth: "200%"}}>
                {quizData.questions[currentQuestionIndex].answers_type === "text" || quizData.questions[currentQuestionIndex].answers_type === "image" ?
                    (<div ref={answers} className={`uk-grid-small  uk-child-width-1-3@l uk-animation-fade uk-flex uk-flex-center uk-margin-small
                     ${isMobile ? 'uk-height-small uk-width-xlarge' : 'uk-height-large'}`}
                          uk-grid="" style={{ maxWidth: "200%", flexWrap: "nowrap"}}/*uk-height-match="target: > div > .uk-card"*/>
                    {quizData.questions[currentQuestionIndex].answers.map((answer, key) => {
                        if (answer.answer_type === 'text'){
                            return <TextAnswer key={key} cssClasses={key%2 === 0 ? 'quarter-circle-bottom-right':'quarter-circle-bottom-left'} text={answer.answer_text} selected={selectedAnswer === key} onAnswerClicked={(e) => onNextClicked(e, key)}></TextAnswer>
                        } else if (answer.answer_type === 'image') {
                            return <ImgAnswer isMobile={isMobile} key={key} text="answer text" img={answer.photo.thumbUrl} onAnswerClicked={(e) => onNextClicked(e, key)} selected={selectedAnswer === key}></ImgAnswer>
                        }
                        // else if (answer.answer_type === 'color_palette') {
                        //     return <ColorPaletteAnswer key={key} colors={answer.color_palette.colors} onAnswerClicked={(e) => onNextClicked(e, key)}></ColorPaletteAnswer>
                        // }
                    })}
                </div>)
                    :
                    (<div className="uk-grid-small uk-animation-fade uk-align-center uk-container-small" uk-grid="">
                    {createRowsOfColorPaletteAnswers(quizData.questions[currentQuestionIndex].answers).map((row, rowkey) => {
                                return (
                                    <div className='uk-flex uk-flex-center'>
                                        {row.map((answer, key) => {
                                            return <ColorPaletteAnswer key={key} colors={answer.color_palette.colors}
                                                                       onAnswerClicked={(e) => onNextClicked(e, rowkey * 2 + key)}></ColorPaletteAnswer>
                                        })}
                                    </div>
                                )
                    })}
                </div>)}
                </div>
                <div className='uk-flex uk-flex-center uk-margin-large-top uk-margin-medium-bottom'>
                    {currentQuestionIndex !== 0 && currentQuestionIndex < quizData.questions.length?
                        <div className="quiz-button uk-button uk-button-small uk-width-small" style={{ color:"red", borderStyle: "solid", borderWidth: "1px", borderColor: "red", borderRadius: "10px"}} onClick={(e) => {onBackClicked(e)}}><span uk-icon={`chevron-${dir === 'ltr' ? "left" : "right"}`}></span>{lang.actions.back}</div> : null}
                    {currentQuestionIndex < quizData.questions.length ?
                        <div className={`uk-margin-small-${dir === "ltr" ? 'left' : 'right'} quiz-button uk-button uk-button-small uk-width-small`}   style={{ color:"white", backgroundColor:"red", borderStyle: "solid", borderWidth: "1px", borderColor: "red", borderRadius: "10px"}} onClick={(e) => {onNextClicked(e)}}>{lang.actions.next}<span uk-icon={`chevron-${dir === 'ltr' ? "right" : "left"}`}></span></div> : null}
                </div>
                <div className="uk-text-muted uk-margin-small uk-text-small" style={{ cursor: "pointer", textDecoration: "underline" }} onClick={() => {setCurrentQuestionIndex(currentQuestionIndex + 1)}}>{lang.actions.skip}</div>
            </div>)}
        </div>
    )
}

export default InspirationQuizPage;