import React, { Component } from "react";
import { Alert } from "../components/Alert/Alert";
import Search from "../components/Search/Search";
import SellerLayout from "../layouts/SellerLayout";
import { Card } from "../components/Card/Card";
import { StateContext } from "../app/state";
import { Loader } from "../components/Loader/Loader";
import { withRouter } from "react-router-dom";
import { ProjectCommonSearch } from "../app/utilities";

class ProjectSearch extends Component {
  static contextType = StateContext;
  state = {
    searchTerm: null
  };
  handleChange = term => {
    const [
      {
        data: { projectCommons: projects }
      }
    ] = this.context;
    this.setState({
      searchTerm: term,
      filteredProjects: ProjectCommonSearch(term, projects)
    });
  };
  render() {
    const [
      {
        localization: { projects: lang },
        active: { consumer },
        data: { projectCommons: projects },
        componentData: { loading },
        auth: { user }
      }
    ] = this.context;
    const handleSelect = commons => {
      this.props.history.push(
        `/consumers/${consumer.id}/projectCommons/${commons.id}/units?project=${commons.project.id}`
      );
    };
    const { filteredProjects } = this.state;
    return (
      <SellerLayout>
        <div className="uk-section uk-position-relative">
          {(loading || !projects) && (
            <div
              className="uk-position-cover uk-flex uk-flex-middle uk-flex-center"
              style={{ zIndex: 100000 }}
            >
              <Loader shadow={true} />
            </div>
          )}
          {projects && (
            <div
              className="uk-container"
              style={{ opacity: loading ? ".2" : "1" }}
            >
              <h2 className="uk-animation-fade uk-text-bold uk-margin-medium">
                {lang.search.title}
              </h2>
              <Search
                lang={lang.search}
                onChange={this.handleChange}
                delayed={false}
              />
              <div className="uk-margin-top">
                <div
                  className="uk-child-width-1-2@m uk-child-width-1-1@s"
                  uk-grid=""
                >
                  {projects &&
                    (filteredProjects || projects).map(p =>
                      p.project.hasUnits || p.hasMatrix ? (
                        <Card
                          key={p.project.id}
                          title={p.project.name}
                          content={`${lang.developer}: ${p.project.developer.name}`}
                          dir={
                            user && user.locality === "he-il" ? "dir-rtl" : ""
                          }
                          image={p.project.cover && p.project.cover.thumbUrl}
                          actionLabel={lang.results.action}
                          onClick={() => handleSelect(p)}
                        />
                      ) : (
                        ""
                      )
                    )}
                </div>
              </div>
            </div>
          )}
        </div>
      </SellerLayout>
    );
  }
}

export default withRouter(ProjectSearch);
