import React from "react";

const noop = () => {};
/**
 *
 * @param {
 * name: string;
 * label: string;
 * value?: boolean;
 * onChange?: () =>{};
 * tooltip?: text;
 * disabled?: boolean;
 * } props
 */
export const Checkbox = props => {
  const { name, label, value, onChange, tooltip, disabled = false , cssClasses} = props;

  return (
    <div className="uk-margin uk-grid-small uk-child-width-auto uk-grid">
      <label className={disabled ? "uk-text-muted" : ""}>
        <input
          className={`uk-checkbox ${disabled ? "uk-disabled" : ""} ${cssClasses}`}
          name={name}
          onChange={!disabled ? onChange : noop}
          type="checkbox"
          checked={value}
        />{" "}
        {label}{" "}
        {tooltip && (
          <a
            uk-tooltip={tooltip}
            onClick={e => {
              e.preventDefault();
            }}
            href="#"
            className="uk-text-muted"
            uk-icon="icon: info"
          />
        )}
      </label>
    </div>
  );
};
