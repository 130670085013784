import React from "react";
import { useStateValue } from "../../app/state";
import { Button } from "../Button/Button";

const LocationIndex = props => {
  const [
    {
      localization: {
        organization: { locations: lang }
      }
    }
  ] = useStateValue();
  return (
    <React.Fragment>
      <div className="uk-flex-middle" uk-grid="">
        <div className="uk-width-expand">
          <div>
            <h2>
              {lang.title}{" "}
              <span className="uk-text-meta">
                ({props.locations && props.locations.length})
              </span>
            </h2>
          </div>
        </div>
        <div className="uk-width-auto">
          <div>
            <Button
              label={lang.actions.add}
              atts={{
                "uk-toggle": `target:#${props.locationModalId}`
              }}
            ></Button>
          </div>
        </div>
      </div>
      {props.locations && (
        <React.Fragment>
          <div
            className="uk-child-width-1-4@xl uk-child-width-1-3@m uk-child-width-1-1@s"
            uk-grid=""
          >
            {props.locations.map((location, key) => (
              <div key={key}>
                <div className="uk-card uk-card-default viz-box-shadow-special uk-card-body uk-position-relative">
                  {location.processing && (
                    <div
                      className="uk-position-cover uk-overlay-default"
                      style={{ zIndex: 10 }}
                    >
                      <div className="uk-position-center">
                        <div uk-spinner=""></div>
                      </div>
                    </div>
                  )}
                  <div className="uk-position-small uk-position-top-right">
                    <ul className="uk-iconnav">
                      <li>
                        <a
                          uk-icon="icon: trash"
                          uk-tooltip={lang.actions.delete}
                          onClick={() => props.onDelete(location.id)}
                        ></a>
                      </li>
                    </ul>
                  </div>
                  <p
                    className="uk-text-bold uk-text-truncate uk-margin-remove"
                    title={location.address}
                  >
                    {location.address}
                  </p>
                  <p className="uk-margin-remove uk-text-meta">
                    {location.country} <br />
                    {location.employeeCount} {lang.cards.employees}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default LocationIndex;
