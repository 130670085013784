import React, { Component } from "react";
import { StateContext } from "../../app/state";
import {
  deleteUnit,
  updateTour,
  updateUnit,
  spreadUnitChanges
} from "../../app/actions";
import { Button } from "../Button/Button";
import UiKit from "uikit";

class UnitActions extends Component {
  static contextType = StateContext;
  state = {
    sending: false,
    queryParams: {
      relation: ["tour", "project"]
    }
  };
  handleUnitDelete = u => {
    const [{ data, componentData }, dispatch] = this.context;
    dispatch({
      type: "updateComponentData",
      newComponentData: { ...componentData, loading: true }
    });
    deleteUnit({ id: u.id }, res => {
      data.units = data.units.filter(unit => {
        return unit.id !== u.id;
      });
      dispatch({
        type: "updateComponentData",
        newComponentData: { ...componentData, loading: false }
      });
      dispatch({
        type: "updateData",
        newData: data
      });
    });
  };
  handleWallUpdate = (e, u) => {
    e.preventDefault();
    this.setState({
      sending: true
    });
    const formData = new FormData(e.target);
    updateTour({ id: u.tour.id, payload: formData }, res => {
      const [{ data }, dispatch] = this.context;
      const i = data.units.indexOf(u);
      data.units[i].tour = res;
      dispatch({
        type: "updateData",
        newData: data
      });
      this.setState({
        sending: false
      });
      UiKit.modal(`#update-wall-${u.id}`).hide();
    });
  };
  handleDesignUpdate = (e, u) => {
    e.preventDefault();
    this.setState({
      sending: true
    });
    const formData = new FormData(e.target);
    updateTour({ id: u.tour.id, payload: formData }, res => {
      const [{ data }, dispatch] = this.context;
      const i = data.units.indexOf(u);
      data.units[i].tour = res;
      dispatch({
        type: "updateData",
        newData: data
      });
      this.setState({
        sending: false
      });
      UiKit.modal(`#update-design-${u.id}`).hide();
    });
  };
  handleSpread = u => {
    this.setState({
      sending: true
    });
    spreadUnitChanges({ id: u.id }, () => {
      this.setState({
        sending: false
      });
    }).then(() => this.setState({ sending: false }));
  };
  handleCoverUpdate = (e, u) => {
    e.preventDefault();
    this.setState({
      sending: true
    });
    const formData = new FormData(e.target);
    updateUnit(
      { id: u.id, payload: formData, params: this.state.queryParams },
      res => {
        const [{ data }, dispatch] = this.context;
        const i = data.units.indexOf(u);
        data.units[i] = res.data;
        dispatch({
          type: "updateData",
          newData: data
        });
        this.setState({
          sending: false
        });
        UiKit.modal(`#update-cover-${u.id}`).hide();
      }
    );
  };
  handleTourStart = () => {
    const {
      tour: { id, data, designs, measurements }
    } = this.props.unit;
    const [
      {
        active: {
          projectCommon: { id: commonsId }
        },
        auth: { user }
      }
    ] = this.context;
    const link = `vizitRetail:*${data.wallUrl}${
      designs && designs.length > 0 ? `*design~${designs[0].designUrl}` : ""
    }*commons:${commonsId}*height:${
      measurements.ceilingHeight
    }*tourId:${id}*mu:${"cm"}*retailer:${user &&
      user.seller &&
      user.seller.retailerId}${
      data.dataUrl ? `*data~${data.dataUrl}` : ""
    }*baseEp~${process.env.REACT_APP_APP_ENDPOINT}`;
    console.log(link);
    window.location.href = link;
  };
  render() {
    const [
      {
        localization: {
          units: { actions: lang }
        }
      }
    ] = this.context;
    const { unit } = this.props;
    let lightBoxAtts = {};
    let hrefAtts = {};
    if (
      unit.tour &&
      unit.tour.floorplan &&
      unit.tour.floorplan.type === "image"
    )
      lightBoxAtts["uk-lightbox"] = "";
    else hrefAtts.target = "_blank";
    console.log(unit);
    return (
      <div
        className="uk-position-top-right uk-margin-small-right uk-overlay-primary uk-margin-small-top uk-border-rounded"
        style={{ padding: "3px 5px" }}
      >
        <ul className="uk-iconnav uk-light">
          <li>
            {!this.state.sending && (
              <a
                uk-icon="icon: bolt"
                uk-tooltip={lang.spread}
                onClick={() => this.handleSpread(unit)}
              />
            )}
            {this.state.sending && <span uk-spinner="ratio: 0.5" />}
          </li>
          <li>
            <a
              uk-icon="icon: play"
              uk-tooltip={lang.editor}
              onClick={this.handleTourStart}
            />
          </li>
          <li>
            <div
              {...lightBoxAtts}
              style={{ display: "inline-block", lineHeight: "0" }}
            >
              <a
                href={
                  unit.tour &&
                  unit.tour.floorplan &&
                  unit.tour.floorplan.mediaUrl
                }
                uk-icon="icon: thumbnails"
                uk-tooltip={lang.plan}
                {...hrefAtts}
              />
            </div>
          </li>
          <li>
            <a
              href=""
              uk-icon="icon: image"
              uk-tooltip={lang.cover}
              uk-toggle={`target: #update-cover-${unit.id}`}
            />
          </li>
          <li>
            <a
              href=""
              uk-icon="icon: paint-bucket"
              uk-tooltip={lang.design}
              uk-toggle={`target: #update-design-${unit.id}`}
            />
          </li>
          <li>
            <a
              href=""
              uk-icon="icon: nut"
              uk-tooltip={lang.wall}
              uk-toggle={`target: #update-wall-${unit.id}`}
            />
          </li>
          <li>
            <a
              uk-icon="icon: trash"
              uk-tooltip={lang.delete}
              onClick={e => this.handleUnitDelete(unit)}
            />
          </li>
        </ul>
        <div id={`update-wall-${unit.id}`} uk-modal="bg-close:false">
          <div className="uk-modal-dialog uk-modal-body uk-width-large">
            <button
              className="uk-modal-close-default"
              type="button"
              uk-close=""
            />
            <h3 className="uk-card-title">{lang.wall}</h3>
            <form onSubmit={e => this.handleWallUpdate(e, unit)}>
              <div className="uk-margin">
                <div uk-form-custom="target: true" className="uk-width-1-1">
                  <input name="wall_url" type="file" required />
                  <input
                    className="uk-input"
                    type="text"
                    placeholder={lang.file}
                    disabled
                    required
                  />
                </div>
              </div>
              <div className="uk-margin-medium-top">
                <Button
                  label={lang.update}
                  type="primary"
                  loading={this.state.sending}
                />
              </div>
            </form>
          </div>
        </div>
        <div id={`update-design-${unit.id}`} uk-modal="bg-close:false">
          <div className="uk-modal-dialog uk-modal-body uk-width-large">
            <button
              className="uk-modal-close-default"
              type="button"
              uk-close=""
            />
            <h3 className="uk-card-title">{lang.design}</h3>
            <form onSubmit={e => this.handleDesignUpdate(e, unit)}>
              <div className="uk-margin">
                <div uk-form-custom="target: true" className="uk-width-1-1">
                  <input name="design_url" type="file" required />
                  <input
                    className="uk-input"
                    type="text"
                    placeholder={lang.file}
                    disabled
                    required
                  />
                </div>
              </div>
              <div className="uk-margin-medium-top">
                <Button
                  label={lang.update}
                  type="primary"
                  loading={this.state.sending}
                />
              </div>
            </form>
          </div>
        </div>
        <div id={`update-cover-${unit.id}`} uk-modal="bg-close:false">
          <div className="uk-modal-dialog uk-modal-body uk-width-large">
            <button
              className="uk-modal-close-default"
              type="button"
              uk-close=""
            />
            <h3 className="uk-card-title">{lang.cover}</h3>
            <form onSubmit={e => this.handleCoverUpdate(e, unit)}>
              <div className="uk-margin">
                <div uk-form-custom="target: true" className="uk-width-1-1">
                  <input name="cover" type="file" required />
                  <input
                    className="uk-input"
                    type="text"
                    placeholder={lang.file}
                    disabled
                    required
                  />
                </div>
              </div>
              <div className="uk-margin-medium-top">
                <Button
                  label={lang.update}
                  type="primary"
                  loading={this.state.sending}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default UnitActions;
