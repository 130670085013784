import React from "react";
import { Card } from "../Card/Card";
import UnitActions from "../UnitActions/UnitActions";

export const Units = props => {
  const { units, onSelect, dir, action, options } = props;
  console.log(units);
  return (
    <div className="uk-child-width-1-2@m uk-child-width-1-1@s" uk-grid="">
      {units.map(u => (
        <Card
          key={u.id}
          title={u.name}
          dir={dir}
          // content={`${lang.developer}: ${p.developer.name}`}
          image={
            (u.cover && u.cover.thumbUrl) ||
            (u.tour && u.tour.floorplan && u.tour.floorplan.thumbUrl)
          }
          actionLabel={action}
          onClick={() => onSelect(u)}
        >
          {options && <UnitActions unit={u} />}
        </Card>
      ))}
    </div>
  );
};
