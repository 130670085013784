import React from "react";
import PropertyCard from "../PropertyCard/PropertyCard";

const TopBar = props => (
  <div className="uk-position-relative">
    <nav
      className="uk-navbar-container uk-navbar-transparent uk-background-secondary uk-light"
      uk-navbar="dropbar: true; dropbar-mode: push; mode:click"
    >
      <div className={"uk-navbar-left uk-margin-left"}>
        <a onClick={props.onBack} className="uk-navbar-item uk-text-capitalize">
          <span uk-icon="icon:arrow-left; ratio:1.5" />
          <span>{props.title}</span>
        </a>
      </div>
      {props.properties && Object.keys(props.properties).length > 0 && (
        <div className={"uk-navbar-right"}>
          <ul className="uk-navbar-nav">
            <li>
              <a href="#">{props.lang.more}</a>
              <div className="uk-navbar-dropdown uk-background-secondary uk-width-1-1">
                <div className="uk-light">
                  {props.loading && <span>Loading... </span>}
                  {!props.loading &&
                    props.properties &&
                    Object.keys(props.properties).map((project, key) => (
                      <div className="uk-margin" dir={props.dir} key={key}>
                        <h5
                          className={props.dir === "rtl" ? "uk-text-right" : ""}
                        >
                          {project}
                        </h5>
                        <div
                          className="uk-child-width-1-4@l uk-child-width-1-3@m uk-child-width-1-1@s"
                          uk-grid=""
                        >
                          {props.properties[project].map(
                            (property, propKey) => (
                              <div key={propKey}>
                                <PropertyCard
                                  thumb={
                                    property.cover && property.cover.thumbUrl
                                  }
                                  name={property.name}
                                  id={property.id}
                                  onClick={props.onPropertyClick}
                                ></PropertyCard>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </li>
          </ul>
        </div>
      )}
    </nav>
    <div className="uk-navbar-dropbar uk-background-secondary"></div>
  </div>
);

export default TopBar;
