export default {
    registrationProgressBar: {
        steps: [
            " העלאת תכנית דירה",
            "שאלון השראה",
            " פרטים נוספים"

        ],
    },
    register: {
        header: "הרשמה",
        next: "המשך",
        steps: {
            register: "הרשמה",
            floorplan: "תכנית דירה",
            inspirationQuiz: "שאלון השראה"
        },
        fields: {
            fullName: "שם מלא",
            email: "אי-מייל",
            phone: "טלפון",
            password: "סיסמא",
            passwordValidation: "אימות סיסמא",
            address: "כתובת",
            consent: "קראתי ואני מסכים ל",
            conditions: "תנאי השימוש"

        },
        errors: {
            email: "כתובת המייל לא נכונה.",
            emailExists: "קיים חשבון עם מייל זה.",
            phone: "מספר הטלפון צריך להיות בעל עשר ספרות.",
            password: "הסיסמא צריכה להיות באורך של 8-20 תוים, ולכלול לפחות אות גדולה אחת, אות קטנה אחת וספרה.",
            password_confirmation: "הסיסמאות שהקלדת אינן תואמות.",
            general: "אנחנו מצטערים אבל קרתה שגיאה. בבקשה נסו שוב מאוחר יותר"
        }
    },
    uploadPdf: {
        header: "העלאת תכנית דירה",
        alreadyHaveAnAccount: "כבר יש לך חשבון?",
        login: "התחבר",
        fields: {
            aptAddress: "שם לדירה/כתובת",
            projectName: "שם הפרויקט",
            uploadFloorPlan: "גרור לכאן תכנית דירה, או לחץ כדי לבחור קובץ",
        },
        buttons: {
            next: "המשך"
        },
        errors: {
            noFloorplan: "יש להעלות תוכנית דירה (קובץ PDF, PNG או JPEG)",
            errorOccured: "אנחנו מתנצלים, קרתה תקלה. בבקשה נסו שוב מאוחר יותר או פנו לשירות הלקוחות"
        }
    },
    topNav: {
        backToPatricia: "חזרה לאתר",
    },
    footer: {
      note: "צריכים עזרה? אנחנו זמינים בטלפון 052-6567777 או במייל"
    },
    inspirationQuiz: {
        header: "עזור לפטרישיה להבין את סגנון העיצוב שלך",
        actions: {
            skip: "דלג",
            next: "הבא",
            back: "חזור",
            done: "סיימתי"
        },
        result: {
            line1: "תודה, עזרת לפטרישיה להבין את סגנון העיצוב שלך!",
            line2: "נראה כי אתה מתחבר לסגנון העיצוב",
            line3: "המאופיין ב",
            line4: "כעת נשאר רק להרשם ובתוך זמן קצר תקבל סיור וירטואלי מעוצב ומלא של הבית שלך.",
        },
    },
    underConstructionPage: {
        title: "פטרישיה מכינה את הבית שלך, בקרוב תוכל לסייר בו ולבחור את המוצרים שאתה הכי אוהב.",
        designStyle: {
            line1: "נראה כי אתה מתחבר לסגנון העיצוב",
            line2: "המאופיין ב",
            line3: "בתוך זמן קצר תקבל סיור וירטואלי מעוצב ומלא של הבית שלך.",
        },
        body: "אנחנו נשלח לך מייל כשהבית שלך יהיה מוכן.",
    },
    login: {
        fields: {
            email: {
                placeholder: "email@company.com",
                label: 'דוא"ל',
                error: "אנא הזן כתובת מייל תקנית ורשומה במערכת"
            },
            password: {
                placeholder: "*********",
                label: "סיסמא",
                error: "אנא הזן ססמה תקנית",
                tooltip:
                    "הססמה חייבת להיות באנלגית, בין 8 ל 20 תווים, לפחות אות אחת גדולה ואחת קטנה, לפחות ספרה אחת"
            },
            remember: {
                label: "זכור אותי",
                tooltip: "זכור את חשבונך במכשיר זה"
            },
            passwordConfirmation: {
                placeholder: "*********",
                label: "וודא ססמה",
                tooltip: "חייבת לתאום לססמה"
            }
        },
        actions: {
            login: {
                label: "התחבר"
            },
            reset: {
                label: "שלח קישור איפוס"
            },
            setPassword: {
                label: "עדכן ססמה"
            },
            forgotPassword: "שכחת ססמה?",
            createAccount: {
                notYetRegistered: "עדיין אין לך חשבון?",
                createAnAccount: "צור מתשתמש"
            }
        },
    },
    checkoutProgressBar: {
        steps: [
            "פרטי משלוח",
            "תשלום וסיכום",
        ],
    },
    successPage: {
        thankYou: "תודה! התשלום התקבל בהצלחה",
        contactAfterOrder: "נציג מטעמינו יצור איתך קשר לתיאום משלוח"
    }
}