import Lang from "../localization/en-us";
import Settings from "./settings";

export default {
  auth: {
    isAuthenticated: false
  },
  url: {
    pathname: "/",
    search: ""
  },
  componentData: {
    loading: true,
    searching: false,
    searchTerm: null
  },
  data: {},
  active: {},
  tasks: {
    consumer: {
      properties: {}
    }
  },
  user: null,
  localization: Lang,
  app: Settings
};
