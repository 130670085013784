import React, {useContext, useEffect, useState} from "react";
import {Navbar} from "../../Navbar/Navbar";
import settings from "../../../app/settings";
import {SideNav} from "../../SideNav/SideNav";
import {StateContext} from "../../../app/state";
import {deletePlace, logoutUser, patriciaAiGetShoppingCart} from "../../../app/actions";
import PatriciaNavBar from "../PatriciaNav/PatriciaSideNav";
import PatriciaSideNav from "../PatriciaNav/PatriciaSideNav";
import PatriciaTopNav from "../PatriciaNav/PatriciaTopNav";
import {
    MobileView, BrowserView,
    isMobile
} from "react-device-detect";
import PatriciaMobileTopNav from "../PatriciaNav/PatriciaMobileTopNav";

function PageLayout(props){
    const context = useContext(StateContext);
    const [
            {
                localization: { nav },
                auth: { user },
                componentData,
                data: { shoppingCart },
            },
            dispatch
        ] = context;
    const dir = user && user.locality === "he-il" ? "rtl" : "ltr";
    const [showSideMenu, setShowSideMenu] = useState(false);

    // useEffect(() => {
    //     patriciaAiGetShoppingCart({}, (res) => {
    //         dispatch({
    //             type: "setShoppingCart",
    //             data: res.data
    //         });
    //     });
    // }, [])

    // useEffect(() => {
    //     patriciaAiGetShoppingCart({}, (res) => {
    //         dispatch({
    //             type: "updateData",
    //             newData: res.data
    //         });
    //     });
    // }, [])

    function handleLogout () {
        componentData.loading = true;
        dispatch({
            type: "updateComponentData",
            newComponentData: componentData
        });
        logoutUser({}, () => {
            user.isAuthenticated = false;
            dispatch({
                type: "setAuth",
                newAuth: user
            });
            window.location.reload();
        });
    };


    return (
        <div dir={dir} uk-height-viewport="" className="uk-flex uk-flex-column patricia-hebrew-font">
            {isMobile ?
                <PatriciaMobileTopNav
                    logo={settings.appLogo}
                    dir={dir}
                    onLogout={handleLogout}
                    logoutText={nav.top.logout}
                    user={user}
                    />
                :

                <PatriciaTopNav
                    logo={settings.appLogo}
                    dir={dir}
                    onLogout={handleLogout}
                    logoutText={nav.top.logout}
                    user={user}
                />}
            <div className="uk-height-expand uk-width-expand uk-flex uk-height-match">
                <div className={`${!isMobile && 'uk-width-1-6@m'}`}>
                    <MobileView>
                    </MobileView>
                    <BrowserView>
                        <PatriciaSideNav
                            logo={settings.appLogo}
                            lang={nav}
                            //onClick={this.handleClick}
                            dir={dir}
                            user={user}
                            //onLogout={this.handleLogout}
                        />
                        <div className='uk-text-center uk-text-small uk-margin-medium-right uk-margin-medium-bottom uk-margin-medium-bottom uk-margin-medium-left uk-position-fixed' style={{bottom: "0" }}>
                            <div>{nav.side.contact}</div>
                            <div>{nav.side.email}<a href="mailto:team@patricia-ai.com" style={{color: "blue"}}> team@patricia-ai.com</a></div>
                            <div>{nav.side.phone}</div>
                        </div>
                    </BrowserView>
                </div>
                <div className="uk-width-expand">
                    <div className="uk-height-1-1">
                        <div className="uk-border-rounded uk-height-1-1 uk-background-muted">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PageLayout;