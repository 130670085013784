import React, { Component } from "react";
import Autocomplete from "react-google-autocomplete";
import { StateContext } from "../../app/state";
import { Input } from "../Input/Input";
import { notification } from "../../app/actions";
import { Button } from "../Button/Button";

class LocationForm extends Component {
  static contextType = StateContext;
  state = {
    address: false,
    country: false,
    coordinates: false,
    google_location_id: false
  };
  handlePlaceSelect = location => {
    let address = location.formatted_address;
    let addressArray = address.split(",");
    let country = addressArray[addressArray.length - 1];
    let coordinates = JSON.stringify({
      lat: location.geometry.location.lat(),
      lng: location.geometry.location.lng()
    });
    let google_location_id = location.place_id;
    this.setState({
      address,
      country,
      coordinates,
      google_location_id
    });
  };

  handleSubmit = event => {
    const [
      {
        localization: {
          organization: {
            locations: {
              create: {
                errors: { general: generalError }
              }
            }
          }
        }
      }
    ] = this.context;
    event.preventDefault();
    if (
      !this.state.address ||
      !this.state.country ||
      !this.state.coordinates ||
      !this.state.google_location_id
    ) {
      notification(generalError, "danger");
      return;
    }
    if (this.props.onSubmit) this.props.onSubmit(event);
  };
  render() {
    const [
      {
        localization: {
          organization: {
            locations: { create: lang }
          }
        }
      }
    ] = this.context;
    return (
      <React.Fragment>
        <div id={this.props.id} uk-modal="bg-close:false">
          <div className="uk-modal-dialog uk-modal-body uk-width-large">
            <button
              className="uk-modal-close-default"
              type="button"
              uk-close=""
            />
            <div className="viz-bg-white uk-padding" dir={this.props.dir}>
              <form className="uk-width-expand@m" onSubmit={this.handleSubmit}>
                <input
                  type="text"
                  name="locateable_type"
                  defaultValue="App\Retailer"
                  hidden
                />
                <input
                  type="text"
                  name="locateable_id"
                  value={this.props.locateableId}
                  hidden
                />
                <input
                  type="text"
                  name="address"
                  value={this.state.address}
                  hidden
                />
                <input
                  type="text"
                  name="country"
                  value={this.state.country}
                  hidden
                />
                <input
                  type="text"
                  name="coordinates"
                  value={this.state.coordinates}
                  hidden
                />
                <input
                  type="text"
                  name="google_location_id"
                  defaultValue={this.state.google_location_id}
                  hidden
                />
                <div>
                  <h2 className="uk-text-bold">{lang.title}</h2>
                </div>
                <div className="uk-margin">
                  <label className="uk-text-meta uk-text-uppercase uk-margin-small-bottom uk-display-block">
                    {lang.fields.address.label}
                  </label>
                  <Autocomplete
                    className="uk-input uk-border-rounded uk-width-1-1"
                    onPlaceSelected={this.handlePlaceSelect}
                    types={["address"]}
                    required
                  />
                  <Input
                    type="text"
                    name="phone"
                    placeholder={lang.fields.phone.placeholder}
                    label={lang.fields.phone.label}
                  />
                  <Input
                    type="text"
                    name="hours"
                    placeholder={lang.fields.hours.placeholder}
                    label={lang.fields.hours.label}
                  />
                  <Button
                    label={lang.actions.create.label}
                    loading={this.props.processing}
                    cssClasses={"uk-width-1-1"}
                    type="primary"
                  ></Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default LocationForm;
