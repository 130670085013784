import React from "react";
import { Button } from "../Button/Button";

const QuotePlace = props => {
  return (
    <div className="uk-background-muted uk-padding-small uk-border-rounded uk-margin">
      <div uk-grid="">
        <div
          className={`uk-width-expand ${props.shouldCompact &&
            "uk-flex uk-flex-middle"}`}
        >
          <h5
            className={`${props.shouldCompact && "uk-margin-remove"} uk-inline ${props.alertUnnamed && (props.place.localeName === "unnamed" || props.place.name === "unnamed") ? " uk-text-alert" : null }`}
            style={{ cursor: props.place.id ? "" : "pointer" }}
            uk-toggle={!props.place.id && "target: >span; mode:hover"}
            uk-tooltip={!props.place.id && props.lang.createPlace}
            onClick={
              !props.place.id && props.onClick
                ? () => props.onClick(props.place)
                : () => {}
            }
          >
            {props.place.localeName || props.place.name}
            {!props.place.id && (
              <span
                hidden
                uk-icon="icon: plus-circle"
                className="uk-margin-small-left uk-text-primary uk-animation-fade uk-animation-fast"
              ></span>
            )}
          </h5>
          {props.shouldCompact && (
            <Button
              size="small"
              label={props.toggleLabel}
              cssClasses="uk-margin-left uk-margin-right"
              atts={{
                "uk-toggle": `target:${props.compactToggle}; cls: viz-compact-place`
              }}
            ></Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuotePlace;
