import React from "react";

const SectionTitle = props => (
  <div className="uk-grid-small uk-margin-medium uk-flex-middle" uk-grid="">
    {props.icon && (
      <div className="uk-width-auto">
        <div>
          <span uk-icon={props.icon}></span>
        </div>
      </div>
    )}
    <div className="uk-width-expand">
      <h3>{props.content}</h3>
    </div>
    {props.children && <div className="uk-width-auto">{props.children}</div>}
  </div>
);

export default SectionTitle;
