import React, { useMemo, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

const baseStyle = {
  borderWidth: "3px",
  borderStyle: "dashed",
  borderColor: "#e0e0e0",
  cursor: "pointer"
};

const activeStyle = {
  borderColor: "#2196f3"
};

const acceptStyle = {
  borderColor: "#00e676"
};

const rejectStyle = {
  borderColor: "#ff1744"
};

const backgroundImage = img => ({
  background: `url(${img}) center center`,
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  ...acceptStyle
});

const DropZone = props => {
  const [files, setFiles] = useState([]);
  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragActive,
    isDragReject
  } = useDropzone({
    accept: "image/jpeg,image/gif,image/png,application/pdf",
    multiple: false,
    onDrop: acceptedFiles => {
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
    },
    onDropAccepted: files => {
      props.onDropAccepted(files)
    }
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      ...(files && files.length > 0 ? backgroundImage(files[0].preview) : {})
    }),
    [isDragActive, isDragAccept, isDragReject, files]
  );

  useEffect(
    () => () => {
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <div
      className={`uk-width-1-1 uk-text-center uk-overflow-hidden ${props.className}`}
    >
      <div
        {...getRootProps({
          className: "uk-padding uk-padding",
          style
        })}
      >
        <input
          {...getInputProps()}
          name={props.fieldName || "file"}
          required={props.required}
        />
        {props.children ||
          "Drag 'n' drop some files here, or click to select files"}
        {files && files.length > 0 && (
          <span className="uk-label uk-display-block uk-border-rounded uk-light uk-background-secondary uk-truncate">
            {files[0].name}
          </span>
        )}
      </div>
    </div>
  );
};

export default DropZone;
