// Comment out logs nd no-locale

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { StateContext } from "../app/state";
import * as QueryString from "query-string";
import {
  getByUrl,
  getAuthUser,
  checkAuthCookie,
  revokeAuthCookie
} from "./actions";

class UrlFilter extends Component {
  static contextType = StateContext;
  state = {
    authenticating: false
  };
  /**
   * Remove in production
   */
  showInfo = () => {
    const { history, location, match } = this.props;
    const [state] = this.context;
    const oldPath = state.url.pathname + state.url.search;
    const newPath = location.pathname + location.search;
    if (oldPath !== newPath) {
      console.log(`Path changed from <${oldPath}> to <${newPath}>`);
    }
    console.log("App State: ", state);
    console.log("Route history", history);
    console.log("Route location", location);
    console.log("Route match", match);
  };

  setLoading = state => {
    const [{ componentData }, dispatch] = this.context;
    dispatch({
      type: "updateComponentData",
      newComponentData: { ...componentData, loading: state }
    });
  };

  monitorUrlChanges = () => {
    const { location } = this.props;
    const [state, dispatch] = this.context;
    const oldPath = state.url.pathname + state.url.search;
    const newPath = location.pathname + location.search;
    const { componentData } = state;
    if (oldPath !== newPath) {
      const hierarchy = location.pathname.split("/");
      if (hierarchy.length === 2) {
        const entry = hierarchy[hierarchy.length - 1];
        if (location.search) {
          dispatch({
            type: "updateComponentData",
            newComponentData: { ...componentData, searching: true }
          });
          getByUrl(newPath, res => {
            const data = state.data;
            const newData = { ...data, [entry]: res.data };
            if (res.links) {
              newData[`${entry}_links`] = res.links;
            }
            if(res.meta) {
              newData[`${entry}_meta`] = res.meta;
            }
            dispatch({
              type: "updateData",
              newData
            });
            dispatch({
              type: "updateComponentData",
              newComponentData: { ...componentData, searching: false }
            });
            this.setLoading(false);
          });
        } else if (entry !== "consumers" && entry !== "projects") {
          getByUrl(newPath, res => {
            const data = state.data;
            const newData = { ...data, [entry]: res.data };
            if (res.links) {
              newData[`${entry}_links`] = res.links;
            }
            if(res.meta) {
              newData[`${entry}_meta`] = res.meta;
            }
            dispatch({
              type: "updateData",
              newData
            });
            this.setLoading(false);
          });
        } else {
          this.setLoading(false);
        }
      } else {
        hierarchy.forEach((item, i) => {
          const isUuid = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/.test(
            item
          );
          if (hierarchy.length !== 3) this.setLoading(true);
          if (isUuid) {
            this.setLoading(true);
            const active = state.active;
            const entry = hierarchy[i - 1].substring(
              0,
              hierarchy[i - 1].length - 1
            );
            getByUrl(
              `${entry}s/${item}${
                i === hierarchy.length - 1 ? location.search : ""
              }`,
              res => {
                active[entry] = res.data;
                dispatch({
                  type: "updateActive",
                  newActive: active
                });
                this.setLoading(false);
              }
            );
          }
          if (!isUuid && i === hierarchy.length - 1) {
            dispatch({
              type: "updateComponentData",
              newComponentData: { ...componentData, searching: true }
            });
            const data = state.data;
            this.setLoading(true);
            getByUrl(`${item}${location.search}`, res => {
              dispatch({
                type: "updateData",
                newData: { ...data, [item]: res.data }
              });
              dispatch({
                type: "updateComponentData",
                newComponentData: { ...componentData, searching: false }
              });
              this.setLoading(false);
            });
          }
        });
      }
      dispatch({
        type: "updateUrl",
        newUrl: {
          pathname: location.pathname,
          search: location.search
        }
      });
    }
  };

  manageAuth = () => {
    const [
      {
        auth,
        componentData: { loading }
      },
      dispatch
    ] = this.context;
    const authToken = checkAuthCookie();
    if (authToken && !auth.isAuthenticated) {
      dispatch({
        type: "setAuth",
        newAuth: { ...auth, isAuthenticated: true, token: authToken }
      });
    }
    if (auth.isAuthenticated && !auth.user) {
      if (!loading) this.setLoading(true);
      if (this.state.authenticating) return;
      this.setState({
        authenticating: true
      });
      getAuthUser({ params: { seller: true, consumer: true } }, (res, error) => {
        if (error || !res.data || !res.data.id || !res.data.locality) {
          revokeAuthCookie();
          console.log("failed");
          window.location.href = "/login";
          return;
        }
        dispatch({
          type: "setAuth",
          newAuth: { ...auth, user: res.data, token: authToken }
        });
        const locality =
          (this.props.location.search &&
            QueryString.parse(this.props.location.search).locality) ||
          res.data.locality;
        import(`../localization/${locality}`).then(lang => {
          dispatch({
            type: "setLang",
            lang: lang.default
          });
          this.setLoading(false);
        });
      }).catch();
    } else if (!this.state.hasLng) {
      const locality =
        this.props.location.search &&
        QueryString.parse(this.props.location.search).locality;
      if (!locality) return;
      console.log("locality", locality);
      import(`../localization/${locality}`).then(lang => {
        console.log(lang);
        this.setState({
          hasLng: true
        });
        dispatch({
          type: "setLang",
          lang: lang.default
        });
      });
    }
  };
  render() {
    // this.showInfo();
    this.manageAuth();
    this.monitorUrlChanges();
    const { children } = this.props;
    return <React.Fragment>{children}</React.Fragment>;
  }
}

export default withRouter(UrlFilter);
