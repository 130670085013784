import React from "react";

const QuoteSum = props => (
  <div className="uk-card uk-card-default uk-border-rounded uk-card-body">
    <div uk-grid="">
      <div className="uk-width-expand">
        <div>
          {props.lang.subTotal}:<br />
          {props.lang.tax}:
        </div>
      </div>
      <div className="uk-width-auto">
        <div className="uk-text-right">
          {props.sum} <br />{" "}
          {(props.tax * parseFloat(props.sum.replace(",", "")))
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </div>
      </div>
    </div>
    <hr />
    <div uk-grid="">
      <div className="uk-width-expand">
        <div>{props.lang.total}:</div>
      </div>
      <div className="uk-width-auto">
        <div>
          {parseFloat(
            parseFloat(props.sum.replace(",", "")) * (1.0 + props.tax)
          )
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </div>
      </div>
    </div>
  </div>
);

export default QuoteSum;
