import React, { Component } from "react";
import { Logo } from "../components/Logo/Logo";
import { Input } from "../components/Input/Input";
import { Button } from "../components/Button/Button";
import { Checkbox } from "../components/Checkbox/Checkbox";
import { StateContext } from "../app/state";
import { loginUser, notification } from "../app/actions";
import { withRouter, Link } from "react-router-dom";

class Login extends Component {
  static contextType = StateContext;
  state = {
    form: {
      email: {
        value: "",
        error: false
      },
      password: {
        value: "",
        error: false
      },
      remember: {
        value: true
      }
    },
    sending: false
  };
  handleLogin = e => {
    e.preventDefault();
    const fields = this.state.form;
    const payload = {};
    for (var key in fields) {
      const item = fields[key];
      if (!item.value || item.error) {
        notification(
          this.context[0].localization.messages.error.login,
          "danger"
        );
        return;
      } else {
        payload[key] = item.value;
      }
    }
    this.setState({
      sending: true
    });
    loginUser({ payload }, this.loginCallback);
  };

  loginCallback = (res, error) => {
    if (!error) {
      const dispatch = this.context[1];
      const newAuth = {
        isAuthenticated: true,
        token: `${res.token_type} ${res.access_token}`
      };
      dispatch({
        type: "setAuth",
        newAuth
      });
      this.props.history.push("/");
    } else {
      notification(
          this.context[0].localization.messages.error.wrongPassword,
          "danger"
      );
    }
    this.setState({
      sending: false
    });
  };

  handleBlur = (e, field) => {
    const value =
      field !== "remember" ? e.currentTarget.value : e.currentTarget.Checked;
    const form = this.state.form;
    switch (field) {
      case "email":
        if (value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/)) {
          form.email.error = false;
          form.email.value = value;
        } else {
          if (e.type === "blur") form.email.error = true;
        }
        this.setState({
          form
        });
        break;
      case "password":
        form.password.value = value;
        this.setState({
          form
        });
        break;
      case "remember":
        form.remember.value = value;
        this.setState({
          form
        });
        break;
      default:
        break;
    }
  };

  render() {
    // const { company, lang } = this.props;
    // const { fields, actions } = lang;
    const [
      {
        localization: { fields, actions },
        app
      }
    ] = this.context;
    const {
      form: { email, password, remember },
      sending
    } = this.state;
    return (
      <div>
        <div
          className="uk-padding uk-flex uk-flex-column uk-flex-center uk-flex-middle"
          style={{ minHeight: "80vh" }}
        >
          <div className="viz-box-shadow-special uk-padding uk-animation-fade viz-bg-white">
            <div className="uk-margin-medium-bottom uk-text-center uk-animation-slide-top-small">
              <Logo
                src={app.appLogo}
                alt={app.appName}
                type="default"
                width="150px"
                cssClasses="uk-align-center uk-margin-medium-top uk-margin-large-bottom"
              />
            </div>
            <div className="uk-animation-slide-bottom-small">
              <form
                className="uk-width-medium uk-width-expand@m"
                id="login-form"
                onSubmit={this.handleLogin}
              >
                <Input
                  atts={{
                    autoFocus: true
                  }}
                  name="email"
                  placeholder={fields.email.placeholder}
                  label={fields.email.label}
                  required
                  error={email.error ? fields.email.error : false}
                  onChange={e => this.handleBlur(e, "email")}
                  onBlur={e => this.handleBlur(e, "email")}
                />
                <Input
                  name="password"
                  type="password"
                  placeholder={fields.password.placeholder}
                  label={fields.password.label}
                  required
                  error={password.error ? fields.password.error : false}
                  onChange={e => this.handleBlur(e, "password")}
                />
                <Checkbox
                  name="remember"
                  label={fields.remember.label}
                  value={remember.value}
                  // onChange={e => this.handleBlur(e, "remember")}
                />
                <Button
                  label={actions.login.label}
                  type="primary"
                  cssClasses="uk-width-1-1 uk-margin-small-top"
                  loading={sending}
                />
              </form>
            </div>
          </div>
          <div className="uk-text-center uk-padding">
            <Link to="/reset-password" className="uk-text-muted">
              {actions.forgotPassword}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Login);
