import * as api from "./api";
import UiKit from "uikit";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export const checkAuthCookie = () => {
  const cookie = cookies.get("__veRetailSellerAuth");
  if (cookie) {
    api.setAuthHeader(cookie);
    return cookie;
  }
  return false;
};

export const revokeAuthCookie = () => {
  cookies.remove("__veRetailSellerAuth");
  return;
};

/**
 * Pop a notification
 *
 * @param {string} string
 * @param {string} type
 */
export const notification = (string, type) => {
  UiKit.notification(`${string}`, {
    timeout: 5000,
    pos: "bottom-right",
    status: type
  });
};

/**
 *
 * @param {string} target
 * @param {function} callback
 */
export const getByUrl = async (target, callback) => {
  await api
    .fetchByUrlQuery(target)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      callback(error, true);
    });
};

export const loginUser = async (props, callback) => {
  await api
    .fetchLoginUser(props)
    .then(res => {
      cookies.set(
        "__veRetailSellerAuth",
        `${res.token_type} ${res.access_token}`,
        {
          maxAge: res.options.maxAge
        }
      );
      callback(res);
      notification("Success", "success");
    })
    .catch(error => {
      callback(error, true);
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const logoutUser = async (props, callback) => {
  await api
    .fetchLogoutUser(props)
    .then(res => {
      revokeAuthCookie();
      notification("Success", "success");
      callback(res);
    })
    .catch(error => {
      revokeAuthCookie();
      callback(error, true);
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const searchUser = async (props, callback) => {
  await api
    .fetchSearchUser(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      callback(error, true);
    });
};

export const updateUser = async (props, callback) => {
  await api
    .fetchUpdateUser(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const updatePassword = async (props, callback) => {
  await api
    .fetchUpdatePassword(props)
    .then(res => {
      notification("Password successfully updated", "success");
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const setPassword = async (props, callback) => {
  await api
    .fetchSetPassword(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const requestResetPassword = async (props, callback) => {
  await api
    .fetchRequestResetPassword(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const getAuthUser = async (props, callback) => {
  await api
    .fetchAuthUser(props)
    .then(res => callback(res))
    .catch(error => callback(error, true));
};

export const createItem = async (props, callback) => {
  await api
    .fetchCreateItem(props)
    .then(res => {
      callback(res);
      notification("Success", "success");
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const getItem = async (props, callback) => {
  await api
    .fetchGetItem(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const updateItem = async (props, callback) => {
  await api
    .fetchUpdateItem(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const indexItems = async (props, callback) => {
  await api
    .fetchIndexItems(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      callback(error, true);
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const createProduct = async (props, callback) => {
  await api
    .fetchCreateProduct(props)
    .then(res => {
      callback(res);
      notification("Success", "success");
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const getProduct = async (props, callback) => {
  await api
    .fetchGetProduct(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const updateProduct = async (props, callback) => {
  await api
    .fetchUpdateProduct(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const indexProducts = async (props, callback) => {
  await api
    .fetchIndexProducts(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const searchProducts = async (props, callback) => {
  await api
    .fetchSearchProducts(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const createRetailer = async (props, callback) => {
  await api
    .fetchCreateRetailer(props)
    .then(res => {
      callback(res);
      notification("Success", "success");
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const getRetailer = async (props, callback) => {
  await api
    .fetchGetRetailer(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const updateRetailer = async (props, callback) => {
  await api
    .fetchUpdateRetailer(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const indexRetailers = async (props, callback) => {
  await api
    .fetchIndexRetailers(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const createDeveloper = async (props, callback) => {
  await api
    .fetchCreateDeveloper(props)
    .then(res => {
      callback(res);
      notification("Success", "success");
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const getDeveloper = async (props, callback) => {
  await api
    .fetchGetDeveloper(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const deleteDeveloper = async (props, callback) => {
  await api
    .fetchDeleteDeveloper(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const updateDeveloper = async (props, callback) => {
  await api
    .fetchUpdateDeveloper(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const indexDevelopers = async (props, callback) => {
  await api
    .fetchIndexDevelopers(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const createManufacturer = async (props, callback) => {
  await api
    .fetchCreateManufacturer(props)
    .then(res => {
      callback(res);
      notification("Success", "success");
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const getManufacturer = async (props, callback) => {
  await api
    .fetchGetManufacturer(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const updateManufacturer = async (props, callback) => {
  await api
    .fetchUpdateManufacturer(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const indexManufacturers = async (props, callback) => {
  await api
    .fetchIndexManufacturers(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const createProjectCommon = async (props, callback) => {
  await api
    .fetchCreateProjectCommon(props)
    .then(res => {
      callback(res);
      notification("Success", "success");
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const duplicateProjectCommon = async (props, callback) => {
  await api
    .fetchDuplicateProjectCommon(props)
    .then(res => {
      callback(res);
      notification("Success", "success");
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const specsFromFile = async (props, callback) => {
  await api
    .fetchSpecsFromFile(props)
    .then(res => {
      callback(res);
      notification("Success", "success");
    })
    .catch(error => {
      callback({}, error.response);
      notification(error.response.data.message, "danger");
    });
};

export const clearMatrix = async (props, callback) => {
  await api
    .fetchClearMatrix(props)
    .then(res => {
      callback(res);
      notification("Success", "success");
    })
    .catch(error => {
      callback({}, error);
      notification(error.response.data.message, "danger");
    });
};

export const getMatrix = async (props, callback) => {
  await api
      .fetchGetMatrix(props)
      .then(res => {
        callback(res);
      })
      .catch(error => {
        callback({}, error);
        notification(error.response.data.message, "danger");
      });
};


export const getProjectCommon = async (props, callback) => {
  await api
    .fetchGetProjectCommon(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const updateProjectCommon = async (props, callback) => {
  await api
    .fetchUpdateProjectCommon(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const indexProjectCommons = async (props, callback) => {
  await api
    .fetchIndexProjectCommons(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const deleteProjectCommon = async (data, callback) => {
  await api
    .fetchDeleteProjectCommon(data)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const createTour = async (props, callback) => {
  await api
    .fetchCreateTour(props)
    .then(res => {
      callback(res);
      notification("Success", "success");
    })
    .catch(error => {
      callback({}, error);
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const getTour = async (props, callback) => {
  await api
    .fetchGetTour(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const updateTour = async (props, callback) => {
  await api
    .fetchUpdateTour(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const indexTours = async (props, callback) => {
  await api
    .fetchIndexTours(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const createDesign = async (props, callback) => {
  await api
    .fetchCreateDesign(props)
    .then(res => {
      callback(res);
      notification("Success", "success");
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const getDesign = async (props, callback) => {
  await api
    .fetchGetDesign(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const updateDesign = async (props, callback) => {
  await api
    .fetchUpdateDesign(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const indexDesigns = async (props, callback) => {
  await api
    .fetchIndexDesigns(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const createSet = async (props, callback) => {
  await api
    .fetchCreateSet(props)
    .then(res => {
      callback(res);
      notification("Success", "success");
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const getSet = async (props, callback) => {
  await api
    .fetchGetSet(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const updateSet = async (props, callback) => {
  await api
    .fetchUpdateSet(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const indexSets = async (props, callback) => {
  await api
    .fetchIndexSets(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const createSeller = async (props, callback) => {
  await api
    .fetchCreateSeller(props)
    .then(res => {
      callback(res);
      notification("Success", "success");
    })
    .catch(({ response }) => {
      const { errors } = response.data;
      if (errors) {
        Object.keys(errors).map(key => {
          notification(errors[key], "danger");
        });
      }
    });
};

export const getSeller = async (props, callback) => {
  await api
    .fetchGetSeller(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const deleteSeller = async (props, callback) => {
  await api
    .fetchDeleteSeller(props)
    .then(res => callback(res))
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const updateSeller = async (props, callback) => {
  await api
    .fetchUpdateSeller(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const indexSellers = async (props, callback) => {
  await api
    .fetchIndexSellers(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const createConsumer = async (props, callback) => {
  await api
    .fetchCreateConsumer(props)
    .then(res => {
      callback(res);
      notification("Success", "success");
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const getConsumer = async (props, callback) => {
  await api
    .fetchGetConsumer(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const updateConsumer = async (props, callback) => {
  await api
    .fetchUpdateConsumer(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const indexConsumers = async (props, callback) => {
  await api
    .fetchIndexConsumers(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const deleteConsumer = async (props, callback) => {
  await api
    .fetchDeleteConsumer(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const extendUnitToProperty = async (props, callback) => {
  await api
    .fetchExtendUnitToProperty(props)
    .then(res => {
      callback(res);
      notification("Success", "success");
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const spreadUnitChanges = async (props, callback) => {
  await api
    .fetchSpreadToProperties(props)
    .then(res => {
      callback(res);
      notification("Success", "success");
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const createProperty = async (props, callback) => {
  await api
    .fetchCreateProperty(props)
    .then(res => {
      callback(res);
      notification("Success", "success");
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const deleteProperty = async (props, callback) => {
  await api
    .fetchDeleteProperty(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const getProperty = async (props, callback) => {
  await api
    .fetchGetProperty(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const updateProperty = async (props, callback) => {
  await api
    .fetchUpdateProperty(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const indexProperties = async (props, callback) => {
  await api
    .fetchIndexProperties(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const createProject = async (props, callback) => {
  await api
    .fetchCreateProject(props)
    .then(res => {
      callback(res);
      notification("Success", "success");
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const getProject = async (props, callback) => {
  await api
    .fetchGetProject(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const updateProject = async (props, callback) => {
  await api
    .fetchUpdateProject(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const indexProjects = async (props, callback) => {
  await api
    .fetchIndexProjects(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const createQuote = async (props, callback) => {
  await api
    .fetchCreateQuote(props)
    .then(res => {
      callback(res);
      notification("Success", "success");
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const getQuote = async (props, callback) => {
  await api
    .fetchGetQuote(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const downloadQuote = async (props, callback) => {
  await api
    .fetchDownloadQuote(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const downloadPropertyCSV = async(props, callback) => {
  await api
      .fetchDownloadPropertyCSV(props)
      .then(res => {
        callback(res)
      })
      .catch(error => {
        if (error.response && error.response.data && error.response.data.errors) {
          Object.keys(error.response.data.errors).map(key => {
            error.response.data.errors[key].map(msg => {
              notification(msg, "danger");
            });
          });
        }
      })
};

export const updateQuote = async (props, callback) => {
  await api
    .fetchUpdateQuote(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const getPrivateConsumersRooms = async (props, callback) => {
  await api
      .fetchGetPrivateConsumersRooms(props, callback)
      .catch(error => {
        if (error.response && error.response.data && error.response.data.errors) {
          Object.keys(error.response.data.errors).map(key => {
            error.response.data.errors[key].map(msg => {
              notification(msg, "danger");
            });
          });
        }
      });
};

export const sendQuote = async (props, callback) => {
  await api
    .fetchSendQuote(props)
    .then(res => {
      callback(res);
      notification(res.message, "success");
    })
    .catch(error => {
      callback(error.response);
      notification(error.response.data.error, "danger");
    });
};

export const updateQuoteComponent = async (props, callback) => {
  await api
    .fetchUpdateQuoteComponent(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const deleteQuoteComponent = async (props, callback) => {
  await api
    .fetchDeleteQuoteComponent(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const indexQuotes = async (props, callback) => {
  await api
    .fetchIndexQuotes(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const deleteQuote = async (props, callback) => {
  await api
    .fetchDeleteQuote(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const createUnit = async (props, callback) => {
  await api
    .fetchCreateUnit(props)
    .then(res => {
      callback(res);
      notification("Success", "success");
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
      callback({}, error);
    });
};

export const getUnit = async (props, callback) => {
  await api
    .fetchGetUnit(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const updateUnit = async (props, callback) => {
  await api
    .fetchUpdateUnit(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const indexUnits = async (props, callback) => {
  await api
    .fetchIndexUnits(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const deleteUnit = async (data, callback) => {
  await api
    .fetchDeleteUnit(data)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const createRoom = async (props, callback) => {
  await api
    .fetchCreateRoom(props)
    .then(res => {
      callback(res);
      notification("Success", "success");
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
      callback({}, error);
    });
};

export const getRoom = async (props, callback) => {
  await api
    .fetchGetRoom(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const updateRoom = async (props, callback) => {
  await api
    .fetchUpdateRoom(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const indexRooms = async (props, callback) => {
  await api
    .fetchIndexRooms(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const deleteRoom = async (data, callback) => {
  await api
    .fetchDeleteRoom(data)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const createPlace = async (props, callback) => {
  await api
    .fetchCreatePlace(props)
    .then(res => {
      callback(res);
      notification("Success", "success");
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
      callback({}, error);
    });
};

export const getPlace = async (props, callback) => {
  await api
    .fetchGetPlace(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const updatePlace = async (props, callback) => {
  await api
    .fetchUpdatePlace(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const indexPlaces = async (props, callback) => {
  await api
    .fetchIndexPlaces(props)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const deletePlace = async (data, callback) => {
  await api
    .fetchDeletePlace(data)
    .then(res => {
      callback(res);
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        Object.keys(error.response.data.errors).map(key => {
          error.response.data.errors[key].map(msg => {
            notification(msg, "danger");
          });
        });
      }
    });
};

export const createLocation = async (data, callback) => {
  await api
    .fetchCreateLocation(data)
    .then(res => {
      callback(res);
    })
    .catch(({ response }) => {
      const { errors } = response.data;
      if (errors) {
        Object.keys(errors).map(key => {
          notification(errors[key], "danger");
        });
      }
    });
};

export const deleteLocation = async (data, callback) => {
  await api
    .fetchDeleteLocation(data)
    .then(res => {
      callback(res);
    })
    .catch(({ response }) => {
      const { errors } = response.data;
      if (errors) {
        Object.keys(errors).map(key => {
          notification(errors[key], "danger");
        });
      }
    });
};

export const createMedia = async (data, callback) => {
  await api
    .fetchCreateMedia(data)
    .then(res => {
      callback(res);
    })
    .catch(({ response }) => {
      const { errors } = response.data;
      if (errors) {
        Object.keys(errors).map(key => {
          notification(errors[key], "danger");
        });
      }
    });
};

export const deleteMedia = async (data, callback) => {
  await api
    .fetchDeleteMedia(data)
    .then(res => {
      callback(res);
    })
    .catch(({ response }) => {
      if (response && response.data) {
        const { errors } = response.data;
        if (errors) {
          Object.keys(errors).map(key => {
            notification(errors[key], "danger");
          });
        }
      }
    });
};



export const importProjectsFromFile = async (data, callback) => {
  await api
      .fetchImportProjectsFromFile(data)
      .then(res => {
        callback(res);
      })
      .catch(({ response }) => {
        if (response && response.data) {
          const { errors } = response.data;
          if (errors) {
            Object.keys(errors).map(key => {
              notification(errors[key], "danger");
            });
          }
        }
      });
};

export const importConsumersFromFile = async (data, callback) => {
  await api
      .fetchImportConsumersFromFile(data)
      .then(res => {
        callback(res);
      })
      .catch(({ response }) => {
        if (response && response.data) {
          const { errors } = response.data;
          if (errors) {
            Object.keys(errors).map(key => {
              notification(errors[key], "danger");
            });
          }
        }
      });
}

export const createTourCreator = async (data, callback) => {
  await api
      .fetchCreateTourCreator(data)
      .then(res => {
        callback(res);
      })
      .catch(({ response }) => {
        if (response && response.data) {
          const { errors } = response.data;
          if (errors) {
            Object.keys(errors).map(key => {
              notification(errors[key], "danger");
            });
          }
        }
      })
}

export const deleteTourCreator = async (data, callback) => {
  await api
      .fetchDeleteTourCreator(data)
      .then(res => {
        callback(res);
      })
      .catch(({ response }) => {
        if (response && response.data) {
          const { errors } = response.data;
          if (errors) {
            Object.keys(errors).map(key => {
              notification(errors[key], "danger");
            });
          }
        }
      })
}

export const deleteTourCreatorByUserId = async (data, callback) => {
  await api
      .fetchDeleteTourCreatorByUserId(data)
      .then(res => {
        callback(res);
      })
      .catch(({ response }) => {
        if (response && response.data) {
          const { errors } = response.data;
          if (errors) {
            Object.keys(errors).map(key => {
              notification(errors[key], "danger");
            });
          }
        }
      })
}

export const createDesignStyle = async (props, callback) => {
  await api
      .fetchCreateDesignStyle(props, callback)
      .catch(error => {
        if (error.response && error.response.data && error.response.data.errors) {
          Object.keys(error.response.data.errors).map(key => {
            error.response.data.errors[key].map(msg => {
              notification(msg, "danger");
            });
          });
        }
      });
}

export const indexDesignStyles = async (props, callback) => {
  await api
      .fetchIndexDesignStyles(props, callback)
      .catch(error => {
        if (error.response && error.response.data && error.response.data.errors) {
          Object.keys(error.response.data.errors).map(key => {
            error.response.data.errors[key].map(msg => {
              notification(msg, "danger");
            });
          });
        }
      });
};

export const deleteDesignStyle = async (props, callback) => {
  await api
      .fetchDeleteDesignStyle(props, callback)
      .catch(error => {
        if (error.response && error.response.data && error.response.data.errors) {
          Object.keys(error.response.data.errors).map(key => {
            error.response.data.errors[key].map(msg => {
              notification(msg, "danger");
            });
          });
        }
      });
}

export const getDesignStyle = async (props, callback) => {
  await api
      .fetchGetDesignStyle(props, callback)
      .catch(error => {
        if (error.response && error.response.data && error.response.data.errors) {
          Object.keys(error.response.data.errors).map(key => {
            error.response.data.errors[key].map(msg => {
              notification(msg, "danger");
            });
          });
        }
      });
}

export const updateDesignStyle = async (props, callback) => {
  await api
      .fetchUpdateDesignStyle(props, callback)
      .catch(error => {
        if (error.response && error.response.data && error.response.data.errors) {
          Object.keys(error.response.data.errors).map(key => {
            error.response.data.errors[key].map(msg => {
              notification(msg, "danger");
            });
          });
        }
      });
}

export const addProductToDesignStyleProductsList = async (props, callback) => {
  await api
      .fetchAddProductToDesignStyleProductsList(props, callback)
      .catch(error => {
        if (error.response && error.response.data && error.response.data.errors) {
          Object.keys(error.response.data.errors).map(key => {
            error.response.data.errors[key].map(msg => {
              notification(msg, "danger");
            });
          });
        }
      });
}

export const removeProductFromDesignStyleProductsList = async (props, callback) => {
  await api
      .fetchRemoveProductFromDesignStyleProductsList(props, callback)
      .catch(error => {
        if (error.response && error.response.data && error.response.data.errors) {
          Object.keys(error.response.data.errors).map(key => {
            error.response.data.errors[key].map(msg => {
              notification(msg, "danger");
            });
          });
        }
      });
}

export const changeDesignStyleProductFlags = async (props, callback) => {
  await api
      .fetchChangeDesignStyleProductFlags(props, callback)
      .catch(error => {
        if (error.response && error.response.data && error.response.data.errors) {
          Object.keys(error.response.data.errors).map(key => {
            error.response.data.errors[key].map(msg => {
              notification(msg, "danger");
            });
          });
        }
      });
}

export const createInspirationQuiz = async (props, callback) => {
  await api
      .fetchCreateInspirationQuiz(props, callback)
      .catch(error => {
        if (error.response && error.response.data && error.response.data.errors) {
          Object.keys(error.response.data.errors).map(key => {
            error.response.data.errors[key].map(msg => {
              notification(msg, "danger");
            });
          });
        }
      });
}

export const indexInspirationQuizzes = async (props, callback) => {
  await api
      .fetchIndexInspirationQuizzes(props, callback)
      .catch(error => {
        if (error.response && error.response.data && error.response.data.errors) {
          Object.keys(error.response.data.errors).map(key => {
            error.response.data.errors[key].map(msg => {
              notification(msg, "danger");
            });
          });
        }
      });
};

export const deleteInspirationQuiz = async (props, callback) => {
  await api
      .fetchDeleteInspirationQuiz(props, callback)
      .catch(error => {
        if (error.response && error.response.data && error.response.data.errors) {
          Object.keys(error.response.data.errors).map(key => {
            error.response.data.errors[key].map(msg => {
              notification(msg, "danger");
            });
          });
        }
      });
}

export const updateInspirationQuiz = async (props, callback) => {
  await api
      .fetchUpdateInspirationQuiz(props, callback)
      .catch(error => {
        if (error.response && error.response.data && error.response.data.errors) {
          Object.keys(error.response.data.errors).map(key => {
            error.response.data.errors[key].map(msg => {
              notification(msg, "danger");
            });
          });
        }
      });
}

export const getInspirationQuiz = async (props, callback) => {
  await api
      .fetchGetInspirationQuiz(props, callback)
      .catch(error => {
        if (error.response && error.response.data && error.response.data.errors) {
          Object.keys(error.response.data.errors).map(key => {
            error.response.data.errors[key].map(msg => {
              notification(msg, "danger");
            });
          });
        }
      });
}

export const getRetailerInspirationQuiz = async (props, callback) => {
  await api
      .fetchGetRetailerInspirationQuiz(props, callback)
      .catch(error => {
        if (error.response && error.response.data && error.response.data.errors) {
          Object.keys(error.response.data.errors).map(key => {
            error.response.data.errors[key].map(msg => {
              notification(msg, "danger");
            });
          });
        }
      });
}

export const setDefaultInspirationQuiz = async (props, callback) => {
  await api
      .fetchSetDefaultInspirationQuiz(props, callback)
      .catch(error => {
        if (error.response && error.response.data && error.response.data.errors) {
          Object.keys(error.response.data.errors).map(key => {
            error.response.data.errors[key].map(msg => {
              notification(msg, "danger");
            });
          });
        }
      });
}

export const setQuestionOrderOfInspirationQuiz = async (props, callback) => {
  await api
      .fetchSetQuestionsOrderOfInspirationQuiz(props, callback)
      .catch(error => {
        if (error.response && error.response.data && error.response.data.errors) {
          Object.keys(error.response.data.errors).map(key => {
            error.response.data.errors[key].map(msg => {
              notification(msg, "danger");
            });
          });
        }
      });
}

export const createInspirationQuizQuestion = async (props, callback) => {
  await api
      .fetchCreateInspirationQuizQuestion(props, callback)
      .catch(error => {
        if (error.response && error.response.data && error.response.data.errors) {
          Object.keys(error.response.data.errors).map(key => {
            error.response.data.errors[key].map(msg => {
              notification(msg, "danger");
            });
          });
        }
      });
}

export const deleteQuizQuestion = async (props, callback) => {
  await api
      .fetchDeleteQuizQuestion(props, callback)
      .catch(error => {
        if (error.response && error.response.data && error.response.data.errors) {
          Object.keys(error.response.data.errors).map(key => {
            error.response.data.errors[key].map(msg => {
              notification(msg, "danger");
            });
          });
        }
      });
}

export const updateQuizQuestion = async (props, callback) => {
  await api
      .fetchUpdateQuizQuestion(props, callback)
      .catch(error => {
        if (error.response && error.response.data && error.response.data.errors) {
          Object.keys(error.response.data.errors).map(key => {
            error.response.data.errors[key].map(msg => {
              notification(msg, "danger");
            });
          });
        }
      });
}

export const createInspirationQuizAnswer = async (props, callback) => {
  await api
      .fetchCreateInspirationQuizAnswer(props, callback)
      .catch(error => {
        if (error.response && error.response.data && error.response.data.errors) {
          Object.keys(error.response.data.errors).map(key => {
            error.response.data.errors[key].map(msg => {
              notification(msg, "danger");
            });
          });
        }
      });
}

export const updateInspirationQuizAnswer = async (props, callback) => {
  await api
      .fetchUpdateInspirationQuizAnswer(props, callback)
      .catch(error => {
        if (error.response && error.response.data && error.response.data.errors) {
          Object.keys(error.response.data.errors).map(key => {
            error.response.data.errors[key].map(msg => {
              notification(msg, "danger");
            });
          });
        }
      });
}

export const deleteQuizAnswer = async (props, callback) => {
  await api
      .fetchDeleteQuizAnswer(props, callback)
      .catch(error => {
        if (error.response && error.response.data && error.response.data.errors) {
          Object.keys(error.response.data.errors).map(key => {
            error.response.data.errors[key].map(msg => {
              notification(msg, "danger");
            });
          });
        }
      });
}

export const saveChosenAnswers = async (props, callback) => {
  await api
      .fetchSaveChosenAnswers(props, callback)
      .catch(error => {
        if (error.response && error.response.data && error.response.data.errors) {
          Object.keys(error.response.data.errors).map(key => {
            error.response.data.errors[key].map(msg => {
              notification(msg, "danger");
            });
          });
        }
      });
}

export const createQuizResult = async (props, callback) => {
  await api
      .fetchCreateQuizResult(props, callback)
      .catch(error => {
        if (error.response && error.response.data && error.response.data.errors) {
          Object.keys(error.response.data.errors).map(key => {
            error.response.data.errors[key].map(msg => {
              notification(msg, "danger");
            });
          });
        }
      });
}

export const calculateQuizResult = async (props, callback) => {
  await api
      .fetchCalculateQuizResult(props, callback)
      .catch(error => {
        if (error.response && error.response.data && error.response.data.errors) {
          Object.keys(error.response.data.errors).map(key => {
            error.response.data.errors[key].map(msg => {
              notification(msg, "danger");
            });
          });
        }
      });
}

export const calculateDesignSets = async (props, callback) => {
  await api
      .fetchCalculateDesignSets(props, callback)
      .catch(error => {
        if (error.response && error.response.data && error.response.data.errors) {
          Object.keys(error.response.data.errors).map(key => {
            error.response.data.errors[key].map(msg => {
              notification(msg, "danger");
            });
          });
        }
      });
}

export const getDesignSets = async (props, callback) => {
  await api
      .fetchGetDesignSets(props, callback)
      .catch(error => {
        if (error.response && error.response.data && error.response.data.errors) {
          Object.keys(error.response.data.errors).map(key => {
            error.response.data.errors[key].map(msg => {
              notification(msg, "danger");
            });
          });
        }
      });
}

export const getDesignSetByTour = async (props, callback) => {
  await api
      .fetchGetDefaultDesignSetByTour(props, callback)
      .catch(error => {
        if (error.response && error.response.data && error.response.data.errors) {
          Object.keys(error.response.data.errors).map(key => {
            error.response.data.errors[key].map(msg => {
              notification(msg, "danger");
            });
          });
        }
      });
}

export const changeDefaultDesignSet = async (props, callback) => {
    await api
        .fetchSetDefaultDesignSet(props, callback)
        .catch(error => {
          if (error.response && error.response.data && error.response.data.errors) {
            Object.keys(error.response.data.errors).map(key => {
              error.response.data.errors[key].map(msg => {
                notification(msg, "danger");
              });
            });
          }
        });
}


/**
 *
 * patria ai
 *
 **/
export const patriciaAiRegister = async (data, callback, handleError) => {
  await api
      .fetchPatriciaAiRegister(data)
      .then(res => {
        callback(res);
      })
      .catch(({ response }) => {
        handleError(response);
        return response;
      });
}

export const patriciaAiUpdateProductAmount = async (props, callback) => {
  await api
      .fetchPatriciaAiUpdateProductAmount(props, callback)
      .catch(error => {
        if (error.response && error.response.data && error.response.data.errors) {
          Object.keys(error.response.data.errors).map(key => {
            error.response.data.errors[key].map(msg => {
              notification(msg, "danger");
            });
          });
        }
      });
}

export const patriciaAiUpdateOrder = async (props, callback, errorCallback) => {
  await api
      .fetchPatriciaAiUpdateOrder(props, callback)
      .catch(error => {
        errorCallback(error)
        if (error.response && error.response.data && error.response.data.errors) {
          Object.keys(error.response.data.errors).map(key => {
            error.response.data.errors[key].map(msg => {
              notification(msg, "danger");
            });
          });
        }
      });
}

export const patriciaAiDeleteProduct = async (props, callback) => {
  await api
      .fetchPatriciaAiDeleteProduct(props, callback)
      .catch(error => {
        if (error.response && error.response.data && error.response.data.errors) {
          Object.keys(error.response.data.errors).map(key => {
            error.response.data.errors[key].map(msg => {
              notification(msg, "danger");
            });
          });
        }
      });
}

export const patriciaAiAddProductToCart = async (props, callback) => {
  await api
      .fetchPatriciaAiAddProduct(props, callback)
      .catch(error => {
        if (error.response && error.response.data && error.response.data.errors) {
          Object.keys(error.response.data.errors).map(key => {
            error.response.data.errors[key].map(msg => {
              notification(msg, "danger");
            });
          });
        }
      });
}

export const patriciaAiAddManyProductsToCart = async (props, callback) => {
  await api
      .fetchPatriciaAiAddManyProducts(props, callback)
      .catch(error => {
        if (error.response && error.response.data && error.response.data.errors) {
          Object.keys(error.response.data.errors).map(key => {
            error.response.data.errors[key].map(msg => {
              notification(msg, "danger");
            });
          });
        }
      });
}

export const patriciaAiClearCart = async (props, callback) => {
  await api
      .fetchPatriciaAiClearCart(props, callback)
      .catch(error => {
        if (error.response && error.response.data && error.response.data.errors) {
          Object.keys(error.response.data.errors).map(key => {
            error.response.data.errors[key].map(msg => {
              notification(msg, "danger");
            });
          });
        }
      });
}

export const patriciaAiGetShoppingCart = async (props, callback) => {
  await api
      .fetchGetShoppingCart(props, callback)
      .catch(error => {
        if (error.response && error.response.data && error.response.data.errors) {
          Object.keys(error.response.data.errors).map(key => {
            error.response.data.errors[key].map(msg => {
              notification(msg, "danger");
            });
          });
        }
      });
}

export const patriciaAiAssignTourToUser = async (props, callback) => {
  await api
      .fetchAssignTourToUser(props, callback)
      .catch(error => {
        if (error.response && error.response.data && error.response.data.errors) {
          Object.keys(error.response.data.errors).map(key => {
            error.response.data.errors[key].map(msg => {
              notification(msg, "danger");
            });
          });
        }
      });
}

export const createPropertyWithNoUser = async (props, callback, handleError) => {
  await api
      .fetchCreatePropertyWithNoUser(props)
      .then(res => {
        callback(res);
        notification("Success", "success");
      })
      .catch(({ response }) => {
        handleError(response);
        return response;
      });
      // .catch(error => {
      //   if (error.response && error.response.data && error.response.data.errors) {
      //     Object.keys(error.response.data.errors).map(key => {
      //       error.response.data.errors[key].map(msg => {
      //         notification(msg, "danger");
      //       });
      //     });
      //   }
      // });
};

export const patriciaAiGetUsersOrdersHistory = async (props, callback) => {
  await api
      .getUsersOrdersHistory(props, callback)
      .catch(error => {
        if (error.response && error.response.data && error.response.data.errors) {
          Object.keys(error.response.data.errors).map(key => {
            error.response.data.errors[key].map(msg => {
              notification(msg, "danger");
            });
          });
        }
      });
}

export const indexDesignPackages = async (props, callback) => {
  await api
      .fetchGetDesignPackages(props, callback)
      .catch(error => {
        if (error.response && error.response.data && error.response.data.errors) {
          Object.keys(error.response.data.errors).map(key => {
            error.response.data.errors[key].map(msg => {
              notification(msg, "danger");
            });
          });
        }
      });
}

export const createDesignPackageOrder = async (props, callback) => {
  await api
      .fetchCreateDesignPackageOrder(props, callback)
      .catch(error => {
        if (error.response && error.response.data && error.response.data.errors) {
          Object.keys(error.response.data.errors).map(key => {
            error.response.data.errors[key].map(msg => {
              notification(msg, "danger");
            });
          });
        }
      });
}

export const getDesignPackage = async (props, callback) => {
  await api
      .fetchGetDesignPackage(props, callback)
      .catch(error => {
        if (error.response && error.response.data && error.response.data.errors) {
          Object.keys(error.response.data.errors).map(key => {
            error.response.data.errors[key].map(msg => {
              notification(msg, "danger");
            });
          });
        }
      });
}


