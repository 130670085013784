import React, {useContext} from "react";
//import Lang from "../../../localization/patricia-he-il";
import heLang from "../../../localization/patricia-he-il";
//import enLang from "../../../localization/patricia-en-us";
import {isMobile, MobileView} from "react-device-detect";
import {Logo} from "../../Logo/Logo";
import PatriciaSideNav from "./PatriciaSideNav";
import settings from "../../../app/settings";
import {StateContext} from "../../../app/state";

function PatriciaMobileTopNav(props){
    const context = useContext(StateContext);
    const Lang = props.dir == 'ltr' ? "enLang" : heLang;
    const lang = Lang.topNav;
    const [
        {
            localization: { nav },
            auth: { user },
            componentData,
            data: { shoppingCart },
        },
        dispatch
    ] = context;
    const dir = user && user.locality === "he-il" ? "rtl" : "ltr";

    function onBackToPatriciaClicked() {
        window.analytics.track('[uploadpdf] "back" clicked', {
            location: window.location.pathname.replace("/", "")
        });
    }
    return (
        <nav className="uk-navbar-container uk-background-muted uk-margin-small uk-margin-small-top uk-width-1-1" uk-navbar="" uk-sticky=""  dir={props.dir}>
            <div className={`${props.dir === "ltr" ? "uk-navbar-left" : "uk-navbar-right"}`} >
                <div className={props.dir === "ltr" ? "uk-navbar-right uk-margin-small-right" : "uk-navbar-left uk-margin-small-left"}>
                    {(props.onLogout ?
                        <div>
                            <a className='uk-padding' href="#offcanvas-slide" uk-icon="icon: menu" uk-toggle=""></a>
                            <div dir={dir} className='uk-width-1-1@s uk-padding-large' id="offcanvas-slide" uk-offcanvas="flip: true">
                                <button className="uk-offcanvas-close" type="button" uk-close=""></button>
                                <PatriciaSideNav
                                    logo={settings.appLogo}
                                    lang={nav}
                                    //onClick={this.handleClick}
                                    dir={dir}
                                    user={user}
                                    //onLogout={this.handleLogout}
                                />
                                <div className="uk-margin-medium-bottom uk-margin-medium-left uk-position-fixed patricia-hebrew-font " style={{bottom: "0" }}>
                                    <div className='uk-display-block uk-text-bold uk-margin-small-right'>{props.user && props.user.name}</div>
                                    <a className='patricia-ai-text-color uk-text-small' href="#" onClick={props.onLogout}>
                                        <span uk-icon="icon:sign-out" className="uk-margin-small-right patricia-ai-text-color"/>
                                        <span>{props.logoutText}</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        :
                        <a className='patricia-ai-text-color uk-text-small' onClick={onBackToPatriciaClicked} href='https://www.patricia-ai.com/' style={{textDecoration: "underline"}}><span uk-icon="chevron-right"></span>{lang.backToPatricia}</a>)}
                </div>
            </div>
            <div className="uk-navbar-item">
                <Logo src={props.logo} width="100px" height="70px" />
            </div>
        </nav>
    );
}

export default PatriciaMobileTopNav;