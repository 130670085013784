import React from "react";
import { StateContext } from "../app/state";
import SellerLayout from "../layouts/SellerLayout";
import Title from "../components/Title/Title";
import { Loader } from "../components/Loader/Loader";
import { Input } from "../components/Input/Input";
import { Button } from "../components/Button/Button";
import {
  updatePassword,
  updateUser,
  getAuthUser,
  notification
} from "../app/actions";

class UserAccount extends React.Component {
  static contextType = StateContext;
  state = {
    sending: false
  };
  handleUserUpdate = e => {
    e.preventDefault();
    const [
      {
        auth,
        localization: {
          messages: { update: lang }
        }
      },
      dispatch
    ] = this.context;
    this.setState({
      sending: true
    });
    const formData = new FormData(e.target);
    let formKeys = [];
    for (var key of formData.keys()) {
      if (auth.user[key] === formData.get(key)) {
        formKeys.push(key);
      }
    }
    formKeys.forEach(key => {
      formData.delete(key);
    });
    updateUser({ payload: formData, id: auth.user.id }, res => {
      notification(lang.success, "success");
      getAuthUser({ params: { seller: true } }, ({ data }) => {
        auth.user = data;
        dispatch({
          type: "setAuth",
          newAuth: auth
        });
      });
    }).then(() => {
      this.setState({ sending: false });
    });
  };
  handleUpdatePassword = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    this.setState({
      sending: true
    });
    updatePassword({ payload: formData }, () => {
      e.target.reset();
    }).then(() => {
      this.setState({
        sending: false
      });
    });
  };

  render() {
    const [
      {
        localization: { account: lang },
        auth: { user }
      }
    ] = this.context;
    return (
      <SellerLayout>
        <div className="uk-container uk-container-expand">
          <Title title={lang.title} />
          {!user && <Loader />}
          {user && (
            <React.Fragment>
              <ul
                uk-tab="animation:uk-animation-fade"
                className="uk-margin-small-top"
              >
                <li className="uk-active">
                  <a href="#">{lang.tabs.info}</a>
                </li>
                <li>
                  <a href="#">{lang.tabs.password}</a>
                </li>
              </ul>
              <ul className="uk-switcher uk-margin-medium-top uk-margin-medium-bottom">
                <li className="uk-active">
                  <div>
                    <div className="uk-align-center uk-background-default viz-box-shadow-special uk-width-large uk-card-body">
                      <h4 className="uk-text-bold">{lang.forms.info.title}</h4>
                      <form onSubmit={this.handleUserUpdate}>
                        <Input
                          label={lang.forms.info.fields.name.label}
                          name="name"
                          value={user.name}
                        />
                        <Input
                          label={lang.forms.info.fields.email.label}
                          name="email"
                          value={user.email}
                        />
                        <Input
                          tooltip={lang.forms.info.fields.phone.tooltip}
                          label={lang.forms.info.fields.phone.label}
                          name="phone"
                          value={user.phone.replace(/\D/g, "")}
                        />
                        <Button
                          label={lang.forms.info.actions.update}
                          type="primary"
                          cssClasses="uk-width-1-1"
                          loading={this.state.sending}
                        />
                      </form>
                    </div>
                  </div>
                </li>
                <li>
                  <div>
                    <div className="uk-align-center uk-background-default viz-box-shadow-special uk-width-large uk-card-body">
                      <h4 className="uk-text-bold">
                        {lang.forms.password.title}
                      </h4>
                      <form onSubmit={this.handleUpdatePassword}>
                        <Input
                          label={lang.forms.password.fields.password.label}
                          name="password"
                          required={true}
                          type="password"
                        />
                        <Input
                          tooltip={
                            lang.forms.password.fields.newPassword.tooltip
                          }
                          label={lang.forms.password.fields.newPassword.label}
                          name="new_password"
                          required={true}
                          type="password"
                        />
                        <Input
                          tooltip={
                            lang.forms.password.fields.newPassword.tooltip
                          }
                          label={
                            lang.forms.password.fields.newPasswordConfirm.label
                          }
                          name="new_password_confirmation"
                          required={true}
                          type="password"
                        />
                        <Button
                          label={lang.forms.password.actions.update}
                          type="primary"
                          cssClasses="uk-width-1-1"
                          loading={this.state.sending}
                        />
                      </form>
                    </div>
                  </div>
                </li>
              </ul>
            </React.Fragment>
          )}
        </div>
      </SellerLayout>
    );
  }
}

export default UserAccount;
