/**
 * Group an array of objects by common key
 *
 * @param {Array} xs
 * @param {String} key
 */
export const groupArrayBy = (xs, key) => {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

/**
 * Join forms into the main FormData object
 *
 * @param {FormData} main
 * @param {array<FormData>} forms
 */
export const joinFormData = (main, forms = []) => {
  for (let i = 0; i < forms.length; i++) {
    const form = forms[i];
    for (var key of form.keys()) {
      main.append(key, form.get(key));
    }
  }
  return main;
};
