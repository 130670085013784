import React, {useContext, useEffect, useRef, useState} from "react";
import PageLayout from "../../components/Patricia/PageLayout/PageLayout";
import {StateContext} from "../../app/state";
import Title from "../../components/Title/Title";
import {Input} from "../../components/Input/Input";
import {Button} from "../../components/Button/Button";
import ScrollableShoppingCart from "../../components/Patricia/ScrollableShoppingCart/ScrollableShoppingCart";
import PatriciaProgressBar from "../../components/Patricia/RegistrationProgressBar/PatriciaProgressBar";
import Lang from "../../localization/patricia-he-il";
import {
    isMobile
} from "react-device-detect";

function CheckoutPage(props){
    const context = useContext(StateContext);
    const [
        {
            localization: { patricia: lang },
            auth: { user },
            data: { shoppingCart },
            // shoppingCart
        },
        dispatch
    ] = context;
    const dir = user && user.locality === "he-il" ? "rtl" : "ltr";
    const iframeForm = useRef();
    const [address, setAddress] = useState(null);
    const [iframeProducts, setIframeProducts] = useState(null);

    useEffect(() => {
        if(shoppingCart){
            setAddress(JSON.parse(shoppingCart.address));
            let products = [];
            shoppingCart.products.forEach((product) => {
                let iframeProduct = {
                    product_name: `${product.name} ${product.sku}`,
                    product_quantity: product.amount,
                    product_price: product.price
                }

                products.push(iframeProduct)
            })
            setIframeProducts(products);
        }
    }, [shoppingCart])

    useEffect(() => {
        if(iframeProducts && iframeForm.current){
            iframeForm.current.submit();
        }

    }, [iframeProducts, iframeForm.current])

    return (
        <PageLayout>
            <div className='uk-width-1-2@l uk-width-1-1@s uk-margin-auto patricia-hebrew-font uk-margin-small-top uk-text-center'>
                <h1 className="uk-padding-small patricia-hebrew-header-font">{lang.checkout.header}</h1>
                {!isMobile && <PatriciaProgressBar current={2} headrs={Lang.checkoutProgressBar}/>}
            </div>
            {shoppingCart && address &&
            <div className='uk-flex uk-flex-row width-1-1'>
                <form ref={iframeForm} id="payment-form" target="payment-iframe"
                      method="post" action="https://direct.tranzila.com/ttxpatriciaa/iframenew.php" >
                    <input type="hidden" name="json_purchase_data" value={encodeURIComponent(JSON.stringify(iframeProducts))}/>
                    <input type="hidden" name="trBgColor" value='f8f8f8'/>
                    {/*<input type="hidden" name="sum" value={14}/>*/}
                    <input type="hidden" name="sum" value={shoppingCart.total_price}/>
                    <input type="hidden" name="currency" value="1"/>
                    <input type="hidden" name="cred_type" value="1"/>
                    <input type="hidden" name="nologo" value="1"/>
                    <input type="hidden" name="lang" value="il"/>
                    <input type="hidden" name="trButtonColor" value="FF3200"/>
                    <input type="hidden" name="email" value={user.email}/>
                    <input type="hidden" name="contact" value={`${address.firstName} ${address.lastName}`}/>
                    <input type="hidden" name="address" value={`${address.street} ${address.number} קומה ${address.floor}, דירה ${address.apartment}, הערות: ${address.notes}`}/>
                    <input type="hidden" name="city" value={address.city}/>
                    <input type="hidden" name="u71" value="1"/>
                    <input type="hidden" name="order_id" value={shoppingCart.id}/>
                    <input type="hidden" name="user_id" value={user.id}/>
                    <input type="hidden" name="tranmode" value="V"/>
                    <input type="hidden" name="success_url_address" value={`https://retail.visualestate.com/api/patriciaSuccess`}/>
                    <input type="hidden" name="fail_url_address" value={`https://retail.visualestate.com/api/patriciaFailed`}/>
                    <input type="hidden" name="notify_url_address" value={`https://retail.visualestate.com/api/orderNotify`}/>
                    {/*<input type="hidden" name="success_ur'קl_address" value={`http://localhost:8000/api/patriciaSuccess`}/>*/}
                    {/*<input type="hidden" name="fail_url_address" value={`http://localhost:8000/api/patriciaFailed`}/>*/}
                    {/*<input type="hidden" name="notify_url_address" value={`https://localhost:8000/api/orderNotify`}/>*/}
                </form>
                <div className='uk-width-1-2@l uk-width-1-1@s uk-flex uk-flex-center uk-position-relative'>
                    <div className='uk-position-center'>
                        <iframe id="payment-iframe"
                                name="payment-iframe"
                                width="370px"
                                height="400px"
                            >
                        </iframe>
                        <div className='uk-flex uk-flex-center'>
                            <img className="uk-margin-left" height="60" width="60" src="https://s3.eu-central-1.amazonaws.com/visualestate.com/wp-content/uploads/2020/01/icons/visa.png"/>
                            <img className="uk-margin-left" height="60" width="60" src="https://s3.eu-central-1.amazonaws.com/visualestate.com/wp-content/uploads/2020/01/icons/mastercard.png"/>
                            <img className="uk-margin-left" height="60" width="60" src="https://s3.eu-central-1.amazonaws.com/visualestate.com/wp-content/uploads/2020/01/icons/amex.png"/>
                        </div>
                    </div>
                </div>
                <hr className="uk-divider-vertical uk-height-large uk-align-center"/>
                {!isMobile && <div className='uk-width-1-2 uk-padding uk-padding-remove-top'>
                    <ScrollableShoppingCart shoppingCart={shoppingCart} dir={dir} lang={lang}/>
                </div>}
            </div>
            }
        </PageLayout>
    )
}
export default CheckoutPage;

