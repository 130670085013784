import React from "react";
import { Button } from "../Button/Button";

const AddProperty = props => {
  return (
    <React.Fragment>
      <p>{props.body}</p>
      <div uk-grid="" className="uk-child-width-1-1 uk-grid-small">
        <div>
          <Button
            label={props.new}
            type="danger"
            cssClasses="uk-width-1-1"
            onClick={props.onNewSelect}
          />
        </div>
        <div>
          <Button
            label={props.fromProject}
            cssClasses="uk-width-1-1"
            type="primary"
            onClick={props.onProjectSelect}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddProperty;
