import React, { Component } from "react";
import { Input } from "../Input/Input";
import { StateContext } from "../../app/state";
import { Button } from "../Button/Button";
import {
  indexDevelopers,
  indexProjects,
  createDeveloper,
  createProject
} from "../../app/actions";
import { withRouter } from "react-router-dom";
import { Loader } from "../Loader/Loader";
import Dropdown from "../Dropdown/Dropdown";
import UiKit from "uikit";

class ProjectForm extends Component {
  static contextType = StateContext;
  state = { sending: false, loading: true };
  handleDeveloperSelect = dev => {
    this.setState({ loading: true, developer: dev });
    indexProjects({ params: { developer: dev.id } }, res => {
      this.setState({
        loading: false
      });
    });
  };
  handleProjectCreate = e => {
    e.preventDefault();
    this.setState({
      sending: true
    });
    const formData = new FormData(e.target);
    createProject(
      { payload: formData, params: { withCommon: true } },
      (res, error) => {
        if (!error) {
          UiKit.modal("#create-project").hide();
          this.props.history.push(
            `/projectCommons/${res.data.id}/units?project=${
              res.data.project.id
            }&relation[]=tour`
          );
        }
      }
    );
  };
  handleDeveloperCreate = e => {
    e.preventDefault();
    this.setState({
      sending: true
    });
    const formData = new FormData(e.target);
    createDeveloper({ payload: formData }, (res, error) => {
      if (!error) {
        this.handleDeveloperSelect(res.data);
      }
      this.setState({
        sending: false
      });
    });
  };
  componentDidMount() {
    indexDevelopers({}, res => {
      this.setState({
        developers: res.data,
        loading: false
      });
    });
  }
  render() {
    const [
      {
        localization: {
          projects: { create: lang }
        },
        auth: { user }
      }
    ] = this.context;
    const {
      sending,
      loading,
      developer,
      developers,
      project,
      projects
    } = this.state;
    return (
      <div
        className="viz-bg-white uk-padding-small uk-height"
        dir={user && user.locality === "he-il" ? "rtl" : "ltr"}
        style={{ minHeight: "270px" }}
      >
        {loading && (
          <div
            className="uk-position-cover uk-flex uk-flex-middle uk-flex-center"
            style={{ zIndex: 100000 }}
          >
            <Loader shadow={true} />
          </div>
        )}
        {!loading && !developer && (
          <div className="uk-animation-fade uk-animation-fast">
            <div className="viz-developer-selection">
              {developers && (
                <div>
                  <div className="uk-margin-top uk-margin-bottom">
                    <h3 className="uk-card-title">{lang.developer.select}</h3>
                    <div className="uk-child-width-1-1">
                      <Dropdown
                        options={developers}
                        buttonLabel={lang.fromList}
                        buttonCls={"uk-width-1-1"}
                        buttonIcon="chevron-down"
                        buttonType="secondary"
                        onSelect={this.handleDeveloperSelect}
                      />
                    </div>
                  </div>
                  <div
                    uk-grid=""
                    className="uk-flex-middle uk-margin-top uk-margin-bottom"
                  >
                    <div className="uk-width-2-5">
                      <hr />
                    </div>
                    <div className="uk-width-1-5">
                      <div>
                        <span className="uk-text-muted">or</span>
                      </div>
                    </div>
                    <div className="uk-width-2-5">
                      <hr />
                    </div>
                  </div>
                </div>
              )}
              <div className="uk-margin-top uk-margin-bottom">
                <Button
                  label={lang.developer.create}
                  cssClasses="uk-width-1-1"
                  atts={{
                    "uk-toggle":
                      "target:.viz-developer-selection; animation: uk-animation-fade; queued: true"
                  }}
                />
              </div>
            </div>
            <form
              onSubmit={this.handleDeveloperCreate}
              className="viz-developer-selection"
              hidden
            >
              <div className="uk-margin-bottom">
                <a
                  className="uk-button uk-text-muted uk-padding-remove"
                  uk-toggle="target:.viz-developer-selection; animation: uk-animation-fade; queued: true"
                >
                  <span
                    className="uk-margin-small-right"
                    uk-icon="icon: chevron-left"
                  />
                  <span>{lang.back}</span>
                </a>
              </div>
              <div>
                <h3 className="uk-card-title uk-margin-bottom">
                  {lang.developer.create}
                </h3>
              </div>
              <Input
                name="name"
                required
                label={lang.developer.fields.name.label}
                placeholder={lang.developer.fields.name.placeholder}
              />
              <Input
                name="url"
                required
                label={lang.developer.fields.url.label}
                placeholder={lang.developer.fields.url.placeholder}
                tooltip={lang.developer.fields.url.tooltip}
              />
              <div className="uk-margin-medium-top">
                <Button
                  label={lang.continue}
                  type="primary"
                  loading={sending}
                />
              </div>
            </form>
          </div>
        )}
        {!loading && developer && (
          <form
            className="uk-animation-fade uk-animation-fast"
            onSubmit={this.handleProjectCreate}
          >
            <div className="uk-margin-bttom">
              <h3 className="uk-card-title uk-margin-remove">
                {lang.project.create}
              </h3>
              <span className="uk-text-bold">{developer.name}</span>
            </div>
            <input
              type="hidden"
              name="developer_id"
              defaultValue={developer.id}
            />
            <input
              type="hidden"
              name="retailer_id"
              defaultValue={user.seller.retailer.id}
            />
            <Input
              name="name"
              label={lang.project.fields.name.label}
              placeholder={lang.project.fields.name.placeholder}
              required
            />
            <div className="uk-margin-medium-top">
              <Button label={lang.create} type="primary" loading={sending} />
            </div>
          </form>
        )}
      </div>
    );
  }
}

export default withRouter(ProjectForm);
