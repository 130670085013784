/**
 * Search a given array of objects by key of 'name'
 *
 * @param {string} substr
 * @param {array} lst
 */
export const LocalSearch = (substr, lst) => {
  var ret = [];
  lst.forEach(function(i) {
    if (
      i.name.toLowerCase().includes(substr.toLowerCase()) ||
      ("developer" in i &&
        i.developer.name.toLowerCase().includes(substr.toLowerCase()))
    )
      ret.push(i);
  });
  return ret;
};

export const ProjectCommonSearch = (substr, lst) => {
  var ret = [];
  lst.forEach(function(i) {
    if (
      i.project.name.toLowerCase().includes(substr.toLowerCase()) ||
      ("developer" in i.project &&
        i.project.developer.name.toLowerCase().includes(substr.toLowerCase()))
    )
      ret.push(i);
  });
  return ret;
};
