import React, {useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {StateContext} from "../../../app/state";
import {
    MobileView, BrowserView,
    isMobile
} from "react-device-detect";
import UiKit from "uikit";

function PatriciaSideNav(){
    const context = useContext(StateContext);
    const [
        {
            auth: { user },
            data: { shoppingCart },
            // shoppingCart,
            localization: { patricia: lang },
        },
    ] = context;
    const [myHomeUrl, setMyHomeUrl] = useState(null);
    const dir = user && user.locality === "he-il" ? "rtl" : "ltr";
    useEffect(() => {
        if (process.env.REACT_APP_HOST === "WEM") {
            setMyHomeUrl(user && user.consumer && `/consumers/${user.consumer.id}?relation[]=properties&relation[]=user`)
        }
        else {
            if (user) {
                setMyHomeUrl(user.consumer && user.consumer.properties && user.consumer.properties[0] && `/properties/${user.consumer.properties[0].id}?relation[]=consumer&relation[]=project&relation[]=media&retailer`)
            } else {
                setMyHomeUrl("/propertyUnderConstruction")
            }
        }
    }, [user])

    function onMenuItemClicked(url){
        window.analytics.page(url);
        if (isMobile){
            UiKit.toggle("#offcanvas-slide").toggle();
        }
    }

    return (
        <div className={`patricia-hebrew-font uk-padding-small ${isMobile && 'uk-offcanvas-bar uk-width-1-1 uk-padding-large'}`}>
            <ul className={`uk-nav uk-nav-default ${isMobile && "uk-margin-medium-top"}`}>
                <li>
                    <Link to={myHomeUrl} onClick={() => onMenuItemClicked(myHomeUrl)}>
                        <span uk-icon="icon:home" />
                        <span className={`uk-margin-small-${dir === 'ltr' ? "left" : "right"}`}>{lang.sideMenu.myHome}</span>
                    </Link>
                </li>
                <li>
                    <Link to="/shoppingCart" onClick={() => onMenuItemClicked("/shoppingCart")}>
                        <span uk-icon="icon:cart" />
                        <span className={`uk-margin-small-${dir === 'ltr' ? "left" : "right"}`}>{`${lang.sideMenu.shoppingCart} (${shoppingCart && shoppingCart.products && shoppingCart.products.length != 0 ? shoppingCart.products.length : 0})`}</span>
                    </Link>
                </li>
                <li>
                    <Link to="/account" onClick={() => onMenuItemClicked("/account")}>
                        <span uk-icon="icon:cog" />
                        <span className={`uk-margin-small-${dir === 'ltr' ? "left" : "right"}`}>{lang.sideMenu.settings}</span>
                    </Link>
                </li>
                <li>
                    <Link to="/ordersHistory" onClick={() => onMenuItemClicked("/ordersHistory")}>
                        <span uk-icon="icon:list" />
                        <span className={`uk-margin-small-${dir === 'ltr' ? "left" : "right"}`}>{lang.sideMenu.orders}</span>
                    </Link>
                </li>
            </ul>
        </div>
    );
}

export default PatriciaSideNav;