import React, { Component } from "react";

class Editable extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value)
      this.setState({
        editing: false
      });
  }

  state = {
    editing: false
  };
  toggleEditing = (end = false) => {
    const { editing } = this.state;
    if (end) {
      this.setState({
        editing: false
      });
      this.inputRef.current.value = this.props.value;
      return;
    }
    if (editing) return;
    this.setState({
      editing: true
    });
  };
  render() {
    return (
      <React.Fragment>
        <form
          className="uk-position-relative"
          onSubmit={e => this.props.onSubmit(e, this.props.targetId)}
        >
          <input
            className="uk-input uk-form-blank"
            name={this.props.name}
            type={this.props.type || "text"}
            onChange={() => this.toggleEditing(false)}
            defaultValue={this.props.value}
            placeholder={this.props.placeholder}
            ref={this.inputRef}
            uk-tooltip={this.props.tooltip}
            pattern={this.props.pattern}
            required
          />
          {this.state.editing && (
            <div
              className="uk-animation-fade uk-overflow-hidden uk-border-rounded uk-flex uk-position-absolute uk-box-shadow-small uk-background-default"
              style={{
                top: "95%",
                right: "0",
                height: "40px",
                width: this.props.processing ? "40px" : "80px"
              }}
            >
              {!this.props.processing && (
                <div
                  className="uk-width-1-2 uk-background-default uk-text-bold uk-flex uk-flex-middle uk-flex-center"
                  style={{ cursor: "pointer" }}
                  onClick={() => this.toggleEditing(true)}
                >
                  <span uk-icon="icon:close"></span>
                </div>
              )}
              <button
                className={`${
                  this.props.processing ? "uk-width-1-1" : "uk-width-1-2"
                } uk-background-primary uk-light uk-text-bold uk-flex uk-flex-middle uk-flex-center`}
                style={{ cursor: "pointer", border: "none" }}
              >
                {this.props.processing && <div uk-spinner=""></div>}
                {!this.props.processing && <span uk-icon="icon:check"></span>}
              </button>
            </div>
          )}
        </form>
      </React.Fragment>
    );
  }
}

export default Editable;
