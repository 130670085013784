import React from 'react';

function ImgAnswer(props){
    return (
        <div className='quiz-button uk-height-1-1' onClick={props.onAnswerClicked}>
            {/*<img src={props.img} alt="" style={{ objectFit: "cover"}} className={`uk-height-1-1 uk-width-1-1 uk-margin-small-right ${props.selected && 'selected-answer'}`}/>*/}
            <div className={`uk-card uk-card-default uk-cover-container ${props.isMobile ? 'uk-height-small' : 'uk-height-large'} ${props.selected ? 'selected-answer' : ""}`}>
                {/*<div className='uk-height-1-1' data-src={props.img} uk-img=""></div>*/}
                <img src={props.img} alt="" uk-cover="" style={{ maxWidth: "100%"}}/>
            </div>
        </div>
    )
}

export default ImgAnswer;
