import React from "react";
import { useStateValue } from "../../app/state";
import { Input } from "../Input/Input";
import { Button } from "../Button/Button";

const TeamInviteForm = props => {
  const [
    {
      localization: {
        organization: {
          team: { invite: lang }
        }
      }
    }
  ] = useStateValue();
  return (
    <React.Fragment>
      <div id={props.id} uk-modal="bg-close:false">
        <div className="uk-modal-dialog uk-modal-body uk-width-large">
          <button
            className="uk-modal-close-default"
            type="button"
            uk-close=""
          />
          <div className="viz-bg-white uk-padding" dir={props.dir}>
            <form className="uk-width-expand@m" onSubmit={props.onSubmit}>
              <div>
                <h2 className="uk-text-bold">{lang.title}</h2>
              </div>
              <Input
                name="name"
                label={lang.fields.name.label}
                required={true}
              />
              <Input
                name="email"
                label={lang.fields.email.label}
                required={true}
              />
              <Button
                label={lang.actions.invite}
                type="primary"
                cssClasses="uk-width-1-1 uk-margin-small-top"
                loading={props.processing}
              />
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TeamInviteForm;
