import React from "react";
import ButtonGroup from "../ButtonGroup/ButonGroup";

const Title = props => (
  <div uk-grid="" className={`uk-flex-middle ${props.className}`}>
    <div className="uk-width-expand">
      {((props.size && props.size === "large") || !props.size) && (
        <div className="viz-actionable uk-position-relative uk-width-auto uk-inline">
          {props.actions && (
            <div className="uk-box-shadow-small viz-actionable-actions">
              <ul className="uk-iconnav uk-iconnav-vertical uk-text-left">
                {props.actions.map((action, key) => (
                  <li key={key}>
                    <a
                      href="#"
                      uk-icon={`icon:${action.icon}`}
                      {...action.atts}
                      onClick={action.onClick}
                    ></a>
                  </li>
                ))}
              </ul>
            </div>
          )}
          <h2
            className={`${!props.margin && "uk-margin-remove"}`}
            style={{
              marginTop: 0
            }}
          >
            {props.title}
          </h2>
          <h5 className="uk-margin-remove">{props.subtitle}</h5>
        </div>
      )}
      {props.size && props.size === "regular" && (
        <div>
          <h5 className="uk-margin-remove uk-text-bold">{props.title}</h5>
          <span className="uk-margin-remove uk-text-small">
            {props.subtitle}
          </span>
        </div>
      )}
    </div>
    <div className="uk-width-auto">
      {/* {props.retailer ? (
        <img
          src={props.retailer.logo && props.retailer.logo.thumbUrl}
          alt={props.retailer.name}
        />
      ) : ( */}
      <div style={{ height: "100px" }} className="uk-flex uk-flex-middle">
        {props.children}
      </div>
      {/* )} */}
    </div>
  </div>
);

export default Title;
