import React, {Component} from "react";
import Title from "../../Title/Title";
import {Button} from "../../Button/Button";
import {Input} from "../../Input/Input";
import Dropdown from "../../Dropdown/Dropdown";
import {forEach} from "lodash";
import {StateContext} from "../../../app/state";
import {updateQuoteComponent} from "../../../app/actions";
import {Checkbox} from "../../Checkbox/Checkbox";
import {MobileView, BrowserView,
    isMobile
} from "react-device-detect";

class PatriciaQuoteComponent extends Component {
    constructor(props) {
        super(props);
        let roomOptions = [];
        let placeOptions = [];

        if (this.props.matrix) {
            this.props.matrix.map((room, key) => {
                roomOptions.push({name: room.localeName, index: key, id: room.id});
            });
            this.props.matrix.map((room, key) => {
                if (this.props.productRoom && room.name === this.props.productRoom.name) {
                    this.props.matrix[key].places.map((place, key) => {
                        placeOptions.push({name: place.localeName, index: key, id: place.id});
                    });
                }
            });
        }
        this.state = {
            selectedRoom: this.props.productRoom ? this.props.productRoom : null,
            selectedPlace: this.props.productPlace ? this.props.productPlace : null,
            placeOptions: placeOptions,
            roomOptions: roomOptions,
        };
    }

    unit = this.props.priceBreakdown.unit === "area" ? this.props.lang.sqm : this.props.lang.unit;
    refund =
        this.props.isUpgrade && this.props.refund
            ? this.props.refund *
            (this.props.netAmount ? this.props.netAmount : this.props.priceBreakdown.amount)
            : null;

    // componentDidUpdate(prevProps) {
    //   let roomOptions = [];
    //   let placeOptions = [];
    //
    //   if (this.props.matrix != prevProps.matrix) {
    //     this.props.matrix.map((room, key) => {
    //       roomOptions.push({name: room.localeName, index: key, id: room.id});
    //     });
    //     this.props.matrix.map((room, key) => {
    //       if (this.props.productRoom && room.name === this.props.productRoom.name) {
    //         this.props.matrix[key].places.map((place, key) => {
    //           placeOptions.push({name: place.localeName, index: key, id: place.id});
    //         });
    //       }
    //     });
    //     this.setState({
    //       selectedRoom: this.props.productRoom ? this.props.productRoom : null,
    //       selectedPlace: this.props.productPlace ? this.props.productPlace : null,
    //       placeOptions: placeOptions,
    //       roomOptions: roomOptions,
    //     });
    //   }
    // }

    onSelectRoom(selected) {
        let placeOptions = [];
        this.props.matrix[selected.index].places.map((place, key) => {
            placeOptions.push({name: place.localeName, index: key, id: place.id});
        })

        this.setState({
            selectedRoom: selected,
            placeOptions: placeOptions,
            selectedPlace: null
        })
    }

    onSelectPlace(selected) {
        this.setState({
            selectedPlace: selected
        })
    }

    updateComponent = (e, id) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        this.setState({
            loading: true
        });
        if (formData.has("discount"))
            formData.append("discount_by", this.props.userId);
        if (this.state.selectedRoom) {
            //this.props.matrix[selectedRoom.index].id
            formData.append("room_id", this.state.selectedRoom.id);
        }
        if (this.state.selectedPlace) {
            formData.append("place_id", this.state.selectedPlace.id);
        }
        updateQuoteComponent({id, payload: formData}, () => {
            this.props.getQuote();
        });
    };

    render() {
        return (
            <React.Fragment>
                {process.env.REACT_APP_HOST === "WEM" && !this.props.isJMI ? null :
                <div
                    className={`viz-actionable uk-position-relative uk-margin uk-border-rounded uk-flex${this.props.className}`}
                    uk-toggle="cls:uk-background-muted; mode:hover"
                    data-key={this.props.index}
                    {...this.props.atts}
                >
                    {this.props.actions && !this.props.deleting && !this.props.editing && (
                        <div className="uk-box-shadow-small viz-actionable-actions actions-center">
                            <ul className="uk-iconnav uk-iconnav-vertical uk-text-left">
                                {this.props.actions.map((action, key) => (
                                    <li key={key}>
                                        <a
                                            href="#"
                                            uk-icon={`icon:${action.icon}`}
                                            onClick={e =>
                                                action.onClick(
                                                    e,
                                                    this.props.id,
                                                    action.status,
                                                    "set",
                                                    this.props.nested
                                                )
                                            }
                                        ></a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                    {this.props.editing && (
                        <span
                            style={{zIndex: "900"}}
                            className="uk-position-top-left uk-margin-left uk-margin-top uk-label uk-label-danger"
                        >
            {this.props.lang.edit.label}
          </span>
                    )}
                    {this.props.deleting && (
                        <div
                            className="uk-overlay uk-position-cover uk-background-default uk-animation-fade uk-animation-fast"
                            style={{zIndex: "100"}}
                        >
                            <div>
                                <p className="uk-text-bold">
                                    {this.props.lang.delete.content.replace(
                                        "%s",
                                        this.props.product.productName
                                    )}
                                </p>
                                <div className="uk-flex uk-flex-right">
                                    <Button
                                        label={this.props.lang.delete.actions.cancel}
                                        cssClasses="uk-margin-small-left uk-margin-small-right"
                                        onClick={e =>
                                            this.props.togglePrompt(e, this.props.id, "deleting", "delete")
                                        }
                                    ></Button>
                                    <Button
                                        label={this.props.lang.delete.actions.confirm}
                                        type="primary"
                                        onClick={e => this.props.confirmDelete(e, this.props.deleting)}
                                    ></Button>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="uk-flex-middle uk-position-relative uk-grid-small" uk-grid="">
                        {this.props.onChecked && (
                            <div className='uk-flex-middle'>
                                <Checkbox
                                    name="select-component"
                                    value={this.props.checked}
                                    onChange={() => this.props.onChecked(this.props.id)}
                                />
                            </div>
                        )}


                        <div className="uk-width-1-6@m uk-width-1-1@s">
                            <div
                                className="uk-height-small uk-width-1-1 uk-background-contain uk-border-rounded"
                                uk-img=""
                                data-src={this.props.product.item.thumbnail.thumbUrl}
                            >
                                {this.props.compacted && (
                                    <span
                                        className="uk-overlay-default uk-border-rounded"
                                        style={{padding: "4px"}}
                                    >
                  <span uk-icon="icon: shrink"></span>
                </span>
                                )}
                            </div>
                        </div>
                        <div className="uk-width-1-6@m uk-width-1-1@s">
                            <div>
                                <Title
                                    title={this.props.product.productName}
                                    subtitle={
                                        this.props.variation_suffix
                                            ? this.props.product.productSku + this.props.variation_suffix
                                            : this.props.product.productSku
                                    }
                                    size="regular"
                                ></Title>
                            </div>
                        </div>
                        <div className="uk-width-1-4@m uk-width-1-1@s uk-position-relative">
                            <div>
                                {this.props.editing ? (
                                    <form
                                        // onSubmit={e => {
                                        //   this.props.onUpdate(e, this.props.id);
                                        // }}
                                        onSubmit={e => {
                                            this.updateComponent(e, this.props.id);
                                        }}
                                    >
                                        <ul className="uk-list uk-padding-remove uk-margin-top">
                                            <li>
                                                <span className="uk-text-small">{this.props.lang.price}:</span>{" "}
                                                <span>{this.props.modyVisionPrice ? this.props.modyVisionPrice : this.props.product.productPrice}</span>
                                                <span className="uk-text-meta">{` /${this.unit}`}</span>
                                                {(!this.props.discount || !this.props.discount.discountPrice) && (
                                                    <span
                                                        uk-icon="icon:minus-circle"
                                                        className="uk-margin-small-left uk-text-success"
                                                        style={{cursor: "pointer"}}
                                                        uk-tooltip={this.props.lang.edit.addDiscount}
                                                        onClick={e =>
                                                            this.props.togglePrompt(
                                                                e,
                                                                this.props.id,
                                                                "discount.discountPrice",
                                                                "set",
                                                                this.props.nested
                                                            )
                                                        }
                                                    ></span>
                                                )}
                                            </li>
                                            {this.props.discount && this.props.discount.discountPrice && (
                                                <li className="uk-flex uk-flex-middle">
                        <span className="uk-text-small uk-margin-small-right">
                          {this.props.lang.discount}:
                        </span>
                                                    <span>
                          <Input
                              required={true}
                              // unit={unit}
                              cssClasses="uk-margin-remove"
                              type="number"
                              name="discount"
                              value={this.props.discount.discountPrice}
                              atts={{step: "any", style: {width: "100px"}}}
                              size="small"
                          />
                        </span>
                                                    <span
                                                        uk-icon="icon:ban"
                                                        className="uk-margin-small-left uk-text-success"
                                                        style={{cursor: "pointer"}}
                                                        uk-tooltip={this.props.lang.edit.removeDiscount}
                                                        onClick={e =>
                                                            this.props.togglePrompt(
                                                                e,
                                                                this.props.id,
                                                                "discount.discountPrice",
                                                                "delete",
                                                                this.props.nested
                                                            )
                                                        }
                                                    ></span>
                                                </li>
                                            )}
                                            <li className="uk-flex uk-flex-middle">
                      <span className="uk-text-small uk-margin-small-right">
                        {this.props.lang.amount}:
                      </span>
                                                <span>
                        <Input
                            required={true}
                            // unit={unit}
                            name={
                                this.props.product.item.category === "wall" ||
                                this.props.product.item.category === "floor"
                                    ? "area"
                                    : "amount"
                            }
                            cssClasses="uk-margin-remove"
                            atts={{step: "any", style: {width: "100px"}}}
                            size="small"
                            value={this.props.priceBreakdown.amount}
                        />
                      </span>
                                            </li>
                                            {this.state.roomOptions.length > 0 ?
                                                <li className="uk-flex uk-flex-middle">
                        <span className="uk-text-small uk-margin-small-right">
                          {this.props.lang.room}:
                        </span>
                                                    <span>
                           <Dropdown
                               options={this.state.roomOptions}
                               buttonLabel={this.state.selectedRoom ? this.state.selectedRoom.locale_name : this.props.lang.room}
                               buttonCls={"uk-width-1-1"}
                               buttonIcon="chevron-down"
                               buttonType="default"
                               onSelect={(val) => this.onSelectRoom(val)}
                               buttonCls="uk-form-small"
                               value={this.state.selectedRoom ? this.state.selectedRoom.locale_name : null}
                           />
                        </span>
                                                </li> : null}
                                            {this.state.selectedRoom && this.state.placeOptions.length > 0 ?
                                                <li className="uk-flex uk-flex-middle">
                              <span className="uk-text-small uk-margin-small-right">
                                    {this.props.lang.place}:
                              </span>
                                                    <span>
                                <Dropdown
                                    options={this.state.placeOptions}
                                    buttonLabel={this.props.lang.place}
                                    buttonCls={"uk-width-1-1"}
                                    buttonIcon="chevron-down"
                                    buttonType="default"
                                    onSelect={(val) => this.onSelectPlace(val)}
                                    buttonCls="uk-form-small"
                                    value={this.state.selectedPlace ? this.state.selectedPlace.locale_name : null}
                                />
                              </span>
                                                </li> : null}
                                        </ul>
                                        <div className="uk-margin-top uk-margin-bottom uk-flex uk-flex-left">
                                            <Button
                                                label={this.props.lang.edit.cancel}
                                                size="small"
                                                cssClasses={"uk-background-default"}
                                                onClick={e => {
                                                    this.props.togglePrompt(
                                                        e,
                                                        this.props.id,
                                                        "editing",
                                                        "delete",
                                                        this.props.nested
                                                    )
                                                }
                                                }
                                            />
                                            <Button
                                                label={this.props.lang.edit.confirm}
                                                size="small"
                                                type="primary"
                                                cssClasses={"uk-margin-small-left uk-margin-small-right"}
                                            />
                                        </div>
                                    </form>
                                ) : (
                                    <ul className="uk-list uk-padding-remove">
                                        <li>
                                            <span className="uk-text-small">{this.props.lang.price}:</span>{" "}
                                            <span>{this.props.modyVisionPrice ? this.props.modyVisionPrice : this.props.product.productPrice}</span>
                                            <span className="uk-text-meta">{` /${this.unit}`}</span>
                                        </li>
                                        {this.props.discount &&
                                        this.props.discount.discountPrice &&
                                        !isNaN(this.props.discount.discountPrice) && (
                                            <li>
                        <span className="uk-text-small">
                          {this.props.lang.discount}:
                        </span>{" "}
                                                <span>{this.props.discount.discountPrice}</span>
                                                <span className="uk-text-meta">{` /${this.unit}`}</span>
                                            </li>
                                        )}
                                        <li>
                                            <span
                                                className="uk-text-small">{this.props.lang.calculated_amount}:</span>{" "}
                                            <span>
                      {parseFloat(this.props.priceBreakdown.amount)
                          .toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </span>
                                            <span className="uk-text-meta">{` ${this.unit}`}</span>
                                        </li>
                                    </ul>
                                )}
                            </div>
                        </div>
                        <div className="uk-width-1-6@m uk-width-1-1@s">
                            <div
                                className={`uk-text-center@m uk-text-left-@s uk-margin-small-left ${isMobile && "uk-flex"}`}>
                                <div className="uk-text-small">{`${this.props.lang.cost}: `}</div>
                                <div>
                                    {this.props.modyVisionPrice ? parseFloat(this.props.modyVisionPrice * this.props.priceBreakdown.amount)
                                        .toFixed(2)
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") : parseFloat(this.props.priceBreakdown.cost)
                                        .toFixed(2)
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </div>
                                {this.props.editing && (
                                    <div>
                                        <p
                                            className="uk-text-meta uk-margin-remove"
                                            style={{fontSize: "10px"}}
                                        >
                                            {this.props.lang.edit.costNote}
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="uk-width-1-6@m uk-width-1-1@s">
                            {this.props.componentsInCart.includes(this.props.id) ?
                                // <button
                                //     className="uk-text-truncate uk-button uk-button-default uk-border-rounded ${cssClasses} uk-button-small"
                                //     onClick={(e) => this.props.onRemoveFromCartClicked(e, this.props.product.id, this.props.id)}>
                                //     <span className="uk-text-truncate uk-text-capitalize" style={{fontSize: "12px"}}>Remove From Cart</span>
                                // </button>
                                <div className='patricia-ai-text-color'>
                                    {this.props.lang.productAddedToCart}
                                    <i className="fas fa-check uk-margin-small"></i>
                                </div>
                                :
                                <button
                                    className="uk-text-truncate uk-button uk-button-primary uk-border-rounded patricia-ai-color uk-button-small"
                                    onClick={(e) => this.props.onAddToCartClicked(e, this.props.product.id, this.props.product.productPrice, this.props.product.productSku, this.props.id)}>
                                    <span className="uk-text-truncate uk-text-capitalize" style={{fontSize: "12px"}}>{this.props.lang.addToCart}</span>
                                </button>
                            }
                        </div>
                    </div>
                </div>}
            </React.Fragment>
        )
            ;
    }
};

export default PatriciaQuoteComponent;
