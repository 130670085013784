import React, {useContext, useEffect, useState} from "react";
import ButtonGroup from "../ButtonGroup/ButonGroup";
import {Button} from "../Button/Button";
import {StateContext} from "../../app/state";
import {changeDefaultDesignSet, createInspirationQuizQuestion, getDesignSets} from "../../app/actions";
import {Loader} from "../Loader/Loader";
import Modal from "../Modal/Modal";
import SellerLayout from "../../layouts/SellerLayout";
import UIkit from "uikit";

function DesignStyle(props) {
    const context = useContext(StateContext);
    const [
        {
            localization: {designStyle: lang },
            auth: { user },
            active: { propertie: property },
        },
    ] = context;
    const [loading, setLoading] = useState(null);
    const [defaultDesignSet, setDefaultDesignSet] = useState(null);
    const [designSets, setDesignSets] = useState([]);
    const [designStylesOptions, setDesignStylesOptions] = useState([]);
    const selectDesignStyleId = "select-design-style-id";


    useEffect(() => {
        if (props.tour) {
            getDesignSets({params: {tour: props.tour.id, quizResult: props.tour.quizResultId}}, res => {
                res.data.forEach((designSet) => {
                    if (designSet.default) {
                        setDefaultDesignSet(designSet);
                    }
                })
                setDesignSets(res.data);
            })
        }
    }, [props.tour]);

    useEffect(() => {
        if(designSets) {
            let designStyles = [];
            designSets.forEach((designSet) => {
                designStyles.push({name: designSet.design_style.name, id: designSet.id});
            })
        setDesignStylesOptions(designStyles);
        }
    }, [designSets])

    function handleChangeDesignStyle(e){
        setLoading(true);
        e.preventDefault();
        setLoading(true);
        const formData = new FormData(e.target);
        let id = formData.get('design_style');
        changeDefaultDesignSet({id}, res => {
            UIkit.modal(`#${selectDesignStyleId}`).hide();
        }).
        then(() => {
            setLoading(false);
        });
    }

    return (
        <div className='uk-padding uk-position-relative '>
                <div uk-grid="" className='uk-position-top-left uk-button-group viz-property-action uk-margin-small-left'>
                    <Button
                        label={props.lang.actions.startQuiz}
                        cssClasses="uk-position-small uk-background-default uk-box-shadow-hover"
                        onClick={() => {
                            window.open(`/takeInspirationQuiz?retailer=${user.seller.retailerId}&tour=${props.tour.id}`, "_blank");
                        }}
                    ></Button>
                    <Button
                        label={props.lang.actions.chooseDesignStyle}
                        cssClasses="uk-position-small uk-background-default uk-box-shadow-hover"
                        atts={{ "uk-toggle": `target:#${selectDesignStyleId}` }}
                        // onClick={() => {
                        //     window.open(property.tour.floorplan.mediaUrl, "_blank");
                        // }}
                    ></Button>
                </div>
            {!defaultDesignSet ? <Loader/> :
                <div uk-grid="" className='viz-border-1 uk-border-rounded uk-padding uk-position-relative'>
                    {defaultDesignSet ?
                            (<div className="uk-width-expand uk-margin-medium-bottom">
                                <p>תודה, <span>{property.consumer.user.name}</span>. עזרת לפטרישיה להבין את סגנון העיצוב שלך!</p>
                                <p> נראה כי אתה מתחבר לסגנון עיצוב<span> {defaultDesignSet.design_style.name} </span></p>
                                <p> הוא מאופיין ב<span>{defaultDesignSet.design_style.description}</span></p>
                                <p> אתה יכול לשנות בכל שלב את סגנון העיצוב. </p>
                            </div>) : null}
                    <Modal dir={user && user.locality === "he-il" ? "rtl" : "ltr"} id={selectDesignStyleId} title={props.lang.modals.changeDesignStyle.title}>
                        <form onSubmit={e => handleChangeDesignStyle(e)}>
                            <label htmlFor="answers_type" className="uk-text-meta uk-text-uppercase uk-margin-small-bottom uk-display-block">{props.lang.modals.changeDesignStyle.labels.designStyles}</label>
                            <select className="uk-select uk-margin-small-bottom" id="form-stacked-select" name={"design_style"}>
                                {designStylesOptions.map((option) => {
                                    return <option value={option.id}>{option.name}</option>
                                })};
                            </select>
                            <div className="uk-grid-small" uk-grid="">
                                <div className="uk-width-auto">
                                    <Button
                                        label={props.lang.modals.changeDesignStyle.actions.cancel}
                                        cssClasses="uk-modal-close"
                                    />
                                </div>
                                <div className="uk-width-expand">
                                    <Button
                                        label={props.lang.modals.changeDesignStyle.actions.update}
                                        type="primary"
                                        cssClasses="uk-width-1-1"
                                        loading={loading}
                                    />
                                </div>
                            </div>
                        </form>
                    </Modal>
                </div>
            }
        </div>
    )
}

export default DesignStyle;