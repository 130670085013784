import React from "react";
//import Lang from "../../../localization/patricia-he-il";
import heLang from "../../../localization/patricia-he-il";
import enLang from "../../../localization/patricia-en-us";

function PatriciaProgressBar(props){
    const Lang = props.dir == 'ltr' ? enLang : heLang;
    const lang = Lang.registrationProgressBar;

    let firstStepClassName = props.current === 1 ? 'active' : 'done';
    let secondStepClassName = props.current === 2 ? 'active' : (props.current === 3 ? 'done' : "future");
    let detailsClassName = props.current === 3 ? 'active' : "future";

    return (
        <div className='patricia-hebrew-font uk-margin-medium-bottom'>
            <div className='uk-flex uk-flex-center uk-margin-small-top'>
                <div className={props.current === 1 && 'patricia-ai-text-color'} style={{flex: 1}}>{props.headrs.steps[0]}</div>
                <div className={props.current === 2 && 'patricia-ai-text-color'} style={{flex: 1}}>{props.headrs.steps[1]}</div>
                {props.headrs.steps.length === 3 && <div className={props.current === 3 && 'patricia-ai-text-color'} style={{flex: 1}}>{props.headrs.steps[2]}</div>}
            </div>
            <div className='uk-flex uk-flex-center'>
                <div className={`reg-progress-step ${firstStepClassName}`}>
                    <span className="points-indicator">1</span>
                </div>
                {/*<i className="far fa-arrow-alt-circle-left" style={{ fontSize: "30px", textAlign: "left"}}></i>*/}
                <div className={`reg-progress-bar-line ${firstStepClassName}`}></div>
                <div className={`reg-progress-step ${secondStepClassName}`}>
                    <span className="points-indicator">2</span>
                </div>
                {props.headrs.steps.length === 3 &&
                <div className={`reg-progress-bar-line ${secondStepClassName}`}></div>}
                {props.headrs.steps.length === 3 &&
                <div className={`reg-progress-step ${detailsClassName}`}>
                    <span className="points-indicator">3</span>
                </div>}
            </div>
        </div>
    )
}

export default PatriciaProgressBar;