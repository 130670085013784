import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { StateContext } from "../app/state";
import { Navbar } from "../components/Navbar/Navbar";
import { SideNav } from "../components/SideNav/SideNav";
import { logoutUser } from "../app/actions";
import settings from "../app/settings";

class SellerLayout extends Component {
  static contextType = StateContext;
  handleClick = () => {
    this.props.history.push("/consumers");
  };
  handleLogout = () => {
    const [{ componentData, auth }, dispatch] = this.context;
    componentData.loading = true;
    dispatch({
      type: "updateComponentData",
      newComponentData: componentData
    });
    logoutUser({}, () => {
      auth.isAuthenticated = false;
      dispatch({
        type: "setAuth",
        newAuth: auth
      });
      window.location.reload();
    });
  };
  render() {
    const [
      {
        localization: { nav },
        auth: { user }
      }
    ] = this.context;
    const dir = user && user.locality === "he-il" ? "rtl" : "ltr";
    return (
      <div dir={dir} uk-height-viewport="" className="uk-flex uk-flex-column">
        <Navbar
          logo={settings.appLogo}
          lang={nav}
          onClick={this.handleClick}
          dir={dir}
          user={user}
          onLogout={this.handleLogout}
        />
        <div className="uk-height-expand uk-width-expand uk-flex uk-height-match">
          <div className="uk-width-1-1@s uk-width-1-6@m">
            <SideNav match={this.props.match} />
          </div>
          <div className="uk-width-expand">
            <div className="uk-height-1-1">
              <div className="uk-border-rounded uk-height-1-1 uk-background-muted">
                {this.props.children}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(SellerLayout);
