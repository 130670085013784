import React, {useContext, useEffect, useState} from "react";
import PageLayout from "../../components/Patricia/PageLayout/PageLayout";
import {StateContext} from "../../app/state";
import {Button} from "../../components/Button/Button";
import {Link} from "react-router-dom";
import {Input} from "../../components/Input/Input";
import {patriciaAiClearCart, patriciaAiDeleteProduct, patriciaAiUpdateProductAmount} from "../../app/actions";
import Modal from "../../components/Modal/Modal";
import UiKit from "uikit";
import underConstruction from "../../under-construction.png";
import AddProperty from "../../components/CreateTour/AddProperty";
import ProductModal from "../../components/Patricia/ProductModal/ProductModal";
import {
    MobileView, BrowserView,
    isMobile
} from "react-device-detect";
import UIkit from "uikit";

function ShoppingCart(props){
    const context = useContext(StateContext);
    const [
        {
            localization: { patricia: lang },
            auth: { user },
            data: { shoppingCart },
            // shoppingCart
        },
        dispatch
    ] = context;
    const dir = user && user.locality === "he-il" ? "rtl" : "ltr";
    const [loading, setLoading] = useState(false);
    const [productInEditMode, setProductInEditMode] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const clearCartId = "clear-cart-id";
    const freeDeliveriesId = "free-deliveries-id";
    const modysLogo = 'https://mody.co.il/wp-content/uploads/2018/03/logo_trans1-171x88-_black.png'; // for poc we didnt change mody products manufacturer to be mody, so this is default logo

    useEffect(() => {
        UIkit.modal(`#${freeDeliveriesId}`).show();
    }, [])
    function updateCart(e, product){
        e.preventDefault();
        const formData = new FormData(e.target);
        formData.append('product_id', product.id);
        setLoading(true);
        patriciaAiUpdateProductAmount({id: shoppingCart.id, payload: formData}, (res) => {
            dispatch({
                type: "updateData",
                newData: {shoppingCart: res.data}
            })
        }).then(() => {
            setLoading(false)
            setProductInEditMode(null)
        }
        );
        window.analytics.track("[my_cart] product quantity changed", {
            location: 'my_cart',
            sku: product.sku

        });

    }

    function deleteProductFromCart(e, product){
        e.preventDefault();
        const formData = new FormData();
        formData.append('product_id', product.id);
        setLoading(true);
        patriciaAiDeleteProduct({id: shoppingCart.id, payload: formData}, (res) => {
            dispatch({
                type: "updateData",
                newData: {shoppingCart: res.data}
            })
        }).then(
            setLoading(false)
        );
        window.analytics.track("[my_cart] remove from cart clicked", {
            location: 'my_cart',
            sku: product.sku
        });
    }

    function clearCart(e){
        e.preventDefault();
        setLoading(true);
        patriciaAiClearCart({id: shoppingCart.id}, (res) => {
            UiKit.modal(`#${clearCartId}`).hide();
            dispatch({
                type: "updateData",
                newData: {shoppingCart: res.data}
            })
        }).then(() => {
                setLoading(false)
            }
        );
    }

    function onContinueClicked(e){
        e.preventDefault();
        window.analytics.track("[my_cart] \"next\" clicked", {
            location: 'my_cart'
        });
        props.history.push(`/shipping`);
    }

    function onGoToMyHomeLink(e){
        e.preventDefault();
        props.history.push(`/properties/${user.consumer.properties[0].id}?relation[]=consumer&relation[]=project&relation[]=media&retailer`);
    }

    function onEditProduct(e, product){
        e.preventDefault();
        window.analytics.track("[my_cart] product edit clicked", {
            location: 'my_cart',
            sku: product.sku
        });
        setProductInEditMode(product.id)
    }

    function onProductClicked(e, product) {
        e.preventDefault();
        e.stopPropagation();
        window.analytics.track("[my_cart] product modal info opened", {
            location: 'my_cart',
            sku: product.sku
        });
        setSelectedProduct(product)
        UiKit.modal('#product-modal').show();
    }
    function onCloseProductModal(e) {
        e.preventDefault();
        e.stopPropagation()
        setSelectedProduct(null)
    }
    const isShoppingCartNotEmpty =  shoppingCart && shoppingCart.products && shoppingCart.products.length > 0;
    return (
        <PageLayout>
            <div className='uk-flex uk-padding-large uk-padding-remove-bottom uk-flex-between'>
                <h1 className="">{lang.shoppingCart.header}</h1>
                <div>
                    <Button cssClasses="patricia-ai-color uk-button-large" label={lang.shoppingCart.actions.continue} type="primary" onClick={onContinueClicked}></Button>
                </div>
            </div>
            {!isShoppingCartNotEmpty ?
                <div className='uk-text-center'>
                    <div className='uk-margin-medium-bottom'>{lang.shoppingCart.messages.emptyCart}</div>
                    <button className="uk-button uk-button-small uk-width-small reg-next-button" onClick={onGoToMyHomeLink}>{lang.shoppingCart.actions.myHome}</button>
                </div>
                :
                <table className="uk-table uk-table-divider">
                    <BrowserView>
                    <thead className=''>
                    <tr>
                        <th className="uk-table-expand uk-text-center patricia-ai-text-color" style={{fontWeight: "bold"}}>{lang.shoppingCart.headers.product}</th>
                        <th className="uk-table-expand uk-text-center patricia-ai-text-color" style={{fontWeight: "bold"}}>{lang.shoppingCart.headers.name}</th>
                        <th className="uk-table-expand uk-text-center patricia-ai-text-color" style={{fontWeight: "bold"}}>{lang.shoppingCart.headers.provider}</th>
                        <th className="uk-table-expand uk-text-center patricia-ai-text-color" style={{fontWeight: "bold"}}>{lang.shoppingCart.headers.quantity}</th>
                        <th className="uk-table-expand uk-text-center patricia-ai-text-color" style={{fontWeight: "bold"}}>{lang.shoppingCart.headers.price}</th>
                        <th className="uk-table-expand uk-text-center patricia-ai-text-color" style={{fontWeight: "bold"}}>{lang.shoppingCart.headers.totalPrice}</th>
                        <th className="uk-table-expand uk-text-center patricia-ai-text-color" style={{fontWeight: "bold"}}></th>
                    </tr>
                    </thead>
                    <tbody className="uk-height-large uk-text-center">
                    {shoppingCart && shoppingCart.products.map(product => (
                        <tr key={product.id} className='uk-child-width uk-box-shadow-hover' style={{  cursor: "pointer"}} >
                            <td className='uk-width-1-6' >
                                <img onClick={(e) => onProductClicked(e, product)} data-src={(product.item.thumbnail && product.item.thumbnail.thumb_url) || product.item.thumbnail.media_url ||""} height="100" width="100"
                                     alt="" uk-img="" className='uk-flex-around uk-background-center viz-card-image'>
                                </img>
                            </td>
                            <td className='uk-width-1-7 uk-text-small' style={{verticalAlign: "middle"}} onClick={(e) => onProductClicked(e, product)}>{product.name}</td>
                            <td className='uk-width-1-7 uk-text-small' style={{verticalAlign: "middle"}} onClick={(e) => onProductClicked(e, product)}>
                                {product.item.manufacturer.logo && product.item.manufacturer.logo.media_url ?
                                <img className="uk-margin-left" height="150" width="150" src={product.item.manufacturer.logo.media_url}/> :
                                <img className="uk-margin-left" height="150" width="100" src={modysLogo}/>}
                            </td>
                            <td className='uk-width-1-7 uk-text-small' style={{verticalAlign: "middle"}} onClick={(e) => productInEditMode !== product.id && onProductClicked(e, product)}>
                                {productInEditMode === product.id ?
                                        <form className='uk-flex uk-flex-center uk-margin-small' onSubmit={(e) => updateCart(e, product)}>
                                            {product.item.item_type === "material" ?
                                            <Input type="text" name="amount" isPatricia={true}
                                                   cssClasses={`${dir === 'rtl' ? 'uk-margin-small-left ': 'uk-margin-small-right'} uk-width-1-3`} value={product.amount}/>
                                                   :
                                            <Input type="number" name="amount" isPatricia={true}
                                                   cssClasses={`${dir === 'rtl' ? 'uk-margin-small-left ': 'uk-margin-small-right'} uk-width-1-3`} value={product.amount}
                                                   atts={{min: 1}}/>
                                             }
                                            <div>
                                            <Button cssClasses="patricia-ai-text-color patricia-ai-border" label={lang.shoppingCart.actions.save} loading={loading} />
                                            </div>

                                    </form>
                                    :
                                    product.amount}
                                </td>
                            <td className='uk-width-1-7 uk-text-small' style={{verticalAlign: "middle"}} onClick={(e) => onProductClicked(e, product)}>{product.calculated_price.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} &#8362;</td>
                            <td className='uk-width-1-7 uk-text-small' style={{verticalAlign: "middle"}} onClick={(e) => onProductClicked(e, product)}>{(product.calculated_price * product.amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} &#8362;</td>
                            <td className='uk-width-1-7 uk-text-small' style={{verticalAlign: "middle"}}>
                                <a className="uk-margin-small-left" uk-icon="icon: trash" onClick={(e) => deleteProductFromCart(e, product)}></a>
                                <a uk-icon="icon: pencil" onClick={(e) => onEditProduct(e, product)}></a>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                    </BrowserView>
                    <MobileView>
                        <tbody className="uk-height-large">
                        {shoppingCart && shoppingCart.products.map(product => (
                            <div className='uk-padding-large uk-padding-remove-bottom uk-padding-remove-top'>
                                <div className='uk-text-small uk-margin-small-bottom' onClick={(e) => onProductClicked(e, product)}>{product.name}</div>
                                <div className="uk-margin-bottom uk-grid-medium" uk-grid="">
                                    <div className='uk-width-1-3 uk-flex uk-flex-column uk-flex-center uk-text-center'>
                                        <img style={{alignSelf: "center"}} onClick={(e) => onProductClicked(e, product)} data-src={(product.item.thumbnail && product.item.thumbnail.thumb_url) || product.item.thumbnail.media_url ||""} height="100" width="100"
                                             alt="" uk-img="" className='uk-flex-around uk-background-center viz-card-image'>
                                        </img>
                                        <div className='uk-text-small uk-text-center uk-margin-small-bottom' onClick={(e) => onProductClicked(e, product)}>
                                            {product.item.manufacturer.logo && product.item.manufacturer.logo.media_url ?
                                                <img className="uk-margin-left" height="120" width="120" src={product.item.manufacturer.logo.media_url}/>
                                                :
                                                product.item.manufacturer.name}
                                        </div>
                                    </div>
                                    <div className='uk-width-2-3 uk-padding-remove-left uk-flex uk-flex-column uk-flex-center'>
                                            <div className='uk-text-small uk-margin-small-bottom' style={{paddingRight: "30px"}} onClick={(e) => productInEditMode !== product.id && onProductClicked(e, product)}>
                                                <span className='uk-text-bold'>{lang.shoppingCart.headers.quantity}: </span>
                                                {productInEditMode === product.id ?
                                                <form className='uk-flex uk-flex-center uk-margin-small' onSubmit={(e) => updateCart(e, product)}>
                                                    {product.item.item_type === "material" ?
                                                        <Input type="text" name="amount" isPatricia={true}
                                                               cssClasses={`${dir === 'rtl' ? 'uk-margin-small-left ': 'uk-margin-small-right'} uk-width-1-3`} value={product.amount}/>
                                                        :
                                                        <Input type="number" name="amount" isPatricia={true}
                                                               cssClasses={`${dir === 'rtl' ? 'uk-margin-small-left ': 'uk-margin-small-right'} uk-width-1-3`} value={product.amount}
                                                               atts={{min: 1}}/>
                                                    }
                                                    <div>
                                                        <Button cssClasses="patricia-ai-text-color patricia-ai-border" label={lang.shoppingCart.actions.save} loading={loading} />
                                                    </div>

                                                </form>
                                                :
                                                product.amount}
                                        </div>
                                        <div className='uk-text-small uk-margin-small-bottom' style={{paddingRight: "30px"}} onClick={(e) => onProductClicked(e, product)}>
                                            <span className='uk-text-bold'>{lang.shoppingCart.headers.price}: </span> {product.calculated_price.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} &#8362;
                                        </div>
                                        <div className='uk-text-small uk-margin-small-bottom' style={{paddingRight: "30px"}} onClick={(e) => onProductClicked(e, product)}>
                                            <span className='uk-text-bold'>{lang.shoppingCart.headers.totalPrice}: </span> {(product.calculated_price * product.amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} &#8362;
                                        </div>
                                        <div className='uk-text-small uk-margin-small-bottom' style={{paddingRight: "30px"}}>
                                            <a className="uk-margin-small-left" uk-icon="icon: trash" onClick={(e) => deleteProductFromCart(e, product)}></a>
                                            <a uk-icon="icon: pencil" onClick={(e) => onEditProduct(e, product)}></a>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                            </div>
                           ))}
                        </tbody>
                    </MobileView>
                </table>
                }
            {isShoppingCartNotEmpty &&
            <div className={`uk-flex uk-margin-bottom ${!isMobile && "uk-flex-center"}`}>
                <div>
                    <Button cssClasses="patricia-ai-color uk-button-large uk-margin-small-right" label={lang.shoppingCart.actions.continue} type="primary" onClick={onContinueClicked}></Button>
                </div>
                <div className='uk-position-absolute uk-margin-small-bottom uk-margin-small-left' style={{left: 0}}><span className='uk-text-bold'>{lang.shoppingCart.summary.total}:</span> {(shoppingCart.total_price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} &#8362;</div>
            </div>}
           <ProductModal lang={lang.productModal} product={selectedProduct} id='product-modal' dir={user && user.locality === "he-il" ? "rtl" : ""} onExit={onCloseProductModal}/>
            <Modal
                id={freeDeliveriesId}
                dir={user && user.locality === "he-il" ? "rtl" : "ltr"}
            >
                <div className='uk-text-center patricia-hebrew-font patricia-ai-text-color'>
                    <i className="fas fa-truck-moving fa-5x"></i>
                    <p className='patricia-ai-text-color uk-text-large'>{lang.shoppingCart.freeDeliveries.message}</p>
                    <div className="uk-text-center">
                        <Button
                            label={lang.shoppingCart.freeDeliveries.ok}
                            cssClasses="uk-modal-close patricia-hebrew-font"
                        />
                    </div>
                </div>
            </Modal>
        </PageLayout>
    );
}

export default ShoppingCart;