import React from "react";
import ScrollableShoppingCart from "../ScrollableShoppingCart/ScrollableShoppingCart";
import {BrowserView, MobileView, isMobile} from "react-device-detect";
import {Input} from "../../Input/Input";
import {Button} from "../../Button/Button";

function OrderModal(props){
    const orderDetailsId = 'order-details-id';
    return (
        <div dir={props.dir} id={orderDetailsId} uk-modal="bg-close: true" className='patricia-hebrew-font uk-modal-container'>
            <div
                className="uk-modal-dialog uk-modal-body"
                dir={props.dir}
            >
                {!props.noEscape && (
                    <button
                        className="uk-modal-close-default"
                        type="button"
                        uk-close=""
                    />
                )}
                {props.order &&
                <div className='uk-width-1-1'>
                    <div className='uk-flex uk-flex-center'>
                        <table className="uk-table uk-table-divider">
                            <BrowserView>
                            <thead className='uk-display-block  uk-text-center'>
                            <tr className='uk-width-1-1' style={{display: "table"}}>
                                <th className="uk-table-expand uk-text-center">{props.lang.shoppingCart.headers.product}</th>
                                <th className="uk-table-expand uk-text-center">{props.lang.shoppingCart.headers.name}</th>
                                <th className="uk-table-expand uk-text-center">{props.lang.shoppingCart.headers.sku}</th>
                                <th className="uk-table-expand uk-text-center">{props.lang.shoppingCart.headers.quantity}</th>
                                <th className="uk-table-expand uk-text-center">{props.lang.shoppingCart.headers.price}</th>
                            </tr>
                            </thead>
                            <tbody className="uk-height-large uk-overflow-auto uk-display-block  uk-text-center">
                            {props.order && props.order.products.map(product => (
                                <tr className='uk-width-1-1' style={{display: "table"}}>
                                    <td className='uk-width-1-5'>
                                        <img data-src={(product.item.thumbnail && product.item.thumbnail.thumb_url) || product.item.thumbnail.media_url ||""} height="100" width="100"
                                             alt="" uk-img="" className='uk-flex-around uk-background-center viz-card-image'>
                                        </img>
                                    </td>
                                    <td className='uk-width-1-5 uk-text-small' style={{verticalAlign: "middle"}}>{product.name}</td>
                                    <td className='uk-width-1-5 uk-text-small' style={{verticalAlign: "middle"}}>{product.sku}</td>
                                    <td className='uk-width-1-5 uk-text-small' style={{verticalAlign: "middle"}}>{product.amount}</td>
                                    <td className='uk-width-1-5 uk-text-small' style={{verticalAlign: "middle"}}>{product.calculated_price.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} &#8362;</td>
                                </tr>
                            ))}
                            </tbody>
                            </BrowserView>
                            <MobileView>
                                <tbody className="uk-height-large">
                                {props.order && props.order.products.map(product => (
                                    <div className='uk-padding-large uk-padding-remove-bottom uk-padding-remove-top'>
                                        <div className="uk-margin-bottom uk-grid-small" uk-grid="">
                                            <div className="uk-text-small uk-text-center uk-margin-small-bottom">{product.name}</div>
                                            <div className='uk-width-1-3 uk-flex uk-flex-column uk-flex-center uk-text-center'>
                                                <img style={{alignSelf: "center"}} data-src={(product.item.thumbnail && product.item.thumbnail.thumb_url) || product.item.thumbnail.media_url ||""} height="100" width="100"
                                                     alt="" uk-img="" className='uk-flex-around uk-background-center viz-card-image'>
                                                </img>
                                                <div className="uk-text-small">{product.sku}</div>
                                            </div>
                                            <div className='uk-width-2-3 uk-padding-remove-left uk-flex uk-flex-column uk-flex-center'>
                                                <div className='uk-text-small uk-margin-small-bottom' style={{paddingRight: "30px"}}>
                                                    <span className='uk-text-bold'>{props.lang.shoppingCart.headers.quantity}: </span>
                                                    {product.amount}
                                                </div>
                                                <div className='uk-text-small uk-margin-small-bottom' style={{paddingRight: "30px"}}>
                                                    <div className='uk-text-bold'>{props.lang.shoppingCart.headers.price}: </div> {product.calculated_price.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} &#8362;
                                                </div>
                                                <div className='uk-text-small uk-margin-small-bottom' style={{paddingRight: "30px"}}>
                                                    <div className='uk-text-bold'>{props.lang.shoppingCart.headers.totalPrice}: </div> {(product.calculated_price * product.amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} &#8362;
                                                </div>
                                            </div>
                                        </div>
                                        <hr/>
                                    </div>
                                ))}
                                </tbody>
                            </MobileView>
                        </table>
                    </div>
                    <br/>
                    <div className={`uk-margin-large-top uk-flex uk-flex-around ${isMobile && 'uk-flex-column-reverse'}`}>
                        {props.order && props.order.address &&
                        <div className={`uk-margin-medium-bottom ${!isMobile && 'uk-margin-medium-right'}`}>
                            <div className="uk-text-bold">{props.lang.orders.headers.shippingAddress}</div>
                            <div>{props.order.address.firstName} {props.order.address.lastName}</div>
                            <div>{props.order.address.street} {props.order.address.number}{props.order.address.apartment && `/ ${props.order.address.apartment}`}, {props.order.address.city}</div>
                            <div>{props.order.address.phone}</div>
                        </div>}
                        <div>
                            <div>{props.lang.shoppingCart.summary.subTotal}: {(props.order && props.order.total_price / 1.17).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} &#8362;</div>
                            <div>{props.lang.shoppingCart.summary.vat}: {(props.order && props.order.total_price * 0.17).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} &#8362;</div>
                            <div className='uk-margin-small-bottom'><span className='uk-text-bold'>{props.lang.shoppingCart.summary.total}:</span> {(props.order && props.order.total_price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} &#8362;</div>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    )
}
export default OrderModal;