import React, {useState} from "react";
import '@fortawesome/fontawesome-free/js/solid'
import {
    MobileView,
    isMobile
} from "react-device-detect";

function OneLineInput(props){
    const [showPassword, setShowPassword] = useState(false);
    const isPassword = props.type === 'password';

    const showPasswordIcon = <i className="fa fa-eye uk-position-absolute"></i>
    const hidePasswordIcon = <i className="fas fa-eye-slash uk-position-absolute"></i>
    function togglePasswordMode(){
        setShowPassword(showPassword ? false : true);
    }

    return (
    <div className={!isMobile && 'uk-flex'} style = {{ marginBottom: "20px"}}>
        <label className="uk-width-1-3@m" htmlFor={props.name} style={{ display: "flex", alignSelf: "center", whiteSpace: "nowrap", flex: "0 0 auto", fontSize: "20px", color: "#999"}}>{`${props.label}${props.required ? '*' : ''}`}   {props.info && <a uk-icon="icon: info" uk-tooltip={props.info}></a>}</label>
        {props.type !== "file" ?
            <div className='uk-text-left' style={{width: "100%"}}>
                <input className="uk-background-muted uk-width-1-1" name={props.name} placeholder={props.placeholder || ""}
                       type={!isPassword ? props.type : (!showPassword ? "password" : "text") } required={props.required} maxlength={props.maxlength || null}
                       style={{height: "38px", border: "1px solid #999", fontSize: "16px"}}>
                </input>
                {isPassword && <span className='uk-position-absolute' style={{cursor: "pointer", padding: "10px"}}
                   onClick={togglePasswordMode}> {showPassword ?  hidePasswordIcon : showPasswordIcon}</span>}
                {props.error && <span className='uk-text-alert uk-text-small uk-margin-small-top'>{props.error}</span>}
            </div>
            :
                <input className="uk-background-muted" name={props.name} placeholder={props.placeholder || ""} hidden
                       type={props.type} required={props.required} id={props.name}
                       style={{ width: "100%", height: "38px", justifyContent: "center", alignSelf: "center", flex: "0 0 auto", fontSize: "20px", color: "#999"}}/>

        }
    </div>
    );
}
export default OneLineInput;