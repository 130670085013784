import React from "react";
import Thumbnail from "./Thumbnail";
import { createMedia, deleteMedia } from "../../app/actions";
import Dialog from "../Dialog/Dialog";
import UiKit from "uikit";

class Gallery extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.promptClass = "gallery-delete-prompt";
    this.promptToggleId = "gallery-delete-toggle";
  }

  state = {
    activeKey: 0,
    uploading: [],
    deleting: null,
    images: this.props.images
  };

  setActiveImage = key => {
    this.setState({
      activeKey: key
    });
  };

  promptUploadDialog = () => {
    this.inputRef.current.click();
  };

  promptDelete = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    const { images } = this.state;
    this.setState({
      images: images.map(i => {
        if (i.id === id) i.uploading = true;
        return i;
      }),
      deleting: id
    });
    UiKit.toggle(`#${this.promptToggleId}`).toggle();
  };

  downloadPhoto = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    const image = this.state.images.filter(i => i.id === id)[0];
    window.open(image.mediaUrl, "_blank");
  };

  handleDelete = () => {
    this.closeDelete(true);
    const id = this.state.deleting;
    deleteMedia({ id }, () => {
      const { images, activeKey } = this.state;
      const state = { images: images.filter(i => i.id !== id), deleting: null };
      if (activeKey === images.length - 1) state.activeKey = activeKey - 1;
      this.setState({
        ...state
      });
    });
  };

  closeDelete = (ignore = null) => {
    UiKit.toggle(`#${this.promptToggleId}`).toggle();
    const elements = document.getElementsByClassName(this.promptClass);
    for (let elem of elements) {
      elem.setAttribute("hidden", true);
    }
    if (ignore === true) return;
    const { images, deleting } = this.state;
    this.setState({
      images: images.map(i => {
        if (i.id === deleting) i.uploading = false;
        return i;
      }),
      deleting: null
    });
  };

  handleInputChange = e => {
    const files = Array.from(e.target.files).map(file => {
      let objectUrl = URL.createObjectURL(file);
      let id = Math.random()
        .toString(36)
        .slice(2);
      this.handleImageUpload(file, id);
      return {
        file,
        id,
        uploading: true,
        progress: "30",
        mediaUrl: objectUrl,
        thumbUrl: objectUrl,
        type: "image"
      };
    });
    this.setState({
      uploading: files
    });
  };

  handleImageUpload = (file, id) => {
    let formData = new FormData();
    formData.append("media", file);
    formData.append("mediable_type", this.props.mediable_type);
    formData.append("mediable_id", this.props.mediable_id);
    createMedia({ payload: formData }, res => {
      let { uploading, images } = this.state;
      this.setState({
        uploading: uploading.filter(f => f.id !== id),
        images: [...images, res.data]
      });
    });
  };

  render() {
    return (
      <div className="uk-position-relative">
        <span
          id={this.promptToggleId}
          hidden
          uk-toggle={`target: .${this.promptClass}; animation:uk-animation-fae`}
        />
        <Dialog
          removeOverlay={true}
          className={this.promptClass}
          onCancel={this.closeDelete}
          onConfirm={this.handleDelete}
          {...this.props.lang.delete}
        ></Dialog>
        {this.state.images && (
          <div className="uk-grid-small" uk-grid="">
            {this.state.images.length > 0 && (
              <div className="uk-width-2-3 uk-visible@m">
                <div uk-lightbox="animation: slide">
                  <a
                    href={
                      this.state.images[this.state.activeKey] &&
                      this.state.images[this.state.activeKey].mediaUrl
                    }
                  >
                    <div
                      className="uk-height-large uk-border-rounded"
                      style={{
                        backgroundImage: `url('${this.state.images[
                          this.state.activeKey
                        ] &&
                          this.state.images[this.state.activeKey].mediaUrl}')`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        cursor: "zoom-in"
                      }}
                    ></div>
                  </a>
                  {this.state.images.map(i => (
                    <a href={i.mediaUrl} key={i.id} />
                  ))}
                </div>
              </div>
            )}

            <div className="uk-width-1-3@m uk-width-1-1@s">
              <div>
                <div className="uk-grid-small" uk-grid="">
                  {this.state.images.length > 0 &&
                    this.state.images
                      .concat(this.state.uploading)
                      .map((image, key) => (
                        <div className={`uk-width-1-3`} key={key}>
                          <Thumbnail
                            onClick={this.setActiveImage}
                            {...image}
                            index={key}
                            activeKey={this.state.activeKey}
                            actions={[
                              ...(this.props.upload
                                ? [
                                    {
                                      icon: "trash",
                                      onClick: this.promptDelete
                                    }
                                  ]
                                : []),
                              {
                                icon: "download",
                                onClick: this.downloadPhoto
                              }
                            ]}
                          ></Thumbnail>
                        </div>
                      ))}
                  {this.props.upload && (
                    <div className={`uk-width-1-3`}>
                      <input
                        type="file"
                        accept="image/*"
                        multiple
                        hidden
                        ref={this.inputRef}
                        onChange={e => this.handleInputChange(e)}
                      />
                      <div
                        style={{
                          height: "110px",
                          cursor: "pointer"
                        }}
                        className="uk-background-muted uk-border-rounded uk-position-relative uk-text-primary uk-animation-fade uk-box-shadow-hover-medium"
                        uk-toggle="mode:hover; cls: uk-background-muted uk-background-primary uk-light uk-text-primary"
                        uk-tooltip={this.props.click}
                        onClick={() => this.promptUploadDialog()}
                      >
                        <span
                          className="uk-position-center"
                          uk-icon="icon:plus;ratio:1.5"
                        ></span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Gallery;
