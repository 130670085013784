import React from "react";
import ReactDOM from "react-dom";
import "./styles/App.scss";
import UiKit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";

UiKit.use(Icons);
ReactDOM.render(
    <Router>
        <App />
    </Router>, document.getElementById("root"));
serviceWorker.unregister();