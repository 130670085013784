import React, {useState, useContext} from 'react';

import { withRouter } from "react-router-dom";
import {StateContext} from "../../../app/state";
import {Checkbox} from "../../Checkbox/Checkbox";

function InspirationQuizCard(props){
    const context = useContext(StateContext);
    const [
        {
            localization: {createInspirationQuiz: lang },
            auth: { user }
        }
    ] = context;
    const updateId = "update-quiz";
    const deleteId = "delete-quiz";

    function OnQuizClicked(id) {
        props.history.push(`/inspirationQuizzes/${id}`);
    }

    return (
        <div key={props.quiz.id}>
            <div
                className="uk-card uk-card-default uk-height-large uk-card-body uk-flex uk-flex-column uk-flex-between viz-actions-container"
                style={{ cursor: "pointer" }}
            >
                <ul className="uk-iconnav uk-position-top-right uk-position-small viz-actions">
                    <li>
                        <a
                            onClick={props.onActionClicked}
                            uk-icon="icon:pencil"
                            uk-tooltip="Edit Quiz"
                            uk-toggle={`target:#${updateId}`}
                        ></a>
                    </li>
                    <li>
                        <a
                            onClick={props.onActionClicked}
                            uk-icon="icon:trash"
                            uk-tooltip="Delete Quiz"
                            uk-toggle={`target:#${deleteId}`}
                        ></a>
                    </li>
                </ul>
                <ul className="uk-iconnav uk-position-top-left uk-position-small">
                    <li>
                        <Checkbox
                            name="select-default-quiz"
                            value={props.isDefault}
                            //tooltip='default quiz'
                            onChange={props.onDefaultClicked}
                        />
                    </li>
                </ul>
                <div onClick={() => OnQuizClicked(props.quiz.id)} className='uk-flex uk-flex-column'>
                    <div
                        className="uk-align-center uk-width-auto"
                        style={{ marginTop: "0", marginBottom: "0" }}
                    >
                    </div>
                    <div className="uk-text-center">
                        <h5 className="uk-text-bold">{props.quiz.name}</h5>
                    </div>
                    <div>
                        <hr />
                        <p className="uk-text-center uk-text-small uk-text-muted">:locality<br/> {props.quiz.locality}</p>
                        <p className="uk-text-center uk-text-small uk-text-muted">:created<br/> {props.quiz.timeStamps.created}</p>
                        <p className="uk-text-center uk-text-small uk-text-muted">:last update<br/> {props.quiz.timeStamps.lastUpdated}</p>
                        <p
                            className="uk-text-center"
                            style={{ cursor: "pointer" }}
                        >
                            {props.quiz.questions ? props.quiz.questions.length : 0}
                            <br />
                            <span className="uk-text-muted uk-text-small">
                                questions
                              </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default withRouter(InspirationQuizCard);
