import React from "react";

const Modal = props => (
  <div dir={props.dir} id={props.id} uk-modal="bg-close: false">
    <div
      className="uk-modal-dialog uk-modal-body uk-width-large"
      dir={props.dir}
    >
      {!props.noEscape && (
        <button
          className="uk-modal-close-default"
          type="button"
          uk-close=""
          onClick={props.onExit ? props.onExit : () => {}}
        />
      )}
      <div className="viz-bg-white uk-padding" dir={props.dir}>
        <div>
          <h2 className="uk-text-bold">{props.title}</h2>
        </div>
        {props.children}
      </div>
    </div>
  </div>
);

export default Modal;
