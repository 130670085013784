import React, { Component } from "react";
import { Input } from "../Input/Input";
import { StateContext } from "../../app/state";
import { Button } from "../Button/Button";
import { createConsumer } from "../../app/actions";
import { withRouter } from "react-router-dom";
import UiKit from "uikit";


class ConsumerForm extends Component {
  static contextType = StateContext;
  state = { sending: false };
  handleSubmit = e => {
    e.preventDefault();
    this.setState({
      sending: true
    });
    const formData = new FormData(e.target);
    createConsumer({ payload: formData }, res => {
      const {
        auth: {
          user: {
            seller: { retailerId }
          }
        }
      } = this.context[0];
      UiKit.modal("#create-consumer").hide();
      this.props.history.push(
        `/consumers/${
          res.data.id
        }?relation[]=properties&relation[]=user&retailer=${retailerId}`
      );
    });
  };
  render() {
    const [
      {
        localization: {
          consumers: { create: lang }
        },
        auth: { user }
      }
    ] = this.context;
    return (
      <div
        className="viz-bg-white uk-padding"
        dir={user && user.locality === "he-il" ? "rtl" : "ltr"}
      >
        <form className="uk-width-expand@m" onSubmit={this.handleSubmit}>
          <div>
            <h2 className="uk-text-bold">{lang.title}</h2>
          </div>
          <Input
            name="name"
            placeholder={lang.fields.name.placeholder}
            label={lang.fields.name.label}
            required={true}
          />
          <Input
            name="email"
            type="email"
            placeholder={lang.fields.email.placeholder}
            label={lang.fields.email.label}
            required={true}
          />
          <Input
            name="phone"
            type="tel"
            placeholder={lang.fields.phone.placeholder}
            label={lang.fields.phone.label}
            required={true}
          />
          <Button
            label={lang.actions.create.label}
            type="primary"
            cssClasses="uk-width-1-1 uk-margin-small-top"
            loading={this.state.sending}
          />
        </form>
      </div>
    );
  }
}

export default withRouter(ConsumerForm);
