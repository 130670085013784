import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_APP_ENDPOINT;
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

export const setAuthHeader = token => {
  axios.defaults.headers.common.Authorization = token;
};

export const fetchLoginUser = data => {
  return new Promise((resolve, reject) => {
    axios
      .post("/auth/login", data.payload)
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};

export const fetchLogoutUser = data => {
  return new Promise((resolve, reject) => {
    axios
      .post("/auth/logout", data.payload)
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};

export const fetchByUrlQuery = target => {
  return new Promise((resolve, reject) => {
    axios
      .get(target)
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};

export const fetchAuthUser = data => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/auth/user`, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};

export const fetchUpdatePassword = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/password/update`, data.payload, { params: data.params })
      .then(res => {
        resolve(res);
      })
      .catch(error => reject(error));
  });
};

export const fetchSetPassword = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/password/reset`, data.payload, { params: data.params })
      .then(res => {
        resolve(res);
      })
      .catch(error => reject(error));
  });
};

export const fetchRequestResetPassword = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/password/create`, data.payload, { params: data.params })
      .then(res => {
        resolve(res);
      })
      .catch(error => reject(error));
  });
};

export const fetchUpdateUser = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/users/update/${data.id}`, data.payload, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};

export const fetchSearchUser = data => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/users`, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchIndexPlaces = data => {
  return new Promise((resolve, reject) => {
    axios
      .get("/places", { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchGetPlace = data => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/places/${data.id}`, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchCreatePlace = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/places`, data.payload, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchUpdatePlace = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/places/${data.id}`, data.payload, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};

export const fetchDeletePlace = data => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/places/${data.id}`)
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchIndexRooms = data => {
  return new Promise((resolve, reject) => {
    axios
      .get("/rooms", { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchGetRoom = data => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/rooms/${data.id}`, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchCreateRoom = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/rooms`, data.payload, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchUpdateRoom = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/rooms/${data.id}`, data.payload, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};

export const fetchDeleteRoom = data => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/rooms/${data.id}`)
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};

/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchIndexItems = data => {
  return new Promise((resolve, reject) => {
    axios
      .get("/items", { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchGetItem = data => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/items/${data.id}`, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchCreateItem = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/items/`, data.payload, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchUpdateItem = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/items/${data.id}`, data.payload, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchIndexProducts = data => {
  return new Promise((resolve, reject) => {
    axios
      .get("/products", { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};

export const fetchSearchProducts = data => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/search/products/${data.term}`, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchGetProduct = data => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/products/${data.id}`, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchCreateProduct = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/products/`, data.payload, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchUpdateProduct = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/products/${data.id}`, data.payload, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchIndexRetailers = data => {
  return new Promise((resolve, reject) => {
    axios
      .get("/retailers", { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchGetRetailer = data => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/retailers/${data.id}`, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchCreateRetailer = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/retailers/`, data.payload, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchUpdateRetailer = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/retailers/${data.id}`, data.payload, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchIndexDevelopers = data => {
  return new Promise((resolve, reject) => {
    axios
      .get("/developers", { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchGetDeveloper = data => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/developers/${data.id}`, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};

/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchDeleteDeveloper = data => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/developers/${data.id}`, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchCreateDeveloper = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/developers/`, data.payload, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchUpdateDeveloper = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/developers/${data.id}`, data.payload, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchIndexManufacturers = data => {
  return new Promise((resolve, reject) => {
    axios
      .get("/manufacturers", { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchGetManufacturer = data => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/manufacturers/${data.id}`, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchCreateManufacturer = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/manufacturers/`, data.payload, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchUpdateManufacturer = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/manufacturers/${data.id}`, data.payload, {
        params: data.params
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchIndexProjectCommons = data => {
  return new Promise((resolve, reject) => {
    axios
      .get("/projectCommons", { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchGetProjectCommon = data => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/projectCommons/${data.id}`, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchCreateProjectCommon = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/projectCommons/`, data.payload, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};

export const fetchDuplicateProjectCommon = data => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/projectCommons/duplicate/${data.original}/${data.target}`, {
        params: data.params
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};

export const fetchSpecsFromFile = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/projectCommons/${data.id}/specsFromFile`, data.payload, {
        params: data.params
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};

export const fetchClearMatrix = data => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/projectCommons/${data.id}/clearMatrix`, {
        params: data.params
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};

export const fetchGetMatrix = data => {
    return new Promise((resolve, reject) => {
        axios
            .get(`/matrices/${data.id}`, {
            params: data.params
        })
        .then(res => {
            resolve(res.data);
        })
            .catch(error => reject(error));
    });
};

export const fetchDeleteProjectCommon = data => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/projectCommons/${data.id}`)
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchUpdateProjectCommon = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/projectCommons/${data.id}`, data.payload, {
        params: data.params
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchIndexTours = data => {
  return new Promise((resolve, reject) => {
    axios
      .get("/tours", { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchGetTour = data => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/tours/${data.id}`, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchCreateTour = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/tours`, data.payload, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchUpdateTour = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/tours/${data.id}`, data.payload, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchIndexDesigns = data => {
  return new Promise((resolve, reject) => {
    axios
      .get("/designs", { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchGetDesign = data => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/designs/${data.id}`, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchCreateDesign = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/designs`, data.payload, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchUpdateDesign = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/designs/${data.id}`, data.payload, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchIndexSets = data => {
  return new Promise((resolve, reject) => {
    axios
      .get("/sets", { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchGetSet = data => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/sets/${data.id}`, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchCreateSet = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/sets`, data.payload, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchUpdateSet = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/sets/${data.id}`, data.payload, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchIndexSellers = data => {
  return new Promise((resolve, reject) => {
    axios
      .get("/sellers", { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};

/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchCreateSeller = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/users/seller`, data.payload, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchGetSeller = data => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/sellers/${data.id}`, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};

export const fetchDeleteSeller = data => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/sellers/${data.id}`, { params: data.params })
      .then(res => resolve(res.data))
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchUpdateSeller = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/sellers/${data.id}`, data.payload, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchIndexConsumers = data => {
  return new Promise((resolve, reject) => {
    axios
      .get("/consumers", { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchGetConsumer = data => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/consumers/${data.id}`, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchCreateConsumer = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/users/consumer`, data.payload, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};

export const fetchDeleteConsumer = data => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/consumers/${data.id}`)
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchUpdateConsumer = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/consumers/${data.id}`, data.payload, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchIndexProperties = data => {
  return new Promise((resolve, reject) => {
    axios
      .get("/properties", { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};

export const fetchDeleteProperty = data => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/properties/${data.id}`)
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchGetProperty = data => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/properties/${data.id}`, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};

export const fetchExtendUnitToProperty = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/units/extend/${data.id}/property`, data.payload, {
        params: data.params
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};

export const fetchSpreadToProperties = data => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/units/spread/${data.id}`, {
        params: data.params
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};

/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchCreateProperty = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/properties/`, data.payload, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchUpdateProperty = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/properties/${data.id}`, data.payload, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchIndexProjects = data => {
  return new Promise((resolve, reject) => {
    axios
      .get("/projects", { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchGetProject = data => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/projects/${data.id}`, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchCreateProject = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/projects`, data.payload, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchUpdateProject = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/projects/${data.id}`, data.payload, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchIndexQuotes = data => {
  return new Promise((resolve, reject) => {
    axios
      .get("/quotes", { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};

export const fetchDeleteQuote = data => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/quotes/${data.id}`)
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};

export const fetchDeleteQuoteComponent = data => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/quoteComponents/${data.id}`)
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};

export const fetchDownloadQuote = data => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/quotes/download/${data.id}`)
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};

export const fetchDownloadPropertyCSV = data => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/quotes/downloadCSV/${data.id}`,{ params: data.payload })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};



export const fetchSendQuote = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/quotes/send/${data.id}`)
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};

/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchGetQuote = data => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/quotes/${data.id}`, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchCreateQuote = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/quotes/`, data.payload, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchUpdateQuote = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/quotes/${data.id}`, data.payload, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};

export const fetchGetPrivateConsumersRooms = async (data, callback) => {
    await axios
        .get('/privateConsumersRooms', {params: data.params})
        .then(res => {
            callback(res.data);
        });

};


export const fetchUpdateQuoteComponent = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/quoteComponents/${data.id}`, data.payload, {
        params: data.params
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchIndexUnits = data => {
  return new Promise((resolve, reject) => {
    axios
      .get("/units", { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchGetUnit = data => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/units/${data.id}`, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchCreateUnit = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/units/`, data.payload, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};

export const fetchDeleteUnit = data => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/units/${data.id}`)
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};
/**
 *
 * @param {payload?:FormData; id?:string; params?:object; } data
 */
export const fetchUpdateUnit = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/units/${data.id}`, data.payload, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};

export const fetchCreateLocation = data => {
  return new Promise((resolve, reject) => {
    axios
      .post("/locations", data.payload, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};

export const fetchDeleteLocation = data => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/locations/${data.id}`)
      .then(res => {
        resolve(res);
      })
      .catch(error => reject(error));
  });
};

export const fetchCreateMedia = data => {
  return new Promise((resolve, reject) => {
    axios
      .post("/media", data.payload, { params: data.params })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};

export const fetchDeleteMedia = data => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/media/${data.id}`)
      .then(res => {
        resolve(res);
      })
      .catch(error => reject(error));
  });
};

export const fetchImportProjectsFromFile = data => {
    return new Promise((resolve, reject) => {
        axios
            .post(`/uploadProjects`, data.payload, {
                params: data.params
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(error => reject(error));
    });
};

export const fetchImportConsumersFromFile = data => {
    return new Promise((resolve, reject) => {
        axios
            .post(`/uploadConsumers`, data.payload, {
                params: data.params
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(error => reject(error));
    });
};


export const fetchCreateTourCreator = data => {
    return new Promise((resolve, reject) => {
        axios
            .post(`/tourCreators`, data.payload, {
                params: data.params
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(error => reject(error));
    });
};

export const fetchDeleteTourCreator = data => {
    return new Promise((resolve, reject) => {
        axios
            .delete(`/tourCreators/${data.id}`)
            .then(res => {
                resolve(res.data);
            })
            .catch(error => reject(error));
    });
};

export const fetchDeleteTourCreatorByUserId = data => {
    return new Promise((resolve, reject) => {
        axios
            .post(`tourCreators/deleteByUser`,  data.payload, {
                params: data.params})
            .then(res => {
                resolve(res.data);
            })
            .catch(error => reject(error));
    });
};

export const fetchCreateDesignStyle = async (data, callback) => {
    await axios
        .post(`/designStyles`, data.payload, { params: data.params })
        .then(res => {
            callback(res.data);
        });
};

export const fetchIndexDesignStyles = async (data, callback) => {
    await axios
        .get('/designStyles', {params: data.params})
        .then(res => {
            callback(res.data);
        });
};

export const fetchUpdateDesignStyle = async (data, callback) => {
    await axios
        .post(`/designStyles/${data.id}`, data.payload, { params: data.params })
        .then(res => {
            callback(res.data);
        });
};

export const fetchDeleteDesignStyle = async (data, callback) => {
    await axios
        .delete(`/designStyles/${data.id}`)
        .then(res => {
            callback(res.data);
        });

};

export const fetchGetDesignStyle = async (data, callback) => {
    await axios
        .get(`/designStyles/${data.id}`, { params: data.params })
        .then(res => {
            callback(res.data);
        });
};

export const fetchAddProductToDesignStyleProductsList = async (data, callback) => {
    await axios
        .post(`/designStyles/${data.id}/add`, data.payload, { params: data.params })
        .then(res => {
            callback(res.data);
        });

};

export const fetchRemoveProductFromDesignStyleProductsList = async (data, callback) => {
    await axios
        .delete(`/designStyles/${data.designStyleId}/remove/${data.productId}`, data.payload, { params: data.params })
        .then(res => {
            callback(res.data);
        });

};

export const fetchChangeDesignStyleProductFlags = async (data, callback) => {
    await axios
        .post(`/designStyles/${data.designStyleId}/flags/${data.productId}`, data.payload, { params: data.params })
        .then(res => {
            callback(res.data);
        });

};

export const fetchCreateInspirationQuiz = async (data, callback) => {
    await axios
        .post(`/inspirationQuizzes`, data.payload, { params: data.params })
        .then(res => {
            callback(res.data);
        });
};

export const fetchIndexInspirationQuizzes = async (data, callback) => {
    await axios
        .get('/inspirationQuizzes', {params: data.params})
        .then(res => {
            callback(res.data);
        });
};

export const fetchDeleteInspirationQuiz = async (data, callback) => {
    await axios
        .delete(`/inspirationQuizzes/${data.id}`)
        .then(res => {
            callback(res.data);
        });
};

export const fetchUpdateInspirationQuiz = async (data, callback) => {
    await axios
        .post(`/inspirationQuizzes/${data.id}`, data.payload, { params: data.params })
        .then(res => {
            callback(res.data);
        });
};

export const fetchGetInspirationQuiz = async (data, callback) => {
    await axios
        .get(`/inspirationQuizzes/${data.id}`, { params: data.params })
        .then(res => {
            callback(res.data);
        });
};

export const fetchGetRetailerInspirationQuiz = async (data, callback) => {
    await axios
        .get('/inspirationQuizzes/byRetailer', { params: data.params })
        .then(res => {
            callback(res.data);
        });
};

export const fetchSetDefaultInspirationQuiz = async (data, callback) => {
    await axios
        .post(`/inspirationQuizzes/${data.id}/setDefault`)
        .then(res => {
            callback(res.data);
        });
};

export const fetchSetQuestionsOrderOfInspirationQuiz = async (data, callback) => {
    await axios
        .post(`/inspirationQuizzes/${data.id}/setQuestionsOrder`, data.payload, { params: data.params })
        .then(res => {
            callback(res.data);
        });
};

export const fetchCreateInspirationQuizQuestion = async (data, callback) => {
    await axios
        .post(`/inspirationQuizzes/${data.id}/addQuestion`, data.payload, { params: data.params })
        .then(res => {
            callback(res.data);
        });
};

export const fetchDeleteQuizQuestion = async (data, callback) => {
    await axios
        .delete(`/quizQuestions/${data.id}`)
        .then(res => {
            callback(res.data);
        });
};

export const fetchUpdateQuizQuestion = async (data, callback) => {
    await axios
        .post(`/quizQuestions/${data.id}`, data.payload, { params: data.params })
        .then(res => {
            callback(res.data);
        });
};

export const fetchCreateInspirationQuizAnswer = async (data, callback) => {
    await axios
        .post(`/quizQuestions/${data.id}/addAnswer`, data.payload, { params: data.params })
        .then(res => {
            callback(res.data);
        });
};

export const fetchUpdateInspirationQuizAnswer = async (data, callback) => {
    await axios
        .post(`/quizAnswers/${data.id}`, data.payload, { params: data.params })
        .then(res => {
            callback(res.data);
        });
};

export const fetchDeleteQuizAnswer = async (data, callback) => {
    await axios
        .delete(`/quizAnswers/${data.id}`)
        .then(res => {
            callback(res.data);
        });
};

export const fetchSaveChosenAnswers = async (data, callback) => {
    await axios
        .post('saveChosenAnswers', data.payload, { params: data.params })
        .then(res => {
            callback(res.data);
        });
};


export const fetchCreateQuizResult = async (data, callback) => {
    await axios
        .post(`/quizResults`, data.payload, { params: data.params })
        .then(res => {
            callback(res.data);
        });
};

export const fetchCalculateQuizResult = async (data, callback) => {
    await axios
        .post(`/calculateQuizResults/${data.id}`, data.payload, { params: data.params })
        .then(res => {
            callback(res.data);
        });
};

export const fetchCalculateDesignSets = async (data, callback) => {
    await axios
        .post(`/calculateDesignSets/${data.id}`, data.payload, { params: data.params })
        .then(res => {
            callback(res.data);
        });
};

export const fetchGetDesignSets = async (data, callback) => {
    await axios
        .get(`/designSets`, { params: data.params })
        .then(res => {
            callback(res.data);
        });
};

export const fetchGetDefaultDesignSetByTour = async (data, callback) => {
    await axios
        .get(`/designSetByTour`, { params: data.params })
        .then(res => {
            callback(res.data);
        });
};

export const fetchSetDefaultDesignSet = async (data, callback) => {
    await axios
        .post(`/designSets/${data.id}/setDefault`,  data.payload,{ params: data.params })
        .then(res => {
            callback(res.data);
        });
};

/**
 *
 * patricia ai api
 *
 **/

export const fetchPatriciaAiRegister = data => {
    return new Promise((resolve, reject) => {
        axios
            .post(`/auth/register`, data.payload, {
                params: data.params
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(error => reject(error));
    });
};

export const fetchPatriciaAiUpdateProductAmount = async (data, callback) => {
    await axios
        .post(`/orders/${data.id}/updateProduct`,  data.payload,{ params: data.params })
        .then(res => {
            callback(res.data);
        });
};

export const fetchPatriciaAiUpdateOrder = async (data, callback) => {
    await axios
        .post(`/orders/${data.id}`,  data.payload,{ params: data.params })
        .then(res => {
            callback(res.data);
        });
};

export const fetchPatriciaAiDeleteProduct = async (data, callback) => {
    await axios
        .post(`/orders/${data.id}/deleteProduct`,  data.payload,{ params: data.params })
        .then(res => {
            callback(res.data);
        });
};

export const fetchPatriciaAiAddProduct = async (data, callback) => {
    await axios
        .post(`/orders/${data.id}/addProduct`,  data.payload,{ params: data.params })
        .then(res => {
            callback(res.data);
        });
};

export const fetchPatriciaAiAddManyProducts = async (data, callback) => {
    await axios
        .post(`/orders/${data.id}/addManyProducts`,  data.payload,{ params: data.params })
        .then(res => {
            callback(res.data);
        });
};

export const fetchPatriciaAiClearCart = async (data, callback) => {
    await axios
        .post(`/orders/${data.id}/clearCart`,  data.payload,{ params: data.params })
        .then(res => {
            callback(res.data);
        });
};

export const fetchGetShoppingCart = async (data, callback) => {
    await axios
        .get(`/shoppingCart`, { params: data.params })
        .then(res => {
            callback(res.data);
        });
};

export const fetchAssignTourToUser = async (data, callback) => {
    await axios
        .post(`/tours/${data.id}/assignTourToUser`,  data.payload,{ params: data.params })
        .then(res => {
            callback(res.data);
        });
};

export const fetchCreatePropertyWithNoUser = data => {
    return new Promise((resolve, reject) => {
        axios
            .post(`/createProperty/`, data.payload, { params: data.params })
            .then(res => {
                resolve(res.data);
            })
            .catch(error => {
                reject(error)
            });
    });
};

export const getUsersOrdersHistory = async (data, callback) => {
    await axios
        .get(`/users/${data.id}/orders`, { params: data.params })
        .then(res => {
            callback(res.data);
        });
};

export const fetchGetDesignPackages = async (data, callback) => {
    await axios
        .get(`/designPackages`, { params: data.params })
        .then(res => {
            callback(res.data);
        });
};

export const fetchCreateDesignPackageOrder = data => {
    return new Promise((resolve, reject) => {
        axios
            .post(`/designPackageOrder/`, data.payload, { params: data.params })
            .then(res => {
                resolve(res.data);
            })
            .catch(error => {
                reject(error)
            });
    });
};

export const fetchGetDesignPackage = async (data, callback) => {
    await axios
        .get(`/designPackages/${data.id}`, { params: data.params })
        .then(res => {
            callback(res.data);
        });
};