import React from "react";

const ButtonGroup = props => (
  <div
    className={`uk-button-group uk-position-relative viz-property-action ${props.className}`}
  >
    {props.buttons &&
      props.buttons.map((button, key) => {
        return (
        <button
          key={key}
          className={`uk-button uk-button-default uk-text-capitalize ${button.className}`}
          {...button.atts}
          uk-tooltip={button.tooltip}
          onClick={button.onClick ? () => button.onClick() : null}
        >
          {button.icon && <span uk-icon={`icon:${button.icon}`} />}
          {button.label && <span>{button.label}</span>}
        </button>
        )})}
  </div>
);

export default ButtonGroup;
