import React from "react";
export const Loader = props => {
  return (
    <div className={`uk-text-center ${props.cssClasses}`} style={props.style}>
      <div
        className={`uk-inline uk-animation-fade uk-card viz-bg-white uk-card-body uk-border-rounded ${
          props.shadow ? "viz-box-shadow-special" : ""
        }`}
      >
        <div uk-spinner="ratio:1.5" />
      </div>
    </div>
  );
};
