import React, {useContext, useState} from "react";
import {StateContext} from "../../app/state";
import PageLayout from "../../components/Patricia/PageLayout/PageLayout";
import {Input} from "../../components/Input/Input";
import DatePicker from "react-datepicker";
import addDays from 'date-fns/addDays'
import format from 'date-fns/format'

import "react-datepicker/dist/react-datepicker.css";
import {Button} from "../../components/Button/Button";
import PatriciaProgressBar from "../../components/Patricia/RegistrationProgressBar/PatriciaProgressBar";
import Lang from "../../localization/patricia-he-il";
import {patriciaAiUpdateOrder} from "../../app/actions";
import ScrollableShoppingCart from "../../components/Patricia/ScrollableShoppingCart/ScrollableShoppingCart";
import {
    MobileView, BrowserView,
    isMobile
} from "react-device-detect";

function ShippingPage(props){
    const context = useContext(StateContext);
    const [
        {
            localization: { patricia: lang },
            auth: { user },
            data: { shoppingCart },
            // shoppingCart
        },
        dispatch
    ] = context

    const shippingTime = {
        asap: "ASAP",
        chooseDate: "CHOSEN DATE"
    }
    const dir = user && user.locality === "he-il" ? "rtl" : "ltr";
    const [chosenShippingTime, setChosenShippingTime] = useState(null);
    const [shippingDate, setShippingDate] = useState(addDays(new Date(),30));
    const [loading, setLoading] = useState(false);
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [street, setStreet] = useState(null);
    const [number, setNumber] = useState(null);
    const [apartment, setApartment] = useState(null);
    const [floor, setFloor] = useState(null);
    const [city, setCity] = useState(null);
    const [phone, setPhone] = useState(null);
    const [notes, setNotes] = useState(null);
    const [isPhoneError, setIsPhoneError ] = useState(false);
    const marginSide = dir === 'rtl' ? 'left' : 'right';

    function validatePhoneNumber(){
        return phone.length <= 14 && phone.length >= 9 && phone.match("^[+]{0,1}[0-9]{1,3}[-\\s/0-9]{0,14}$")
    }
     function onPhoneNumberChange(e) {
         setPhone(e.target.value);
         if(isPhoneError){
             setIsPhoneError(false);
         }
     }
    function onNextClicked(e){
        e.preventDefault();
        if(!validatePhoneNumber()) {
            setIsPhoneError(true);
            return;
        }
        setLoading(true);
        const addressData = {
            firstName: firstName,
            lastName: lastName,
            street: street,
            number: number,
            floor: floor,
            apartment: apartment,
            city: city,
            phone: phone,
            notes: notes
        }

        let formData = new FormData();
        formData.append('address', JSON.stringify(addressData));
        formData.append('shipping_time', chosenShippingTime);
        if (shippingDate){
            formData.append('shipping_chosen_date', format(new Date(shippingDate), 'yyyy/MM/dd'));
        }
        patriciaAiUpdateOrder({id: shoppingCart.id, payload: formData}, (res) => {
            window.analytics.track("[checkout] 1st step completed", {
                location: 'checkout/details'
            });
            dispatch({
                type: "updateData",
                newData: {shoppingCart: res.data}
            })
            setLoading(false);
        }, (error) => {
            window.analytics.track("[checkout] 1st step failed", {
                location: 'checkout/details'
            });
        }).then(() => {
            props.history.push('/checkout');
        } )

    }

    return (
       <PageLayout>
           <div className='uk-width-1-2@l uk-width-1-1@s  uk-margin-auto patricia-hebrew-font uk-margin-small-top uk-text-center'>
               <h1 className="uk-padding-small patricia-hebrew-header-font">{lang.shipping.header}</h1>
               {!isMobile && <PatriciaProgressBar current={1} headrs={Lang.checkoutProgressBar}/>}
           </div>
           {/*<h1 className="uk-text-center uk-padding-small patricia-hebrew-header-font">{lang.shipping.header}</h1>*/}

           {shoppingCart &&
           <div className='uk-flex uk-flex-row width-1-1'>
               <div className='uk-width-1-2@l uk-width-1-1@s uk-padding uk-padding-remove-top'>
                   <form onSubmit={onNextClicked} className='uk-padding'>
                       <div className='uk-flex'>
                           <Input name="first_name" label={`${lang.shipping.fields.firstName}*`} required={true} onChange={(e) => setFirstName(e.target.value)} cssClasses={`uk-margin-small-${marginSide} uk-width-1-2`}/>
                           <Input name="last_name" label={`${lang.shipping.fields.lastName}*`} required={true} onChange={(e) => setLastName(e.target.value)} cssClasses="uk-width-1-2 uk-margin-remove-top"/>
                       </div>
                       <div className='uk-flex'>
                           <Input name="street" label={`${lang.shipping.fields.streetAddress}*`} required={true} onChange={(e) => setStreet(e.target.value)} cssClasses={`uk-margin-small-${marginSide} uk-width-1-2`}/>
                           <span className='uk-width-1-2 uk-flex'>
                                <Input name="number" label={`${lang.shipping.fields.number}*`} type="number" required={true} onChange={(e) => setNumber(e.target.value)} cssClasses={`uk-margin-small-${marginSide} uk-width-1-3`}/>
                                <Input name="apartment" label={lang.shipping.fields.apartment} type="number" onChange={(e) => setApartment(e.target.value)} cssClasses={`uk-margin-remove-top uk-margin-small-${marginSide} uk-width-1-3 `}/>
                                <Input name="floor" label={lang.shipping.fields.floor} type="number" onChange={(e) => setFloor(e.target.value)} cssClasses="uk-margin-remove-top uk-width-1-3"/>
                           </span>
                       </div>
                       <div className='uk-flex'>
                           <Input name="city" label={`${lang.shipping.fields.city}*`} required={true} onChange={(e) => setCity(e.target.value)} cssClasses={`uk-margin-small-${marginSide} uk-width-1-2`}/>
                           <div className="uk-width-1-2 uk-margin-remove-top">
                               <Input name="phone" label={`${lang.shipping.fields.phone}*`} required={true} type="text" onChange={(e) => onPhoneNumberChange(e)} cssClasses="uk-width-1-1 uk-margin-remove-top"/>
                               {isPhoneError && <div className='uk-text-alert uk-text-small uk-margin-small-top'>{lang.shipping.errors.phone}</div>}
                           </div>
                       </div>
                       <div>
                           <label className='uk-text-meta uk-margin-small-bottom uk-display-block"'>{lang.shipping.fields.notes}</label>
                           <textarea className="uk-textarea uk-width-1-1 uk-margin-remove-top" name="note" rows="2" label={lang.shipping.fields.notes} onChange={(e) => setNotes(e.target.value)}></textarea>
                       </div>
                       <hr/>
                       <h3>{lang.shipping.fields.deliveryMethod} <span className='patricia-ai-text-color'>({lang.shipping.fields.freeDeliveries})</span></h3>
                       <div className="uk-margin">
                           <label className='uk-display-block uk-margin-small-bottom'><input className={`uk-radio uk-margin-small-${marginSide}`} type="radio" name="shipping_time" value={shippingTime.asap}
                                 onChange={(e) => {
                                setChosenShippingTime(e.target.value)
                                 window.analytics.track("[checkout] regular shipment checked", {
                                     location: 'checkout/details'
                                 });
                           }}
                            required/>{lang.shipping.fields.upTo21Days}</label>
                           <label className={`uk-margin-small-${marginSide}`}><input className={`uk-radio uk-margin-small-${marginSide}`} type="radio" name="shipping_time" value={shippingTime.chooseDate}
                                  onChange={(e) => {
                                      setChosenShippingTime(e.target.value);
                                      window.analytics.track("[checkout] custom shipment checked", {
                                          location: 'custom shipment chose'
                                      });
                                  }}/>{lang.shipping.fields.laterDate}</label>
                           {chosenShippingTime === shippingTime.chooseDate && <DatePicker selected={shippingDate} onChange={date => setShippingDate(date)} dateFormat="yyyy/MM/dd" minDate={addDays(new Date(),30)} />}
                       </div>
                       <Button
                           label={lang.shipping.actions.next}
                           type="primary"
                           cssClasses={`uk-margin-small-top patricia-ai-color ${dir === 'rtl' ? 'uk-align-left' :'uk-align-right' }`}
                           loading={loading}
                       />
                   </form>
               </div>
               <hr className="uk-divider-vertical uk-height-large uk-align-center"/>
               {!isMobile && <div className='uk-width-1-2 uk-padding uk-padding-remove-top'>
                   <ScrollableShoppingCart shoppingCart={shoppingCart} dir={dir} lang={lang}/>
               </div>}
           </div>
           }
       </PageLayout>
    )
}
export default ShippingPage;