import React from "react";
import ProductCard from "./ProductCard";

function DesignPackageProductsModal(props) {
    const DesignPackageProductsModalId = 'order-details-id';
    return (
        <div dir={props.dir} id={DesignPackageProductsModalId} uk-modal="bg-close: true" className='patricia-hebrew-font uk-modal-container'>
            {props.designPackage &&
            <div
                className="uk-modal-dialog uk-modal-body uk-padding-large"
                dir={props.dir}
            >
                    <button
                        className="uk-modal-close-default"
                        type="button"
                        uk-close=""
                    />
                <h2 className="uk-modal-title">{`${props.lang.title} ${props.designPackage.name}`}</h2>
                <div className='uk-width-1-1'>
                    <div className='uk-flex uk-flex-center uk-child-width-1-3@l uk-child-width-1-2@m' uk-grid="">
                        {props.designPackage.products.map((product) => {
                            return <ProductCard product={product}/>
                        })}
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default  DesignPackageProductsModal;