import React from 'react';
import {Logo} from "../../Logo/Logo";
import heLang from "../../../localization/patricia-he-il";
import enLang from "../../../localization/patricia-en-us";

function PatriciaTopNav(props){
    const Lang = props.dir === 'ltr' ? enLang : heLang;
    const lang = Lang.topNav;

    function onBackToPatriciaClicked() {
        window.analytics.track('[uploadpdf] "back" clicked', {
            location: window.location.pathname.replace("/", "")
        });
    }
    return (
        <nav className="uk-navbar-container uk-background-muted uk-margin-small uk-margin-small-top" uk-navbar="" dir='rtl'>
            <div className="uk-navbar-right">
                <div className={`uk-display-block ${props.dir === "ltr" ? "uk-navbar-right uk-margin-small-right" : "uk-navbar-left uk-margin-small-left"}`}>
                    <div className='uk-display-block uk-text-bold uk-margin-small-right'>{props.user && props.user.name}</div>
                    {props.onLogout ?
                    <a className='patricia-ai-text-color' href="#" onClick={props.onLogout}>
                        <span uk-icon="icon:sign-out" className="uk-margin-small-right patricia-ai-text-color"/>
                        <span>{props.logoutText}</span>
                    </a>
                        :
                    <a className='patricia-ai-text-color uk-text-small' onClick={onBackToPatriciaClicked} href='https://www.patricia-ai.com/' style={{textDecoration: "underline"}}><span uk-icon="chevron-right"></span>{lang.backToPatricia}</a>}
                </div>
            </div>
            <div className="uk-navbar-item">
                <Logo src={props.logo} width="100px" height="70px" />
            </div>
        </nav>
    );
}

export default PatriciaTopNav;