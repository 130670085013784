import React, {useState, useContext} from 'react';
import {Logo} from "../Logo/Logo";
import {StateContext} from "../../app/state";
import { withRouter } from "react-router-dom";
import {Checkbox} from "../Checkbox/Checkbox";

function DesignStyleCard(props){
    const context = useContext(StateContext);
    const [
        {
            localization: {designStyles: lang },
            auth: { user }
        }
    ] = context;
    const updateId = "update-design-style";
    const deleteId = "delete-design-style";

    function onDesignStyleClicked(id) {
        props.history.push(`/designStyles/${id}`);
    }

    const isMody = process.env.REACT_APP_HOST === "MODY";

    return (
        <div key={props.designStyle.id}>
            <div
                className="uk-card uk-card-default uk-height-medium uk-card-body uk-flex uk-flex-column uk-flex-between viz-actions-container"
                style={{ cursor: "pointer" }}
            >
                <ul className="uk-iconnav uk-position-top-right uk-position-small viz-actions">
                    <li>
                        <a
                            onClick={props.onDesignStyleActionClicked}
                            uk-icon="icon:pencil"
                            uk-tooltip="Edit Design Style"
                            uk-toggle={`target:#${updateId}`}
                        ></a>
                    </li>
                    <li>
                        <a
                            onClick={props.onDesignStyleActionClicked}
                            uk-icon="icon:trash"
                            uk-tooltip="Delete Design Style"
                            uk-toggle={`target:#${deleteId}`}
                        ></a>
                    </li>
                </ul>
                {isMody &&
                <ul className="uk-iconnav uk-position-top-left uk-position-small">
                    <li>
                        <Checkbox
                            name="select-default-design-style"
                            value={props.designStyle.isDefault}
                            onChange={(e) => props.onChangeIsDefault(e, props.designStyle)}
                        />
                    </li>
                </ul>}
                <div onClick={() => onDesignStyleClicked(props.designStyle.id)} className='uk-flex uk-flex-column uk-flex-between'>
                    <div
                        className="uk-align-center uk-width-auto"
                        style={{ marginTop: "0", marginBottom: "0" }}
                    >
                        {props.designStyle.photo && (
                            <Logo
                                src={props.designStyle.photo.thumbUrl}
                                type="circle"
                                width="70px"
                                cssClasses="uk-margin-remove"
                            />
                        )}
                        {!props.designStyle.photo && <span uk-icon="icon: image; ratio: 4" />}
                    </div>
                    <div className="uk-text-center uk-margin-small-top">
                        <h5 className="uk-text-bold">{props.designStyle.name}</h5>
                        <div className="uk-text-meta">{props.designStyle.point_value ? `point value: ${props.designStyle.point_value}` : "missing point value"}</div>
                    </div>
                    <div>
                        <hr />
                        <p
                            className="uk-text-center"
                            style={{ cursor: "pointer" }}
                        >
                            {props.designStyle.products ? props.designStyle.products.length : 0}
                            <br />
                            <span className="uk-text-muted uk-text-small">
                                products
                              </span>
                        </p>
                    </div>
            </div>
        </div>
    </div>
    )

}

export default withRouter(DesignStyleCard);
