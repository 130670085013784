import React, { createContext, useContext, useReducer } from "react";
import { ConsumerListener } from "./listeners";
import { getProperty } from "./actions";

export const StateContext = createContext();
export const StateProvider = ({ reducer, initialState, children }) => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    <ConsumerListener
      listenTo={"properties"}
      listenFor={"pending"}
      action={getProperty}
    />
    {children}
  </StateContext.Provider>
);
export const useStateValue = () => useContext(StateContext);
