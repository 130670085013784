import React from "react";
import {
    Route,
    Switch,
    Redirect, useLocation,
} from "react-router-dom";
import Login from "../pages/Login";
import ConsumerSearch from "../pages/ConsumerSearch";
import ProjectSearch from "../pages/ProjectSearch";
import UnitSearch from "../pages/UnitSearch";
import ConsumerPage from "../pages/ConsumerPage";
import ProjectsIndex from "../pages/ProjectsIndex";
import ProjectView from "../pages/ProjectView";
import { AuthRoute, LoginRoute, DualRoute } from "./RouteValidators";
import TeamView from "../pages/TeamView";
import PropertyPage from "../pages/PropertyPage";
import UserAccount from "../pages/UserAccount";
import ResetPassword from "../pages/ResetPassword";
import SetPassword from "../pages/SetPassword";
import DevelopersIndex from "../pages/DevelopersIndex";
import PropertyPagePublic from "../pages/PropertyPagePublic";
import PatriciaAiLogin from "../pages/PatriciaAi/PatriciaAiLogin";
import Register from "../pages/PatriciaAi/Register";
import PatriciaAccountSettings from "../pages/PatriciaAi/PatriciaAccountSettings";
import PatriciaPropertyPage from "../pages/PatriciaAi/PatriciaPropertyPage";
import ShoppingCart from "../pages/PatriciaAi/ShoppingCart";
import InspirationQuizPage from "../pages/InspirationQuizPage";
import DesignStylesIndex from "../pages/DesignStylesIndex";
import DesignStyleView from "../pages/DesignStyleView";
import CreateInspirationQuizPage from "../pages/CreateInspirationQuizPage";
import InspirationQuizView from "../pages/InspirationQuizView";
import CheckoutPage from "../pages/PatriciaAi/CheckoutPage";
import PatriciaUploadPDF from "../pages/PatriciaAi/PatriciaUploadPdf";
import UnderConstructionPage from "../pages/PatriciaAi/UnderConstructionPage";
import ShippingPage from "../pages/PatriciaAi/ShippingPage";
import TermsAndConditions from "../pages/PatriciaAi/TermsAndConditions";
import OrdersPage from "../pages/PatriciaAi/OrdersPage";
import WemLogin from "../pages/wem/WemLogin";
import WemConsumerPage from "../pages/wem/WemConsumerPage";
import WemPropertyPage from "../pages/wem/WemPropertyPage";

export const WemViews = ({params}) => {
    return (
        <Switch>
            <LoginRoute path="/login" exact><WemLogin {...params}/></LoginRoute>
            <AuthRoute path="/consumers/:consumerId" component={WemConsumerPage}/>
            <LoginRoute path="/register" exact><Register {...params}/></LoginRoute>
            <LoginRoute path="/reset-password" exact component={ResetPassword}/>
            <Route
                path="/"
                exact
                render={() => <Redirect to="/shoppingCart"/>}
            />
            <AuthRoute path="/account" component={PatriciaAccountSettings}/>
            <AuthRoute path="/properties/:propertyId" component={WemPropertyPage}/>
            <AuthRoute path="/propertyUnderConstruction" exact component={UnderConstructionPage}/>
            <AuthRoute path="/shoppingCart" exact component={ShoppingCart}/>
            <AuthRoute path="/shipping" exact component={ShippingPage}/>
            <AuthRoute path="/checkout" exact component={CheckoutPage}/>
            <AuthRoute path="/ordersHistory" exact component={OrdersPage}/>
            <Route path="/takeInspirationQuiz"><InspirationQuizPage {...params}/></Route>
        </Switch>
    )
}

export const PatriciaViews = ({params}) => {
      return (
          <Switch>
            <LoginRoute path="/login" exact><PatriciaAiLogin {...params}/></LoginRoute>
            <LoginRoute path="/register" exact><Register {...params}/></LoginRoute>
            <LoginRoute path="/reset-password" exact component={ResetPassword}/>
            <Route
                path="/"
                exact
                render={() => <Redirect to="/shoppingCart"/>}
            />
            <Route path="/uploadpdf" exact><PatriciaUploadPDF {...params}/></Route>
            <Route path="/termsAndConditions" exact component={TermsAndConditions}/>
            <AuthRoute path="/account" component={PatriciaAccountSettings}/>
            <AuthRoute path="/properties/:propertyId" exact component={PatriciaPropertyPage}/>
            <AuthRoute path="/propertyUnderConstruction" exact component={UnderConstructionPage}/>
            <AuthRoute path="/shoppingCart" exact component={ShoppingCart}/>
            <AuthRoute path="/shipping" exact component={ShippingPage}/>
            <AuthRoute path="/checkout" exact component={CheckoutPage}/>
            <AuthRoute path="/ordersHistory" exact component={OrdersPage}/>
            <Route path="/takeInspirationQuiz"><InspirationQuizPage {...params}/></Route>
          </Switch>
      )
}

export const RetailerViews = ({params}) => {
    return (
            <Switch>
              <LoginRoute path="/login" exact component={Login}/>
              <LoginRoute
                  path="/reset-password"
                  exact
                  component={ResetPassword}
              />
              <LoginRoute path="/set-password" exact component={SetPassword}/>
              <Route
                  path="/"
                  exact
                  render={() => <Redirect to="/consumers"/>}
              />
              <DualRoute
                  path="/properties/:propertyId"
                  exact
                  component={{
                    Authenticated: PropertyPage,
                    Public: PropertyPagePublic
                  }}
              />
              <AuthRoute path="/consumers" exact component={ConsumerSearch}/>
              <AuthRoute path="/projects" exact component={ConsumerSearch}/>
              <AuthRoute
                  path="/projects/:projectId"
                  exact
                  component={ConsumerSearch}
              />
              <AuthRoute
                  path="/consumers/:consumerId/projectCommons"
                  exact
                  component={ProjectSearch}
              />
              <AuthRoute
                  path="/consumers/:consumerId/projectCommons/:projectId/units"
                  exact
                  component={UnitSearch}
              />
              <AuthRoute
                  path="/consumers/:consumerId"
                  component={ConsumerPage}
              />
              <AuthRoute
                  path="/projectCommons"
                  exact
                  component={ProjectsIndex}
              />
              <AuthRoute
                  path="/projectCommons/:commonsId"
                  component={ProjectView}
              />
              <AuthRoute
                  path="/projectCommons/:commonsId/:unitId"
                  component={ProjectView}
              />
              <AuthRoute path="/developers" component={DevelopersIndex}/>
              <AuthRoute path="/retailers/:retailerId" component={TeamView}/>
              <AuthRoute path="/account" component={UserAccount}/>
              <AuthRoute path="/designStyles" exact component={DesignStylesIndex} />
              <AuthRoute path="/designStyles/:designStyleId" exact component={DesignStyleView} />
              <AuthRoute path="/inspirationQuizzes" exact component={CreateInspirationQuizPage} />
              <AuthRoute path="/inspirationQuizzes/:inspirationQuizId" exact component={InspirationQuizView} />
              <Route path="/takeInspirationQuiz"><InspirationQuizPage {...params}/></Route>
            </Switch>
        )
}