import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { StateContext } from "../app/state";
import SellerLayout from "../layouts/SellerLayout";
import Iframe from "react-iframe";
import { Loader } from "../components/Loader/Loader";

class ProjectView extends Component {
  static contextType = StateContext;

  componentDidMount() {
    window.addEventListener("message", this.handleMessage, false);
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.handleMessage);
  }

  handleMessage = ({ data }) => {
    const [{ auth }] = this.context;
    switch (data.action) {
      case "goToProperty":
        window.location.href = `/properties/${data.id}?relation[]=consumer&relation[]=project&relation[]=media&retailer=${auth.user.seller.retailerId}`;
        break;
      case "goToConsumer":
        window.location.href = `/consumers/${data.id}?relation[]=properties&relation[]=user&retailer=${auth.user.seller.retailerId}`;
        break;
      default:
        break;
    }
  };
  render() {
    const [{ auth }] = this.context;
    const { commonsId, unitId } = this.props.match.params;
    if (!commonsId || !auth.user)
      return (
        <SellerLayout>
          <div className="uk-section uk-position-relative">
            <div
              className="uk-position-cover uk-flex uk-flex-middle uk-flex-center"
              style={{ zIndex: 100000 }}
            >
              <Loader shadow={true} />
            </div>
          </div>
        </SellerLayout>
      );
    return (
      <SellerLayout>
        <Iframe
          url={`${process.env.REACT_APP_NEW_APP}/projectCommons?id=${unitId ||
            commonsId}&locale=${auth.user.locality.split("-")[0]}&auth=${
            auth.token
          }&user=${auth.user.id}&retailer=${auth.user.seller.retailerId}${
            process.env.REACT_APP_ENV === "STAGING"
              ? `&endpoint=${process.env.REACT_APP_APP_ENDPOINT}`
              : ""
          }`}
          width="100%"
          height="100%"
          overflow="auto"
        />
      </SellerLayout>
    );
  }
}

export default withRouter(ProjectView);
