import React from "react";
import { Alert } from "../Alert/Alert";

const noop = () => {};
/**
 *
 * @param {
 * name: string;
 * cssClasses?: string;
 * onChange?: () => {};
 * onBlur?: () => {};
 * onFocus?: () => {};
 * placeholder?: string;
 * type?: "text" | "number" | "tel" | "email" | "password";
 * status?: "danger" | "success";
 * disabled?: boolean;
 * size?: "large" | "small";
 * error?: string;
 * value?: string;
 * tooltip?: string;
 * label?: string;
 * icon?: string
 * required?: boolean;
 * } props
 */
export const Input = props => {
  const {
    unit,
    id,
    cssClasses,
    onChange,
    onBlur,
    onFocus,
    placeholder,
    type = "text",
    name,
    status,
    disabled = false,
    size,
    error,
    required,
    value,
    tooltip,
    label,
    icon,
    atts,
    isPatricia
  } = props;
  return (
    <div className={`uk-margin  ${cssClasses}`}>
      {label && (
        <label className="uk-text-meta uk-text-uppercase uk-margin-small-bottom uk-display-block">
          {label}
        </label>
      )}
      <div className="uk-inline uk-width-1-1">
        {unit && (
          <span className="uk-text-meta uk-position-center-right uk-margin-small-right">
            {unit}
          </span>
        )}
        {(tooltip || icon) && (
          <a
            className="uk-form-icon uk-form-icon-flip"
            uk-tooltip={tooltip}
            onClick={e => {
              e.preventDefault();
            }}
            href="#"
            uk-icon={`icon: ${icon || "info"}`}
          />
        )}
        <input
          id={id}
          className={`uk-input ${status ? `uk-form-${status}` : ""} ${
            size ? `uk-form-${size}` : ""
          } ${isPatricia? "patricia-ai-input" : ""}`}
          type={type}
          name={name}
          defaultValue={value}
          placeholder={placeholder}
          disabled={disabled}
          onBlur={!disabled ? onBlur : noop}
          onChange={!disabled ? onChange : noop}
          onFocus={!disabled ? onFocus : noop}
          required={required}
          {...atts}
        />
      </div>
      {error && (
        <Alert
          message={error}
          type="danger"
          cssClasses="uk-margin-small-top"
          // close={true}
        />
      )}
    </div>
  );
};
