import React from "react";
import { Input } from "../Input/Input";
import { Alert } from "../Alert/Alert";
import { Result } from "../Result/Result";
import { Loader } from "../Loader/Loader";

class ProductSearch extends React.Component {
  state = {
    searchTerm: "",
    typeTimeout: 0
  };

  handleChange = e => {
    if (this.state.typeTimeout) {
      clearTimeout(this.state.typeTimeout);
    }
    const term = e.target.value;
    if (!term) return;
    this.setState({
      typeTimeout: setTimeout(() => {
        this.props.onChange(term);
        this.setState({
          searchTerm: term
        });
      }, 1000)
    });
  };

  render() {
    const {
      lang,
      results,
      searching,
      onClick,
      delayed = true,
      onChange
    } = this.props;
    const { searchTerm } = this.state;
    return (
      <div
        className="uk-position-relative"
        uk-toggle="target: #results-dropdown"
      >
        <div className="uk-position-relative">
          <Input
            name="search"
            icon="search"
            placeholder={lang.placeholder}
            onChange={
              delayed ? this.handleChange : e => onChange(e.target.value)
            }
            cssClasses="uk-margin-remove"
          />
          {searching && (
            <div className="uk-position-center-right uk-margin-medium-right">
              <div uk-spinner="" />
            </div>
          )}
        </div>
        <div id="results-dropdown" hidden>
          {results && results.length > 0 && (
            <div
              className="viz-bg-white viz-border-1 uk-border-rounded viz-box-shadow-special"
              style={{
                width: "100%",
                position: "absolute",
                top: "150%",
                left: 0,
                zIndex: "10000"
              }}
            >
              <ul className="uk-list uk-list-divider uk-height-max-small uk-overflow-auto">
                {results.map((r, key) => (
                  <li
                    key={key}
                    className="uk-padding-small"
                    style={{ cursor: "pointer" }}
                    onClick={() => onClick(r)}
                  >
                    <p className="uk-margin-remove">
                      {r.productName},{" "}
                      <span className="uk-text-meta uk-text-bold">
                        {r.item.category}
                      </span>
                    </p>
                    <div
                      uk-drop="mode: hover; pos:top-center"
                      className="viz-bg-white uk-box-shadow-small uk-width-auto"
                    >
                      <img
                        src={r.item.thumbnail.thumbUrl}
                        alt={r.productName}
                        className="uk-height-max-small"
                      />
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ProductSearch;
