import React from "react";
import { Logo } from "../Logo/Logo";

export const Navbar = props => {
  const { logo, lang, onClick, dir, user, onLogout } = props;
  return (
    <nav className="uk-navbar-container uk-background-muted" uk-navbar="">
      <div
        className={`yk-background-muted ${
          dir === "ltr" ? "uk-navbar-left" : "uk-navbar-right"
        }`}
      >
        <div className="uk-navbar-item">
          <Logo src={logo} width="100px" height="70px" />
        </div>
      </div>
      <div
        className={
          dir === "ltr"
            ? "uk-navbar-right uk-margin-small-right"
            : "uk-navbar-left uk-margin-small-left"
        }
      >
        <a
          onClick={onClick}
          className="uk-button uk-button-danger uk-border-rounded"
        >
          {lang.top.findConsumer}
        </a>
        <a className="uk-button uk-margin-small-left uk-border-rounded uk-padding-small">
          {user && (
            <div uk-grid="" className="uk-grid-small uk-flex-middle">
              <div className="uk-width-auto">
                {user.profilePicture && user.profilePicture.thumbUrl && (
                  <div
                    className="uk-background-cover uk-border-circle"
                    uk-img=""
                    data-src={user.profilePicture.thumbUrl}
                    style={{ width: "30px", height: "30px" }}
                  />
                )}
                {(!user.profilePicture || !user.profilePicture.thumbUrl) && (
                  <div>
                    <span uk-icon="icon:user" />
                  </div>
                )}
              </div>
              <div className="uk-width-expand">
                <div>
                  <span>{user.name}</span>
                </div>
              </div>
              <div className="uk-width-auto">
                <div>
                  <span uk-icon="icon: chevron-down" />
                </div>
              </div>
            </div>
          )}
          {!user && <div uk-spinner="" />}
        </a>
        <div uk-dropdown="mode:click">
          <ul
            className="uk-nav uk-dropdown-nav uk-nav-parent-icon"
            uk-nav="multiple: true"
          >
            <li>
              <a href={user && `/account`}>
                <span className="uk-margin-small-right" uk-icon="icon:user" />
                <span>{lang.top.account}</span>
              </a>
            </li>
            <li>
              <a
                href={
                  user &&
                  `/retailers/${user.seller.retailerId}?relation[]=sellers&relation[]=locations&empCount&sellerLocation`
                }
              >
                <span className="uk-margin-small-right" uk-icon="icon:users" />
                <span>{lang.top.organization}</span>
              </a>
            </li>
            <li>
              <a href="#" onClick={onLogout}>
                <span
                  uk-icon="icon:sign-out"
                  className="uk-margin-small-right"
                />
                <span>{lang.top.logout}</span>
              </a>
            </li>
            <li className="uk-nav-divider uk-margin-top"></li>
            <li className="uk-nav-header">{lang.top.downloads}</li>
            {user && user.level >= 3 && (
              <li>
                <a href={process.env.REACT_APP_EDITOR_CLIENT}>
                  <span
                    uk-icon="icon:download"
                    className="uk-margin-small-right"
                  />
                  <span>{lang.top.editor}</span>
                </a>
              </li>
            )}
            {user && user.level > 0 && (
            <li>
              <a href={process.env.REACT_APP_RETAIL_CLIENT}>
                <span
                  uk-icon="icon:download"
                  className="uk-margin-small-right"
                />
                <span>{lang.top.retail}</span>
              </a>
            </li>)}
          </ul>
        </div>
      </div>
    </nav>
  );
};
