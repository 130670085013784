import React from "react";
import { withRouter, Link } from "react-router-dom";
import { Input } from "../components/Input/Input";
import { Button } from "../components/Button/Button";
import { StateContext } from "../app/state";
import { requestResetPassword, notification } from "../app/actions";

class ResetPassword extends React.Component {
  static contextType = StateContext;
  state = {
    sending: false
  };
  handleRequestResetPassword = e => {
    e.preventDefault();
    this.setState({
      sending: true
    });
    const formData = new FormData(e.target);
    const [
      {
        localization: {
          messages: { resetSent }
        }
      }
    ] = this.context;
    requestResetPassword({ payload: formData }, ({ data }) => {
      notification(
        `${data.name ? `${data.name}, ` : ""}${resetSent}`,
        "success"
      );
    }).then(() => {
      this.setState({
        sending: false
      });
    });
  };
  render() {
    const [
      {
        localization: { fields, actions, resetPassword }
      }
    ] = this.context;
    return (
      <div>
        <div
          className="uk-padding uk-flex uk-flex-column uk-flex-center uk-flex-middle"
          style={{ minHeight: "80vh" }}
        >
          <div className="viz-box-shadow-special uk-padding uk-animation-fade viz-bg-white uk-width-large">
            <div className="uk-animation-slide-bottom-small">
              <div>
                <h4 className="uk-text-bold">{resetPassword.title}</h4>
                <p>{resetPassword.body}</p>
              </div>
              <form
                className="uk-width-large uk-width-expand@m"
                id="login-form"
                onSubmit={this.handleRequestResetPassword}
              >
                <Input
                  atts={{
                    autoFocus: true
                  }}
                  name="email"
                  placeholder={fields.email.placeholder}
                  label={fields.email.label}
                  required={true}
                />
                <Button
                  label={actions.reset.label}
                  type="primary"
                  cssClasses="uk-width-1-1 uk-margin-small-top"
                  loading={this.state.sending}
                />
              </form>
            </div>
          </div>
          <div className="uk-text-center uk-padding">
            <Link to="/login" className="uk-text-muted">
              {actions.login.label}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ResetPassword);
