import React from "react";
import {
  BrowserRouter as Router,
  useLocation
} from "react-router-dom";
import { StateProvider } from "./app/state";
import state from "./app/initialState";
import UrlFilter from "./app/UrlFilter";
import { checkAuthCookie } from "./app/actions";
import * as qs from "qs";
import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/regular'
import '@fortawesome/fontawesome-free/js/brands'
import {PatriciaViews, RetailerViews, WemViews} from "./app/Views";

const App = () => {
  const initialState = state;
  if (checkAuthCookie()) initialState.auth.isAuthenticated = true;
  const reducer = (state, action) => {
    switch (action.type) {
      case "updateUrl":
        return {
          ...state,
          url: action.newUrl
        };
      case "updateData":
        return {
          ...state,
          data: action.newData
        };
      case "updateTasks":
        return {
          ...state,
          tasks: action.tasks
        };
      case "updateActive":
        return {
          ...state,
          active: action.newActive
        };
      case "setAuth":
        return {
          ...state,
          auth: action.newAuth
        };
      case "updateComponentData":
        return {
          ...state,
          componentData: action.newComponentData
        };
      case "setLang":
        return {
          ...state,
          localization: action.lang
        };
      //for patricia
      case "setProperty":
        return {
          ...state,
          propertyId: action.data
        };
      case "setShoppingCart":
        return {
          ...state,
          shoppingCart: action.data
        };


      default:
        return state;
    }
  };

  const location = useLocation();
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });
  if (!params.locale) params.locale = "en";

  function getRoutes(){
      switch (process.env.REACT_APP_HOST) {
          case "WEM":
              return <WemViews params={params}/>;
          case "PATRICIAAI":
              return <PatriciaViews params={params}/>
          default:
              return <RetailerViews params={params}/>
      }
  }

  return (
    <div>
      <StateProvider initialState={initialState} reducer={reducer}>
        <Router>
          <UrlFilter>
            {getRoutes()}
          </UrlFilter>
        </Router>
      </StateProvider>
    </div>
  );
};

export default App;
