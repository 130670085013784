import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { StateContext } from "../app/state";
import { Loader } from "../components/Loader/Loader";
import Search from "../components/Search/Search";
import SellerLayout from "../layouts/SellerLayout";
import {LocalSearch, ProjectCommonSearch} from "../app/utilities";
import { Card } from "../components/Card/Card";
import { Button } from "../components/Button/Button";
import ProjectForm from "../components/ProjectForm/ProjectForm";
import QueryString, { parse } from "query-string";
import {importProjectsFromFile, indexProjectCommons} from "../app/actions";
import UiKit from "uikit";
import {Alert} from "../components/Alert/Alert";
import ReactPaginate from "react-paginate";

class ProjectsIndex extends Component {
  static contextType = StateContext;
  state = {
    developerId:
      parse(this.props.location.search) &&
      parse(this.props.location.search).developer
        ? parse(this.props.location.search).developer
        : null,
    file: null,
    error : false
  };
  // handleChange = term => {
  //   const [
  //     {
  //       data: { projectCommons: projects }
  //     }
  //   ] = this.context;
  //   this.setState({
  //     searchTerm: term,
  //     filteredProjects: ProjectCommonSearch(term, projects)
  //   });
  // };
  handleChange = term => {
    const [{ componentData, auth }, dispatch] = this.context;
      componentData.searchTerm = term;
      dispatch({
        type: "updateComponentData",
        newComponentData: componentData
      });
      const query = {};
      query.name = term;
      query.retailer = auth.user.seller.retailerId;
      query.paginate = 20;
      this.props.history.push({
        search: QueryString.stringify(query),
      });
  };

  handleClear = () => {
    this.props.history.push(`/projectCommons?paginate=30`);
  }

  clearFilters = () => {
    const [{ componentData }, dispatch] = this.context;
    dispatch({
      type: "updateComponentData",
      newComponentData: { ...componentData, loading: true }
    });
    this.setState({
      developerId: null
    });
    this.props.history.push(`/projectCommons?paginate=30`);
  };
  handleUploadProjectsFromFile = () => {
    if (!this.state.file) {
      this.setState({
        error: true
      });
      return;
    }
    const [{ auth }] = this.context;
    const formData = new FormData();
    formData.append("file", this.state.file, this.state.file.name);
    formData.append("email", auth.user.email);
    importProjectsFromFile(
        { payload: formData},
        (res, error) => {
          UiKit.modal("#import-from-file").hide();
          this.props.history.push(`/projectCommons`);
        }
    )
  };

  handlePageClick = (data) => {
    this.props.history.push(`/projectCommons?paginate=30&&page=${data.selected + 1}`)
    // const [dispatch] = this.context;
    // indexProjectCommons({ params: { page: data.selected } }, res => {
    //   dispatch({
    //     type: "updateData",
    //     newData: {projectCommons: res.data}
    //   });
    // });
  }




  render() {
    const [
      {
        localization: { projects: lang },
        data: {
          projectCommons: projects,
          projectCommons_links: projectsLinks,
          projectCommons_meta: projectsMeta
        },
        componentData: { loading, searching},
        auth: { user }
      }
    ] = this.context;
    const { filteredProjects } = this.state;
    const handleSelect = commons => {
      this.props.history.push(`/projectCommons/${commons.id}`);
    };
    return (
      <SellerLayout>
        <div className="uk-section uk-position-relative">
          {(loading || !projects) && (
            <div
              className="uk-position-cover uk-flex uk-flex-middle uk-flex-center"
              style={{ zIndex: 100000 }}
            >
              <Loader shadow={true} />
            </div>
          )}
          <div
            className="uk-container"
            style={{ opacity: loading ? ".2" : "1" }}
          >
            <div>
              <div uk-grid="">
                <div className="uk-width-expand">
                  <div>
                    <h2 className="uk-animation-fade uk-text-bold uk-margin-medium">
                      {lang.index.all}
                      {this.state.developerId && (
                        <Button
                          label={projects && projects[0].project.developer.name}
                          cssClasses="uk-margin-small-left"
                          icon="close"
                          onClick={() => this.clearFilters()}
                        ></Button>
                      )}
                      <span className="uk-text-meta uk-margin-left">
                          ({projects && projects.length})
                      </span>
                    </h2>
                  </div>
                </div>
                <div className="uk-width-auto">
                  <div>
                    {user && user.level >= 3 && (
                      <Button
                        cssClasses="uk-margin-small-right uk-margin-small-left"
                        label={lang.index.create}
                        type={user.level >= 3 ? "default" : "disabled"}
                        atts={{ "uk-toggle": "target: #create-project" }}
                      />
                    )}
                    {user && user.level >= 90 && (
                        <Button
                            label={lang.index.uploadFile}
                            type={user.level >= 3 ? "default" : "disabled"}
                            atts={{ "uk-toggle": "target: #import-from-file" }}
                            cssClasses="uk-margin-small-right uk-margin-small-left"
                        />
                    )}
                  </div>
                </div>
              </div>
              <Search
                lang={lang.search}
                onChange={this.handleChange}
                onClearSearch={this.handleClear}
                //searching={searching}
                //results={projects}
              />
            </div>
            <div className="uk-margin-top">
              <div
                className="uk-child-width-1-2@m uk-child-width-1-1@s"
                uk-grid=""
              >
                {projects &&
                  (filteredProjects || projects).map(p => {
                    const tags = [];
                    if (!p.project.hasUnits) tags.push(lang.index.tags.noUnits);
                    if (!p.hasMatrix) tags.push(lang.index.tags.noMatrix);
                    return (
                      <Card
                        key={p.id}
                        title={p.project.name}
                        content={`${lang.developer}: ${p.project.developer.name}`}
                        dir={user && user.locality === "he-il" ? "dir-rtl" : ""}
                        image={p.project.cover && p.project.cover.thumbUrl}
                        actionLabel={lang.results.action}
                        onClick={() => handleSelect(p)}
                        tags={tags}
                      />
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <div id="create-project" uk-modal="bg-close:false">
          <div className="uk-modal-dialog uk-modal-body uk-width-large">
            <button
              className="uk-modal-close-default"
              type="button"
              uk-close=""
            />
            <ProjectForm lang={lang.create} />
          </div>
        </div>
        <div id="import-from-file" uk-modal="bg-close:false">
          <div className="uk-modal-dialog uk-modal-body uk-width-large">
            <button
                className="uk-modal-close-default"
                type="button"
                uk-close=""
            />
            <div uk-form-custom="target: true">
              <input
                  name="floorplan"
                  type="file"
                  accept=".xls, .xlsx"
                  required
                  onChange={e => {
                    if (e.target.value) {
                      this.setState({
                        file: e.target.files[0]
                      });
                    }
                    else {
                      this.setState({
                        file: null
                      });
                    }
                  }}
              />
              <input
                  className="uk-input"
                  type="text"
                  placeholder={"browse"}
                  disabled
                  required
              />
            </div>
            <Button
                label={"upload"}
                onClick={() => this.handleUploadProjectsFromFile()}
                cssClasses="uk-margin-small-left"
            />
            {this.state.error && (
                <Alert
                    message="please choose file to upload"
                    type="danger"
                    cssClasses="uk-margin-small-top"
                    // close={true}
                />)}
          </div>
        </div>
        {projectsMeta && <ReactPaginate
            pageCount={projectsMeta.last_page}
            initialPage={projectsMeta.current_page - 1}
            pageRangeDisplayed={4}
            marginPagesDisplayed={2}
            previousLabel="&#x276E;"
            nextLabel="&#x276F;"
            containerClassName="uk-pagination uk-flex-center"
            activeClassName="uk-active"
            disabledClassName="uk-disabled"
            onPageChange={this.handlePageClick}
            disableInitialCallback={true}
        />}
      </SellerLayout>
    );
  }
}

export default withRouter(ProjectsIndex);
