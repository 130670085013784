import React from "react";
import { useStateValue } from "../../app/state";
import { Link } from "react-router-dom";

export const SideNav = props => {
  const [
    {
      auth: { user },
      active: { projectCommon, developer, designStyle, inspirationQuiz },
      localization: { nav: lang }
    }
  ] = useStateValue();
  const {
    match: { params }
  } = props;
  return (
    <div className="uk-padding-small">
      <ul className="uk-nav uk-nav-default">
        <li>
          <Link to="/consumers">
            <span uk-icon="icon:users" />
            <span className="uk-margin-small-left">{lang.side.consumers}</span>
          </Link>
        </li>
        {user && user.level > 2 && (
          <React.Fragment>
            <li>
              <Link to="/projectCommons?paginate=30">
                <span uk-icon="icon:thumbnails" />
                <span className="uk-margin-small-left">
                  {lang.side.projects}
                </span>
              </Link>
              {params.commonsId && projectCommon && (
                <ul className="uk-nav-sub">
                  <li>
                    <ul>
                      <li
                        className="uk-text-truncate uk-active"
                        title={projectCommon.project.name}
                      >
                        <Link to={`/projectCommons/${params.commonsId}`}>
                          {projectCommon.project.name}
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              )}
            </li>
            <li>
              <Link to="/developers">
                <span uk-icon="icon:nut" />
                <span className="uk-margin-small-left">
                  {lang.side.developers}
                </span>
              </Link>
              {params.developerId && developer && (
                <ul className="uk-nav-sub">
                  <li>
                    <ul>
                      <li
                        className="uk-text-truncate uk-active"
                        title={developer.name}
                      >
                        <Link to={`/developers/${params.commonsId}`}>
                          {developer.name}
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              )}
            </li>
            {user && user.level > 99  && (
            <li>
              <Link to={`/designStyles?retailer=${process.env.REACT_APP_RETAILER}`}>
                <span uk-icon="icon:image" />
                <span className="uk-margin-small-left">
                  {lang.side.designStyles}
                </span>
              </Link>
              {params.designStyleId && designStyle && (
                  <ul className="uk-nav-sub">
                    <li>
                      <ul>
                        <li
                            className="uk-text-truncate uk-active"
                            title={designStyle.name}
                        >
                          <Link to={`/designStyles/${params.designStyleId}`}>
                            {designStyle.name}
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
              )}
            </li>)}
            {user && user.level > 99  && (
              <li>
              <Link to="/inspirationQuizzes">
              <span uk-icon="icon:question" />
              <span className="uk-margin-small-left">
              {lang.side.inspirationQuizzes}
              </span>
              </Link>
              {params.inspirationQuizId && inspirationQuiz && (
                  <ul className="uk-nav-sub">
                    <li>
                      <ul>
                        <li
                            className="uk-text-truncate uk-active"
                            title={inspirationQuiz.name}
                        >
                          <Link to={`/inspirationQuizzes/${params.inspirationQuizId}`}>
                            {inspirationQuiz.name}
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
              )}
              </li>)}
          </React.Fragment>
        )}
      </ul>
    </div>
  );
};
