import React, {useRef, useEffect, useContext} from "react";
import {
  MobileView,
  isMobile
} from "react-device-detect";
import {StateContext} from "../../app/state";

const Tour = props => {
  const iframeRef = useRef();
  const context = useContext(StateContext);
  const [{ auth }] = context;

  useEffect(() => {
    if (iframeRef.current) {
      if (!isMobile) {
        iframeRef.current.style.height =
            (iframeRef.current.offsetWidth / 16) * 9 + "px";
      } else {
        iframeRef.current.style.height =
            (iframeRef.current.offsetWidth / 16) * 24 + "px";
      }
    }
  }, [iframeRef.current]);

  const link = `${process.env.REACT_APP_NEW_APP}/player?id=${
    props.tourId
  }&locale=${props.lang}&host=${window.location.hostname}${
      process.env.REACT_APP_ENV === "STAGING"
          ? `&endpoint=${process.env.REACT_APP_APP_ENDPOINT}`
          : ""
  }${process.env.REACT_APP_HOST === 'WEM' ? "&isWem=true" : ""
  }${process.env.REACT_APP_ENV === "PATRICIA" ? `&auth=${props.auth}&order=${props.orderId}&user=${auth.user.id}` : ""}` ;
  //}&order=${props.orderId}&auth=${props.auth}&user=${auth.user.id}` ;
  useEffect(() => {
    props.passLink(link);
  }, [link]);

  return (
    <iframe
      title="player"
      src={link}
      width="100%"
      scrolling="no"
      ref={iframeRef}
      allowFullScreen
    />
  );
};

export default Tour;
