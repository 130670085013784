import React from "react";
import { StateContext } from "../../app/state";
import { Alert } from "../Alert/Alert";

class ErrorBoundary extends React.Component {
  static contextType = StateContext;
  state = {
    hasError: false
  };

  componentDidCatch(error, info) {
    this.setState({
      hasError: true
    });
  }

  render() {
    const [
      {
        localization: { errorBoundary: lang }
      }
    ] = this.context;
    if (this.state.hasError) {
      return (
        <div>
          <Alert
            title={lang.title}
            message={lang.message}
            cssClasses="uk-padding"
            action={{
              label: lang.action,
              icon: "refresh",
              onClick: () => {
                window.location.reload();
              }
            }}
          ></Alert>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
