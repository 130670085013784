import React, {useContext, useEffect, useState} from "react";
import {Link, withRouter} from "react-router-dom";
import {StateContext, useStateValue} from "../../app/state";
import floorplan from "../../floorplan-sketch.png"
import OneLineInput from "../../components/Patricia/OneLineInput/OneLineInput";
import {
    createPropertyWithNoUser,
} from "../../app/actions";
import enLang from "../../localization/patricia-en-us";
import heLang from "../../localization/patricia-he-il";
import PatriciaProgressBar from "../../components/Patricia/RegistrationProgressBar/PatriciaProgressBar";
import DropZone from "../../components/DropZone/DropZone";
import Cookies from 'universal-cookie';
import settings from "../../app/settings";
import PatriciaTopNav from "../../components/Patricia/PatriciaNav/PatriciaTopNav";
import PatriciaFooter from "../../components/Patricia/PatriciaFooter/PatriciaFooter";
import {
    isMobile
} from "react-device-detect";

function PatriciaUploadPDF(props){
    const context = useContext(StateContext);
    const [{}, dispatch] = context;
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [errorOccurred, setErrorOccurred] = useState(false);
    const Lang = props.lang == 'en' ? enLang : heLang;
    const lang = Lang.uploadPdf;
    const dir = props.lang == 'en' ? 'ltr' : 'rtl';

    function onSubmit(e){
        e.preventDefault()
        if (errorOccurred) {
            setErrorOccurred(null)
        }
        let formData = new FormData(e.target)
        formData.append("door_width", "100");
        formData.append("ceiling_height", "285");
        formData.delete('floorplan')
        formData.append("floorplan", file[0]);
        if (!formData.get('name')){
            formData.delete('name')
        }
        window.analytics.track("[uploadpdf] 1st next clicked", {
            location: "uploadpdf",
            unit_name: formData.get('name')
        });
        if(formData.get("floorplan").size === 0){
            setErrorOccurred(lang.errors.noFloorplan);
            return ;
        }
        setLoading(true);
        createPropertyWithNoUser({ payload: formData }, (res) => {
            dispatch({
                type: "setProperty",
                data: res.data.id
            })
            const cookies = new Cookies();
            cookies.set('tourId', res.data.tour.id);
            setLoading(false);
            props.history.push(`/takeInspirationQuiz?tour=${res.data.tour.id}&lang=${props.lang}`)
        }, (err) => {
            setErrorOccurred(lang.errors.errorOccured);
            setLoading(false)
        }).then(() => {
        });

    }

    function onDropAccepted(files) {
        setFile(files)
        window.analytics.track('[uploadpdf] file uploaded', {
            location: "uploadpdf"
        });
    }

    function onMoveToLogInClicked() {
        window.analytics.track('[uploadpdf] switch to log in clicked', {
            location: "uploadpdf"
        });
    }
    return (
        <div className='uk-background-muted uk-animation-fade patricia-hebrew-font uk-height-viewport' uk-height-viewport="" dir={dir}>
            <PatriciaTopNav logo={settings.appLogo} dir={dir}/>
            <div className='uk-flex'>
                {!isMobile &&
                <div className='uk-width-1-2@m uk-width-1-1@s'>
                    <div className="uk-background-cover uk-height-1-1 uk-panel uk-flex uk-flex-center uk-flex-middle" uk-img="" data-src={floorplan}  style={{ maxWidth: "100%", borderRadius: "30px"}}/>
                </div>}
                <div className='uk-height-1-1 uk-width-1-2@l uk-width-1-1@s uk-text-center uk-margin-small-top'>
                    <div>{lang.alreadyHaveAnAccount} <Link to={'/login'} style={{ color: "blue" }} onClick={onMoveToLogInClicked}>{lang.login}</Link></div>
                    <h1 className="uk-text-center uk-padding-small patricia-hebrew-header-font">{lang.header}</h1>
                    {!isMobile && <PatriciaProgressBar current={1} headrs={Lang.registrationProgressBar}/>}
                    <form onSubmit={onSubmit} className='uk-width-auto uk-margin-auto' style={{width: "80%"}}>
                        <DropZone fieldName="floorplan" className="uk-margin-medium-bottom" required={false} onDropAccepted={(files) => onDropAccepted(files)}>
                            <p><i className="fas fa-download" style={{fontSize: "50px"}}></i>
                                <div className='uk-margin-medium-top'>{lang.fields.uploadFloorPlan}</div></p>
                        </DropZone>
                        <OneLineInput name="name" type="text" label={lang.fields.aptAddress} maxlength="20"/>
                        <div className='uk-align-center uk-margin-large-top'>
                            {errorOccurred && <div className='uk-text-alert uk-margin-small-bottom'>{errorOccurred}</div>}
                            <button className='uk-button uk-text-center uk-width-1-2 reg-next-button'>{lang.buttons.next}
                                {loading && (
                                    <span
                                        className="uk-margin-small-left uk-animation-fade"
                                        uk-spinner="ratio: .7"
                                    />
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <PatriciaFooter origin='uploadpdf' dir={dir}/>
        </div>
    );
}
export default withRouter(PatriciaUploadPDF);