import React, {useContext, useEffect, useState} from "react";
import PageLayout from "../../components/Patricia/PageLayout/PageLayout";
import {Button} from "../../components/Button/Button";
import {BrowserView, isMobile, MobileView} from "react-device-detect";
import {Input} from "../../components/Input/Input";
import ProductModal from "../../components/Patricia/ProductModal/ProductModal";
import Modal from "../../components/Modal/Modal";
import {StateContext} from "../../app/state";
import {patriciaAiGetUsersOrdersHistory} from "../../app/actions";
import ScrollableShoppingCart from "../../components/Patricia/ScrollableShoppingCart/ScrollableShoppingCart";
import UiKit from "uikit";
import OrderModal from "../../components/Patricia/OrderModal/OrderModal";

function OrdersPage(props){
    const context = useContext(StateContext);
    const [
        {
            localization: { patricia: lang },
            auth: { user },
        },
        dispatch
    ] = context;

    const [orders, setOrders] = useState(null);
    const [currentOrder, setCurrentOrder] = useState(null);

    const orderDetailsId = 'order-details-id';
    const dir = user && user.locality === "he-il" ? "rtl" : "ltr";

    useEffect(() => {
        if (user) {
            patriciaAiGetUsersOrdersHistory({id: user.id}, (res) => {
                setOrders(res.data)
            })
        }
    }, [user]);

    function onGoToMyHomeLink(e){
        e.preventDefault();
        props.history.push(`/properties/${user.consumer.properties[0].id}?relation[]=consumer&relation[]=project&relation[]=media&retailer`);
    }

    function onCloseProductModal(e) {
        e.preventDefault();
        e.stopPropagation()
        UiKit.modal(`#${orderDetailsId}`).hide();
    }

    function onOrderDetailsClicked(e, order) {
        e.preventDefault();
        let currentOrder = {...order};
        currentOrder.address = JSON.parse(order.address);

        setCurrentOrder(currentOrder)
        UiKit.modal(`#${orderDetailsId}`).show();
    }
    return(
        <PageLayout>
            <div className='uk-flex uk-padding-large uk-padding-remove-bottom uk-flex-between'>
                <h1 className="">{lang.orders.header}</h1>
            </div>
            {!orders ?
                <div className='uk-text-center'>
                    <div className='uk-margin-medium-bottom'>{lang.orders.noOrdersYet}</div>
                    <button className="uk-button uk-button-small uk-width-small reg-next-button" onClick={onGoToMyHomeLink}>{lang.orders.actions.myHome}</button>
                </div>
                :
                <table className="uk-table uk-table-divider uk-margin-auto">
                    <BrowserView>
                        <thead className='uk-text-center'>
                        <tr>
                            <th className="uk-table-expand uk-text-center patricia-ai-text-color" style={{fontWeight: "bold"}}>{lang.orders.headers.orderNumber}</th>
                            <th className="uk-table-expand uk-text-center patricia-ai-text-color" style={{fontWeight: "bold"}}>{lang.orders.headers.date}</th>
                            <th className="uk-table-expand uk-text-center patricia-ai-text-color" style={{fontWeight: "bold"}}>{lang.orders.headers.orderTotal}</th>
                            <th className="uk-table-expand uk-text-center patricia-ai-text-color" style={{fontWeight: "bold"}}>{lang.orders.headers.selectedDelivery}</th>
                            <th className="uk-table-expand uk-text-center patricia-ai-text-color" style={{fontWeight: "bold"}}></th>
                        </tr>
                        </thead>
                        <tbody className="uk-height-large uk-text-center uk-width-1-1 uk-text-center">
                        {orders && orders.map(order => (
                            <tr key={order.id} className='uk-child-width uk-box-shadow-hover uk-width-1-1'>
                                <td className='uk-width-1-5 uk-text-small'>{order.order_number}</td>
                                <td className='uk-width-1-5 uk-text-small' style={{verticalAlign: "middle"}}>{order.order_placed_date}</td>
                                <td className='uk-width-1-5 uk-text-small' style={{verticalAlign: "middle"}}>{order.total_price.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} &#8362;</td>
                                <td className='uk-width-1-5 uk-text-small' style={{verticalAlign: "middle"}}>
                                    {order.shipping_chosen_date || order.shipping_time}
                                </td>
                                <td className='uk-width-1-5 uk-text-small' style={{verticalAlign: "middle"}}>
                                    <a className="uk-margin-small-left patricia-ai-text-color" onClick={(e) => onOrderDetailsClicked(e, order)}>{lang.orders.actions.orderDetails}</a>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </BrowserView>
                    <MobileView>
                        <tbody className="uk-height-large">
                        {orders && orders.map(order => (
                            <div className='uk-padding-large uk-padding-remove-bottom uk-padding-remove-top'>
                                <div className="uk-margin-bottom uk-grid-medium" uk-grid="">
                                    <div className='uk-width-1-1 uk-padding-remove-left uk-flex uk-flex-column uk-flex-center'>
                                        <div className='uk-text-small uk-margin-small-bottom' style={{paddingRight: "30px"}}>
                                            <span className='uk-text-bold'>{lang.orders.headers.orderNumber}: </span>
                                            {order.order_number}
                                        </div>
                                        <div className='uk-text-small uk-margin-small-bottom' style={{paddingRight: "30px"}}>
                                            <span className='uk-text-bold'>{lang.orders.headers.date}: </span> {order.order_placed_date}
                                        </div>
                                        <div className='uk-text-small uk-margin-small-bottom' style={{paddingRight: "30px"}} >
                                            <span className='uk-text-bold'>{lang.orders.headers.orderTotal}: </span> {order.total_price.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} &#8362;
                                        </div>
                                        <div className='uk-text-small uk-margin-small-bottom' style={{paddingRight: "30px"}}>
                                            <span className='uk-text-bold'>{lang.orders.headers.selectedDelivery}: </span>  {order.shipping_chosen_date || order.shipping_time}
                                        </div>
                                        <div className='uk-text-small uk-margin-small-bottom' style={{paddingRight: "30px"}}>
                                            <a className="uk-margin-small-left patricia-ai-text-color" onClick={(e) => onOrderDetailsClicked(e, order)}>{lang.orders.actions.orderDetails}</a>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                            </div>
                        ))}
                        </tbody>
                    </MobileView>
                    <OrderModal dir={dir} order={currentOrder} lang={lang}/>
                </table>
            }

        </PageLayout>
    );
}
export default OrdersPage;