import React from "react";

function ScrollableShoppingCart(props){

    return (
        <div className='uk-flex uk-flex-center'>
            <div>
                <table className="uk-table uk-table-divider uk-text-center">
                    <thead className='uk-display-block'>
                    <tr>
                        <th className="uk-table-expand uk-text-center">{props.lang.shoppingCart.headers.product}</th>
                        <th className="uk-table-expand uk-text-center">{props.lang.shoppingCart.headers.name}</th>
                        <th className="uk-table-expand uk-text-center">{props.lang.shoppingCart.headers.quantity}</th>
                        <th className="uk-table-expand uk-text-center">{props.lang.shoppingCart.headers.price}</th>
                    </tr>
                    </thead>
                    <tbody className="uk-height-large uk-overflow-auto uk-display-block">
                    {props.shoppingCart && props.shoppingCart.products.map(product => (
                        <tr>
                            <td className='uk-width-1-4'>
                                <img data-src={(product.item.thumbnail && product.item.thumbnail.thumb_url) || product.item.thumbnail.media_url ||""} height="100" width="100"
                                     alt="" uk-img="" className='uk-flex-around uk-background-center viz-card-image'>
                                </img>
                            </td>
                            <td className='uk-width-1-4 uk-text-small' style={{verticalAlign: "middle"}}>{product.name}</td>
                            <td className='uk-width-1-4 uk-text-small' style={{verticalAlign: "middle"}}>{product.amount}</td>
                            <td className='uk-width-1-4 uk-text-small' style={{verticalAlign: "middle"}}>{product.calculated_price.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} &#8362;</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <div className={`uk-margin-medium-top ${props.dir === 'rtl' ? 'uk-align-left uk-margin-medium-left' : 'uk-align-right uk-margin-medium-right'}`}>
                    <div className='uk-margin-small-bottom'><span className='uk-text-bold'>{props.lang.shoppingCart.summary.total}:</span> {(props.shoppingCart && props.shoppingCart.total_price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} &#8362;</div>
                </div>
            </div>
        </div>
    )
}

export default ScrollableShoppingCart;